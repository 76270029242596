import React from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Topbar from './Topbar';
import Navbar from './Navbar';
import BottomNavbar from './BottomNavbar';
import Footer from '../Comp/Footer/Footer';
import LogoutLoader from './Logout/LogoutLoader';
import LogoutErrorModal from './Logout/LogoutErrorModal';
import { handleLogout } from '../../redux/slices/logoutSlice'
import '../../Styles/MainStyles/Main.css';


function Main() {
    const dispatch = useDispatch()
    const isLoggingOut = useSelector((state) => state.logout.loading ? state.logout.loading : '');
    const logoutError = useSelector((state) => state.logout.error ? state.logout.error : '');

    const tryAgain = () => {
        dispatch(handleLogout())
    }

    return (
        <div className="main">
            <div className="main-container">
                <div className="main-header">
                    <Topbar />
                    <Navbar />
                </div>
                <div className="main-content">
                    <Outlet />
                </div>
                <div className="main-footer">
                    <Footer />
                </div>
                <div className="main-bottom-navbar">
                    <BottomNavbar />
                </div>
            </div>
            {isLoggingOut && <LogoutLoader />}
            {logoutError && 
                <LogoutErrorModal
                    logoutError={logoutError}
                    tryAgain={tryAgain}
                />
            }
        </div>
    );
};

export default Main;