import React from 'react';
import { BsEmojiFrown } from 'react-icons/bs';
import '../../Styles/CompStyles/ErrorPages/Error404Page.css';

function Error404Page() {
    return (
        <>
            <div className="error404">
                <div className="error404__page-container">
                    <div className="header">
                        <BsEmojiFrown className="error404-icon" />
                        <p>Page Not Found</p>
                    </div>
                    <div className="body">
                        <p>Oops! We couldn't find the page that you're looking for.</p>
                        <p>Please check the address and try again.</p>
                    </div>
                    <div className="footer">
                        <p>Error Code:</p>
                        <p>404</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Error404Page;