import React from 'react';
import '../../../../Styles/MainStyles/Settings/LoginSecurity/LoginSecurity.css';

function LoginSecurity({ 
    settingsData, 
    isLoadingSettingPage,
    showChangeEmailModal, 
    changeEmailModalOpen, 
    isUpdatingPhone,
    updatedPhone,
    handleUpdatingPhone,
    cancelUpdatePhone,
    saveUpdatePhone,
    isSavingPhone,
    isAddingPhone,
    addedPhone,
    handleAddingPhone,
    cancelAddPhone,
    saveAddPhone,
    addPhone,
    updatePhone,
    displayChangePasswordModal,
    showChangePasswordModal,
    phoneNumberError,
    phoneNumberErrorMsg,
    phoneNumberErrorCode,
    handlePhoneNumberOKButton,
    displayComingSoon,
    displayComingSoonCSID,
    showComingSoonModal,
    showComingSoonCSIDModal
}) {
    return (
        <>
            <div className="login-security">
                <div className="login-security__page-container">
                    <div className="login-security__title-wrapper">
                        <p className={`title ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-title' : ''}`}>
                            {isLoadingSettingPage && 'Security and Login'}
                        </p>
                        <p className={`caption ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-caption' : ''}`}>
                            {isLoadingSettingPage && 
                                'Manage login credentials and security settings for robust protection.'
                            }
                        </p>
                    </div>

                    {/* EMAIL ADDRESS SECTION */}
                    <div className="login-security__content-wrapper">
                        <div className="login-security__content-item">
                            <div className="login-security__content-label">
                                <p className={`email-address-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Email Address'}
                                </p>
                            </div>
                            <div className="login-security__content-value">
                                <p className={`email-address-value ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                    {isLoadingSettingPage ? settingsData.email : ''}
                                </p>
                            </div>
                            <div className="login-security__content-action">
                                <button 
                                    className={`email-address-action-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''} ${changeEmailModalOpen ? 'active-button' : ''}`}
                                    onClick={isLoadingSettingPage ? showChangeEmailModal : undefined}
                                >
                                    {isLoadingSettingPage && 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* PHONE NUMBER SECTION */}
                    <div className="login-security__content-wrapper">
                        <div className="login-security__content-item">
                            <div className="login-security__content-label">
                                <p className={`phone-number-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Phone Number'}
                                </p>
                            </div>
                            <div className="login-security__content-value">
                                {phoneNumberError ? (
                                    <div className="phone-number-error-wrapper">
                                        <p className="phone-number-error-message">
                                            {phoneNumberErrorMsg} ({phoneNumberErrorCode})
                                        </p>
                                        <button 
                                            type="button"
                                            onClick={handlePhoneNumberOKButton}
                                        >
                                            OKAY
                                        </button>
                                    </div>                    
                                ) : (
                                    <>
                                        {isUpdatingPhone 
                                            ?
                                                <div className="phone-input-wrapper">
                                                    <input
                                                        type="text"
                                                        className="phone-input"
                                                        value={updatedPhone}
                                                        onChange={handleUpdatingPhone}
                                                        placeholder='Enter phone number'
                                                        maxLength="11"
                                                    />
                                                    <span className="buttons-wrapper">
                                                        <button 
                                                            type="button" 
                                                            className="cancel-phone-btn"
                                                            onClick={cancelUpdatePhone}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button 
                                                            type="button"
                                                            className={`save-phone-btn ${!updatedPhone.trim() ? "disabled" : ""}`} 
                                                            onClick={saveUpdatePhone} 
                                                            disabled={!updatedPhone.trim()}
                                                        >
                                                            Save
                                                        </button>
                                                    </span>                                 
                                                </div>   
                                            :
                                                isSavingPhone
                                                    ?
                                                        <p className="save-phone-number-loader">Saving changes . . .</p>
                                                    :
                                                        (settingsData.phone_number 
                                                            ? 
                                                                <p className={`phone-number-withValue ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                                                    {isLoadingSettingPage ? settingsData.phone_number : ''}
                                                                </p>
                                                            : 
                                                                isAddingPhone
                                                                    ?
                                                                        <div className="phone-input-wrapper">
                                                                            <input
                                                                                type="text"
                                                                                className="phone-input"
                                                                                value={addedPhone}
                                                                                onChange={handleAddingPhone}
                                                                                maxLength="11"
                                                                                inputMode="numeric"
                                                                                onKeyDown={(e) => {
                                                                                    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
                                                                                    if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <span className="buttons-wrapper">
                                                                                <button 
                                                                                    type="button" 
                                                                                    className="cancel-phone-btn"
                                                                                    onClick={cancelAddPhone}
                                                                                >
                                                                                    Cancel
                                                                                </button>
                                                                                <button 
                                                                                    type="button"
                                                                                    className={`save-phone-btn ${!addedPhone.trim() ? "disabled" : ""}`} 
                                                                                    onClick={saveAddPhone} 
                                                                                    disabled={!addedPhone.trim()}
                                                                                >
                                                                                    Save
                                                                                </button>
                                                                            </span>                                 
                                                                        </div>  
                                                                :
                                                                    <p className={`phone-number-noValue ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                                                        {isLoadingSettingPage && 'You have not added a phone number to your account'}
                                                                    </p>
                                                        )
                                        } 
                                    </> 
                                )}                                    
                            </div>
                            <div className="login-security__content-action">
                                {phoneNumberError ? null :
                                    (isSavingPhone ? null :
                                        (isAddingPhone ? null :
                                            (isUpdatingPhone ? null :
                                                <button 
                                                    className={`phone-number-action-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''}`}
                                                    onClick={isLoadingSettingPage ? (settingsData.phone_number ? updatePhone : addPhone) : undefined}
                                                >
                                                    {isLoadingSettingPage ? (settingsData.phone_number ? 'Update' : 'Add') : ''}
                                                </button>
                                            )
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    {/* PASSWORD SECTION */}
                    <div className="login-security__content-wrapper">
                        <div className="login-security__content-item">
                            <div className="login-security__content-label">
                                <p className={`password-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Password'}
                                </p>
                            </div>
                            <div className="login-security__content-value">
                                <p className={`password-value ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                    {isLoadingSettingPage && '••••••••••••'}
                                </p>
                            </div>
                            <div className="login-security__content-action">
                                <button 
                                    className={`password-action-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''} ${showChangePasswordModal ? 'active-button' : ''}`}
                                    onClick={isLoadingSettingPage ? displayChangePasswordModal : undefined}
                                >
                                    {isLoadingSettingPage && 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* SECURITY QUESTIONS SECTION */}
                    <div className="login-security__content-wrapper">
                        <div className="login-security__content-item">
                            <div className="login-security__content-label">
                                <p className={`security-questions-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Security Questions'}
                                </p>
                            </div>
                            <div className="login-security__content-value">
                                {settingsData.security_q1 && settingsData.security_q2 
                                    ? 
                                        <div>
                                            <p className={`security-questions-withValue ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                                {isLoadingSettingPage ? settingsData.security_q1 : ''}
                                            </p>
                                            <p className={`security-questions-withValue ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                                {isLoadingSettingPage ? settingsData.security_q2 : ''}
                                            </p>
                                        </div>
                                    : 
                                        <p className={`security-questions-noValue ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                            {isLoadingSettingPage && 'Setup your security questions'}
                                        </p>
                                }
                            </div>
                            <div className="login-security__content-action">
                                <button 
                                    className={`security-questions-action-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''} ${showComingSoonModal ? 'active-button' : ''}`}
                                    onClick={isLoadingSettingPage ? displayComingSoon : undefined}
                                >
                                    {isLoadingSettingPage ? (settingsData.security_q1 && settingsData.security_q2 ? 'Update' : 'Setup') : ''}
                                </button>  
                            </div>
                        </div>
                    </div>
                    <div className="login-security__content-wrapper">
                        <div className="login-security__content-item">
                            <div className="login-security__content-label">
                                <p className={`cs-id-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Customer Service ID'}
                                </p>
                            </div>
                            <div className="login-security__content-value">
                                <p className={`cs-id-value ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                    {isLoadingSettingPage && 'When you call Customer Service, we’ll ask you to confirm your identity using this account information.'}
                                </p>
                            </div>
                            <div className="login-security__content-action">
                                <button 
                                    className={`cs-id-action-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''} ${showComingSoonCSIDModal ? 'active-button' : ''}`}
                                    onClick={isLoadingSettingPage ? displayComingSoonCSID : undefined}
                                >
                                    {isLoadingSettingPage && 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginSecurity

