import React from 'react';
import { Outlet, Navigate  } from 'react-router-dom';
import { useSelector } from 'react-redux';

function WNLayout ({ children }) {
  const isLoggedIn = useSelector((state) => state.auth.user ? state.auth.user.is_login : '');

  return (
    <div>
      {isLoggedIn ? (
        <>
          <Outlet />
          {children}
        </>
      ) : (
        <Navigate to="/signin" />
      )}
    </div>
  );
};

export default WNLayout;