import React from 'react';
import '../../../../Styles/MainStyles/Settings/LoginSecurity/ComingSoon.css';

function ComingSoonCSID({hideComingSoonCSID}) {
    return (
        <>
            <div className="coming-soon">
                <div className="coming-soon__page-container">
                    <div className="coming-soon__text-wrapper">
                        <p className="coming-soon__text-wrapper">
                            Stay tuned — this feature is launching soon..
                        </p>
                        <p className="coming-soon__button"type="button" onClick={hideComingSoonCSID}>OKAY</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ComingSoonCSID
