import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import Cookies from 'js-cookie';

const FETCH_SETTINGS_DATA_ENDPOINT = '/apps/fetch_settings_data/'
const UPDATE_INVITED_USER_ENDPOINT = '/apps/update_invited_user/'

const isOlderThan15Minutes = (timestamp) => {
     if (!timestamp) return true;
          const now = new Date();
          const lastFetched = new Date(timestamp);
          const diff = (now - lastFetched) / 1000 / 60; // difference in minutes
          return diff > 15;
};

export const fetchSettingsData = createAsyncThunk(
     'settings/Data',
     async (_, { getState, rejectWithValue }) => {
          const state = getState();
          const lastFetchedTime = state.settings.lastFetchedTime;
          const merchantProfile = state.merchant.profile;
          const merchant_id = merchantProfile ? merchantProfile.merchant_id : '';

          if (!isOlderThan15Minutes(lastFetchedTime)) {
               //Data is up to date, do nothing
               return null;
          }

          try {
               const res = await axios.post(
                    FETCH_SETTINGS_DATA_ENDPOINT,
                    JSON.stringify({ merchant_id: merchant_id }),
                    {
                         headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'X-CSRFToken' : Cookies.get('csrftoken')
                         }
                    }
               );  
               if (res.data.success) {
                    return { 
                         settingsData: res.data.settings_data, 
                         bankData: res.data.bank_data,
                         users: res.data.users,
                         loggedInUserPerm: res.data.loggedInUserPermissions, 
                         lastFetchedTime: new Date().toISOString() 
                    }
               } else {
                    console.error('Invalid data received', res.data);
                    return rejectWithValue({
                         message: "Oops! We encountered an error.",
                         action: "Please try again or contact support.",
                         code: "ERR_FINDUKU_1000"
                    });
               }
          } catch (error) {
               console.error('Failed to fetch payments list', error);
               let errorMsg, errorAction, errorCode;
               if (error.response) {
                    switch (error.response.status) {
                         case 400:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_3000";
                              break;
                         case 401:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Please login your account.";
                              errorCode = "ERR_FINDUKU_4000";
                              break;
                         case 403:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Click Okay to log in again.";
                              errorCode = "ERR_FINDUKU_5000";
                              break;
                         case 404:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_6000";
                              break;
                         case 500:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_7000";
                              break;
                         default:
                              errorMsg = "Oops! We encountered an error";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_2000";
                    }
                    return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
               } else {
                    return rejectWithValue({
                         message: "Oops! We encountered an error",
                         action: "Please try again or contact support.",
                         code: "ERR_FINDUKU_8000"
                    })
               }
          }
     }
)


export const updateInvitedUsers = createAsyncThunk(
     'settings/update_invited_user',
     async (invited_user_id, { getState, rejectWithValue }) => {
          console.log(invited_user_id)
          const state = getState();
          const merchantProfile = state.merchant.profile;
          const merchant_id = merchantProfile ? merchantProfile.merchant_id : '';

          try {
               const res = await axios.post(
                    UPDATE_INVITED_USER_ENDPOINT,
                    JSON.stringify({ 
                         merchant_id: merchant_id,
                         user_id: invited_user_id 
                    }),
                    {
                         headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'X-CSRFToken' : Cookies.get('csrftoken')
                         }
                    }
               );  
               if (res.data.success) {
                    return { 
                         updatedInvitedUser: res.data.updatedInvitedUser, 
                    }
               } else {
                    console.error('Invalid data received', res.data);
                    return rejectWithValue({
                         message: "Oops! We encountered an error.",
                         action: "Please try again or contact support.",
                         code: "ERR_FINDUKU_1000"
                    });
               }
          } catch (error) {
               console.error('Failed to fetch updated user', error);
               let errorMsg, errorAction, errorCode;
               if (error.response) {
                    switch (error.response.status) {
                         case 400:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_3000";
                              break;
                         case 401:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Please login your account.";
                              errorCode = "ERR_FINDUKU_4000";
                              break;
                         case 403:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Click Okay to log in again.";
                              errorCode = "ERR_FINDUKU_5000";
                              break;
                         case 404:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_6000";
                              break;
                         case 500:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_7000";
                              break;
                         default:
                              errorMsg = "Oops! We encountered an error";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_2000";
                    }
                    return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
               } else {
                    return rejectWithValue({
                         message: "Oops! We encountered an error",
                         action: "Please try again or contact support.",
                         code: "ERR_FINDUKU_8000"
                    })
               }
          }
     }
)

export const settingsSlice = createSlice({
     name: 'settings',
     initialState: {
          loading: false,
          error: null,
          loggedInUserPerm: [],
          lastFetchedTime: null,
          users: [],
          unauthorizedError: false,
          settingsData: {
               user_id: '',
               email: '',
               full_name: '',
               address: '',
               phone_number: '',
               profile_photo: '',
               merchant_id: '',
               store_name: '',
               store_qr: '',
               company_name: '',
               reg_date: '',
               reg_number: '',
               business_type: '',
               business_activity: '',
               industry: '',
               website: '',
               contact_number: '',
               company_address: '',
               company_logo: '',
               security_q1: '',
               security_ans1: '',
               security_q2: '',
               security_ans2: '',
               bank_code: '',
               bank_name: '',
               account_number: '',
               account_name: ''
          },
          bankData: {
               bank_code: '',
               bank_name: '',
               account_number: '',
               account_name: '',
               account_type: '',
               address_line1: '',
               address_line2: '',
               city: '',
               province: '',
               zipcode: '',
          }
     },
     reducers: {
          setProfilePhoto: (state, action) => {
               state.settingsData.profile_photo = action.payload;
          },
          setUserAddress: (state, action) => {
               state.settingsData.address = action.payload;
          },
          setPhoneNumber: (state, action) => {
               state.settingsData.phone_number = action.payload;
          },
          setCompanyLogo: (state, action) => {
               state.settingsData.company_logo = action.payload;
          },
          setStoreName: (state, action) => {
               state.settingsData.store_name = action.payload;
          },
          setContactNumber: (state, action) => {
               state.settingsData.contact_number = action.payload;
          },
          setWebsite: (state, action) => {
               state.settingsData.website = action.payload;
          },
          setBankData: (state, action) => {
               state.settingsData.bank_code = action.payload.bank_code;
               state.settingsData.bank_name = action.payload.bank_name;
               state.settingsData.account_name = action.payload.account_name;
               state.settingsData.account_number = action.payload.account_number_4digits;
               state.bankData.bank_code = action.payload.bank_code;
               state.bankData.bank_name = action.payload.bank_name;
               state.bankData.account_number = action.payload.account_number;
               state.bankData.account_name = action.payload.account_name;
               state.bankData.account_type = action.payload.account_type;
               state.bankData.address_line1 = action.payload.address_line1;
               state.bankData.address_line2 = action.payload.address_line2;
               state.bankData.city = action.payload.city;
               state.bankData.province = action.payload.province;
               state.bankData.zipcode = action.payload.zipcode;
          },
          setNewUser: (state, action) => {
               state.users = [...state.users, action.payload];
          },
          updateUserPermissions: (state, action) => {
               state.users = state.users.map(user =>
                   user.user_id === action.payload.userID
                       ? { ...user, permissions: action.payload.permissions }
                       : user
               );
          },
          removeUser: (state, action) => {
               state.users = state.users.filter(user => user.user_id !== action.payload);
          },
          toggleUnauthorizedError: (state, action) => {
               state.unauthorizedError = action.payload;
          },
          reset_settings: (state) => {
               state.loading = false;
               state.error = null;
               state.loggedInUserPerm = [];
               state.lastFetchedTime = null;
               state.users = [];
               state.unauthorizedError = false;
               state.settingsData = {
                    user_id: '',
                    email: '',
                    full_name: '',
                    address: '',
                    phone_number: '',
                    profile_photo: '',
                    merchant_id: '',
                    store_name: '',
                    store_qr: '',
                    company_name: '',
                    reg_date: '',
                    reg_number: '',
                    business_type: '',
                    business_activity: '',
                    industry: '',
                    website: '',
                    contact_number: '',
                    company_address: '',
                    company_logo: '',
                    security_q1: '',
                    security_ans1: '',
                    security_q2: '',
                    security_ans2: '',
                    bank_code: '',
                    bank_name: '',
                    account_number: '',
                    account_name: ''
               };
               state.bankData = {
                    bank_code: '',
                    bank_name: '',
                    account_number: '',
                    account_name: '',
                    account_type: '',
                    address_line1: '',
                    address_line2: '',
                    city: '',
                    province: '',
                    zipcode: '',
               };
          }
     },
     extraReducers: (builder) => {
          builder
               .addCase(fetchSettingsData.pending, (state) => {
                    state.loading = false;
                    state.error = null;
               })
               .addCase(fetchSettingsData.fulfilled, (state, action) => {
                    if (action.payload) {
                         const { settingsData, bankData, users, loggedInUserPerm, lastFetchedTime } = action.payload;
                         state.settingsData = settingsData;
                         state.bankData = bankData;
                         state.users = users;
                         state.loggedInUserPerm = loggedInUserPerm;
                         state.lastFetchedTime = lastFetchedTime;
                    }
                    state.loading = true;
               })
               .addCase(fetchSettingsData.rejected, (state, action) => {
                    state.loading = false;
                    if (action.payload) {
                         state.error = {
                              message: action.payload.message,
                              action: action.payload.action,
                              code: action.payload.code,
                         };
                         if (action.payload.code === "ERR_FINDUKU_4000" || action.payload.code === "ERR_FINDUKU_5000") {
                              state.unauthorizedError = true;
                         }
                    } else {
                         state.error = {
                              message: "Oops! We encountered an error",
                              action: "Refresh the page or contact support.",
                              code: "ERR_FINDUKU_9000"
                         };
                    }
               })
               .addCase(updateInvitedUsers.pending, (state) => {
                    state.error = null;
               })
               .addCase(updateInvitedUsers.fulfilled, (state, action) => {
                    if (action.payload) {
                         const { updatedInvitedUser } = action.payload;
                         const userIndex = state.users.findIndex(user => user.user_id === updatedInvitedUser.user_id);
                         if (userIndex !== -1) {
                              // Merge the existing user data with the updated values
                              state.users[userIndex] = { ...state.users[userIndex], ...updatedInvitedUser };
                         } else {
                              // Add the user if not found
                              state.users.push(updatedInvitedUser);
                         }
                    }
               })
               .addCase(updateInvitedUsers.rejected, (state, action) => {
                    state.error = action.payload || {
                        message: "An unexpected error occurred",
                        action: "Please try again later.",
                        code: "ERR_FINDUKU_UNKNOWN"
                    };
               });
     }
});

export const { 
     setProfilePhoto, 
     setUserAddress, 
     setPhoneNumber, 
     setCompanyLogo, 
     setStoreName,
     setContactNumber,
     setWebsite,
     setBankData,
     setNewUser,
     updateUserPermissions,
     removeUser,
     toggleUnauthorizedError,
     reset_settings
} = settingsSlice.actions;
export default settingsSlice.reducer;