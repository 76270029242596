import React from 'react';
import { Formik, Form } from 'formik';
import { BsArrowLeft, BsExclamationCircle } from "react-icons/bs";
import * as Yup from 'yup';
import CSRFToken from '../../../components/CSRFToken';


const Step4 = ({ handleNextStep, handlePrevStep, merchantData, updateMerchantData}) => {

    //FUNCTION HANDLING VALIDATION
    const validate = Yup.object({
        address: Yup.string()
        .required('Please complete this field.')
        .max(50, 'Only 50 characters allowed.'),

        contact_number: Yup.string()
        .required('Please complete this field.')
        .matches(/^\d{9,11}$/, {message: "Phone number must be 9-11 digits.", excludeEmptyString: false}),

        entity_nature: Yup.string()
        .required('Please complete this field.')
        .max(30, 'Only 30 characters allowed.'),

        industry: Yup.string()
        .required('Please complete this field.')
        .max(30, 'Only 30 characters allowed.'),

    })
    
    return (
        <Formik
            initialValues={merchantData}
            onSubmit={handleNextStep}
            validationSchema={validate}
        >

            {( { handleChange, handleBlur, values, handleSubmit, errors, touched }) => {

                return (
                    <Form>
                        <CSRFToken />
                        <div className="onboarding__form">

                            {/* BACK BUTTON */}
                            <button className="onboarding__back-button" type="button" onClick={handlePrevStep}>
                                <BsArrowLeft className="onboarding__back-button-icon" />
                                <p className="onboarding__back-button-text">Back to Step 3</p>
                            </button>

                            {/* TITLE */}
                            <p className="onboarding__form-title">Business Information</p>

                            {/* SUBTITLE */}
                            <p className="onboarding__form-subtitle">
                                Tell us more information about your business - its products, services 
                                and location to create your business profile.
                            </p>

                            {/* BUSINESS ADDRESS */}
                            <div className="onboarding__input-wrapper">
                                <p className={`onboarding__input-label ${errors.address && touched.address ? "onboarding__input-error" : ""}`}>
                                    Business Address
                                </p>
                                <input
                                    className={`onboarding__text-input ${errors.address && touched.address ? "onboarding__input-error": null}`}
                                    type="text"
                                    name="address"
                                    onChange={(e) => {
                                        handleChange(e);
                                        updateMerchantData({ ...values, address: e.target.value });
                                    }}
                                    onBlur={handleBlur}
                                    value={values.address}
                                    autoComplete="off"
                                    maxLength={51}
                                />
                                {errors.address && touched.address && (
                                    <p className="onboarding__input-error-message"> 
                                        <BsExclamationCircle className="onboarding__input-error-icon" />
                                        {errors.address}
                                    </p>
                                )}
                            </div>
                            
                            {/* PHONE NUMBER INPUT */}
                            <div className="onboarding__input-wrapper">
                                <p className={`onboarding__input-label ${errors.contact_number && touched.contact_number ? "onboarding__input-error" : ""}`}>
                                    Contact Number
                                </p>
                                <input
                                    className={`onboarding__text-input ${errors.contact_number && touched.contact_number ? "onboarding__input-error" : null}`}
                                    type="text"
                                    name="contact_number"
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/\D/g, ''); // filter out non-numeric characters
                                        handleChange({ target: { name: 'contact_number', value: value } });
                                        updateMerchantData({ ...values, contact_number: e.target.value });
                                    }}
                                    onBlur={handleBlur}
                                    value={values.contact_number}
                                    autoComplete="off"
                                    required
                                    maxLength="11"
                                    inputMode="numeric"
                                />
                                {errors.contact_number && touched.contact_number && (
                                    <p className="onboarding__input-error-message"> 
                                        <BsExclamationCircle className="onboarding__input-error-icon" />
                                        {errors.contact_number}
                                    </p>
                                )}
                            </div>

                            {/* BUSINESS NATURE */}               
                            <div className="onboarding__input-wrapper">
                                <p className={`onboarding__input-label ${errors.entity_nature && touched.entity_nature ? "onboarding__input-error" : ""}`}>
                                    Nature of Business
                                </p>
                                <input
                                    className={`onboarding__text-input ${errors.entity_nature && touched.entity_nature ? "onboarding__input-error": null}`}
                                    type="text"
                                    name="entity_nature"
                                    onChange={(e) => {
                                        handleChange(e);
                                        updateMerchantData({ ...values, entity_nature: e.target.value });
                                    }}
                                    onBlur={handleBlur}
                                    value={values.entity_nature}
                                    autoComplete="off"
                                    maxLength={31}
                                    required
                                />
                                {errors.entity_nature && touched.entity_nature && (
                                    <p className="onboarding__input-error-message"> 
                                        <BsExclamationCircle className="onboarding__input-error-icon" />
                                        {errors.entity_nature}
                                    </p>
                                )}
                            </div>

                            {/* BUSINESS INDUSTRY */}
                            <div className="onboarding__input-wrapper">
                                <p className={`onboarding__input-label ${errors.industry && touched.industry ? "onboarding__input-error" : ""}`}>
                                    Industry
                                </p>
                                <input
                                    className={`onboarding__text-input ${errors.industry && touched.industry ? "onboarding__input-error": null}`}
                                    type="text"
                                    name="industry"
                                    onChange={(e) => {
                                        handleChange(e);
                                        updateMerchantData({ ...values, industry: e.target.value });
                                    }}
                                    onBlur={handleBlur}
                                    value={values.industry}
                                    autoComplete="off"
                                    maxLength={31}
                                />
                                {errors.industry && touched.industry && (
                                    <p className="onboarding__input-error-message"> 
                                        <BsExclamationCircle className="onboarding__input-error-icon" />
                                        {errors.industry}
                                    </p>
                                )}
                            </div>
                            
                            {/* NEXT/CONTINUE BUTTON */}
                            <div className="onboarding__button-wrapper">
                                <button 
                                    type="submit" 
                                    className={`onboarding__button ${values.address && values.entity_nature && values.industry && values.contact_number && (values.contact_number.length >= 9 && values.contact_number.length <= 11) ? "" : "disabled"}`}
                                    disabled={!values.address || !values.entity_nature || !values.industry || !values.contact_number || (values.contact_number.length <9 || values.contact_number.length > 11)}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    );
};

export default Step4;