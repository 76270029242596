import React from 'react';
import SyncLoader from "react-spinners/SyncLoader";
import '../../../../Styles/MainStyles/Settings/ManageUsers/ManageUsersLoader.css';

function ManageUsersLoader() {
     return (
          <div className="manageUsersLoader">
               <SyncLoader
                    size={13}
                    margin={5}
                    color="#294254" 
                    loading="true"
                    speedMultiplier={0.50} 
                    aria-label="Loading Spinner" 
                    data-testid="loader"
               />                
          </div>
     )
}

export default ManageUsersLoader;