import React, { useState, useEffect, PureComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from '../../../api/axios';
import MUITooltip from '@mui/material/Tooltip';
import { useDispatch } from 'react-redux';
import { fetchHomeData } from '../../../redux/slices/homeSlice';
import { fetchGrossVolumeData, fetchNetVolumeData, fetchFeeSummaryData } from '../../../redux/slices/insightsSlice';
import { fetchSettingsData } from '../../../redux/slices/settingsSlice';
import { useSelector } from 'react-redux';
import { Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { 
    LineChart, 
    Line, 
    BarChart, 
    Bar, 
    XAxis, 
    CartesianGrid, 
    Tooltip, 
    ResponsiveContainer
} from 'recharts';
import { 
    BsQrCodeScan, 
    BsLink45Deg,
    BsSendCheck,
    BsBoxArrowRight,
    BsUiChecks,
    BsInfoCircle,
    BsEmojiFrown
} from "react-icons/bs";
import SyncLoader from "react-spinners/SyncLoader";
import ComingSoon from './ComingSoon';
import NoBankMsg from './NoBankMsg';
import CreatePayout from '../Transactions/Payouts/CreatePayout';
import ViewQR from '../Settings/BusinessProfile/ViewQR';
import NoPermMsg from './NoPermMsg';
import '../../../Styles/MainStyles/Home/Home.css';

// API ENDPOINTS
const FETCH_QR_DATA_ENDPOINT = '/apps/fetch_settings_data/'

const CustomDateSelect = styled(Select)({
    width: '100%',   
    height: '35px',    
    '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        borderWidth: '1px',
        borderColor: 'gray', // default border color
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#57768D', // border color when focused
        borderRadius: 0,
        borderWidth: '1px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#57768D', // border color when hovered
        borderRadius: 0,
    },
    '& .MuiSelect-select': { 
        fontFamily: "'PP Neue Montreal Variable', sans-serif",  
        fontSize: '16px',       
    },
});

const CustomMenuItem = styled(MenuItem)({
    fontFamily: "'PP Neue Montreal Variable', sans-serif", 
    fontSize: '16px',  
});

const BalanceTooltip = styled(({ className, ...props }) => (
    <MUITooltip classes={{ popper: className }} {...props} />
))({
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'white',  
        maxWidth: 480, 
        height: 38,          
        color: 'black',
        fontFamily: 'Figtree',
        fontSize: '14px',
        boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.25)', 
        padding: '10px',
    },
    '& .MuiTooltip-arrow': {
        color: 'white',  // Change this to your preferred color
    },
});


function Home() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const first_name = useSelector((state) => state.auth.user ? state.auth.user.first_name : '');
    const merchant_id = useSelector((state) => state.merchant.profile ? state.merchant.profile.merchant_id : '');
    const bankData = useSelector((state) => state.settings.bankData ? state.settings.bankData : '');
    const balanceData = useSelector((state) => state.home.balanceData ? state.home.balanceData : '');
    const dailyPayments = useSelector((state) => state.home.dailyPayments ? state.home.dailyPayments : '');
    const recentPayments = useSelector((state) => state.home.recentPayments ? state.home.recentPayments : '');
    const homeError = useSelector((state) => state.home.error ? state.home.error : '');
    const homeLoading = useSelector((state) => state.home.loading ? state.home.loading : '');

    const grossVolume = useSelector((state) => state.insights.grossVolumeData ? state.insights.grossVolumeData.grossVolume : '');
    const grossVolumeError = useSelector((state) => state.insights.grossVolumeData ? state.insights.grossVolumeData.error : '');
    const grossVolumeLoading = useSelector((state) => state.insights.grossVolumeData ? state.insights.grossVolumeData.loading : '');

    const netVolume = useSelector((state) => state.insights.netVolumeData ? state.insights.netVolumeData.netVolume : '');
    const netVolumeError = useSelector((state) => state.insights.netVolumeData ? state.insights.netVolumeData.error : '');
    const netVolumeLoading = useSelector((state) => state.insights.netVolumeData ? state.insights.netVolumeData.loading : '');

    const feeSummary = useSelector((state) => state.insights.feeSummaryData ? state.insights.feeSummaryData.feeSummary : '');
    const feeSummaryError = useSelector((state) => state.insights.feeSummaryData ? state.insights.feeSummaryData.error : '');
    const feeSummaryLoading = useSelector((state) => state.insights.feeSummaryData ? state.insights.feeSummaryData.loading : '');
    
    const loggedInUserPerm = useSelector((state) => state.settings.loggedInUserPerm);

    const [selectedInterval, setSelectedInterval] = useState('Today');

    useEffect(() => {
        fetchQRValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(fetchSettingsData());
        dispatch(fetchHomeData());
        dispatch(fetchGrossVolumeData());
        dispatch(fetchNetVolumeData());
        dispatch(fetchFeeSummaryData());
    }, [dispatch])

    const navToPayments = () => {
        navigate(`/transactions`);
    };

    // START OF LOGIC HANDLING FETCHING PAYMENT GROSS VOLUME
    const [totalGrossPayments, setTotalGrossPayments] = useState(0);

    useEffect(() => {
        const intervalKey = selectedInterval.toLowerCase().replace(' ', '_');
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth(); // 0-11 for January-December
        const todayFormatted = currentDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short' }); // "Nov 10"
    
        // Function to get the start (Sunday) of the current week
        const getStartOfWeek = (date) => {
            const day = date.getDay();
            const diff = date.getDate() - day;
            return new Date(date.setDate(diff));
        };
    
        const startOfWeek = getStartOfWeek(new Date());
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6); // Add 6 days to get the end of the week (Saturday)
    
        if (Array.isArray(grossVolume[intervalKey])) {
            let totalSum = 0;
    
            grossVolume[intervalKey].forEach(entry => {

                if (intervalKey === 'today' && entry.date === todayFormatted) {
                    totalSum += entry.total;
                } else if (intervalKey === 'this_week') {
                    const [weekMonth, dayRange] = entry.date.split(' ');
                    const [startDay, endDay] = dayRange.split('-').map(Number);
                    const startDate = new Date(`${weekMonth} ${startDay}, ${currentYear}`);
                    const endDate = new Date(`${weekMonth} ${endDay}, ${currentYear}`);
    
                    if ((startDate >= startOfWeek && startDate <= endOfWeek) || (endDate >= startOfWeek && endDate <= endOfWeek)) {
                        totalSum += entry.total;
                    }
                } else if (intervalKey === 'this_month' && new Date(`${entry.date} 1, ${currentYear}`).getMonth() === currentMonth) {
                    totalSum += entry.total;
                } else if (intervalKey === 'this_year') {
                    const entryYear = parseInt(entry.date, 10);
                    if (entryYear === currentYear) {
                        totalSum += entry.total;
                    }
                }
            });
            setTotalGrossPayments(totalSum);
        }
    }, [selectedInterval, grossVolume]);

    class CustomizedGrossVolumeLabel extends PureComponent {
        render() {
            const { x, y, value } = this.props;
      
            // Format the value
            const formattedValue = `₱${value.toLocaleString('en-PH', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`;
            return (
                <text x={x} y={y} dy={-7} fill="#54585c" fontSize={13} textAnchor="middle">
                    {formattedValue}
                </text>
            );
        }
    }
    // END OF LOGIC HANDLING FETCHING PAYMENT GROSS VOLUME


    // START OF LOGIC HANDLING FETCHING PAYMENT NET VOLUME
    const [totalNetPayments, setTotalNetPayments] = useState(0);

    useEffect(() => {
        const intervalKey = selectedInterval.toLowerCase().replace(' ', '_');
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth(); // 0-11 for January-December
        const todayFormatted = currentDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short' }); // "Nov 10"
    
        // Function to get the start (Sunday) of the current week
        const getStartOfWeek = (date) => {
            const day = date.getDay();
            const diff = date.getDate() - day;
            return new Date(date.setDate(diff));
        };
    
        const startOfWeek = getStartOfWeek(new Date());
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6); // Add 6 days to get the end of the week (Saturday)
    
        if (Array.isArray(netVolume[intervalKey])) {
            let totalSum = 0;
    
            netVolume[intervalKey].forEach(entry => {

                if (intervalKey === 'today' && entry.date === todayFormatted) {
                    totalSum += entry.total;
                } else if (intervalKey === 'this_week') {
                    const [weekMonth, dayRange] = entry.date.split(' ');
                    const [startDay, endDay] = dayRange.split('-').map(Number);
                    const startDate = new Date(`${weekMonth} ${startDay}, ${currentYear}`);
                    const endDate = new Date(`${weekMonth} ${endDay}, ${currentYear}`);
    
                    if ((startDate >= startOfWeek && startDate <= endOfWeek) || (endDate >= startOfWeek && endDate <= endOfWeek)) {
                        totalSum += entry.total;
                    }
                } else if (intervalKey === 'this_month' && new Date(`${entry.date} 1, ${currentYear}`).getMonth() === currentMonth) {
                    totalSum += entry.total;
                } else if (intervalKey === 'this_year') {
                    const entryYear = parseInt(entry.date, 10);
                    if (entryYear === currentYear) {
                        totalSum += entry.total;
                    }
                }
            });
            setTotalNetPayments(totalSum);
        }
    }, [selectedInterval, netVolume]);

    class CustomizedNetVolumeLabel extends PureComponent {
        render() {
            const { x, y, value } = this.props;
      
            // Format the value
            const formattedValue = `₱${value.toLocaleString('en-PH', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`;
            
            return (
                <text x={x} y={y} dy={-7} fill="#54585c" fontSize={13} textAnchor="middle">
                    {formattedValue}
                </text>
            );
        }
    }
    // END OF LOGIC HANDLING FETCHING PAYMENT NET VOLUME

    // START OF LOGIC HANDLING FETCHING FEE SUMMARY
    const [totalFees, setTotalFees] = useState(0);
 
    useEffect(() => {
        const intervalKey = selectedInterval.toLowerCase().replace(' ', '_');
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth(); // 0-11 for January-December
        const todayFormatted = currentDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short' }); // "Nov 10"
    
        // Function to get the start (Sunday) of the current week
        const getStartOfWeek = (date) => {
            const day = date.getDay();
            const diff = date.getDate() - day;
            return new Date(date.setDate(diff));
        };
    
        const startOfWeek = getStartOfWeek(new Date());
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6); // Add 6 days to get the end of the week (Saturday)
    
        if (Array.isArray(feeSummary[intervalKey])) {
            let totalSum = 0;
    
            feeSummary[intervalKey].forEach(entry => {

                if (intervalKey === 'today' && entry.date === todayFormatted) {
                    totalSum += entry.total;
                } else if (intervalKey === 'this_week') {
                    const [weekMonth, dayRange] = entry.date.split(' ');
                    const [startDay, endDay] = dayRange.split('-').map(Number);
                    const startDate = new Date(`${weekMonth} ${startDay}, ${currentYear}`);
                    const endDate = new Date(`${weekMonth} ${endDay}, ${currentYear}`);
    
                    if ((startDate >= startOfWeek && startDate <= endOfWeek) || (endDate >= startOfWeek && endDate <= endOfWeek)) {
                        totalSum += entry.total;
                    }
                } else if (intervalKey === 'this_month' && new Date(`${entry.date} 1, ${currentYear}`).getMonth() === currentMonth) {
                    totalSum += entry.total;
                } else if (intervalKey === 'this_year') {
                    const entryYear = parseInt(entry.date, 10);
                    if (entryYear === currentYear) {
                        totalSum += entry.total;
                    }
                }
            });
            setTotalFees(totalSum);
        }
    }, [selectedInterval, feeSummary]);

    class CustomizedFeeSummaryLabel extends PureComponent {
        render() {
            const { x, y, width, value } = this.props;
    
            // Format the value
            const formattedValue = `₱${value.toLocaleString('en-PH', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`;
    
            // Positioning the label in the center of the bar and above it
            const xPos = x + width / 2; // Center of the bar
            const yPos = y - 5; // Slightly above the bar
    
            return (
                <text x={xPos} y={yPos} fill="#54585c" fontSize={13} textAnchor="middle">
                    {formattedValue}
                </text>
            );
        }
    }
    // END OF LOGIC HANDLING FETCHING FEE SUMMARY

    // START OF LOGIC HANDLING DISPLAYING THE DATE
    const [dateDisplay, setDateDisplay] = useState('');

    useEffect(() => {
        const today = new Date();
        const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
        const currentYear = today.getFullYear();
      
        let display = '';
        switch (selectedInterval) {
          case 'Today':
            display = today.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
            break;
          case 'This Week':
            // Calculate Sunday (start of the week)
            const weekStart = new Date(today);
            weekStart.setDate(today.getDate() - dayOfWeek);
            const weekStartFormatted = weekStart.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
      
            // Calculate Saturday (end of the week)
            const weekEnd = new Date(weekStart);
            weekEnd.setDate(weekStart.getDate() + 6);
            const weekEndDay = weekEnd.toLocaleDateString('en-US', { day: 'numeric' });
      
            display = `${weekStartFormatted}-${weekEndDay}, ${currentYear}`;
            break;
          case 'This Month':
            display = today.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
            break;
          case 'This Year':
            display = `Year ${currentYear.toString()}`;
            break;
          default:
            display = '';
        }
        setDateDisplay(display);
    }, [selectedInterval]);
    // END OF LOGIC HANDLING DISPLAYING THE DATE

    // FUNCTION NAVIGATING THE USER TO THE TRANSACTIONS/PAYMENTS PAGE
    const seePayments = () => {
        navigate(`/transactions`);
    };

    // FUNCTION NAVIGATING THE USER TO THE DISBURSEMENTS/PAYOUTS PAGE
    const seePayouts = () => {
        navigate(`/transactions/payouts`);
    };

    // START OF LOGIC FETCHING QR VALUE OF THE MERCHANT FROM DATABASE
    const [qrData, setQRData] = useState([])
    const [openQRModal, setOpenQRModal] = useState(false)
    const [isViewQRActive, setIsViewQRActive] = useState(false)

    const fetchQRValue = async () => {
        try {
            const res = await axios.post(
                FETCH_QR_DATA_ENDPOINT,
                JSON.stringify({ merchant_id: merchant_id }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );  
            if (res.data.success) {
                setQRData(res.data.settings_data);
            } else {
                console.error('Invalid data received', res.data);
            }
        } catch (error) {
            console.error('Failed to fetch QR data', error);
        }
    }

    const openViewQRModal = () => {
        setOpenQRModal(true);
        setIsViewQRActive(true);
    };

    const closeViewQRModal = () => {
        setOpenQRModal(false);
        setIsViewQRActive(false);
    };

    // END OF LOGIC FETCHING QR VALUE OF THE MERCHANT FROM DATABASE


    // START OF LOGIC HANDLING CREATE PAYOUT
    const [displayCreatePayout, setDisplayCreatePayout] = useState(false)
    const [showNoBankMsg, setShowNoBankMsg] = useState(false)
    const [isCreatePayoutActive, setIsCreatePayoutActive ] = useState(false)

    const isBankDataValid = (data) => {
        if (!data) return false;
        for (let key in data) {
            if (data[key] !== null && data[key] !== '') {
                return true;
            }
        }
        return false;
    };

    const handleCreatePayout = () => {
        setIsCreatePayoutActive(true)
        if (isBankDataValid(bankData)) {
            setDisplayCreatePayout(true);
        } else {
            setShowNoBankMsg(true);
        }
    }

    // FUNCTION NAVIGATING THE USER TO THE PAYOUT SETTINGS PAGE
    const seePayoutSettings = () => {
        navigate(`/settings/PayoutSettings`);
    };


    // FUNCTION HANDLING DISPLAY OF COMING SOON FOR CREATE PAYLINK
    const [isCreatePaylinkActive, setIsCreatePaylinkActive] = useState(false);
    const [showComingSoonModal, setShowComingSoonModal] = useState(false);

    const displayComingSoon = () => {
        setIsCreatePaylinkActive(true);
        setShowComingSoonModal(true);
    }

    const hideComingSoon = () => {
        setIsCreatePaylinkActive(false);
        setShowComingSoonModal(false);
    }

    // FUNCTION HANDLING DISPLAY OF NO PERMISSION MESSAGE
    const [showNoPermModal, setShowNoPermModal] = useState(false)
    const displayNoPermModal = () => {
        setShowNoPermModal(true)
    }

    const hideNoPermModal = () => {
        setShowNoPermModal(false)
    }
    

    return (
        <>
            <div className="homepage">
                <div className="homepage__page-container">
                    <div className="homepage__content-container">
                        <div className="homepage__left-content">
                            <div className="homepage__greetings">
                                <p className="homepage__main-title">
                                    Hi <span>{first_name}</span>, here's the latest on your account.
                                </p>
                            </div>
                            <div className="homepage__account-overview">

                                {/* ACCOUNT BALANCE SECTION */}
                                <div className="account-details-wrapper">
                                    {homeError ? (
                                        <div className="error-message-wrapper">
                                            <BsEmojiFrown className="error-message-icon"/>
                                            <p className="error-msg">{homeError.message}</p>
                                            <p className="error-action">{homeError.action}</p>
                                            <p className="error-code">{homeError.code}</p>
                                        </div>
                                    ) : (
                                        <>
                                            <p className={`account-details-title ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-detail-title' : ''}`}>
                                                {!homeLoading && 'Balance'}
                                            </p>
                                            <div className="amount">
                                                <div className={`amount-wrapper ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-amount' : ''}`}>
                                                    {!homeLoading 
                                                        ?
                                                            <>
                                                                <p>
                                                                    &#8369; {(balanceData && balanceData.amount) ? parseFloat(balanceData.amount)
                                                                        .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : "0.00"}
                                                                </p>
                                                                <div className="balance-tooltip">
                                                                    <BalanceTooltip 
                                                                        title="This is the sum of all the money in your account." 
                                                                        placement="right" 
                                                                        arrow
                                                                    >
                                                                        <span className="">
                                                                            <BsInfoCircle className="balance-info-icon" />
                                                                        </span>
                                                                    </BalanceTooltip>
                                                                </div>
                                                            </>
                                                        : ''
                                                    }
                                                </div>
                                            </div>
                                            <div className={`volume ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-volume' : ''}`}>
                                                {!homeLoading ? (
                                                    <p>Balance as of {balanceData.asOfDate}</p>
                                                ) : (
                                                    null
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>

                                {/* DAILY PAYMENTS SECTION */}
                                <div className="account-details-wrapper">
                                    {homeError ? (
                                        <div className="error-message-wrapper">
                                            <BsEmojiFrown className="error-message-icon"/>
                                            <p className="error-msg">{homeError.message}</p>
                                            <p className="error-action">{homeError.action}</p>
                                            <p className="error-code">{homeError.code}</p>
                                        </div>                                        
                                    ) : (
                                        <>
                                            <p className={`account-details-title ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-detail-title' : ''}`}>
                                                {!homeLoading && 'Payments'}
                                            </p>
                                            <div className="amount">
                                                <div className={`amount-wrapper ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-amount' : ''}`}>
                                                    {!homeLoading
                                                        ?
                                                            <>
                                                                <p>
                                                                    &#8369; {(dailyPayments && dailyPayments.total) ? parseFloat(dailyPayments.total)
                                                                        .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : "0.00"}
                                                                </p>
                                                                <div className="balance-tooltip">
                                                                    <BalanceTooltip 
                                                                        title="This is the total payments completed for the day." 
                                                                        placement="right" 
                                                                        arrow
                                                                    >
                                                                        <span className="">
                                                                            <BsInfoCircle className="balance-info-icon" />
                                                                        </span>
                                                                    </BalanceTooltip>
                                                                </div>
                                                            </>
                                                        : ''
                                                    }
                                                </div>
                                            </div>
                                            <div className={`volume ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-volume' : ''}`}>
                                                {!homeLoading ? (
                                                    <p>Total payments for {dailyPayments.date}</p>
                                                ) : (
                                                    null
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="homepage__quick-links">
                                <div className="header">
                                    <p className={`title ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-quick-links' : ''}`}>
                                        {!homeLoading && 'Quick links'}
                                    </p>
                                </div>
                                <div className="options">
                                    <div 
                                        className={`option-items ${isCreatePaylinkActive ? 'item-active' : ''} ${homeLoading ? 'remove-hover' : ''}`} 
                                        onClick={!homeLoading ? displayComingSoon : undefined} 
                                    >
                                        <p className={`options-icon-wrapper ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-options' : ''}`}>
                                            {!homeLoading ? <BsLink45Deg className="options-icon" /> : ''}
                                        </p>
                                        <p className={`option-content ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-option-content' : ''}`}>
                                            {!homeLoading && 'Make Paylink'}
                                        </p>
                                    </div>
                                    <div 
                                        className={`option-items ${homeLoading ? 'remove-hover' : ''}`} 
                                        onClick={!homeLoading ? seePayments : undefined}
                                    >
                                        <p className={`options-icon-wrapper ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-options' : ''}`}>
                                            {!homeLoading ? <BsUiChecks className="options-icon" /> : ''}
                                        </p>
                                        <p className={`option-content ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-option-content' : ''}`}>
                                            {!homeLoading && 'See Payments'}
                                        </p>
                                    </div>
                                    <div 
                                        className={`option-items ${isViewQRActive ? 'item-active' : ''} ${homeLoading ? 'remove-hover' : ''}`}
                                        onClick={!homeLoading ? openViewQRModal : undefined}
                                    >
                                        <p className={`options-icon-wrapper ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-options' : ''}`}>
                                            {!homeLoading ? <BsQrCodeScan className="options-icon"/> : ''}
                                        </p>
                                        <p className={`option-content ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-option-content' : ''}`}>
                                            {!homeLoading && 'View QR'}
                                        </p>
                                    </div>
                                    <div 
                                        className={`option-items ${homeLoading ? 'remove-hover' : ''}`} 
                                        onClick={!homeLoading ? seePayouts : undefined}
                                    >
                                        <p className={`options-icon-wrapper ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-options' : ''}`}>
                                            {!homeLoading ? <BsSendCheck className="options-icon"/> : ''}
                                        </p>
                                        <p className={`option-content ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-option-content' : ''}`}>
                                            {!homeLoading && 'See Payouts'}
                                        </p>
                                    </div>
                                    <div 
                                        className={`option-items ${isCreatePayoutActive ? 'item-active' : ''} ${homeLoading ? 'remove-hover' : ''}`}
                                        onClick={!homeLoading ? (loggedInUserPerm.can_manage_payouts ? handleCreatePayout : displayNoPermModal) : undefined}
                                    >
                                        <p className={`options-icon-wrapper ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-options' : ''}`}>
                                            {!homeLoading ? <BsBoxArrowRight className="options-icon"/> : ''}
                                        </p>
                                        <p className={`option-content ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-option-content' : ''}`}>
                                            {!homeLoading && 'Payout Funds'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="homepage__notifications">
                                <div className="homepage__notifications-header">
                                    <p className={`homepage__notifications-title ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-notice-title' : ''}`}>
                                        {!homeLoading && 'More tools for your business'}
                                    </p>
                                </div>
                                <div className="homepage__notifications-details">
                                    <p className={`homepage__notifications-text ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-notice-text' : ''}`}>
                                        {!homeLoading && "Stay tuned for the launch of our new collection of tools and features designed to support and enhance your business operations to the next level. Exciting updates are on the way!"}
                                    </p>
                                </div>
                            </div>

                            {/* RECENT PAYMENTS SECTION */}
                            <div className="homepage__transactions">
                                {homeError ? (
                                    <div className="error-message-wrapper">
                                        <BsEmojiFrown className="error-message-icon"/>
                                        <p className="error-msg">{homeError.message}</p>
                                        <p className="error-action">{homeError.action}</p>
                                        <p className="error-code">{homeError.code}</p>
                                    </div> 
                                ) : (
                                    <>
                                        <div className="homepage__transactions-header">
                                            <p className={`homepage__transactions-title ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-trans-title' : ''}`}>
                                                {!homeLoading && 'Recent Payments'}
                                            </p>
                                            <p 
                                                className={`homepage__transactions-selector ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-trans-selector' : ''}`} 
                                                onClick={!homeLoading ? navToPayments : undefined}
                                            >
                                                {!homeLoading && 'See Payments'}
                                            </p>
                                        </div>
                                        <div className="homepage__transactions-content-area">
                                            {homeLoading ? (
                                                <div className="homepage__transactions-data-loader">
                                                    <SyncLoader
                                                        size={10}
                                                        margin={3}
                                                        color="#a3b8c2" 
                                                        loading="true"
                                                        speedMultiplier={0.50} 
                                                        aria-label="Loading Spinner" 
                                                        data-testid="loader"
                                                    />                
                                                </div>
                                            ) : (
                                                <>
                                                    {recentPayments.length === 0 ? (
                                                        <div className="homepage__nopayment-wrapper">
                                                            <div className="homepage__nopayments">
                                                                <div className="homepage__nopayments-container">
                                                                    <BsQrCodeScan className="homepage__nopayments-icon"/>
                                                                    <div className="homepage__nopayments-text-wrapper">
                                                                        <p className="homepage__nopayments-heading">
                                                                            No payments
                                                                        </p>
                                                                        <p className="homepage__nopayments-subheading1">
                                                                            Accept payments by letting your customers scan your In-store QR code.
                                                                        </p>
                                                                        <p className="homepage__nopayments-subheading2">
                                                                            Recent payments will show up here, along with the transaction details.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        recentPayments.map((payment) => (
                                                            <>
                                                                <div className="homepage__transactions-payment-details" key={payment.payment_id}>
                                                                    <p className="homepage__transactions-payment-date">{payment.created_date}</p>
                                                                    <p className="homepage__transactions-payment-status">{payment.status}</p>
                                                                    <p className="homepage__transactions-payment-type">In-store Payment </p>
                                                                    <p className="homepage__transactions-payment-amount">
                                                                        &#8369; {payment && payment.amount ? parseFloat(payment.amount)
                                                                        .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ""}
                                                                    </p>
                                                                </div>
                                                                <div className="homepage__transactions-payment-details-mobile">
                                                                    <div className="homepage__transactions-payment-details-mobile-section1">
                                                                        <p className="homepage__transactions-payment-type">In-store Payment </p>
                                                                        <p className="homepage__transactions-payment-date">{payment.created_date}</p>
                                                                    </div>
                                                                    <div className="homepage__transactions-payment-details-mobile-section2">
                                                                        <p className="homepage__transactions-payment-amount">
                                                                            &#8369; {payment && payment.amount ? parseFloat(payment.amount)
                                                                            .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ""}
                                                                        </p>
                                                                        <div className="homepage__transactions-payment-status-wrapper">
                                                                            <p className="homepage__transactions-payment-status">{payment.status}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="homepage__right-content">
                            <div className="homepage__right-content__header">
                                <p className={`homepage__right-content-title ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-right-content-title' : ''}`}>
                                    {!homeLoading && 'Insights'}
                                </p>
                                <div className={`homepage__right-content-selection ${homeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-right-content-selection' : ''}`}>
                                    {!homeLoading && 
                                        <CustomDateSelect 
                                            value={selectedInterval} 
                                            onChange={(event) => setSelectedInterval(event.target.value)}
                                        >
                                            <CustomMenuItem value="Today">Today</CustomMenuItem>
                                            <CustomMenuItem value="This Week">This Week</CustomMenuItem>
                                            <CustomMenuItem value="This Month">This Month</CustomMenuItem>
                                            <CustomMenuItem value="This Year">This Year</CustomMenuItem>
                                        </CustomDateSelect>
                                    }
                                </div>
                            </div>

                            {/* GRAPHS SECTION */}
                            <div className="homepage__right-content__analytics">
                                <div className="graphs-wrapper">
                                    <div className="homepage__graph-container">
                                        {grossVolumeError ? (
                                            <div className="error-message-wrapper">
                                                <BsEmojiFrown className="error-message-icon"/>
                                                <p className="error-msg">{grossVolumeError.message}</p>
                                                <p className="error-action">{grossVolumeError.action}</p>
                                                <p className="error-code">{grossVolumeError.code}</p>
                                            </div> 
                                        ) : (
                                            <>
                                                <div className="homepage__graph-title">
                                                    <p className={`homepage__graph-label ${grossVolumeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-graph-label' : ''}`}>
                                                        {!grossVolumeLoading && 'Gross Volume'}
                                                    </p>
                                                    <p className={`homepage__graph-output ${grossVolumeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-graph-output' : ''}`}>
                                                        {!grossVolumeLoading &&
                                                            <>
                                                                &#8369; {totalGrossPayments.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} <span>(as of {dateDisplay})</span>
                                                            </>
                                                        }
                                                    </p>
                                                </div>
                                                {grossVolumeLoading ? (
                                                    <div className="homepage__data-loader">
                                                        <SyncLoader
                                                            size={10}
                                                            margin={3}
                                                            color="#a3b8c2" 
                                                            loading="true"
                                                            speedMultiplier={0.50} 
                                                            aria-label="Loading Spinner" 
                                                            data-testid="loader"
                                                        />                
                                                    </div>
                                                ) : (
                                                    <ResponsiveContainer width="100%" height={250}>
                                                        <LineChart 
                                                            data={grossVolume[selectedInterval.toLowerCase().replace(' ', '_')]}
                                                            margin={{
                                                                top: 15,
                                                                right: 15,
                                                                left: 15,
                                                                bottom: 15,
                                                            }}
                                                            barCategoryGap={0}
                                                            barGap={0}
                                                        >
                                                            <XAxis 
                                                                dataKey="date" 
                                                                tick={{ fontSize: 13 }} 
                                                                padding={{ left: 20, right: 20 }}
                                                            />
                                                            
                                                            <Tooltip 
                                                                formatter={(value) => 
                                                                    [`₱${Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`]}
                                                                contentStyle={{ fontSize: '13px' }} 
                                                            />
                                                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                                            <Line 
                                                                dataKey="total" 
                                                                type="monotone"
                                                                stroke="#FF5161"
                                                                activeDot={{ r: 8 }}
                                                                strokeWidth={2}
                                                                label={<CustomizedGrossVolumeLabel />} 
                                                            />
                                                        </LineChart>
                                                    </ResponsiveContainer>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    
                                    <div className="homepage__graph-container"> 
                                        {netVolumeError ? (
                                            <div className="error-message-wrapper">
                                                <BsEmojiFrown className="error-message-icon"/>
                                                <p className="error-msg">{netVolumeError.message}</p>
                                                <p className="error-action">{netVolumeError.action}</p>
                                                <p className="error-code">{netVolumeError.code}</p>
                                            </div> 
                                        ) : (
                                        <>
                                                <div className="homepage__graph-title">
                                                    <p className={`homepage__graph-label ${netVolumeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-graph-label' : ''}`}>
                                                        {!netVolumeLoading && 'Net Volume'}
                                                    </p>
                                                    <p className={`homepage__graph-output ${netVolumeLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-graph-output' : ''}`}>
                                                        {!netVolumeLoading &&
                                                            <>
                                                                &#8369; {totalNetPayments.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} <span>(as of {dateDisplay})</span>
                                                            </>
                                                        }
                                                    </p>
                                                </div>
                                                {netVolumeLoading ? (
                                                    <div className="homepage__data-loader">
                                                        <SyncLoader
                                                            size={10}
                                                            margin={3}
                                                            color="#a3b8c2" 
                                                            loading="true"
                                                            speedMultiplier={0.50} 
                                                            aria-label="Loading Spinner" 
                                                            data-testid="loader"
                                                        />               
                                                    </div>
                                                ) : (
                                                    <ResponsiveContainer width="100%" height={250}>
                                                        <LineChart 
                                                            data={netVolume[selectedInterval.toLowerCase().replace(' ', '_')]}
                                                            margin={{
                                                                top: 15,
                                                                right: 15,
                                                                left: 15,
                                                                bottom: 15,
                                                            }}
                                                            barCategoryGap={0}
                                                            barGap={0}
                                                        >
                                                            <XAxis 
                                                                dataKey="date" 
                                                                tick={{ fontSize: 13 }} 
                                                                padding={{ left: 20, right: 20 }}
                                                            />
                                                            
                                                            <Tooltip 
                                                                formatter={(value) => 
                                                                    [`₱${Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`]}
                                                                contentStyle={{ fontSize: '13px' }} 
                                                            />
                                                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                                            <Line 
                                                                dataKey="total" 
                                                                type="monotone"
                                                                stroke="#FF5161"
                                                                activeDot={{ r: 8 }}
                                                                strokeWidth={2}
                                                                label={<CustomizedNetVolumeLabel />} 
                                                            />
                                                        </LineChart>
                                                    </ResponsiveContainer>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className="homepage__graph-container">
                                        {feeSummaryError ? (
                                            <div className="error-message-wrapper">
                                                <BsEmojiFrown className="error-message-icon"/>
                                                <p className="error-msg">{feeSummaryError.message}</p>
                                                <p className="error-action">{feeSummaryError.action}</p>
                                                <p className="error-code">{feeSummaryError.code}</p>
                                            </div> 
                                        ) : (
                                            <>
                                                <div className="homepage__graph-title">
                                                    <p className={`homepage__graph-label ${feeSummaryLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-graph-label' : ''}`}>
                                                        {!feeSummaryLoading && 'Fee Summary'}
                                                    </p>
                                                    <p className={`homepage__graph-output ${feeSummaryLoading ? 'homepage-skeleton-loader homepage-skeleton-loader-graph-output' : ''}`}>
                                                        {!feeSummaryLoading &&
                                                            <>
                                                                &#8369; {totalFees.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} <span>(as of {dateDisplay})</span>
                                                            </>
                                                        }
                                                    </p>
                                                </div>
                                                {feeSummaryLoading ? (
                                                    <div className="homepage__data-loader">
                                                        <SyncLoader
                                                            size={10}
                                                            margin={3}
                                                            color="#a3b8c2" 
                                                            loading="true"
                                                            speedMultiplier={0.50} 
                                                            aria-label="Loading Spinner" 
                                                            data-testid="loader"
                                                        />             
                                                    </div>
                                                ) : (
                                                    <ResponsiveContainer width="100%" height={250}>
                                                        <BarChart 
                                                            data={feeSummary[selectedInterval.toLowerCase().replace(' ', '_')]}
                                                            margin={{
                                                                top: 15,
                                                                right: 15,
                                                                left: 15,
                                                                bottom: 15,
                                                            }}
                                                            barCategoryGap={3}
                                                            barGap={3}
                                                        >
                                                            <XAxis 
                                                                dataKey="date" 
                                                                tick={{ fontSize: 13 }} 
                                                                padding={{ left: 5, right: 5 }}
                                                            />
                                                            
                                                            <Tooltip 
                                                                formatter={(value) => 
                                                                    [`₱${Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`]}
                                                                contentStyle={{ fontSize: '13px' }} 
                                                            />
                                                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                                            <Bar 
                                                                dataKey="total" 
                                                                fill="#FF5161"
                                                                label={<CustomizedFeeSummaryLabel />}  
                                                            />
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                <NoBankMsg 
                    open={showNoBankMsg} 
                    onClose={() => {
                        setShowNoBankMsg(false);
                        setIsCreatePayoutActive(false);
                    }} 
                    seePayoutSettings={seePayoutSettings}
                />
            }
            {
                <CreatePayout 
                    open={displayCreatePayout} 
                    onClose={() => {
                        setDisplayCreatePayout(false);
                        setIsCreatePayoutActive(false);
                    }} 
                />
            }
            {openQRModal && (
                <ViewQR qrValue={qrData.store_qr} onClose={closeViewQRModal}/>
            )}
            {showComingSoonModal &&
                <ComingSoon 
                    hideComingSoon={hideComingSoon}
                />
            }
            {showNoPermModal &&
                <NoPermMsg onClose={hideNoPermModal}/>
            }
        </>
    )
}

export default Home