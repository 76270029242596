import React from 'react';
import { useSelector } from 'react-redux';
import ManageUsersLoader from './ManageUsersLoader';
import { BsTrash3, BsCheckCircle } from "react-icons/bs";
import '../../../../Styles/MainStyles/Settings/ManageUsers/DeleteUser.css';

function DeleteUser({ selectedUserToDelete, onClose, submitRemoveUser, submittingRemoveUser, removingUserSuccess }) {
    const merchant = useSelector((state) => state.merchant.profile ? state.merchant.profile.merchant_name : '');

    return (
        <>
            <div className="delete-user">
                <div className="delete-user__page-container">
                    {removingUserSuccess ? (
                        <div className="delete-user__success-message-wrapper">
                            <BsCheckCircle className="delete-user__success-message-icon"/>
                            <div className="delete-user__success-message-texts">
                                <p className="delete-user__success-message-heading">
                                    Hurray!!
                                </p>
                                <p className="delete-user__success-message-subheading">
                                    {selectedUserToDelete} has been removed successfully.
                                </p>
                            </div>
                            <div className="delete-user__success-message-btns">
                                <button
                                    type="button"
                                    onClick={onClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    ) : (

                        <div className="delete-user__content">
                            <div className="delete-user__icon-wrapper">
                                <BsTrash3 className="delete-user__delete-icon"/>
                            </div>
                            <div className="delete-user__text-wrapper">
                                <p className="delete-user__text">
                                    Are you sure you want to remove {selectedUserToDelete} from {merchant}?
                                    This action cannot be undone.
                                </p>
                            </div>
                            <div className="delete-user__button-wrapper">
                                {submittingRemoveUser ? (
                                    <p className="delete-user__removing-user">Removing User</p>
                                ):(
                                    <>
                                        <button
                                            type="button"
                                            onClick={submitRemoveUser}
                                        >
                                            Delete
                                        </button>
                                        <button
                                            type="button"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {submittingRemoveUser && <ManageUsersLoader />}
            </div>
        </>
    )
}

export default DeleteUser