import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import fileDownload from 'js-file-download';
import { useSelector } from 'react-redux';
import { BsCheck2, BsCopy } from "react-icons/bs";
import '../../../../Styles/MainStyles/Settings/BusinessProfile/ViewQR.css';



function ViewQR({ qrValue, onClose }) {
    const store_name = useSelector((state) => state.merchant.profile ? state.merchant.profile.merchant_name : '');

    // STATES
    const [copySuccess, setCopySuccess] = useState('');
    const [inputBgColor, setInputBgColor] = useState('');
    const [icon, setIcon] = useState(<BsCopy />);
    const [buttonBgColor, setButtonBgColor] = useState('');
    
    //FUNCTION HANDLING COPYING THE PAYMENT LINK
    const handleCopyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(qrValue);
            setCopySuccess('Link copied!');
            setInputBgColor('lightgreen');
            setIcon(<BsCheck2 />);
            setButtonBgColor('green');

            setTimeout(() => {
                setCopySuccess(''); 
                setInputBgColor('');
                setIcon(<BsCopy />);
                setButtonBgColor('');
            }, 5000);
        } catch (err) {
        console.error('Failed to copy text: ', err);
        }
    }
    
    //FUNCTION TO DOWNLOAD QR CODE
    const handleDownloadQR = () => {
        const qrWrapper = document.querySelector('.viewQRModal_qr-wrapper');
        html2canvas(qrWrapper)
            .then((canvas) => {
                canvas.toBlob((blob) => {
                    fileDownload(blob, 'QRCode.png');
                });
            })
            .catch((error) => console.error("Download QR Failed", error));
    };

    return (
        <>
            <div className="viewQRModal">
                <div className="viewQRModal-page-container">
                    <div className="viewQRModal-qr-container">
                        <p className="qr-title">
                            Open your camera app or QR scanner in your mobile and pay by scanning the QR below
                        </p>
                        <div className="viewQRModal_qr-wrapper">   
                            <p className="viewQRModal-qr-store-name">{store_name}</p>                
                            <QRCode 
                                value={qrValue} 
                                size={180}
                                fgColor="#284154"
                                imageSettings={{
                                    src: "/images/finduku-icon.svg",
                                    x: null,
                                    y: null,
                                    height: 31,
                                    width: 31,
                                    excavate: true, // true means it will "carve out" space in the QR code behind the image
                                }}
                            />
                            <p className="viewQRModal-qr-scan-text">Scan to Pay by Bank</p>  
                        </div> 
                        <p className="qr-link-title">or share this store payment link:</p>
                        <div className="qr_payment-link">
                            <input
                                style={{ backgroundColor: inputBgColor }}
                                type="text"
                                value={qrValue}  
                                readOnly
                            />
                            <button onClick={handleCopyToClipboard} style={{ backgroundColor: buttonBgColor }}>
                                <span>{icon}</span>
                            </button>
                        </div>
                        {copySuccess && <div className="copy-section">{copySuccess}</div>}  
                    </div>
                    <div className="viewQRModal-button-wrapper">
                        <button onClick={onClose}>Close</button>
                        <button onClick={handleDownloadQR}>Download</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewQR