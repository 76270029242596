import React from 'react';
import '../../../Styles/AuthStyles/Password/ResetPassSuccess.css';

function ResetPassSuccess({ closeResetPassSuccessModal }) {
     return (
          <>
               <div className="resetPassSuccess">
                    <div className="resetPassSuccess__page-container">
                         <p className="resetPassSuccess__message">
                              Your new password has been set successfully.
                         </p>
                         <button 
                              className="resetPassSuccess__button"
                              type="button"
                              onClick={closeResetPassSuccessModal}
                         >
                              LOGIN
                         </button>
                    </div>
               </div>
          </>
     )
}

export default ResetPassSuccess