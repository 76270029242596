import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CSRFToken from '../../../../components/CSRFToken';
import ChangePasswordLoader from './ChangePasswordLoader';
import '../../../../Styles/MainStyles/Settings/LoginSecurity/ChangePassword.css';
import { 
    BsEyeSlashFill, 
    BsEyeFill, 
    BsExclamationCircle,
    BsEmojiFrown,
    BsCheckCircle,
} from "react-icons/bs";

function ChangePassword({
    passwordData,
    submitNewPassword,
    updateChangePasswordData,
    hideChangePasswordModal,
    changePasswordSuccessful,
    wrongOldPassword,
    submittingNewPassword,
    changePasswordError,
    changePasswordErrorMsg,
    changePasswordErrorAction,
    changePasswordErrorCode,
    resetWrongOldPasswordMsg,
    navigate2Login
}) {

    // Data Validation
    const validate = Yup.object({
        oldPassword: Yup.string()
            .required('Please complete this field.'),

        newPassword: Yup.string()
            .required('Please complete this field.')
            .matches(
                // eslint-disable-next-line
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Use atleast 8 characters with uppercase and lowercase letters, numbers and special characters"
        ),
        confirmPassword: Yup.string()
            .required('Please complete this field.')
            .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match'),
    })

    // Password Toggle
    const [type, setType] = useState('password');
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordToggle = () => {
        if (type === 'password'){
            setType('text');
            setShowPassword(true);
        }
        else {
            setType('password');
            setShowPassword(false);
        }
    }

    return (
        <>
            <div className="change-password" onClick={(e) => {e.stopPropagation()}}>
                <div className="change-password__page-container">
                    {changePasswordError ? (
                        <div className="change-password__error-message-wrapper">
                            <BsEmojiFrown  className="change-password__error-message-icon"/>
                            <p className="change-password__error-msg">{changePasswordErrorMsg}</p>
                            <p className="change-password__error-action">{changePasswordErrorAction}</p>
                            <p className="change-password__error-code">{changePasswordErrorCode}</p>
                            <button 
                                type="button" 
                                onClick={hideChangePasswordModal}
                            >
                                OKAY
                            </button>
                        </div>                       
                    ) : (
                        <>
                            {changePasswordSuccessful ? (
                                <div className="change-password__confirmation-message-wrapper">
                                    <BsCheckCircle className="confirm-icon"/>
                                    <p className="confirmation-message-heading">Password changed.</p>
                                    <p className="confirmation-message-subheading">Use your new password for future logins.</p>
                                    <p className="confirmation-message-notice">
                                        We are logging you out for security reasons.
                                    </p>
                                    <button className="change-password__confirmation-button"type="button" onClick={navigate2Login}>OKAY</button>
                                </div>                                
                            ) : (
                                <Formik
                                    initialValues={passwordData}
                                    onSubmit={submitNewPassword}
                                    validationSchema={validate}
                                >
                                    {({ handleChange, handleBlur, values, errors, touched }) => {

                                        return (
                                            <Form>
                                                <CSRFToken />
                                                <div className="change-password__header">
                                                    <div className="change-password__title">Update Password</div>
                                                </div>
                                                <div className="change-password__body">
                                                    {/* OLD PASSWORD */}
                                                    <div className="change-password___inputs-wrapper">
                                                        <p className={`change-password__input-label ${wrongOldPassword ? "wrong-old-password" : ""}`}>Current password</p>
                                                        <div className="change-password__input-icon-container">
                                                            <input 
                                                                className={`change-password__input ${
                                                                    errors.oldPassword && touched.oldPassword
                                                                    ? "change-password__input-error"
                                                                    : wrongOldPassword
                                                                    ? "change-password__wrong-old-password-error"
                                                                    : null
                                                                }`}
                                                                name="oldPassword"
                                                                type={type}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    updateChangePasswordData({ ...values, oldPassword: e.target.value });
                                                                    resetWrongOldPasswordMsg();
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={values.oldPassword}
                                                                placeholder="Enter your current password"
                                                                autoComplete="off"
                                                            />
                                                            <div className="change-password__toggle-wrapper" onClick={handlePasswordToggle}>
                                                                {values.oldPassword && (
                                                                    <span className="change-password__toggle-icon-wrapper">
                                                                        {showPassword ? (
                                                                            <BsEyeSlashFill className="change-password__eye-icon" />
                                                                        ) : (
                                                                            <BsEyeFill className="change-password__eye-icon" />
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {errors.oldPassword && touched.oldPassword && (
                                                            <p className="change-password__input-error-message">
                                                                <BsExclamationCircle className="change-password__input-error-icon" />
                                                                {errors.oldPassword}
                                                            </p>
                                                        )}
                                                        {wrongOldPassword && (
                                                            <p className="change-password__input-error-message">
                                                                <BsExclamationCircle className="change-password__input-error-icon" />
                                                                Your current password is incorrect.
                                                            </p>
                                                        )}
                                                    </div>
        
                                                    {/* NEW PASSWORD */}
                                                    <div className="change-password___inputs-wrapper">
                                                        <p className="change-password__input-label">New password</p>
                                                        <div className="change-password__input-icon-container">
                                                            <input 
                                                                className={`change-password__input ${(errors.newPassword && touched.newPassword) ? "change-password__input-error" : ""}`} 
                                                                name="newPassword"
                                                                type={type}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    updateChangePasswordData({ ...values, newPassword: e.target.value });
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={values.newPassword}
                                                                placeholder="Create your new password"
                                                                autoComplete="off"
                                                            />
                                                            <div className="change-password__toggle-wrapper" onClick={handlePasswordToggle}>
                                                                {values.newPassword && (
                                                                    <span className="change-password__toggle-icon-wrapper">
                                                                        {showPassword ? (
                                                                            <BsEyeSlashFill className="change-password__eye-icon" />
                                                                        ) : (
                                                                            <BsEyeFill className="change-password__eye-icon" />
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {errors.newPassword && touched.newPassword && (
                                                            <p className="change-password__input-error-message">
                                                                <BsExclamationCircle className="change-password__input-error-icon" />
                                                                {errors.newPassword}
                                                            </p>
                                                        )}
                                                    </div>
        
                                                    {/* CONFIRM PASSWORD */}
                                                    <div className="change-password___inputs-wrapper">
                                                        <p className="change-password__input-label">Confirm password</p>
                                                        <div className="change-password__input-icon-container">
                                                            <input 
                                                                className={`change-password__input ${(errors.confirmPassword && touched.confirmPassword) ? "change-password__input-error" : ""}`} 
                                                                name="confirmPassword"
                                                                type={type}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    updateChangePasswordData({ ...values, confirmPassword: e.target.value });
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={values.confirmPassword}
                                                                placeholder="Re-enter your new password"
                                                                autoComplete="off"
                                                            />
                                                            <div className="change-password__toggle-wrapper" onClick={handlePasswordToggle}>
                                                                {values.confirmPassword && (
                                                                    <span className="change-password__toggle-icon-wrapper">
                                                                        {showPassword ? (
                                                                            <BsEyeSlashFill className="change-password__eye-icon" />
                                                                        ) : (
                                                                            <BsEyeFill className="change-password__eye-icon" />
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {errors.confirmPassword && touched.confirmPassword && (
                                                            <p className="change-password__input-error-message">
                                                                <BsExclamationCircle className="change-password__input-error-icon" />
                                                                {errors.confirmPassword}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="change-password__footer">
                                                    <div className="change-password__btn-wrapper">
                                                        <button 
                                                            type="button"
                                                            onClick={hideChangePasswordModal}
                                                            className="change-password__cancel-btn"
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button 
                                                            type="submit"
                                                            className={`change-password__submit-btn ${values.oldPassword && values.newPassword && values.confirmPassword ? "" : "disabled"}`}
                                                            disabled={!values.oldPassword || !values.newPassword || !values.confirmPassword}
                                                        >
                                                            {submittingNewPassword ? "Processing" : "Submit"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            )}
                        
                        </>
                    )}
                </div>
                {submittingNewPassword && <ChangePasswordLoader />}
            </div>
        </>
    )
}

export default ChangePassword