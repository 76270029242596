import React from 'react';
import SyncLoader from "react-spinners/SyncLoader";
import '../../../Styles/MainStyles/Settings/SettingsLoader.css';


function SettingsLoader() {
    return (
        <div className="settings-loader">
            <SyncLoader
                size={13}
                margin={5}
                color="#294254" 
                loading="true"
                speedMultiplier={0.50} 
                aria-label="Loading Spinner" 
                data-testid="loader"
            />                
        </div>
    )
}

export default SettingsLoader