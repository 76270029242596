export const destinationBanks = [
    { bank_code: 'PH_AUB', bank_name: 'Asia United Bank' },
    { bank_code: 'PH_BOC', bank_name: 'Bank of Commerce' },
    { bank_code: 'PH_BPI', bank_name: 'Bank of the Philippine Islands' },
    { bank_code: 'PH_BDO', bank_name: 'Banco de Oro' },
    { bank_code: 'PH_CBCN', bank_name: 'Chinabank' },
    { bank_code: 'PH_CIMBBP', bank_name: 'CIMB Bank' },
    { bank_code: 'PH_DBOTP', bank_name: 'Development Bank of the Philippines' },
    { bank_code: 'PH_EWB', bank_name: 'EastWest Bank' },
    { bank_code: 'PH_LBP', bank_name: 'Land Bank of the Philippines' },
    { bank_code: 'PH_MAYBANK', bank_name: 'Maybank' },
    { bank_code: 'PH_METRO', bank_name: 'Metrobank' },
    { bank_code: 'PH_PNB', bank_name: 'Philippine National Bank' },
    { bank_code: 'PH_RCBC', bank_name: 'Rizal Commercial Banking Corporation' },
    { bank_code: 'PH_SB', bank_name: 'Security Bank' },
    { bank_code: 'PH_UB', bank_name: 'UnionBank of the Philippines' },
    { bank_code: 'PH_NB', bank_name: 'Netbank' },
];