import React from 'react';
import { BsEmojiFrown } from "react-icons/bs";
import '../../../Styles/AuthStyles/Onboarding/OnboardingError.css';


function OnboardingError({ onboardingErrorMsg, onboardingErrorAction, onboardingErrorCode, closeOnboardingErrorModal }) {
    return (
        <>
            <div className="onboarding-error">
                <div className="onboarding-error__page-container">
                    <div className="onboarding-error__content-wrapper">
                        <div className="onboarding-error__error-message-wrapper">
                            <BsEmojiFrown  className="onboarding-error__error-message-icon"/>
                            <p className="onboarding-error__error-msg">{onboardingErrorMsg}</p>
                            <p className="onboarding-error__error-action">{onboardingErrorAction}</p>
                            <p className="onboarding-error__error-code">{onboardingErrorCode}</p>
                            <button 
                                type="button" 
                                onClick={closeOnboardingErrorModal}
                            >
                                OKAY
                            </button>
                        </div>    
                    </div>
                </div>
            </div>
        </>
    )
}

export default OnboardingError