import React from 'react';
import '../../../../Styles/MainStyles/Settings/UserInfo/EditName.css';

function EditNameModal({ onCloseEditName }) {
    return (
        <>
            <div className="edit-name">
                <div className="edit-name__page-container">
                    <div className="edit-name__text-wrapper">
                        <p className="edit-name__text-wrapper">
                            Contact our support team if you wish to update or change your name.
                        </p>
                        <p className="edit-name__button"type="button" onClick={onCloseEditName}>OKAY</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditNameModal
