import React from 'react';
import { Formik, Form } from 'formik';
import CSRFToken from '../../../../components/CSRFToken';
import * as Yup from 'yup';
import ToggleSwitch from './ToggleSwitch';
import ScaleLoader from "react-spinners/ScaleLoader";
import { BsFillExclamationTriangleFill, BsCheckCircle } from 'react-icons/bs';
import '../../../../Styles/MainStyles/Settings/ManageUsers/EditUser.css';

function EditUser({ 
    editedUserData, 
    submitEditedUser, 
    updateEditedUserData, 
    onClose, 
    submittingEditedUser,
    submitEditUserSuccess,
}) {

    const validate = Yup.object({
        name: Yup.string()
        .required('Please provide a full name'),

        email: Yup.string()
        .email('The email address format is not valid.')
        .required('Email address is required'),
    })

    return (
        <>
            <div className="edit-user">
                <div className="edit-user__page-container">
                    {submitEditUserSuccess ? (
                        <div className="edit-user__success-message-wrapper">
                            <BsCheckCircle className="edit-user__success-message-icon"/>
                            <div className="edit-user__success-message-texts">
                                <p className="edit-user__success-message-heading">
                                    Hurray!!
                                </p>
                                <p className="edit-user__success-message-subheading">
                                    {editedUserData.name}'s permissions has been updated.
                                </p>
                            </div>
                            <div className="edit-user__success-message-btns">
                                <button
                                    type="button"
                                    onClick={onClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            {submittingEditedUser ? (
                                <div className="edit-user__submission-loader">
                                    <ScaleLoader
                                        height={23}
                                        width={4}
                                        color="#4040ac" 
                                        loading="true"
                                        speedMultiplier={0.80} 
                                        aria-label="Loading Spinner" 
                                        data-testid="loader"
                                    />  
                                    <p>Updating User's Permissions</p>               
                                </div>
                            ) : (
                                <Formik
                                    initialValues={editedUserData}
                                    onSubmit={submitEditedUser}
                                    validationSchema={validate}
                                >
                                    {( { handleChange, handleBlur, values, handleSubmit, errors, touched, setFieldValue, isValid, dirty }) => {

                                        return(
                                            <Form>
                                                <CSRFToken />
                                                <div className="edit-user__header">
                                                    <p className="edit-user__title">Edit User</p>
                                                </div>
                                                <div className="edit-user__body">

                                                    {/* FULL NAME SECTION */}
                                                    <div className="edit-user__input-wrapper">
                                                        <p className="edit-user__input-label">Name</p>
                                                        <input 
                                                            type="text" 
                                                            className={`edit-user__input ${errors.name && touched.name ? "input-error" : null}`}
                                                            name="name"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateEditedUserData({ ...values, name: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.name}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                        {errors.name && touched.name && (
                                                            <p className="edit-user__input-error-message"> 
                                                                <BsFillExclamationTriangleFill className="edit-user__input-error-icon" />
                                                                {errors.name}
                                                            </p>
                                                        )}
                                                    </div>

                                                    {/* EMAIL SECTION */}
                                                    <div className="edit-user__input-wrapper">
                                                        <p className="edit-user__input-label">Email Address</p>
                                                        <input 
                                                            type="text" 
                                                            className={`edit-user__input ${errors.email && touched.email ? "input-error" : null}`}
                                                            name="email"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateEditedUserData({ ...values, email: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                        {errors.email && touched.email && (
                                                            <p className="edit-user__input-error-message"> 
                                                                <BsFillExclamationTriangleFill className="edit-user__input-error-icon" />
                                                                {errors.email}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="edit-user__permissions-container">
                                                        <p className="edit-user__permissions-title">Permissions</p>
                                                        <div className="edit-user__permission-wrapper">
                                                            <ToggleSwitch 
                                                                name="can_manage_users" 
                                                                onChange={(checked) => {
                                                                    setFieldValue('can_manage_users', checked);
                                                                    updateEditedUserData({ ...values, can_manage_users: checked });
                                                                }} 
                                                            />
                                                            <div className="edit-user__permission">
                                                                <p>Manage Users</p>
                                                                <p>Add and remove account users.</p>
                                                            </div>
                                                        </div>                                               
                                                        <div className="edit-user__permission-wrapper">
                                                            <ToggleSwitch 
                                                                name="can_manage_business" 
                                                                onChange={(checked) => {
                                                                    setFieldValue('can_manage_business', checked);
                                                                    updateEditedUserData({ ...values, can_manage_business: checked });
                                                                }} 
                                                            />
                                                            <div className="edit-user__permission">
                                                                <p>Manage Business</p>
                                                                <p>Update business information.</p>
                                                            </div>
                                                        </div>   
                                                        <div className="edit-user__permission-wrapper">
                                                            <ToggleSwitch 
                                                                name="can_manage_bank_account" 
                                                                onChange={(checked) => {
                                                                    setFieldValue('can_manage_bank_account', checked);
                                                                    updateEditedUserData({ ...values, can_manage_bank_account: checked });
                                                                }} 
                                                            />
                                                            <div className="edit-user__permission">
                                                                <p>Manage Banks</p>
                                                                <p>Add and update bank accounts.</p>
                                                            </div>
                                                        </div>  
                                                        <div className="edit-user__permission-wrapper">
                                                            <ToggleSwitch 
                                                                name="can_manage_payouts" 
                                                                onChange={(checked) => {
                                                                    setFieldValue('can_manage_payouts', checked);
                                                                    updateEditedUserData({ ...values, can_manage_payouts: checked });
                                                                }} 
                                                            />
                                                            <div className="edit-user__permission">
                                                                <p>Manage Payouts</p>
                                                                <p>Initiate and manage payouts.</p>
                                                            </div>
                                                        </div>                                                                                                                             
                                                    </div>
                                                </div>
                                                <div className="edit-user__footer">
                                                    <button
                                                        type="button"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                    >
                                                        Edit User
                                                    </button>
                                                </div>
                                            </Form>
                                        )

                                    }}
                                </Formik>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default EditUser