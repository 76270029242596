import React from 'react';
import { BsEmojiFrown } from 'react-icons/bs';
import '../../../Styles/AuthStyles/Register/RegisterError.css';

function RegisterError({ registerErrorMsg, registerErrorAction, registerErrorCode, onClose }) {
     return (
          <>
               <div className="register-error">
                    <div className="register-error__message-container">
                         <BsEmojiFrown  className="register-error__message-icon"/>
                         <p className="register-error__text">{registerErrorMsg}</p>
                         <p className="register-error__action">{registerErrorAction}</p>
                         <p className="register-error__code">{registerErrorCode}</p>
                         <div className="register-error__button-wrapper">
                              <button 
                                   type="button"
                                   className="register-error__button"
                                   onClick={onClose}
                              >
                                   OKAY
                              </button>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default RegisterError