import React, { useState } from 'react';
import ViewQR from './ViewQR';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { BsInfoCircleFill } from "react-icons/bs";
import { styled } from '@mui/system';
import '../../../../Styles/MainStyles/Settings/BusinessProfile/BusinessProfile.css';

const SIDTooltip = styled(({ className, ...props }) => (
    <Tooltip classes={{ popper: className }} {...props} />
))({
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'white', 
        maxWidth: 480,          
        color: 'black',
        fontFamily: 'Figtree',
        fontSize: '14px',
        boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.25)', 
        padding: '6px',
    },
    '& .MuiTooltip-arrow': {
        color: 'white',  
    },
});

const StyledLogo = styled(Avatar)({
    backgroundColor: '#57768D',    
    color: '#ffffff',              
    fontSize: '25px',              
    width: '60px',                 
    height: '60px',    
});


function CustomLogo({ src, name, isLoadingSettingPage, isUploadingLogo, isRemovingLogo }) {
    const shouldShowSkeletonLoader = !isLoadingSettingPage || isUploadingLogo || isRemovingLogo;

    if (shouldShowSkeletonLoader) {
        return (
            <StyledLogo className="skeleton-loader">
                <div style={{ width: '100%', height: '100%', borderRadius: '50%' }}></div>
            </StyledLogo>
        );
    } else {
        return src ? (
            <StyledLogo 
                src={src} 
                alt="Company Logo" 
                style={{ borderWidth: '1.9px', borderStyle: 'solid', borderColor: '#282b33' }}
            />
        ) : (
            <StyledLogo style={{ borderWidth: '1.9px', borderStyle: 'solid', borderColor: '#282b33' }}>
                <span>{name ? name.charAt(0) : "?"}</span>
            </StyledLogo>
        );
    }
}

function BusinesProfile({ 
    settingsData, 
    onLogoUpload, 
    onLogoRemove,
    handleCompanyLogoOKButton, 
    isEditingStoreName, 
    editStoreName,
    editedStoreName,
    handleStoreNameChange,
    cancelEditStoreName,
    saveEditedStoreName, 
    isSavingStoreNameChanges,
    isLoadingSettingPage,
    loggedInUserPerm,
    displayNoPermModal,
    isSavingContactNoChanges,
    isEditingContactNo,
    editContactNo,
    editedContactNo,
    handleContactNoChange,
    cancelEditContactNo,
    saveEditedContactNo,
    isSavingWebsiteChanges,
    isEditingWebsite,
    editWebsite,
    editedWebsite,
    handleWebsiteChange,
    cancelEditWebsite,
    saveEditedWebsite,
    companyLogoError,
    companyLogoErrorMsg,
    companyLogoErrorCode,
    isUploadingLogo,
    isRemovingLogo,
    fileInputRef,
    storeNameError,
    storeNameErrorMsg,
    storeNameErrorCode,
    handleStoreNameOKButton,
    contactNumberError,
    contactNumberErrorMsg,
    contactNumberErrorCode,
    handleContactNumberOKButton,
    editWebsiteError,
    editWebsiteErrorMsg,
    editWebsiteErrorCode,
    handleEditWebsiteOKButton
}) {

    const company_logo = settingsData.company_logo ? settingsData.company_logo : '';

    const [isViewQROpen, setIsViewQROpen] = useState(false);

    // FUNCTIONS HANDLING PAYLINK MODAL
    const openViewQRModal = () => {
        setIsViewQROpen(true);
    };
        
    const closeViewQRModal = () => {
        setIsViewQROpen(false);
    };

    // Reference to the hidden file input
    const triggerLogoUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    return (
        <>
            <div className="busines-profile">
                <div className="business-profile__page-container">
                    <div className="business-profile__title-wrapper">
                        <p className={`title ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-title' : ''}`}>
                            {isLoadingSettingPage && 'Business Profile'}
                        </p>
                        <p className={`caption ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-caption' : ''}`}>
                            {isLoadingSettingPage && 
                                'Configure business profile and manage company-related information.'
                            }
                        </p>
                    </div>

                    {/* COMPANY LOGO SECTION */}
                    <div className="business-profile__content-wrapper">
                        <div className="business-profile__content-item">
                            <div className="business-profile__content-label">
                                <p className={`company-logo-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Company Logo'}
                                </p>
                            </div>
                            <div className="business-profile__content-value">
                                {companyLogoError ? (
                                    <div className="company-logo-error-wrapper">
                                        <p className="company-logo-error-message">
                                            {companyLogoErrorMsg} ({companyLogoErrorCode})
                                        </p>
                                        <button 
                                            type="button"
                                            onClick={handleCompanyLogoOKButton}
                                        >
                                            OKAY
                                        </button>
                                    </div>
                                ) : (
                                    <div className="avatar">
                                        <CustomLogo 
                                            src={company_logo} 
                                            name={settingsData.store_name}
                                            isLoadingSettingPage={isLoadingSettingPage} 
                                            isUploadingPhoto={isUploadingLogo}
                                            isRemovingPhoto={isRemovingLogo}
                                        />
                                        {/* Hidden input for file upload */}
                                        <input 
                                            type="file" 
                                            style={{ display: 'none' }} 
                                            onChange={onLogoUpload}
                                            ref={fileInputRef}
                                        />
                                        {
                                            isUploadingLogo ? (
                                                <p className="upload-logo-loader">Uploading Logo . . .</p>
                                            ) : isRemovingLogo ? (
                                                <p className="remove-logo-loader">Removing Logo . . .</p>
                                            ) : (
                                                <>
                                                    <p 
                                                        className={`upload-logo-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-photo-btn' : ''}`} 
                                                        onClick={isLoadingSettingPage ? (loggedInUserPerm.can_manage_business ? triggerLogoUpload : displayNoPermModal) : undefined}
                                                    >
                                                        {isLoadingSettingPage ? (company_logo ? 'Change Logo' : 'Upload Logo') : ''}
                                                    </p>
                                                    {company_logo && (
                                                        <p 
                                                            className={`remove-logo-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-photo-btn' : ''}`} 
                                                            onClick={isLoadingSettingPage ? (loggedInUserPerm.can_manage_business ? onLogoRemove : displayNoPermModal) : undefined}
                                                        >
                                                            {isLoadingSettingPage && 'Remove Logo'}
                                                        </p>
                                                    )}
                                                </>
                                            )
                                        }
                                    </div>
                                )}
                            </div>
                            <div className="business-profile__content-action">
                                <button 
                                    type="button"
                                    className="hidden-button"
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* MERCHANT ID SECTION */}
                    <div className="business-profile__content-wrapper">
                        <div className="business-profile__content-item">
                            <div className="business-profile__content-label">
                                <p className={`store-id-label-text ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Merchant ID'}
                                </p>
                            </div>
                            <div className="business-profile__content-value">
                                <p className={`store-id ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-userid' : ''}`}>
                                    {isLoadingSettingPage ? (
                                        <>
                                            {settingsData.merchant_id}
                                            <SIDTooltip 
                                                title="You can't edit your Merchant ID" 
                                                placement="right" 
                                                arrow
                                            >
                                                <span className="value-non-edit">
                                                    <BsInfoCircleFill className="non-edit-info-icon" />
                                                </span>
                                            </SIDTooltip>
                                        </>
                                    ) : ''}
                                </p>
                            </div>
                            <div className="business-profile__content-action">
                                <p></p>
                            </div>
                        </div>
                    </div>

                    {/* STORE NAME SECTION */}
                    <div className="business-profile__content-wrapper">
                        <div className="business-profile__content-item">
                            <div className="business-profile__content-label">
                                <p className={`store-name-label-text ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Store Name'}
                                </p>
                            </div>
                            <div className="business-profile__content-value">
                                {storeNameError ? (
                                    <div className="business-profile__store-name-error-wrapper">
                                        <p className="business-profile__store-name-error-message">
                                            {storeNameErrorMsg} ({storeNameErrorCode})
                                        </p>
                                        <button 
                                            type="button"
                                            onClick={handleStoreNameOKButton}
                                        >
                                            OKAY
                                        </button>
                                    </div>    
                                ) : (
                                    <>
                                        {isSavingStoreNameChanges ? (
                                            <p className="save-edit-store-name-loader">Saving changes . . .</p>
                                        ) : (
                                            <>
                                                {isEditingStoreName ? (
                                                    <div className="store-name-input-wrapper">
                                                        <input
                                                            type="text"
                                                            className="store-name-input"
                                                            value={editedStoreName}
                                                            onChange={handleStoreNameChange}
                                                        />
                                                        <span className="buttons-wrapper">
                                                            <button 
                                                                type="button" 
                                                                className="cancel-edit-store-name-btn"
                                                                onClick={cancelEditStoreName}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button 
                                                                type="button"
                                                                className={`save-edit-store-name-btn ${!editedStoreName.trim() ? "disabled" : ""}`} 
                                                                onClick={saveEditedStoreName} 
                                                                disabled={!editedStoreName.trim()}
                                                            >
                                                                Save
                                                            </button>
                                                        </span>                                 
                                                    </div>
                                                ) : (
                                                    <p className={`store_name ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                                        {isLoadingSettingPage ? settingsData.store_name  : ''}
                                                    </p>            
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="business-profile__content-action">
                                {isSavingStoreNameChanges ? null : 
                                    (isEditingStoreName ? null : 
                                        <button 
                                            className={`store-name__action-button ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''}`}
                                            onClick={isLoadingSettingPage ? (loggedInUserPerm.can_manage_business ? editStoreName : displayNoPermModal) : undefined}
                                        >
                                            {isLoadingSettingPage && 'Update'}
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    {/* STORE QR SECTION */}
                    <div className="business-profile__content-wrapper">
                        <div className="business-profile__content-item">
                            <div className="business-profile__content-label">
                                <p className={`store-qr-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Store QR'}
                                </p>
                            </div>
                            <div className="business-profile__content-value">
                                <p className={`value-output ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                    {isLoadingSettingPage && 'Click the View button to see your Store QR Code'}
                                </p>
                            </div>
                            <div className="business-profile__content-action">
                                <button 
                                    className={`view-qr-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''} ${isViewQROpen ? 'active-button' : ''}`}
                                    onClick={isLoadingSettingPage ? openViewQRModal : undefined}
                                >
                                    {isLoadingSettingPage && 'View'}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* CONTACT NUMBER SECTION */}
                    <div className="business-profile__content-wrapper">
                        <div className="business-profile__content-item">
                            <div className="business-profile__content-label">
                                <p className={`contact-number-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Contact Number'}
                                </p>
                            </div>
                            <div className="business-profile__content-value">
                                {isSavingContactNoChanges ? (
                                    <p className="save-contact-number-loader">Saving changes . . .</p>
                                ) : (
                                    <>
                                        {contactNumberError ? (
                                            <div className="business-profile__contact-number-error-wrapper">
                                                <p className="business-profile__contact-number-error-message">
                                                    {contactNumberErrorMsg} ({contactNumberErrorCode})
                                                </p>
                                                <button 
                                                    type="button"
                                                    onClick={handleContactNumberOKButton}
                                                >
                                                    OKAY
                                                </button>
                                            </div>
                                        ) : (
                                            <>
                                                {isEditingContactNo ? (
                                                    <div className="contact-number__input-wrapper">
                                                        <input
                                                            type="text"
                                                            className="contact-number__input"
                                                            value={editedContactNo}
                                                            onChange={handleContactNoChange}
                                                            maxLength="11"
                                                            inputMode="numeric"
                                                            onKeyDown={(e) => {
                                                                const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
                                                                if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                        <span className="buttons-wrapper">
                                                            <button 
                                                                type="button" 
                                                                className="cancel-edit-contact-no-btn"
                                                                onClick={cancelEditContactNo}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button 
                                                                type="button"
                                                                className={`save-edit-contact-no-btn ${!editedContactNo.trim() ? "disabled" : ""}`} 
                                                                onClick={saveEditedContactNo} 
                                                                disabled={!editedContactNo.trim()}
                                                            >
                                                                Save
                                                            </button>
                                                        </span>                                 
                                                    </div>
                                                ) : (
                                                    <p className={`contact-number ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                                        {isLoadingSettingPage ? `${settingsData.contact_number}` : ''}
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="business-profile__content-action">
                                {isSavingContactNoChanges ? null :
                                    (isEditingContactNo ? null :
                                        <button 
                                            className={`contact-number-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''}`}
                                            onClick={isLoadingSettingPage ? (loggedInUserPerm.can_manage_business ? editContactNo : displayNoPermModal) : undefined}
                                        >
                                            {isLoadingSettingPage && 'Update'}
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    {/* WEBSITE SECTION */}
                    <div className="business-profile__content-wrapper">
                        <div className="business-profile__content-item">
                            <div className="business-profile__content-label">
                                <p className={`website-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Website'}
                                </p>
                            </div>
                            <div className="business-profile__content-value">
                                {isSavingWebsiteChanges ? (
                                    <p className="save-website-loader">Saving changes . . .</p>
                                ) : (
                                    <>
                                        {editWebsiteError ? (
                                            <div className="business-profile__edit-website-error-wrapper">
                                                <p className="business-profile__edit-website-error-message">
                                                    {editWebsiteErrorMsg} ({editWebsiteErrorCode})
                                                </p>
                                                <button 
                                                    type="button"
                                                    onClick={handleEditWebsiteOKButton}
                                                >
                                                    OKAY
                                                </button>
                                            </div>
                                        ) : (
                                            <>
                                                {isEditingWebsite ? (
                                                    <div className="website__input-wrapper">
                                                        <input
                                                            type="text"
                                                            className="website__input"
                                                            value={editedWebsite}
                                                            onChange={handleWebsiteChange}
                                                        />
                                                        <span className="buttons-wrapper">
                                                            <button 
                                                                type="button" 
                                                                className="cancel-edit-website-btn"
                                                                onClick={cancelEditWebsite}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button 
                                                                type="button"
                                                                className={`save-edit-website-btn ${!editedWebsite.trim() ? "disabled" : ""}`} 
                                                                onClick={saveEditedWebsite} 
                                                                disabled={!editedWebsite.trim()}
                                                            >
                                                                Save
                                                            </button>
                                                        </span>                                 
                                                    </div>
                                                ) : (
                                                    <>
                                                        {settingsData.website 
                                                            ? 
                                                                <p className={`withValue ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                                                    {isLoadingSettingPage ? settingsData.website : ''}
                                                                </p>
                                                            : 
                                                                <p className={`noValue ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                                                    {isLoadingSettingPage && 'No website has been added to this merchant'}
                                                                </p>
                                                        }
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="business-profile__content-action">
                                {isSavingWebsiteChanges ? null:
                                    (isEditingWebsite ? null:
                                        <button 
                                            className={`website-action-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''}`}
                                            onClick={isLoadingSettingPage ? (loggedInUserPerm.can_manage_business ? editWebsite : displayNoPermModal) : undefined}
                                        >
                                            {isLoadingSettingPage ? (settingsData.website ? 'Update' : 'Add') : ''}
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {isViewQROpen && (
                    <ViewQR qrValue={settingsData.store_qr} onClose={closeViewQRModal} />
                )}
            </div>
        </>
    )
}

export default BusinesProfile