import React from 'react';
import { BsEmojiFrown } from "react-icons/bs";
import '../../../Styles/AuthStyles/Login/LoginError.css';


function LoginError({ loginErrorMsg, loginErrorAction, loginErrorCode, closeLoginErrorModal }) {
    return (
        <>
            <div className="login-error">
                <div className="login-error__page-container">
                    <div className="login-error__content-wrapper">
                        <div className="login-error__error-message-wrapper">
                            <BsEmojiFrown  className="login-error__error-message-icon"/>
                            <p className="login-error__error-msg">{loginErrorMsg}</p>
                            <p className="login-error__error-action">{loginErrorAction}</p>
                            <p className="login-error__error-code">{loginErrorCode}</p>
                            <button 
                                type="button" 
                                onClick={closeLoginErrorModal}
                            >
                                OKAY
                            </button>
                        </div>    
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginError