import React from 'react';
import { BsExclamationCircleFill, BsChevronRight } from 'react-icons/bs';
import '../../../Styles/MainStyles/Home/NoBankMsg.css';

function NoBankMsg({ open, onClose, seePayoutSettings}) {

    if (!open) return null;

    return (
        <>
            <div className="noBankMsg">
                <div className="noBankMsg__page-container">
                    <div className="noBankMsg__header">
                        <BsExclamationCircleFill className="noBankMsg__title-icon" />
                        <p className="noBankMsg__title">No Bank Account Added</p>
                    </div>
                    <div className="noBankMsg__body">
                        <p className="noBankMsg__detail1">No bank account has been added to this merchant.</p>
                        <p className="noBankMsg__detail2">Navigate to the Payout Settings then add a checking or savings bank account to pay out your balance.</p>
                    </div>
                    <div className="noBankMsg__footer">
                        <button 
                            type="button"
                            className="noBankMsg__close-btn"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button 
                            type="button"
                            className="noBankMsg__navigate-btn"
                            onClick={seePayoutSettings}
                        >
                            Payout Settings
                            <BsChevronRight className="noBankMsg__chevron"/>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoBankMsg