import React from 'react';
import { Formik, Form } from 'formik';
import CSRFToken from '../../../components/CSRFToken';
import * as Yup from 'yup';
import LoginLoader from './LoginLoader';
import ResendOTPLoader from './ResendOTPLoader';
import Logo from '../../../assets/logo.svg';
import '../../../Styles/AuthStyles/Login/ConfirmLogin.css'

function ConfirmLogin ({ 
    wrongOTP,
    expiredOTP,
    otpError,
    returnedEmail,
    submitConfirmLogin,
    loginData,
    updateLoginData,
    resendingOTP,
    resentOTPSuccessful,
    submitResendLoginOTP,
    resetInvalidOTPMsg,
    isLoggingIn
}) {

    // Form Validation
    const validateOTP = Yup.object().shape({
        otp: Yup.string()
          .required('Verification code is required')
          .matches(/^[0-9]\d{5}$/, {message: "Verification code must be 6 digits.", excludeEmptyString: false}),
    })


    return (
        <>
            <div className="confirm-login" onClick={(e) => {e.stopPropagation()}}>
                <div className="confirm-login__page-container">
                    <Formik
                        initialValues={loginData}
                        onSubmit={submitConfirmLogin}
                        validationSchema={validateOTP}
                    >

                        {({ handleChange, handleBlur, setFieldValue, values, errors, touched, isValid, dirty  }) => {
                            
                            const handleOtpPaste = (e, setFieldValue) => {
                                e.preventDefault();
                                const pastedData = e.clipboardData.getData("text").trim();
                                if (pastedData.length === 6 && /^\d{6}$/.test(pastedData)) {
                                    setFieldValue("otp", pastedData);
                                    updateLoginData({ ...values, otp: pastedData });
                                    resetInvalidOTPMsg();
                                }
                            };

                            return (
                                <Form>
                                    <CSRFToken />

                                    {/* HEADER SECTION */}
                                    <div className="confirm-login__header">
                                        <img src={Logo} alt="Finduku Logo"/>
                                        <p className="confirm-login__subheading">We're just making sure it's you.</p>
                                    </div>

                                    {/* BODY SECTION */}
                                    <div className="confirm-login__body">

                                        {resentOTPSuccessful 
                                            ?   (<div className="confirm-login__resend-otp">
                                                    <p className="confirm-login__resend-otp-text">A new OTP has been sent to your email.</p>
                                                </div>)
                                            :   null 
                                        }

                                        {/* DIRECTION SECTION */}
                                        <div className="confirm-login__direction-wrapper">
                                            <p className="confirm-login__direction-text">
                                                We sent a single-use verification code to your email, <span className="email">{returnedEmail}</span>
                                            </p>
                                        </div>

                                        {/* OTP INPUT SECTION */}
                                        <div className="confirm-login__otp-wrapper" onPaste={(e) => handleOtpPaste(e, setFieldValue)}>
                                            <input
                                                type="text"
                                                className={`confirm-login__otp-input ${
                                                    errors.otp && touched.otp
                                                    ? "confirm-login__input-error"
                                                    : (wrongOTP || expiredOTP)
                                                    ? "confirm-login__wrong-otp-error"
                                                    : null
                                                }`}
                                                name="otp"
                                                inputMode="numeric"
                                                value={values.otp}
                                                autoComplete="off"
                                                onKeyDown={(e) => {
                                                    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'v'];
                                                    const isCtrlV = (e.ctrlKey || e.metaKey) && e.key === 'v'; 
                                                
                                                    if (!/\d/.test(e.key) && !allowedKeys.includes(e.key) && !isCtrlV) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    updateLoginData({ ...values, otp: e.target.value });
                                                    resetInvalidOTPMsg();
                                                }}
                                                onBlur={handleBlur}
                                                maxLength="6"
                                            /> 
                                        </div>

                                        {/* VALIDATION ERRORS SECTION */}
                                        <div className="confirm-login__verify-error-wrapper">
                                            {errors.otp && touched.otp && <p className="confirm-login__verify-error">{errors.otp}</p>}
                                            {wrongOTP && <p className="confirm-login__verify-error">{otpError}</p>}
                                            {expiredOTP && <p className="confirm-login__verify-error">{otpError}</p>}
                                        </div>

                                        {/* BUTTON SECTION */}
                                        <div className="confirm-login__button-wrapper">
                                            <button 
                                                type="submit" 
                                                className={`confirm-login__submit-button ${!(values.otp && values.otp.length === 6) ? "disabled" : ""}`}
                                                disabled={!(values.otp && values.otp.length === 6)}
                                            >
                                                {isLoggingIn ? "Confirming" : "CONFIRM"}
                                            </button>
                                        </div>

                                        {/* RESENT CODE SECTION */}
                                        <div className="confirm-login__resend-code-wrapper">
                                            <p className="confirm-login__resend-code">Haven't receive the code?</p>
                                            <p className="confirm-login__resend-code-link" onClick={submitResendLoginOTP}>Resend code</p>
                                        </div>
                                    </div>

                                    {/* FOOTER SECTION */}
                                    <div className="confirm-login__footer">
                                        <div className="confirm-login__code-direction-wrapper">
                                            <p className="confirm-login__code-direction-text">
                                                Please enter your verification code to access your Finduku Account.
                                                Your code will expire if not used in 5 minutes.
                                            </p>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
                {isLoggingIn &&
                    <LoginLoader />
                }
                {resendingOTP &&
                    <ResendOTPLoader />
                }
            </div>
        </>
    )
};



export default ConfirmLogin;