import React from 'react';
import { useSelector } from 'react-redux';
import MUITooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import '../../../../Styles/MainStyles/Settings/ManageUsers/ManageUsers.css';
import { 
    BsPencilSquare, 
    BsFillXCircleFill,
    BsPlusLg 
} from 'react-icons/bs'

const EditUserTooltip = styled(({ className, ...props }) => (
    <MUITooltip classes={{ popper: className }} {...props} />
))({
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'white',  
        maxWidth: 480,         
        color: 'black',
        fontFamily: 'Figtree',
        fontSize: '14px',
        boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.25)', 
        padding: '5px',
    },
    '& .MuiTooltip-arrow': {
        color: 'white',  // Change this to your preferred color
    },
});

const DeleteUserTooltip = styled(({ className, ...props }) => (
    <MUITooltip classes={{ popper: className }} {...props} />
))({
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'white',  
        maxWidth: 480,         
        color: 'black',
        fontFamily: 'Figtree',
        fontSize: '14px',
        boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.25)', 
        padding: '5px',
    },
    '& .MuiTooltip-arrow': {
        color: 'white',  // Change this to your preferred color
    },
});

function ManageUsers({ 
    usersData, 
    isLoadingSettingPage, 
    openAddUserModal, 
    addUserModalActive, 
    handleEditClick,
    selectedUserIdToEdit,
    selectedUserIdToDelete,
    handleDeleteUserClick,
    loggedInUserPerm,
    displayNoPermModal 
}) {

    const loggedInUserEmail = useSelector((state) => state.auth.user ? state.auth.user.email : '');

    // FUNCTIONS SETTING STATUS CLASS
    const getUserStatusClass = (status) => {
        switch(status) {
            case 'Active':
                return 'manage-users__status-active';
            case 'Inactive':
                return 'manage-users__status-inactive';
            case 'Invited':
                return 'manage-users__status-invited';
            default:
                return '';
        }
    }

    const getFullName = (user) => `${user.first_name} ${user.last_name}`;
    
    return (
        <>
            <div className="manage-users">
                <div className="manage-users__page-container">
                    <div className="manage-users__title-wrapper">
                        <p className={`title ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-title' : ''}`}>
                            {isLoadingSettingPage && 'Manage Users'}
                        </p>
                        <p className={`caption ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-caption' : ''}`}>
                            {isLoadingSettingPage && 
                                "View and manage account users and their permissions."
                            }
                        </p>                        
                    </div>
                    <div className="manage-users__content-wrapper">
                        <div className="manage-users__top-content">
                            <div className="manage-users__add-user-wrapper">
                                <p
                                    className={`manage-users__add-user-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-add-user-btn' : ''} ${addUserModalActive ? 'modal-active' : ''}`}
                                    onClick={isLoadingSettingPage ? (loggedInUserPerm.can_manage_users ? openAddUserModal : displayNoPermModal) : undefined}
                                >
                                    {isLoadingSettingPage &&
                                        <>
                                            <BsPlusLg className="manage-users__add-user-icon"/>
                                            Add User
                                        </>
                                    }
                                </p>
                            </div>
                            <div className="manage-users__add-users-text-wrapper" >
                                <p className={`manage-users__add-users-text ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-add-users' : ''}`}>
                                    {isLoadingSettingPage && 'You can add up to 5 member users to your account.'}
                                </p>
                            </div>
                        </div>
                        <table className="manage-users__table">
                            <thead>
                                <tr className="manage-users__table-heading">
                                    <td className="manage-users__name-heading">
                                        {isLoadingSettingPage && 'NAME'}
                                    </td>
                                    <td className="manage-users__email-heading">
                                        {isLoadingSettingPage && 'EMAIL'}
                                    </td>
                                    <td className="manage-users__role-heading">
                                        {isLoadingSettingPage && 'ROLE'}
                                    </td>
                                    <td className="manage-users__status-heading">
                                        {isLoadingSettingPage && 'STATUS'}
                                    </td>
                                    <td className="manage-users__actions-heading">
                                        {isLoadingSettingPage && 'ACTIONS'}
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoadingSettingPage && (
                                    <>
                                        {usersData.map((user) => (
                                            <tr key={user.user_id} className={`manage-users__table-body ${selectedUserIdToEdit === user.user_id || selectedUserIdToDelete === user.user_id ? 'edit-user-td-active' : ''}`}>
                                                <td className="manage-users__name-data">{getFullName(user)}</td>
                                                <td className="manage-users__email-data">{user.email}</td>
                                                <td className="manage-users__role-data">{user.role}</td>
                                                <td className="manage-users__status-data">
                                                    <span className={`manage-users__status ${getUserStatusClass(user.status)}`}>
                                                        {user.status}
                                                    </span>
                                                </td>
                                                <td className="manage-users__actions-data">
                                                    {(user.email !== loggedInUserEmail && user.role !== "Account Owner") ? (
                                                        <>
                                                            <EditUserTooltip
                                                                title="Edit User" 
                                                                placement="top" 
                                                                arrow
                                                            >
                                                                <span>
                                                                    <BsPencilSquare 
                                                                        className={`manage-users__edit-action-icon ${selectedUserIdToEdit === user.user_id ? 'edit-icon-active' : ''} `}
                                                                        onClick={() => {
                                                                            if (loggedInUserPerm.can_manage_users) {
                                                                                handleEditClick(user);
                                                                            } else {
                                                                                displayNoPermModal();
                                                                            }
                                                                        }}
                                                                    />
                                                                </span>
                                                            </EditUserTooltip>
                                                            <DeleteUserTooltip
                                                                title="Remove User" 
                                                                placement="right" 
                                                                arrow
                                                            >
                                                                <span>
                                                                    <BsFillXCircleFill 
                                                                        className={`manage-users__delete-action-icon ${selectedUserIdToDelete === user.user_id ? 'delete-icon-active' : ''} `} 
                                                                        onClick={() => {
                                                                            if (loggedInUserPerm.can_manage_users) {
                                                                                handleDeleteUserClick(user);
                                                                            } else {
                                                                                displayNoPermModal();
                                                                            }
                                                                        }}
                                                                    />
                                                                </span>
                                                            </DeleteUserTooltip>
                                                        </>
                                                    ): (
                                                        <span>—</span>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageUsers