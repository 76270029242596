import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import Cookies from 'js-cookie';

const FETCH_GROSS_VOLUME_DATA_ENDPOINT = '/apps/fetch_gross_volume_data/';
const FETCH_NET_VOLUME_DATA_ENDPOINT = '/apps/fetch_net_volume_data/';
const FETCH_FEE_SUMMARY_DATA_ENDPOINT = '/apps/fetch_fee_summary_data/';

const isOlderThan5Minutes = (timestamp) => {
    if (!timestamp) return true;
    const now = new Date();
    const lastFetched = new Date(timestamp);
    const diff = (now - lastFetched) / 1000 / 60; // difference in minutes
    return diff > 5;
};

export const fetchGrossVolumeData = createAsyncThunk(
    'data/gross_volume',
    async (_, { getState, rejectWithValue }) => {
        const state = getState();
        const lastFetchedTime = state.insights.grossVolumeData.lastFetchedTime;

        if (!isOlderThan5Minutes(lastFetchedTime)) {
            //Data is up to date, do nothing
            return null;
        }

        const merchant_id = getState().merchant.profile ? getState().merchant.profile.merchant_id : '';
        try {
            const res = await axios.post(
                FETCH_GROSS_VOLUME_DATA_ENDPOINT,
                JSON.stringify({ merchant_id: merchant_id }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                return {
                    grossVolume: res.data.grossVolume,
                    lastFetchedTime: new Date().toISOString()
                };
            } else {
                console.error('Invalid data received', res.data);
                return rejectWithValue({
                        message: "Oops! We encountered an error.",
                        action: "Please try again or contact support.",
                        code: "ERR_FINDUKU_1000"
                });
            }
        } catch (error) {
            console.error('Failed to fetch gross volume', error);
            let errorMsg, errorAction, errorCode;
            if (error.response) {
                switch (error.response.status) {
                        case 400:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_3000";
                            break;
                        case 401:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Please login your account.";
                            errorCode = "ERR_FINDUKU_4000";
                            break;
                        case 403:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Click Okay to log in again now.";
                            errorCode = "ERR_FINDUKU_5000";
                            break;
                        case 404:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_6000";
                            break;
                        case 500:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_7000";
                            break;
                        default:
                            errorMsg = "Oops! We encountered an error";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_2000";
                }
                return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
            } else {
                return rejectWithValue({
                        message: "Oops! We encountered an error",
                        action: "Please try again or contact support.",
                        code: "ERR_FINDUKU_8000"
                })
            }
        }
    }
);

export const fetchNetVolumeData = createAsyncThunk(
    'data/net_volume',
    async (_, { getState, rejectWithValue }) => {
        const state = getState();
        const lastFetchedTime = state.insights.netVolumeData.lastFetchedTime;

        if (!isOlderThan5Minutes(lastFetchedTime)) {
            //Data is up to date, do nothing
            return null;
        }

        const merchant_id = getState().merchant.profile ? getState().merchant.profile.merchant_id : '';
        try {
            const res = await axios.post(
                FETCH_NET_VOLUME_DATA_ENDPOINT,
                JSON.stringify({ merchant_id: merchant_id }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                return {
                    netVolume: res.data.netVolume,
                    lastFetchedTime: new Date().toISOString()
                };
            } else {
                console.error('Invalid data received', res.data);
                return rejectWithValue({
                        message: "Oops! We encountered an error.",
                        action: "Please try again or contact support.",
                        code: "ERR_FINDUKU_1000"
                });
            }
        } catch (error) {
            console.error('Failed to fetch net volume', error);
            let errorMsg, errorAction, errorCode;
            if (error.response) {
                switch (error.response.status) {
                        case 400:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_3000";
                            break;
                        case 401:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Please login your account.";
                            errorCode = "ERR_FINDUKU_4000";
                            break;
                        case 403:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Click Okay to log in again now.";
                            errorCode = "ERR_FINDUKU_5000";
                            break;
                        case 404:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_6000";
                            break;
                        case 500:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_7000";
                            break;
                        default:
                            errorMsg = "Oops! We encountered an error";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_2000";
                }
                return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
            } else {
                return rejectWithValue({
                        message: "Oops! We encountered an error",
                        action: "Please try again or contact support.",
                        code: "ERR_FINDUKU_8000"
                })
            }
        }
    }
);


export const fetchFeeSummaryData = createAsyncThunk(
    'data/fee_summary',
    async (_, { getState, rejectWithValue }) => {
        const state = getState();
        const lastFetchedTime = state.insights.feeSummaryData.lastFetchedTime;

        if (!isOlderThan5Minutes(lastFetchedTime)) {
            //Data is up to date, do nothing
            return null;
        }

        const merchant_id = getState().merchant.profile ? getState().merchant.profile.merchant_id : '';
        try {
            const res = await axios.post(
                FETCH_FEE_SUMMARY_DATA_ENDPOINT,
                JSON.stringify({ merchant_id: merchant_id }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                return {
                    feeSummary: res.data.feeSummary,
                    lastFetchedTime: new Date().toISOString()
                };
            } else {
                console.error('Invalid data received', res.data);
                return rejectWithValue({
                        message: "Oops! We encountered an error.",
                        action: "Please try again or contact support.",
                        code: "ERR_FINDUKU_1000"
                });
            }
        } catch (error) {
            console.error('Failed to fetch fee summary', error);
            let errorMsg, errorAction, errorCode;
            if (error.response) {
                switch (error.response.status) {
                        case 400:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_3000";
                            break;
                        case 401:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Please login your account.";
                            errorCode = "ERR_FINDUKU_4000";
                            break;
                        case 403:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Click Okay to log in again now.";
                            errorCode = "ERR_FINDUKU_5000";
                            break;
                        case 404:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_6000";
                            break;
                        case 500:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_7000";
                            break;
                        default:
                            errorMsg = "Oops! We encountered an error";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_2000";
                }
                return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
            } else {
                return rejectWithValue({
                        message: "Oops! We encountered an error",
                        action: "Please try again or contact support.",
                        code: "ERR_FINDUKU_8000"
                })
            }
        }
    }
);


export const updateGrossVolumeData = createAsyncThunk(
    'data/update_gross_volume',
    async (_, { getState, rejectWithValue }) => {
        const merchant_id = getState().merchant.profile ? getState().merchant.profile.merchant_id : '';
        try {
            const res = await axios.post(
                FETCH_GROSS_VOLUME_DATA_ENDPOINT,
                JSON.stringify({ merchant_id: merchant_id }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                return {
                    grossVolume: res.data.grossVolume,
                };
            } else {
                console.error('Invalid data received', res.data);
                return rejectWithValue({
                        message: "Oops! We encountered an error.",
                        action: "Please try again or contact support.",
                        code: "ERR_FINDUKU_1000"
                });
            }
        } catch (error) {
            console.error('Failed to fetch gross volume', error);
            let errorMsg, errorAction, errorCode;
            if (error.response) {
                switch (error.response.status) {
                        case 400:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_3000";
                            break;
                        case 401:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Please login your account.";
                            errorCode = "ERR_FINDUKU_4000";
                            break;
                        case 403:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Click Okay to log in again now.";
                            errorCode = "ERR_FINDUKU_5000";
                            break;
                        case 404:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_6000";
                            break;
                        case 500:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_7000";
                            break;
                        default:
                            errorMsg = "Oops! We encountered an error";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_2000";
                }
                return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
            } else {
                return rejectWithValue({
                        message: "Oops! We encountered an error",
                        action: "Please try again or contact support.",
                        code: "ERR_FINDUKU_8000"
                })
            }
        }
    }
);

export const updateNetVolumeData = createAsyncThunk(
    'data/update_net_volume',
    async (_, { getState, rejectWithValue }) => {
        const merchant_id = getState().merchant.profile ? getState().merchant.profile.merchant_id : '';
        try {
            const res = await axios.post(
                FETCH_NET_VOLUME_DATA_ENDPOINT,
                JSON.stringify({ merchant_id: merchant_id }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                return {
                    netVolume: res.data.netVolume,
                };
            } else {
                console.error('Invalid data received', res.data);
                return rejectWithValue({
                        message: "Oops! We encountered an error.",
                        action: "Please try again or contact support.",
                        code: "ERR_FINDUKU_1000"
                });
            }
        } catch (error) {
            console.error('Failed to fetch net volume', error);
            let errorMsg, errorAction, errorCode;
            if (error.response) {
                switch (error.response.status) {
                        case 400:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_3000";
                            break;
                        case 401:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Please login your account.";
                            errorCode = "ERR_FINDUKU_4000";
                            break;
                        case 403:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Click Okay to log in again now.";
                            errorCode = "ERR_FINDUKU_5000";
                            break;
                        case 404:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_6000";
                            break;
                        case 500:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_7000";
                            break;
                        default:
                            errorMsg = "Oops! We encountered an error";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_2000";
                }
                return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
            } else {
                return rejectWithValue({
                        message: "Oops! We encountered an error",
                        action: "Please try again or contact support.",
                        code: "ERR_FINDUKU_8000"
                })
            }
        }
    }
);


export const updateFeeSummaryData = createAsyncThunk(
    'data/update_fee_summary',
    async (_, { getState, rejectWithValue }) => {
        const merchant_id = getState().merchant.profile ? getState().merchant.profile.merchant_id : '';
        try {
            const res = await axios.post(
                FETCH_FEE_SUMMARY_DATA_ENDPOINT,
                JSON.stringify({ merchant_id: merchant_id }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                return {
                    feeSummary: res.data.feeSummary,
                };
            } else {
                console.error('Invalid data received', res.data);
                return rejectWithValue({
                        message: "Oops! We encountered an error.",
                        action: "Please try again or contact support.",
                        code: "ERR_FINDUKU_1000"
                });
            }
        } catch (error) {
            console.error('Failed to fetch fee summary', error);
            let errorMsg, errorAction, errorCode;
            if (error.response) {
                switch (error.response.status) {
                        case 400:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_3000";
                            break;
                        case 401:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Please login your account.";
                            errorCode = "ERR_FINDUKU_4000";
                            break;
                        case 403:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Click Okay to log in again now.";
                            errorCode = "ERR_FINDUKU_5000";
                            break;
                        case 404:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_6000";
                            break;
                        case 500:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_7000";
                            break;
                        default:
                            errorMsg = "Oops! We encountered an error";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_2000";
                }
                return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
            } else {
                return rejectWithValue({
                        message: "Oops! We encountered an error",
                        action: "Please try again or contact support.",
                        code: "ERR_FINDUKU_8000"
                })
            }
        }
    }
);



export const insightsSlice = createSlice({
    name: 'insights',
    initialState:{
        grossVolumeData: {
            grossVolume: {},
            loading: false,
            error: null,
            lastFetchedTime: null,
        },
        netVolumeData: {
            netVolume: {},
            loading: false,
            error: null,
            lastFetchedTime: null,
        },
        feeSummaryData: {
            feeSummary: {},
            loading: false,
            error: null,
            lastFetchedTime: null,
        },
    },
    reducers: {
        reset_insights: (state) => {
            state.grossVolumeData = {
                grossVolume : {},
                loading : false,
                error : null,
                lastFetchedTime : null,
            };
            state.netVolumeData = {
                netVolume: {},
                loading: false,
                error: null,
                lastFetchedTime: null,
            };
            state.feeSummaryData = {
                feeSummary: {},
                loading: false,
                error: null,
                lastFetchedTime: null,
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGrossVolumeData.pending, (state) => {
                state.grossVolumeData.loading = true;
                state.grossVolumeData.error = null;
            })
            .addCase(fetchGrossVolumeData.fulfilled, (state, action) => {
                if (action.payload) {
                    const { grossVolume, lastFetchedTime } = action.payload;
                    state.grossVolumeData.grossVolume = grossVolume;
                    state.grossVolumeData.lastFetchedTime = lastFetchedTime;
                }
                state.grossVolumeData.loading = false;
            })
            .addCase(fetchGrossVolumeData.rejected, (state, action) => {
                state.grossVolumeData.loading = false;
                state.grossVolumeData.error = action.payload || {
                    message: "An unexpected error occurred",
                    action: "Please try again later.",
                    code: "ERR_FINDUKU_UNKNOWN"
                };
            })
            .addCase(fetchNetVolumeData.pending, (state) => {
                state.netVolumeData.loading = true;
                state.netVolumeData.error = null;
            })
            .addCase(fetchNetVolumeData.fulfilled, (state, action) => {
                if (action.payload) {
                    const { netVolume, lastFetchedTime } = action.payload;
                    state.netVolumeData.netVolume = netVolume;
                    state.netVolumeData.lastFetchedTime = lastFetchedTime;
                }
                state.netVolumeData.loading = false;
            })
            .addCase(fetchNetVolumeData.rejected, (state, action) => {
                state.netVolumeData.loading = false;
                state.netVolumeData.error = action.payload || {
                    message: "An unexpected error occurred",
                    action: "Please try again later.",
                    code: "ERR_FINDUKU_UNKNOWN"
                };
            })
            .addCase(fetchFeeSummaryData.pending, (state) => {
                state.feeSummaryData.loading = true;
                state.feeSummaryData.error = null;
            })
            .addCase(fetchFeeSummaryData.fulfilled, (state, action) => {
                if (action.payload) {
                    const { feeSummary, lastFetchedTime } = action.payload;
                    state.feeSummaryData.feeSummary = feeSummary;
                    state.feeSummaryData.lastFetchedTime = lastFetchedTime;
                }
                state.feeSummaryData.loading = false;
            })
            .addCase(fetchFeeSummaryData.rejected, (state, action) => {
                state.feeSummaryData.loading = false;
                state.feeSummaryData.error = action.payload || {
                    message: "An unexpected error occurred",
                    action: "Please try again later.",
                    code: "ERR_FINDUKU_UNKNOWN"
                };
            })
            .addCase(updateGrossVolumeData.pending, (state) => {
                state.grossVolumeData.loading = false;
                state.grossVolumeData.error = null;
            })
            .addCase(updateGrossVolumeData.fulfilled, (state, action) => {
                if (action.payload) {
                    const { grossVolume } = action.payload;
                    state.grossVolumeData.grossVolume = grossVolume;
                }
                state.grossVolumeData.loading = false;
            })
            .addCase(updateGrossVolumeData.rejected, (state, action) => {
                state.grossVolumeData.loading = false;
                state.grossVolumeData.error = action.payload || {
                    message: "An unexpected error occurred",
                    action: "Please try again later.",
                    code: "ERR_FINDUKU_UNKNOWN"
                };
            })
            .addCase(updateNetVolumeData.pending, (state) => {
                state.netVolumeData.loading = false;
                state.netVolumeData.error = null;
            })
            .addCase(updateNetVolumeData.fulfilled, (state, action) => {
                if (action.payload) {
                    const { netVolume } = action.payload;
                    state.netVolumeData.netVolume = netVolume;
                }
                state.netVolumeData.loading = false;
            })
            .addCase(updateNetVolumeData.rejected, (state, action) => {
                state.netVolumeData.loading = false;
                state.netVolumeData.error = action.payload || {
                    message: "An unexpected error occurred",
                    action: "Please try again later.",
                    code: "ERR_FINDUKU_UNKNOWN"
                };
            })
            .addCase(updateFeeSummaryData.pending, (state) => {
                state.feeSummaryData.loading = false;
                state.feeSummaryData.error = null;
            })
            .addCase(updateFeeSummaryData.fulfilled, (state, action) => {
                if (action.payload) {
                    const { feeSummary } = action.payload;
                    state.feeSummaryData.feeSummary = feeSummary;
                }
                state.feeSummaryData.loading = false;
            })
            .addCase(updateFeeSummaryData.rejected, (state, action) => {
                state.feeSummaryData.loading = false;
                state.feeSummaryData.error = action.payload || {
                    message: "An unexpected error occurred",
                    action: "Please try again later.",
                    code: "ERR_FINDUKU_UNKNOWN"
                };
            });

    }
});

export const { reset_insights } = insightsSlice.actions;
export default insightsSlice.reducer;
