import React, { useState } from 'react';
import CSRFToken from '../../../../components/CSRFToken';
import * as Yup from 'yup';
import axios from '../../../../api/axios';
import Cookies from 'js-cookie';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { BsCheck2Circle, BsEmojiFrown, BsExclamationCircle } from 'react-icons/bs';
import CreatePayoutLoader from './CreatePayoutLoader';
import NoPermModal from '../NoPermModal';
import '../../../../Styles/MainStyles/Transactions/Payouts/CreatePayout.css';

//BACKEND API ENDPOINTS
const CREATE_PAYOUT_URL = '/apps/create_payout/'


function CreatePayout({ open, onClose }) {
    const merchant_id = useSelector((state) => state.merchant.profile ? state.merchant.profile.merchant_id : '');
    const bankData = useSelector((state) => state.settings.bankData);
    const balance = useSelector((state) => state.home.balanceData);
    const [payoutData, setPayoutData] = useState({
        merchant_id: merchant_id,
        amount: '',
        description: '',
    })
    const [payoutResult, setPayoutResult] = useState([])
    const [amountDisplay, setAmountDisplay] = useState('');
    const [isSubmittingPayout, setIsSubmittingPayout] = useState(false);
    const [isPayoutSuccessful, setIsPayoutSuccessful] = useState(false);

    const [createPayoutError, setCreatePayoutError] = useState(false)
    const [createPayoutErrorMsg, setCreatePayoutErrorMsg] = useState(null)
    const [createPayoutErrorAction, setCreatePayoutErrorAction] = useState(null)
    const [createPayoutErrorCode, setCreatePayoutErrorCode] = useState(null)
    const [showNoPermModal, setShowNoPermModal] = useState(false)

    const hideNoPermModal = () => {
        setShowNoPermModal(false)
    }

    const maskAccountNumber = (accountNumber) => {
        return `••••${accountNumber.slice(-4)}`;
    };

    //FUNCTION HANDLING VALIDATION
    const validate = Yup.object({
        amount: Yup.string()
        .required('Amount is required')
        .matches(/^\d+(\.\d{1,2})?$/, { message: "Amount must be a valid number.", excludeEmptyString: false })
        .test('is-not-zero', 'Amount cannot be zero.', value => value !== '0.00')
        .test('is-below-balance', 'Amount exceeds account balance.', value => {
            const enteredAmount = parseFloat(value);
            const availableBalance = parseFloat(balance.amount); // Assuming balance.balance is a string
            return enteredAmount <= availableBalance;
        })
        .test('is-greater-than-minimum', 'Amount should not be less than \u20B1100.00.', value => {
            const enteredAmount = parseFloat(value);
            return enteredAmount >= 100;
        })
          .test('is-less-than-maximum', 'Amount should not exceed \u20B150,000.00.', value => {
            const enteredAmount = parseFloat(value);
            return enteredAmount <= 50000;
        }),
    })

  
    //FUNCTION RESETTING ALL STATES WHEN THE CLOSE/CANCEL BUTTON IS CLICKED
    const resetState = () => {
        setPayoutData({
            merchant_id: merchant_id,
            amount: '',
            description: ''
        });
        setIsPayoutSuccessful(false);
        setCreatePayoutError(false);
        setAmountDisplay('');
    };
    
    //FUNCTION SUBMITTING NEW PAYOUT REQUEST TO THE BACKEND
    const submitPayout = async (payoutData) => {
        setIsSubmittingPayout(true)
        try {
            const res = await axios.post(
                CREATE_PAYOUT_URL,
                JSON.stringify({ 
                    merchant_id: payoutData.merchant_id, 
                    amount: payoutData.amount, 
                    description: payoutData.description
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success){
                setPayoutResult(res.data.result);
                setIsPayoutSuccessful(true);
            } else if (res.data.user_no_perm){
                setShowNoPermModal(true);
            } else {
                console.error('Invalid data received', res.data)
                setCreatePayoutError(true);
                setCreatePayoutErrorMsg("Oops! We encountered an error.")
                setCreatePayoutErrorAction("Please try again or contact support.")
                setCreatePayoutErrorCode("ERR_FINDUKU_1000")
            }

        } catch (error) {
            console.error('Failed to request new payout', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setCreatePayoutErrorMsg("Oops! We encountered an error.");
                        setCreatePayoutErrorAction("Please try again or contact support.")
                        setCreatePayoutErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setCreatePayoutErrorMsg("Oops! Authorization is required.");
                        setCreatePayoutErrorAction("Please login your account.")
                        setCreatePayoutErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setCreatePayoutErrorMsg("Oops! We encountered an error.");
                        setCreatePayoutErrorAction("Please try again or contact support.")
                        setCreatePayoutErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setCreatePayoutErrorMsg("Oops! We encountered an error.");
                        setCreatePayoutErrorAction("Please try again or contact support.")
                        setCreatePayoutErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setCreatePayoutErrorMsg("Oops! We encountered an error.");
                        setCreatePayoutErrorAction("Please try again or contact support.")
                        setCreatePayoutErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setCreatePayoutErrorMsg("Oops! We encountered an error");
                        setCreatePayoutErrorAction("Please try again or contact support.")
                        setCreatePayoutErrorCode("ERR_FINDUKU_2000")                    
                }
            } else {
                setCreatePayoutErrorMsg("Oops! We encountered an error");
                setCreatePayoutErrorAction("Please try again or contact support.")
                setCreatePayoutErrorCode("ERR_FINDUKU_8000") 
            }           
            setCreatePayoutError(true);       
        } finally {
            setIsSubmittingPayout(false);
        }
    };

    if (!open) return null;

    return (
        <>
            <div className="create-payout" onClick={(e) => {e.stopPropagation()}}>
                <div className="create-payout__page-container">
                    {createPayoutError ? (
                        <div className="create-payout__error-wrapper">
                            <div className="create-payout__error-message-container">
                                <BsEmojiFrown  className="create-payout__error-message-icon"/>
                                <p className="create-payout__error-msg">{createPayoutErrorMsg}</p>
                                <p className="create-payout__error-action">{createPayoutErrorAction}</p>
                                <p className="create-payout__error-code">{createPayoutErrorCode}</p>
                            </div>
                            <div className="create-payout__error-message-btn-wrapper">
                                <button 
                                    type="button" 
                                    className="create-payout__error-message-btn"
                                    onClick={() => {
                                        resetState();
                                        onClose();
                                    }}
                                >
                                    OKAY
                                </button>
                            </div>
                        </div>
                    ) : (
                        <Formik
                            initialValues={payoutData}
                            onSubmit={submitPayout}
                            validationSchema={validate}
                        >
                            {( { handleChange, handleBlur, values, handleSubmit, errors, touched, isValid, dirty }) => (
                                <Form>
                                    {/* START OF THE HEADER SECTION */}
                                    <CSRFToken />
                                    <div className="create-payout__header">
                                        <div className="new-payment__header__title">
                                        
                                        </div>
                                        <div className="new-payment__header__exit">
                                        </div>
                                    </div>
                                    {/* END OF THE HEADER SECTION */}

                                    {/* START OF THE BODY SECTION */}
                                    <div className="create-payout__body">
                                        {!isPayoutSuccessful ? (
                                            <div className="create-payout__input-container">
                                                <div className="create-payout__input-container__heading">
                                                    <p>Create Payout</p>
                                                </div>
                                                <div className="create-payout__input-container__subheading">
                                                    <p>
                                                        Create a payout request to disburse funds from your account balance
                                                        to your nominated bank accounts.
                                                    </p>
                                                </div>

                                                <div className="create-payout__balance-wrapper">
                                                    <p>Account Balance:</p>
                                                    <p>    
                                                        &#8369; {balance.amount ? parseFloat(balance.amount)
                                                            .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ""}
                                                    </p>
                                                </div>

                                                {/* INPUT SECTION FOR AMOUNT */}
                                                <div className="create-payout__inputs-wrapper-amount">
                                                    <p className={`create-payout__amount-label ${errors.amount && touched.amount ? "amount-input-error" : ''}`}>
                                                        Amount
                                                    </p>
                                                    <div className="amount-input-section">
                                                        <p className={`peso-symbol ${errors.amount && touched.amount ? "amount-input-error" : ''}`}>
                                                            &#8369;
                                                        </p>     
                                                        <input
                                                            className={`create-payout__amount-input ${errors.amount && touched.amount ? "amount-input-error" : ''}`}
                                                            type="text"
                                                            name="amount"
                                                            onKeyDown={(e) => {
                                                            const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                let rawValue = e.target.value.replace(/\D/g, ''); // filter out non-numeric characters
                                                                let formattedValue = '';
                                                                let unformattedValue = '';
                                                                
                                                                if (rawValue.length <= 2) {
                                                                    formattedValue = `0.${rawValue.padEnd(2, '0')}`;
                                                                    unformattedValue = `0.${rawValue.padEnd(2, '0')}`;
                                                                } else {
                                                                    const integerPart = parseInt(rawValue.slice(0, -2), 10); // parseInt will remove leading zeros
                                                                    const formattedIntegerPart = integerPart.toLocaleString(); // convert to local string to add commas
                                                                    const decimalPart = rawValue.slice(-2);
                                                                    formattedValue = `${formattedIntegerPart}.${decimalPart}`;
                                                                    unformattedValue = `${integerPart}.${decimalPart}`;
                                                                }
                                                                
                                                                setAmountDisplay(formattedValue);
                                                                setPayoutData(prev => ({ ...prev, amount: unformattedValue }));
                                                                
                                                                // Creating a new event object with the unformatted numeric value
                                                                const newEvent = {
                                                                    target: {
                                                                    name: e.target.name,
                                                                    value: unformattedValue
                                                                    }
                                                                };
                                                                
                                                                handleChange(newEvent); // validate the unformatted numeric value
                                                            }}
                                                            onClick={(e) => {
                                                                if (!e.target.value) {
                                                                    const initialEvent = {
                                                                    target: {
                                                                        name: e.target.name,
                                                                        value: '0.00'
                                                                    }
                                                                    };
                                                                    setAmountDisplay('0.00');
                                                                    setPayoutData(prev => ({ ...prev, amount: '0.00' }));
                                                                    handleChange(initialEvent);
                                                                }
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={amountDisplay}
                                                            placeholder="0.00"
                                                            autoComplete="off"
                                                            required
                                                            inputMode="numeric"
                                                            maxLength={9}
                                                        >
                                                        </input>            
                                                    </div>
                                                    {errors.amount && touched.amount && (
                                                        <p className="create-payout__amount-error-message"> 
                                                            <BsExclamationCircle className="create-payout__amount-error-icon" />
                                                            {errors.amount}
                                                        </p>
                                                    )}
                                                </div>

                                                <div className="create-payout__inputs-wrapper">
                                                    <p>Payout will be sent to: </p>
                                                    <input
                                                        className="create-payout__bank-inputs"
                                                        type="text"
                                                        name="bank"
                                                        value={`${bankData.bank_name} ${maskAccountNumber(bankData.account_number)}`}
                                                        readOnly
                                                    />
                                                </div>

                                                {/* INPUT SECTION FOR DESCRIPTION */}
                                                <div className="create-payout__inputs-wrapper">
                                                    <div className="description-input-label">
                                                        <p className="payment-label">Description</p>
                                                        <p className="character-count">{20 - values.description.length} characters left</p>
                                                    </div>
                                                    <input
                                                        className="create-payout__description-input"
                                                        type="text"
                                                        name="description"
                                                        onChange={e => {
                                                            handleChange(e);
                                                            setPayoutData(prev => ({ ...prev, description: e.target.value }));
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.description}
                                                        autoComplete="off"
                                                        maxLength={20}
                                                        placeholder='(optional)'
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="create-payout__confirmation">
                                                <div className="create-payout__confirmation__header">
                                                    <BsCheck2Circle className="submitted-icon" />
                                                    <p className="title">
                                                        Submitted
                                                    </p>
                                                    <p className="reference">
                                                        Payout ID: <span>{payoutResult.payout_id}</span>
                                                    </p>
                                                </div>
                                                <div className="create-payout__confirmation__body">
                                                    <p className="message">
                                                        Your payout request has been submitted.
                                                    </p>
                                                    <p className="message">
                                                        A confirmation email will be sent to your email shortly.
                                                    </p>
                                                </div>
                                                <div className="create-payout__confirmation__body-mobile">
                                                    <p className="message">
                                                        Your payout request has been submitted. 
                                                    </p>
                                                    <p className="message">
                                                        A confirmation will be sent to you shortly.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/* END OF THE BODY SECTION */}

                                    {/* START OF THE FOOTER SECTION */}    
                                    <div className={`create-payout__footer ${isPayoutSuccessful ? 'centered' : ''}`}>
                                        <div className="create-payout__footer__button-wrapper">
                                            {isPayoutSuccessful ? (
                                                <>     
                                                    <button type="button" onClick={resetState}>
                                                        New Request
                                                    </button>
                                                    <button 
                                                        type="button" 
                                                        onClick={() => {
                                                            resetState();
                                                            onClose();
                                                        }}
                                                    >
                                                        Close
                                                    </button>
                                                </>
                                            ) : (
                                                <>          
                                                    <button 
                                                        type="button" 
                                                        onClick={() => {
                                                        resetState();
                                                        onClose();
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button 
                                                        type="submit"
                                                        disabled={!(isValid && dirty && payoutData.amount !== '0.00')}
                                                        className={!(isValid && dirty && payoutData.amount !== '0.00') ? "disabled" : ""}
                                                    >
                                                        {isSubmittingPayout ? "Sending" : `Send ${values.amount ? `₱${amountDisplay}` : ''}`}
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                    {isSubmittingPayout  &&
                        <CreatePayoutLoader />
                    }
                    {showNoPermModal &&
                        <NoPermModal onClose={hideNoPermModal} />
                    }
                </div>
            </div>
        </>
    )
}

export default CreatePayout