import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: {
            email: null,
            first_name: null,
            last_name: null,
            is_login: false,
            is_first_login: null,
        }
    },
    reducers: {
        register: (state, action) => {
            state.user = {...state.user, ...action.payload};
        },
        confirm_email: (state, action) => {
            state.user = action.payload;
        },
        login: (state, action) => {
            state.user = {...state.user, ...action.payload};
        },
        user_authentication: (state, action) => {
            state.user = action.payload;
        },
        updateEmail: (state, action) => {
            if (state.user) {
                state.user.email = action.payload.email;
            }
        },
        logout: (state) => {
            state.user = {
                email: null,
                first_name: null,
                last_name: null,
                is_login: false,
                is_first_login: null,
            }
        },
        reset_user: (state) => {
            state.user = {
                email: null,
                first_name: null,
                last_name: null,
                is_login: false,
                is_first_login: null,
            }
        }
    }
})

export const { register, confirm_email, login, logout, user_authentication, updateEmail, reset_user } = authSlice.actions;
export default authSlice.reducer;