import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/system';
import { BsFillInfoCircleFill, BsEmojiFrown } from "react-icons/bs";
import '../../../../Styles/MainStyles/Settings/UserInfo/UserInfo.css';

const UIDTooltip = styled(({ className, ...props }) => (
    <Tooltip classes={{ popper: className }} {...props} />
))({
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'white',  
        maxWidth: 480,           
        color: 'black',
        fontFamily: 'Figtree',
        fontSize: '14px',
        boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.25)', 
        padding: '6px',
    },
    '& .MuiTooltip-arrow': {
        color: 'white',  
    },
});

const StyledAvatar = styled(Avatar)({
    backgroundColor: '#57768D',    
    color: '#ffffff',              
    fontSize: '25px',              
    width: '60px',                 
    height: '60px',  
});

function CustomAvatar({ src, name, isLoadingSettingPage, isUploadingPhoto, isRemovingPhoto }) {
    const shouldShowSkeletonLoader = !isLoadingSettingPage || isUploadingPhoto || isRemovingPhoto;

    if (shouldShowSkeletonLoader) {
        return (
            <StyledAvatar className="skeleton-loader">
                <div style={{ width: '100%', height: '100%', borderRadius: '50%' }}></div>
            </StyledAvatar>
        );
    } else {
        return src ? (
            <StyledAvatar 
                src={src} 
                alt="Profile" 
                style={{ borderWidth: '1.9px', borderStyle: 'solid', borderColor: '#282b33' }}
            />
        ) : (
            <StyledAvatar style={{ borderWidth: '1.9px', borderStyle: 'solid', borderColor: '#282b33' }}>
                <span>{name ? name.charAt(0) : "?"}</span>
            </StyledAvatar>
        );
    }
}


function UserInfo({ 
    settingsData,
    full_name, 
    onImageUpload, 
    isUploadingPhoto,
    onImageRemove, 
    isRemovingPhoto,
    handleProfilePhotoOKButton,
    isLoadingSettingPage, 
    showChangeEmailModal, 
    changeEmailModalOpen, 
    addedAddress,
    isAddingAddress,
    handleAddingAddress,
    addAddress,
    cancelAddAddress,
    saveAddAddress,
    isSavingAddress,
    updateAddress,
    isUpdatingAddress,
    updatedAddress,
    handleUpdatingAddress,
    cancelUpdateAddress,
    saveUpdateAddress,
    addedPhone,
    isAddingPhone,
    isSavingPhone,
    addPhone,
    handleAddingPhone,
    cancelAddPhone,
    saveAddPhone,
    updatedPhone,
    isUpdatingPhone,
    updatePhone,
    handleUpdatingPhone,
    cancelUpdatePhone,
    saveUpdatePhone,
    error,
    profilePhotoError,
    profilePhotoErrorMsg,
    profilePhotoErrorCode,
    addressError,
    addressErrorMsg,
    addressErrorCode,
    handleAddressOKButton,
    phoneNumberError,
    phoneNumberErrorMsg,
    phoneNumberErrorCode,
    handlePhoneNumberOKButton,
    editName,
    showEditNameModal,
    fileInputRef,
}) {

    const profile_photo = settingsData.profile_photo ? settingsData.profile_photo : '';

    const triggerFileUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    return (
        <>
            <div className="user-info">
                <div className="user-info__page-container">
                    {error ? (
                        <div className="user-info__error-message-wrapper">
                            <BsEmojiFrown className="user-info__error-message-icon"/>
                            <p className="user-info__error-msg">{error.message}</p>
                            <p className="user-info__error-action">{error.action}</p>
                            <p className="user-info__error-code">{error.code}</p>
                        </div> 
                    ) : (
                        <>
                            <div className="user-info__title-wrapper">
                                <p className={`title ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-title' : ''}`}>
                                    {isLoadingSettingPage && 'User Information'}
                                </p>
                                <p className={`caption ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-caption' : ''}`}>
                                    {isLoadingSettingPage && 
                                        'Manage account information and preferences to customize user experience.'
                                    }
                                </p>
                            </div>

                            {/* USER INFO SECTION */}
                            <div className="user-info__content-wrapper">
                                <div className="user-info__content-item">
                                    <div className="user-info__content-label">
                                        <p className={`profile-photo-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                            {isLoadingSettingPage && 'Profile Photo'}
                                        </p>
                                    </div>
                                    <div className="user-info__content-value">
                                        {profilePhotoError ? (
                                            <div className="user-info__profile-photo-error-wrapper">
                                                <p className="user-info__profile-photo-error-message">
                                                    {profilePhotoErrorMsg} ({profilePhotoErrorCode})
                                                </p>
                                                <button 
                                                    type="button"
                                                    onClick={handleProfilePhotoOKButton}
                                                >
                                                    OKAY
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="avatar">
                                                <CustomAvatar 
                                                    src={profile_photo} 
                                                    name={full_name}
                                                    isLoadingSettingPage={isLoadingSettingPage} 
                                                    isUploadingPhoto={isUploadingPhoto}
                                                    isRemovingPhoto={isRemovingPhoto}
                                                />
                                                {/* Hidden input for file upload */}
                                                <input 
                                                    type="file" 
                                                    style={{ display: 'none' }} 
                                                    onChange={onImageUpload}
                                                    ref={fileInputRef}
                                                />
                                                {
                                                    isUploadingPhoto ? (
                                                        <p className="upload-photo-loader">Uploading Photo . . .</p>
                                                    ) : isRemovingPhoto ? (
                                                        <p className="remove-photo-loader">Removing Photo . . .</p>
                                                    ) : (
                                                        <>
                                                            <p 
                                                                className={`upload-photo-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-photo-btn' : ''}`} 
                                                                onClick={isLoadingSettingPage ? triggerFileUpload : undefined}
                                                            >
                                                                {isLoadingSettingPage ? (profile_photo ? 'Change Photo' : 'Upload Photo') : ''}
                                                            </p>
                                                            {profile_photo && (
                                                                <p 
                                                                    className={`remove-photo-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-photo-btn' : ''}`} 
                                                                    onClick={isLoadingSettingPage ? onImageRemove : undefined}
                                                                    
                                                                >
                                                                    {isLoadingSettingPage && 'Remove Photo'}
                                                                </p>
                                                            )}
                                                        </>
                                                    )
                                                }
                                            </div>
                                        )}
                                    </div>
                                    <div className="user-info__content-action">
                                        <button 
                                            type="button"
                                            className="hidden-button"
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* USER ID SECTION */}
                            <div className="user-info__content-wrapper">
                                <div className="user-info__content-item">
                                    <div className="user-info__content-label">
                                        <p className={`user-id-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                            {isLoadingSettingPage && 'User ID'}
                                        </p>
                                    </div>
                                    <div className="user-info__content-value">
                                        <p className={`user-id ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-userid' : ''}`}>
                                            {isLoadingSettingPage ? (
                                                <>
                                                    {settingsData.user_id}
                                                    <UIDTooltip 
                                                        title="You can't edit your User ID" 
                                                        placement="right" 
                                                        arrow
                                                    >
                                                        <span className="value-non-edit">
                                                            <BsFillInfoCircleFill  className="non-edit-info-icon" />
                                                        </span>
                                                    </UIDTooltip>
                                                </>
                                            ) : ''}
                                        </p>
                                    </div>
                                    <div className="user-info__content-action">
                                        <button 
                                            type="button"
                                            className="hidden-button"
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* FULL NAME SECTION */}
                            <div className="user-info__content-wrapper">
                                <div className="user-info__content-item">
                                    <div className="user-info__content-label">
                                        <p className={`full-name-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                            {isLoadingSettingPage && 'Name'}
                                        </p>
                                    </div>
                                    <div className="user-info__content-value">
                                        <p className={`full-name ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                            {isLoadingSettingPage ? full_name : ''}
                                        </p>
                                    </div>
                                    <div className="user-info__content-action">
                                        <button 
                                            className={`full-name-action-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''} ${showEditNameModal ? 'active-button' : ''}`}
                                            onClick={isLoadingSettingPage ? editName : undefined}
                                        >
                                            {isLoadingSettingPage && 'Update'}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* EMAIL ADDRESS SECTION */}
                            <div className="user-info__content-wrapper">
                                <div className="user-info__content-item">
                                    <div className="user-info__content-label">
                                        <p className={`email-address-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                            {isLoadingSettingPage && 'Email Address'}
                                        </p>
                                    </div>
                                    <div className="user-info__content-value">
                                        <p className={`email-address-value ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                            {isLoadingSettingPage ? settingsData.email : ''}
                                        </p>
                                    </div>
                                    <div className="user-info__content-action">
                                        <button 
                                            className={`email-address-action-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''} ${changeEmailModalOpen ? 'active-button' : ''}`}
                                            onClick={isLoadingSettingPage ? showChangeEmailModal : undefined}
                                        >
                                            {isLoadingSettingPage && 'Update'}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* ADDRESS SECTION */}
                            <div className="user-info__content-wrapper">
                                <div className="user-info__content-item">
                                    <div className="user-info__content-label">
                                        <p className={`address-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                            {isLoadingSettingPage && 'Address'}
                                        </p>
                                    </div>
                                    <div className="user-info__content-value">
                                        {addressError ? (
                                            <div className="address-error-wrapper">
                                                <p className="address-error-message">
                                                    {addressErrorMsg} ({addressErrorCode})
                                                </p>
                                                <button 
                                                    type="button"
                                                    onClick={handleAddressOKButton}
                                                >
                                                    OKAY
                                                </button>
                                            </div>
                                        ) : (
                                            <>
                                                {isUpdatingAddress  
                                                    ? 
                                                        <div className="address-input-wrapper">
                                                            <input
                                                                type="text"
                                                                className="address-input"
                                                                value={updatedAddress}
                                                                onChange={handleUpdatingAddress}
                                                                maxLength="50"
                                                            />
                                                            <span className="buttons-wrapper">
                                                                <button 
                                                                    type="button" 
                                                                    className="cancel-address-btn"
                                                                    onClick={cancelUpdateAddress}
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button 
                                                                    type="button"
                                                                    className={`save-address-btn ${!updatedAddress.trim() ? "disabled" : ""}`} 
                                                                    onClick={saveUpdateAddress} 
                                                                    disabled={!updatedAddress.trim()}
                                                                >
                                                                    Save
                                                                </button>
                                                            </span>                                 
                                                        </div>                                     
                                                    :   
                                                        isSavingAddress 
                                                            ?
                                                                <p className="save-address-loader">Saving changes . . .</p>
                                                            :
                                                                (settingsData.address 
                                                                    ? 
                                                                        <p className={`address-withValue ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                                                            {isLoadingSettingPage ? settingsData.address : ''}
                                                                        </p>
                                                                    : 
                                                                        isAddingAddress 
                                                                            ? 
                                                                                <div className="address-input-wrapper">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="address-input"
                                                                                        value={addedAddress}
                                                                                        onChange={handleAddingAddress}
                                                                                        maxLength="50"
                                                                                    />
                                                                                    <span className="buttons-wrapper">
                                                                                        <button 
                                                                                            type="button" 
                                                                                            className="cancel-address-btn"
                                                                                            onClick={cancelAddAddress}
                                                                                        >
                                                                                            Cancel
                                                                                        </button>
                                                                                        <button 
                                                                                            type="button"
                                                                                            className={`save-address-btn ${!addedAddress.trim() ? "disabled" : ""}`} 
                                                                                            onClick={saveAddAddress} 
                                                                                            disabled={!addedAddress.trim()}
                                                                                        >
                                                                                            Save
                                                                                        </button>
                                                                                    </span>                                 
                                                                                </div>
                                                                            :
                                                                                <p className={`address-noValue ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                                                                    {isLoadingSettingPage && 'You have not added an address to your account'}
                                                                                </p>
                                                                )
                                                }
                                            </>
                                        )}
                                    </div>
                                    <div className="user-info__content-action">
                                        {addressError ? null:
                                            (isSavingAddress  ? null : 
                                                (isAddingAddress ? null : 
                                                    (isUpdatingAddress ? null :
                                                        <button 
                                                            className={`address-action-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''}`}
                                                            onClick={isLoadingSettingPage ? (settingsData.address ? updateAddress : addAddress) : undefined}
                                                        >
                                                            {isLoadingSettingPage ? (settingsData.address ? 'Update' : 'Add') : ''}
                                                        </button>  
                                                    )                                      
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* PHONE NUMBER SECTION */}
                            <div className="user-info__content-wrapper">
                                <div className="user-info__content-item">
                                    <div className="user-info__content-label">
                                        <p className={`phone-number-label ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                            {isLoadingSettingPage && 'Phone Number'}
                                        </p>
                                    </div>
                                    <div className="user-info__content-value">
                                        {phoneNumberError ? (
                                            <div className="user-info__phone-number-error-wrapper">
                                                <p className="user-info__phone-number-error-message">
                                                    {phoneNumberErrorMsg} ({phoneNumberErrorCode})
                                                </p>
                                                <button 
                                                    type="button"
                                                    onClick={handlePhoneNumberOKButton}
                                                >
                                                    OKAY
                                                </button>
                                            </div>                    
                                        ) : (
                                            <>
                                                {isUpdatingPhone 
                                                    ?
                                                        <div className="phone-input-wrapper">
                                                            <input
                                                                type="text"
                                                                className="phone-input"
                                                                value={updatedPhone}
                                                                onChange={handleUpdatingPhone}
                                                                placeholder='Enter phone number'
                                                                maxLength="11"
                                                            />
                                                            <span className="buttons-wrapper">
                                                                <button 
                                                                    type="button" 
                                                                    className="cancel-phone-btn"
                                                                    onClick={cancelUpdatePhone}
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button 
                                                                    type="button"
                                                                    className={`save-phone-btn ${!updatedPhone.trim() ? "disabled" : ""}`} 
                                                                    onClick={saveUpdatePhone} 
                                                                    disabled={!updatedPhone.trim()}
                                                                >
                                                                    Save
                                                                </button>
                                                            </span>                                 
                                                        </div>   
                                                    :
                                                        isSavingPhone
                                                            ?
                                                                <p className="save-phone-number-loader">Saving changes . . .</p>
                                                            :
                                                                (settingsData.phone_number 
                                                                    ? 
                                                                        <p className={`phone-number-withValue ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                                                            {isLoadingSettingPage ? `${settingsData.phone_number}` : ''}
                                                                        </p>
                                                                    : 
                                                                        isAddingPhone
                                                                            ?
                                                                                <div className="phone-input-wrapper">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="phone-input"
                                                                                        value={addedPhone}
                                                                                        onChange={handleAddingPhone}
                                                                                        maxLength="11"
                                                                                        inputMode="numeric"
                                                                                        onKeyDown={(e) => {
                                                                                            const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
                                                                                            if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <span className="buttons-wrapper">
                                                                                        <button 
                                                                                            type="button" 
                                                                                            className="cancel-phone-btn"
                                                                                            onClick={cancelAddPhone}
                                                                                        >
                                                                                            Cancel
                                                                                        </button>
                                                                                        <button 
                                                                                            type="button"
                                                                                            className={`save-phone-btn ${!addedPhone.trim() ? "disabled" : ""}`} 
                                                                                            onClick={saveAddPhone} 
                                                                                            disabled={!addedPhone.trim()}
                                                                                        >
                                                                                            Save
                                                                                        </button>
                                                                                    </span>                                 
                                                                                </div>  
                                                                        :
                                                                            <p className={`phone-number-noValue ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                                                                {isLoadingSettingPage && 'You have not added a phone number to your account'}
                                                                            </p>
                                                                )
                                                }
                                            </>
                                        )}                
                                    </div>
                                    <div className="user-info__content-action">
                                        {phoneNumberError ? null :
                                            (isSavingPhone ? null :
                                                (isAddingPhone ? null :
                                                    (isUpdatingPhone ? null :
                                                        <button 
                                                            className={`phone-number-action-btn ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''}`}
                                                            onClick={isLoadingSettingPage ? (settingsData.phone_number ? updatePhone : addPhone) : undefined}
                                                        >
                                                            {isLoadingSettingPage ? (settingsData.phone_number ? 'Update' : 'Add') : ''}
                                                        </button>
                                                    )
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

        </>
    )
}

export default UserInfo