import React from 'react';
import { NavLink } from 'react-router-dom';
import { 
    BsFillGrid1X2Fill, 
    BsFileEarmarkTextFill,
    BsGearFill,
    BsBarChartFill
} from "react-icons/bs";
import '../../Styles/MainStyles/Main.css';


function BottomNavbar() {
    return (
        <>
            <div className="bottom-navbar">
                <div className="bottom-navbar__section-wrapper">
                    <div className="bottom-navbar__menu-wrapper">
                        <NavLink 
                            to="/" 
                            className={({ isActive }) =>
                                isActive ? "bottom-navbar-active" : "bottom-navbar-item"
                            }
                        >
                            <BsFillGrid1X2Fill className="bottom-navbar-icon"/>
                            <p className="bottom-navbar__menu-name">Dashboard</p>
                        </NavLink>
                        <NavLink 
                            to="/reports" 
                            className={({ isActive }) =>
                                isActive ? "bottom-navbar-active" : "bottom-navbar-item"
                            }
                        >
                            <BsBarChartFill className="bottom-navbar-icon"/>
                            <p className="bottom-navbar__menu-name">Reports</p>
                        </NavLink>
                        <NavLink 
                            to="/transactions" 
                            className={({ isActive }) =>
                                isActive ? "bottom-navbar-active" : "bottom-navbar-item"
                            }
                        >
                            <BsFileEarmarkTextFill className="bottom-navbar-icon"/>
                            <p className="bottom-navbar__menu-name">Transactions</p>
                        </NavLink>

                        <NavLink 
                            to="/settings" 
                            className={({ isActive }) =>
                                isActive ? "bottom-navbar-active" : "bottom-navbar-item"
                            }
                        >
                            <BsGearFill className="bottom-navbar-icon"/>
                            <p className="bottom-navbar__menu-name">Settings</p>
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BottomNavbar
