import React from 'react';
import FadeLoader from "react-spinners/FadeLoader";
import '../../../Styles/AuthStyles/Users/ResendSetupUserOTPLoader.css';

function ResendSetupUserOTPLoader() {
    return (
        <>
            <div className="resendOTPLoader">
                <div className="resendOTPLoader__page-container">
                    <div className="resendOTPLoader__loader-wrapper">
                        <FadeLoader
                            height={15}
                            width={5}
                            color="#294254" 
                            loading="true"
                            speedMultiplier={0.80} 
                            aria-label="Loading Spinner" 
                            data-testid="loader"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResendSetupUserOTPLoader