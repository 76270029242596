import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../../api/axios';
import Cookies from 'js-cookie';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import SyncLoader from "react-spinners/SyncLoader";
import Logo from '../../../assets/logo.svg';
import CSRFToken from '../../../components/CSRFToken';
import VerifySetup from './VerifySetup';
import SetupUserError from './SetupUserError';
import SetupUserLoader from './SetupUserLoader';
import VerifySetupSuccess from './VerifySetupSuccess';
import SetupUserSuccess from './SetupUserSuccess';
import '../../../Styles/AuthStyles/Users/SetupUsers.css';
import { 
    BsExclamationCircleFill, 
    BsEyeFill, 
    BsEyeSlashFill, 
    BsChevronRight, 
    BsEmojiFrown, 
} from "react-icons/bs";

const FETCH_INVITED_USER = '/apps/fetch_invited_user/'
const SETUP_USER_ENDPOINT = '/apps/setup_user/'
const CONFIRM_SETUP_ENDPOINT = '/apps/confirm_setup_user/'
const RESEND_INVITE_EMAIL_ENDPOINT = '/apps/resend_invite_email/'

function SetupUser() {
    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const userId = searchParams.get('user_id')

    const [invitedUserData, setInvitedUserData] = useState({
        email:'',
        password: '',
        confirm_password: '',
        otp: '',
    });

    const [fetchingInitUserData, setFetchingInitUserData] = useState(false);
    const [isSettingAccount, setIsSettingAccount] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [confirmSetupSuccess, setConfirmSetupSuccess] = useState(false);
    const [setupUserSuccessful, setSetupUserSuccessful] = useState(false)

    const [setupUserError, setSetupUserError] = useState(false);
    const [setupUserErrorMsg, setSetupUserErrorMsg] = useState(null);
    const [setupUserErrorAction, setSetupUserErrorAction] = useState(null);
    const [setupUserErrorCode, setSetupUserErrorCode] = useState(null);

    const [wrongOTP, setWrongOTP] = useState(false);
    const [expiredOTP, setExpiredOTP] = useState(false)
    const [otpError, setOtpError] = useState(null);
    const [resentOTP, setResentOTP] = useState(false);
    const [resendingOTP, setResendingOTP] = useState(false);

    const [type, setType] = useState('password');
    const [showPassword, setShowPassword] = useState(false);

    // This function handles data validation
    const validate = Yup.object({
        password: Yup.string()
            .required('Please complete this field.')
            .matches(
                // eslint-disable-next-line
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Password must have atleast 8 characters, 1 number, 1 uppercase, 1 lowercase and 1 special character."
            ),
        confirm_password: Yup.string()
            .required('Please complete this field.')
            .oneOf([Yup.ref('password'), null], 'Passwords must match.')
    })

    // This function handles showing and hiding password
    const handlePasswordToggle = () => {
        if (type === 'password'){
            setType('text');
            setShowPassword(true);
        }
        else {
            setType('password');
            setShowPassword(false);
        }
    }

    const fetchInvitedUser = useCallback(async () => {
        setFetchingInitUserData(true);
        try {
            const res = await axios.post(
                FETCH_INVITED_USER,
                JSON.stringify({
                    user_id: userId,
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success){
                setInvitedUserData((prevState) => ({
                    ...prevState,
                    email: res.data.invited_user_email,
                }));
                setShowOtpModal(true);
            } else {
                console.error('Invalid data received', res.data)
                setSetupUserError(true);
                setSetupUserErrorMsg("Oops! We encountered an error.")
                setSetupUserErrorAction("Please try again or contact support.")
                setSetupUserErrorCode("ERR_FINDUKU_2000")                
            }
        } catch (error) {
            console.error('Something went wrong when creating account', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please login your account.")
                        setSetupUserErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setSetupUserErrorMsg("Oops! We encountered an error");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setSetupUserErrorMsg("Oops! We encountered an error");
                setSetupUserErrorAction("Please try again or contact support.")
                setSetupUserErrorCode("ERR_FINDUKU_8000") 
            }                     
            setSetupUserError(true)
        } finally {
            setFetchingInitUserData(false)
        }
    }, [userId]);

    useEffect(() => {
        fetchInvitedUser();
    }, [fetchInvitedUser]) 

    // Updates the Register Data State on every keystroke
    const updateInvitedUserData = (updatedData) => {
        setInvitedUserData(updatedData);
    };

    const onCloseModal = () => {
        setConfirmSetupSuccess(false);
        setShowOtpModal(false);
    }

    // This function send request to backend to create account
    const submitSetupAccount = async () => {
        setIsSettingAccount(true)
        try {
            const res = await axios.post(
                SETUP_USER_ENDPOINT,
                JSON.stringify({ 
                    email: invitedUserData.email, 
                    password: invitedUserData.password 
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success){
                setSetupUserSuccessful(true)
            } else {
                console.error('Invalid data received', res.data)
                setSetupUserError(true);
                setSetupUserErrorMsg("Oops! We encountered an error.")
                setSetupUserErrorAction("Please try again or contact support.")
                setSetupUserErrorCode("ERR_FINDUKU_2000")                
            }

        } catch (error) {
            console.error('Something went wrong when creating account', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please login your account.")
                        setSetupUserErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setSetupUserErrorMsg("Oops! We encountered an error");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setSetupUserErrorMsg("Oops! We encountered an error");
                setSetupUserErrorAction("Please try again or contact support.")
                setSetupUserErrorCode("ERR_FINDUKU_8000") 
            }                     
            setSetupUserError(true)
        } finally {
            setIsSettingAccount(false)
        }
    };

    // This function handles verification of sign up
    const confirmSetup = async () => {
        console.log('invitedUserData', invitedUserData)
        setIsSettingAccount(true)
        try {
            const res = await axios.post(
                CONFIRM_SETUP_ENDPOINT,
                JSON.stringify({ 
                    email: invitedUserData.email,
                    otp: invitedUserData.otp  
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success){
                setConfirmSetupSuccess(true)
            } else if (res.data.wrong_otp){
                setWrongOTP(true);
                setExpiredOTP(false);
                setOtpError("The verification code you entered is incorrect.")
            } else if (res.data.expired_otp){
                setExpiredOTP(true);
                setWrongOTP(false);
                setOtpError("The verification code you entered has expired.") 
            } else {
                console.error('Invalid data received', res.data)
                setSetupUserError(true);
                setSetupUserErrorMsg("Oops! We encountered an error.")
                setSetupUserErrorAction("Please try again or contact support.")
                setSetupUserErrorCode("ERR_FINDUKU_2000")                
            }

        } catch (error) {
            console.error('Something went wrong confirming registration', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please login your account.")
                        setSetupUserErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setSetupUserErrorMsg("Oops! We encountered an error");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setSetupUserErrorMsg("Oops! We encountered an error");
                setSetupUserErrorAction("Please try again or contact support.")
                setSetupUserErrorCode("ERR_FINDUKU_8000") 
            }                     
            setSetupUserError(true)
        } finally {
            setIsSettingAccount(false)
        }
    };

    // This function handles sending request to resend OTP
    const resendInviteEmail = async () => {
        setResendingOTP(true)
        try {
            const res = await axios.post(
                RESEND_INVITE_EMAIL_ENDPOINT,
                JSON.stringify({ 
                    email: invitedUserData.email, 
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success){
                setResentOTP(true);
                setWrongOTP(false);
                setExpiredOTP(false);
            } else {
                console.error('Invalid data received', res.data)
                setSetupUserError(true);
                setSetupUserErrorMsg("Oops! We encountered an error.")
                setSetupUserErrorAction("Please try again or contact support.")
                setSetupUserErrorCode("ERR_FINDUKU_2000")                
            }

        } catch (error) {
            console.error('Something went wrong requesting resend OTP', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please login your account.")
                        setSetupUserErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setSetupUserErrorMsg("Oops! We encountered an error.");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setSetupUserErrorMsg("Oops! We encountered an error");
                        setSetupUserErrorAction("Please try again or contact support.")
                        setSetupUserErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setSetupUserErrorMsg("Oops! We encountered an error");
                setSetupUserErrorAction("Please try again or contact support.")
                setSetupUserErrorCode("ERR_FINDUKU_8000") 
            }                     
            setSetupUserError(true)
        } finally {
            setResendingOTP(false)
        }
    };

    const resetInvalidOTPMsg = () => {
        setWrongOTP(false);
        setExpiredOTP(false);
        setOtpError(null);
        setResentOTP(false);
    }

    const closeErrorModal = () => {
        setInvitedUserData({
            email: '',
            first_name: '',
            last_name: '',
            password: '',
            otp: '',
        });
        setSetupUserError(false);
    }

    const routeToLogin = () => {
        navigate("/signin");
    }
    
    const navigateToLogin = () => {
        navigate("/signin");
    }

    return (
        <>
            <div className="setup-user">
                <div className="setup-user__page-container">
                    {fetchingInitUserData ? (
                        <div className="setup-user__loader-wrapper">
                            <SyncLoader
                                size={13}
                                margin={5}
                                color="#294254" 
                                loading="true"
                                speedMultiplier={0.50} 
                                aria-label="Loading Spinner" 
                                data-testid="loader"
                            />                
                        </div>
                    ) : (
                        <>
                            {setupUserError ? (
                                <div className="setup-user-error-section">
                                    <div className="setup-user-error__message-container">
                                        <BsEmojiFrown  className="setup-user-error__message-icon"/>
                                        <p className="setup-user-error__text">{setupUserErrorMsg}</p>
                                        <p className="setup-user-error__action">{setupUserErrorAction}</p>
                                        <p className="setup-user-error__code">{setupUserErrorCode}</p>
                                        <div className="setup-user-error__button-wrapper">
                                            <button 
                                                type="button"
                                                className="setup-user-error__button"

                                            >
                                                OKAY
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="setup-user__header">
                                        <div className="setup-user__logo-wrapper">
                                            <img src={Logo} alt="Finduku Logo" className="setup-user__logo" />
                                        </div>
                                        <div className="setup-user__login-wrapper" onClick={routeToLogin}>
                                            <p className="setup-user__login-text">Sign in</p>
                                            <BsChevronRight className="setup-user__login-icon"/>
                                        </div>
                                    </div>
                                    <div className="setup-user__page-border">
                                        <div className="setup-user__body">
                                            <p className="setup-user__form-heading">Set up your account</p>
                                            <p className="setup-user__form-subheading">Create a password to set up your account.</p>
                                            <div className="setup-user__route-to-login-section">
                                                <span className="setup-user__route-to-login">Already have an account?</span>
                                                <NavLink to="/login" className="setup-user__route-to-login-link">
                                                    Log in
                                                </NavLink>
                                            </div>
                                            <Formik
                                                initialValues={invitedUserData}
                                                onSubmit={submitSetupAccount}
                                                validationSchema={validate}
                                            >
                                                {( { handleChange, handleBlur, values, errors, touched }) => {

                                                    return (
                                                        <Form>
                                                            <CSRFToken />
                                                            <div className="setup-user__regform">
                                                                {/* THIS IS THE PASSWORD INPUT SECTION */}
                                                                <div className="setup-user__password-section">
                                                                    <p className="setup-user__password-input-title">Password</p>
                                                                    <div className="setup-user__password-input-container">
                                                                        <input
                                                                            className={`setup-user__password-input ${errors.password && touched.password ? "input-error": null}`}
                                                                            type={type}
                                                                            name="password"
                                                                            minLength="8"
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                updateInvitedUserData({ ...values, password: e.target.value });
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            value={values.password}
                                                                            required
                                                                        />
                                                                        <div className="setup-user__password-toggle-text" onClick={handlePasswordToggle}>
                                                                            {values.password && (
                                                                                <span className="setup-user__password-toggle-icon-wrapper">
                                                                                    {showPassword ? (
                                                                                        <BsEyeSlashFill className="setup-user__password-toggle-eye-icon"/>
                                                                                    ) : (
                                                                                        <BsEyeFill className="setup-user__password-toggle-eye-icon"/>
                                                                                    )}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {errors.password && touched.password && (
                                                                        <p className="setup-user__password-error"> 
                                                                            <BsExclamationCircleFill className="setup-user__password-error-icon" />
                                                                            {errors.password}
                                                                        </p>
                                                                    )}
                                                                </div>

                                                                <div className="setup-user__password-section">
                                                                    <p className="setup-user__password-input-title">Confirm Password</p>
                                                                    <div className="setup-user__password-input-container">
                                                                        <input
                                                                            className={`setup-user__password-input ${errors.confirm_password && touched.confirm_password ? "input-error": null}`}
                                                                            type={type}
                                                                            name="confirm_password"
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                updateInvitedUserData({ ...values, confirm_password: e.target.value });
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            value={values.confirm_password}
                                                                            required
                                                                        />
                                                                        <div className="setup-user__password-toggle-text" onClick={handlePasswordToggle}>
                                                                            {values.confirm_password && (
                                                                                <span className="setup-user__password-toggle-icon-wrapper">
                                                                                    {showPassword ? (
                                                                                        <BsEyeSlashFill className="setup-user__password-toggle-eye-icon"/>
                                                                                    ) : (
                                                                                        <BsEyeFill className="setup-user__password-toggle-eye-icon"/>
                                                                                    )}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {errors.confirm_password && touched.confirm_password && (
                                                                        <p className="setup-user__password-error"> 
                                                                            <BsExclamationCircleFill className="setup-user__password-error-icon" />
                                                                            {errors.confirm_password}
                                                                        </p>
                                                                    )}
                                                                </div>

                                                                <div className="setup-user__button-wrapper">
                                                                    <button 
                                                                        type="submit" 
                                                                        className={`setup-user__create-account-btn ${values.password && values.confirm_password ? "" : "disabled"}`}
                                                                        disabled={!values.password || !values.password}
                                                                    >
                                                                        {isSettingAccount ? "Processing" : "Setup Account"}
                                                                    </button>
                                                                </div>

                                                                <div className="setup-user__policy">
                                                                    <p>By clicking "Setup Account", I agree to 
                                                                        Finduku's <a href="https://www.finduku.com/terms-of-use" target="_blank" rel="noopener noreferrer" className="setup-user__terms-of-service">Terms of Service</a> and <a href="https://www.finduku.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="setup-user__privacy-policy">Privacy Policy</a>, and
                                                                        to receive electronic communication about my accounts and services per Finduku's <a href="https://www.finduku.com/electronic-communication-agreement" target="_blank" rel="noopener noreferrer" className="setup-user__communication-agreement">Electronic Communications Agreement.</a>
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </Form>
                                                    )
                                                }}
                                            </Formik>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
                {isSettingAccount &&
                    <SetupUserLoader />
                }
                {setupUserError &&
                    <SetupUserError
                        setupUserErrorMsg={setupUserErrorMsg}
                        setupUserErrorAction={setupUserErrorAction}
                        setupUserErrorCode={setupUserErrorCode}
                        onClose={closeErrorModal}
                    />
                }
            </div>
            {showOtpModal &&
                <VerifySetup 
                    wrongOTP={wrongOTP}
                    expiredOTP={expiredOTP}
                    otpError={otpError}
                    confirmSetup={confirmSetup}
                    invitedUserData={invitedUserData}
                    updateRegisterData={updateInvitedUserData}
                    resendingOTP={resendingOTP}
                    resentOTP={resentOTP}
                    resendInviteEmail={resendInviteEmail}
                    resetInvalidOTPMsg={resetInvalidOTPMsg}
                    isSettingAccount={isSettingAccount}
                />
            }
            {confirmSetupSuccess &&
                <VerifySetupSuccess 
                    onClose={onCloseModal}
                />
            }
            {setupUserSuccessful &&
                <SetupUserSuccess
                 navigateToLogin={navigateToLogin}
                />
            }
        </>
    )
}

export default SetupUser
