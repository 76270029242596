import React from 'react';
import ProductLandingPage from '../../../assets/product_landing_page.svg';
import '../../../Styles/MainStyles/Reports/Reports.css';

function Reports() {
    return (
        <>
            <div className="reports">
                <div className="reports__page-container">
                    <div className="reports__content-area">
                        <img src={ProductLandingPage} alt="Reports Landing Page Icon" />
                        <div className="reports__text-wrapper">
                            <p className="reports__subheading1">
                                Our Reports module is currently in development.
                            </p>
                            <p className="reports__subheading1">
                                We're developing tools to help you generate reports more efficiently.
                            </p>
                            <p className="reports__subheading2">
                                Hold tight! Our Reports feature is on its way. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Reports;
