import React, { useState, useEffect} from 'react';
import axios from 'axios';


const CSRFToken = () => {
  const [csrftoken, setcsrftoken] = useState('')

  function getCookie(name) {
    let cookieValue = '';
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/apps/get_csrftoken/`, {
          withCredentials: true,
        });

        if (response.status === 200) {
          const csrfTokenValue = getCookie("csrftoken");
          setcsrftoken(csrfTokenValue);
        }
      } catch (err) {
        console.error(err);
      }
    };
  
    fetchData();
  }, []);

  return (
      <input type='hidden' name='csrfmiddlewaretoken' value={csrftoken} />
  );    

};

export default CSRFToken;
