import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsBoxArrowRight } from "react-icons/bs";
import { handleLogout } from '../../../redux/slices/logoutSlice';
import '../../../Styles/CompStyles/Header/ProfileMenu.css';


function ProfileMenu({ open, onClose }) {
  const dispatch = useDispatch()
  const email = useSelector((state) => state.auth.user ? state.auth.user.email : '');
  const first_name = useSelector((state) => state.auth.user ? state.auth.user.first_name : '');
  const last_name = useSelector((state) => state.auth.user ? state.auth.user.last_name : '');
  const full_name = `${first_name} ${last_name}`.trim();

  const submitLogout = () => {
    dispatch(handleLogout());
  }

  if (!open) return null;

  return (
    <>
      <div className="profile-menu" onClick={onClose}>
        <div onClick={(e) => {e.stopPropagation()}} className="modalContainer" id="pointer">
          <div className="modalHeader">
            <div className="profile-container">
              <p className="name">{full_name}</p>
              <p className="email">{email}</p>
            </div>
          </div>
          <div className="modalBody">
            <NavLink to="/" className="modal-item">
              <BsBoxArrowRight className="nav-icon"/>
              <p className="modal-item-name" onClick={submitLogout}>Log out</p>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileMenu;