import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './slices/authSlice';
import merchantReducer from './slices/merchantSlice';
import paymentsReducer from './slices/paymentsSlice';
import payoutsReducer from './slices/payoutsSlice';
import settingsReducer from './slices/settingsSlice';
import homeReducer from './slices/homeSlice';
import logoutReducer from './slices/logoutSlice';
import insightsReducer from './slices/insightsSlice';

const persistConfig = {
   key: 'root',
   version: 1,
   storage,
};

const rootReducer = combineReducers({
   auth: authReducer,
   merchant: merchantReducer,
   payments: paymentsReducer,
   payouts: payoutsReducer,
   home: homeReducer,
   settings: settingsReducer,
   logout: logoutReducer,
   insights: insightsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
   reducer: persistedReducer,
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
         serializableCheck: {
            ignoredActions: ['persist/PERSIST'],
         },
      }),
});
