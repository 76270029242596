import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BsExclamationCircle } from "react-icons/bs";
import CSRFToken from '../../../components/CSRFToken';

const Step1 = ({ handleNextStep, merchantData, updateMerchantData }) => {

    //FUNCTION HANDLING VALIDATION
    const validate = Yup.object({
        first_name: Yup.string()
        .required('Please complete this field.')
        .max(40, 'Only 40 characters allowed.'),

        last_name: Yup.string()
        .required('Please complete this field')
        .max(40, 'Only 40 characters allowed.'),

        job_title: Yup.string()
        .required('Please complete this field.')
        .max(40, 'Only 40 characters allowed.'),

    })


    return (

        <Formik
            initialValues={merchantData}
            onSubmit={handleNextStep}
            validationSchema={validate}
            enableReinitialize={true}
        >

            {( { handleChange, handleBlur, values, handleSubmit, errors, touched }) => {

                return(
                    <Form>
                        <CSRFToken />
                        <div className="onboarding__form">

                            {/* TITLE SECTION */}
                            <p className="onboarding__form-title">Personal Information</p>
                            
                            {/* SUBTITLE SECTION */}
                            <p className="onboarding__form-subtitle">
                                In adherence to BSP's rules and regulations, we collect the personal 
                                information of the beneficial owner or authorized signatory of every business that joins Finduku.
                            </p>

                            {/* FIRST NAME SECTION */}
                            <div className="onboarding__input-wrapper">
                                <p className={`onboarding__input-label ${errors.first_name && touched.first_name ? "onboarding__input-error" : ""}`}>
                                    First Name
                                </p>
                                <input
                                    className={`onboarding__text-input ${errors.first_name && touched.first_name ? "onboarding__input-error": null}`}
                                    type="text"
                                    name="first_name"
                                    onChange={(e) => {
                                        handleChange(e);
                                        updateMerchantData({ ...values, first_name: e.target.value });
                                    }}
                                    onBlur={handleBlur}
                                    value={values.first_name}
                                    autoComplete="off"
                                    maxLength={41}
                                />
                                {errors.first_name && touched.first_name && (
                                    <p className="onboarding__input-error-message"> 
                                        <BsExclamationCircle className="onboarding__input-error-icon" />
                                        {errors.first_name}
                                    </p>
                                )}
                            </div>
                            
                            {/* SECOND NAME SECTION */}
                            <div className="onboarding__input-wrapper">
                                <p className={`onboarding__input-label ${errors.last_name && touched.last_name ? "onboarding__input-error" : ""}`}>
                                    Last Name
                                </p>
                                <input
                                    className={`onboarding__text-input ${errors.last_name && touched.last_name ? "onboarding__input-error": null}`}
                                    type="text"
                                    name="last_name"
                                    onChange={(e) => {
                                        handleChange(e);
                                        updateMerchantData({ ...values, last_name: e.target.value });
                                    }}
                                    onBlur={handleBlur}
                                    value={values.last_name}
                                    autoComplete="off"
                                    maxLength={41}
                                />
                                {errors.last_name && touched.last_name && (
                                    <p className="onboarding__input-error-message"> 
                                        <BsExclamationCircle className="onboarding__input-error-icon" />
                                        {errors.last_name}
                                    </p>
                                )}
                            </div>
                            
                            {/* JOB TITLE SECTION */}
                            <div className="onboarding__input-wrapper">
                                <p className={`onboarding__input-label ${errors.job_title && touched.job_title ? "onboarding__input-error" : ""}`}>
                                    Job Title
                                </p>
                                <input
                                    className={`onboarding__text-input ${errors.job_title && touched.job_title ? "onboarding__input-error": null}`}
                                    type="text"
                                    name="job_title"
                                    onChange={(e) => {
                                        handleChange(e);
                                        updateMerchantData({ ...values, job_title: e.target.value });
                                    }}
                                    onBlur={handleBlur}
                                    value={values.job_title}
                                    autoComplete="off"
                                    maxLength={41}
                                />
                                {errors.job_title && touched.job_title && (
                                    <p className="onboarding__input-error-message"> 
                                        <BsExclamationCircle className="onboarding__input-error-icon" />
                                        {errors.job_title}
                                    </p>
                                )}
                            </div>
                            
                            {/* SUBMIT BUTTON */}
                            <div className="onboarding__button-wrapper">
                                <button 
                                    type="submit" 
                                    className={`onboarding__button ${values.first_name && values.last_name && values.job_title ? "" : "disabled"}`}
                                    disabled={!values.first_name || !values.last_name || !values.job_title }
                                >
                                    Continue
                                </button>
                            </div>

                            <div className="onboarding__agreement-wrapper">
                                <p className="onboarding__agreement">
                                    By clicking the "Continue" button, I acknowledge that I am the beneficial owner 
                                    or authorized signatory of the business I am signing up.
                                </p>
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    );
};

export default Step1;