import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BsArrowLeft } from "react-icons/bs";
import CSRFToken from '../../../components/CSRFToken';


function Step5({ submitCreateProfile, handlePrevStep, companyDocs, updateCompanyDocs, merchantData }) {

     //FUNCTION HANDLING VALIDATION
     const validationSchema = Yup.object().shape({
          reg_cert: Yup.mixed()
          .required('Please complete this field.')
          .test('fileSize', 'File size is too large', value => value && value.size <= 1024 * 1024) // 1 MB size limit
          .test('fileType', 'Unsupported file format', value => value && ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type)),

          articles: Yup.mixed()
          .required('Please complete this field.')
          .test('fileSize', 'File size is too large', value => value && value.size <= 1024 * 1024) // 1 MB size limit
          .test('fileType', 'Unsupported file format', value => value && ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type)),

          gis: Yup.mixed()
          .required('Please complete this field.')
          .test('fileSize', 'File size is too large', value => value && value.size <= 1024 * 1024) // 1 MB size limit
          .test('fileType', 'Unsupported file format', value => value && ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type)),

          reso: Yup.mixed()
          .required('Please complete this field.')
          .test('fileSize', 'File size is too large', value => value && value.size <= 1024 * 1024) // 1 MB size limit
          .test('fileType', 'Unsupported file format', value => value && ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type)),
     })

     const [regCertFileName, setRegCertFileName] = useState(companyDocs.reg_cert ? companyDocs.reg_cert.name : '');
     const [articlesFileName, setArticlesFileName] = useState(companyDocs.articles ? companyDocs.articles.name : '');
     const [gisFileName, setGisFileName] = useState(companyDocs.gis ? companyDocs.gis.name : '');
     const [resoFileName, setResoFileName] = useState(companyDocs.reso ? companyDocs.reso.name : '');

     const [regCertError, setRegCertError] = useState('');
     const [articlesError, setArticlesError] = useState('');
     const [gisError, setGisError] = useState('');
     const [resoError, setResoError] = useState('');

     useEffect(() => {
          if (companyDocs.reg_cert) setRegCertFileName(companyDocs.reg_cert.name);
          if (companyDocs.articles) setArticlesFileName(companyDocs.articles.name);
          if (companyDocs.gis) setGisFileName(companyDocs.gis.name);
          if (companyDocs.reso) setResoFileName(companyDocs.reso.name);
     }, [companyDocs]);

     const handleFileChange = (e, setFieldValue, setFileName, setError, fieldName) => {
          const { files } = e.target;
          const file = files[0];

          if (file) {
               let error = null;

               if (file.size > 1024 * 1024) {
                    error = 'File size is too large';
               } else if (!['image/jpeg', 'image/png', 'application/pdf'].includes(file.type)) {
                    error = 'Unsupported file format';
               }

               if (error) {
                    setError(error);
                    setFileName(file.name);
                    setFieldValue(fieldName, null);
               } else {
                    setError('');
                    setFileName(file.name);
                    setFieldValue(fieldName, file);
                    updateCompanyDocs({ ...companyDocs, [fieldName]: file });
               }
          } else {
               setError('Please complete this field.');
               setFileName('');
               setFieldValue(fieldName, null);
          }
     };

     const renderFileInput = (name, label, fileName, handleChange, error) => (
          <div className="onboarding__custom-file-input-wrapper">
               <input
                    type="file"
                    name={name}
                    accept="application/pdf,image/jpeg,image/png"
                    onChange={handleChange}
                    style={{ display: 'none' }}
                    id={name}
               />
               <div className={`onboarding__custom-file-input ${error ? "onboarding__custom-input-error" : ""}`} onClick={() => document.getElementById(name).click()}>
                    <p className={fileName ? (error ? "onboarding__custom-file-input-filename error" : "onboarding__custom-file-input-filename") : "onboarding__custom-file-input-placeholder"}>
                         {fileName ? fileName : label}
                    </p>
                    {!fileName && <p className="onboarding__custom-file-types">(PDF/JPG/PNG)</p>}
               </div>
               {error && (<p className="onboarding__custom-input-error-message">{error}</p>)}
          </div>
     );

     return (
          <Formik
               initialValues={{ 
                    reg_cert: companyDocs.reg_cert, 
                    articles: companyDocs.articles, 
                    gis: companyDocs.gis,
                    reso: companyDocs.reso
               }}
               validationSchema={validationSchema}
               onSubmit={(values, { setSubmitting }) => {
                    submitCreateProfile(values);
                    setSubmitting(false);
               }}
          >
               {( { values, errors, touched, setFieldValue, isSubmitting }) => (
                    <Form>
                         <CSRFToken />
                         <div className="onboarding__form">
                              {/* BACK BUTTON */}
                              <button className="onboarding__back-button" type="button" onClick={handlePrevStep}>
                                   <BsArrowLeft className="onboarding__back-button-icon" />
                                   <p className="onboarding__back-button-text">Back to Step 4</p>
                              </button>

                              {/* TITLE SECTION */}
                              <p className="onboarding__form-title">Company Documents</p>
                              
                              {/* SUBTITLE SECTION */}
                              <p className="onboarding__form-subtitle">
                                   To comply with AMLC and BSP rules and regulations, please upload these required business documents.
                              </p>
                              {merchantData.entity_type === 'Sole Proprietorship' &&
                                   <div className="onboarding__docs-section-corp">
                                        {/* DTI CERTIFICATE SECTION */}
                                        <div className="onboarding__dti-input-wrapper">
                                             <p className="onboarding__input-label">DTI Certificate of Registration</p>
                                             <p className="onboarding__input-sublabel">
                                                  Please upload a photo of your Certificate of Business Name
                                                  Registration issued by Department of Trade and Industry.
                                             </p>
                                             {renderFileInput("reg_cert", "Drag and drop here or click to upload", regCertFileName, 
                                                  (e) => handleFileChange(e, setFieldValue, setRegCertFileName, setRegCertError, 'reg_cert'), 
                                                  regCertError)}
                                        </div>
                                   </div>
                              }

                              {merchantData.entity_type === 'Partnership' && 
                                   <div className="onboarding__docs-section-corp">
                                        {/* COI SECTION */}
                                        <div className="onboarding__input-wrapper">
                                             <p className="onboarding__input-label">Certificate of Recording</p>
                                             {renderFileInput("reg_cert", "Drag and drop here or click to upload", regCertFileName, 
                                                  (e) => handleFileChange(e, setFieldValue, setRegCertFileName, setRegCertError, 'reg_cert'), 
                                                  regCertError)}
                                        </div>

                                        {/* ARTICLES SECTION */}
                                        <div className="onboarding__docs-input-wrapper">
                                             <p className="onboarding__input-label">Articles of Partnership</p>
                                             {renderFileInput("articles", "Drag and drop here or click to upload", articlesFileName, 
                                                  (e) => handleFileChange(e, setFieldValue, setArticlesFileName, setArticlesError, 'articles'), 
                                                  articlesError)}
                                        </div>

                                        {/* SECRETARY'S CERTIFICATE SECTION */}
                                        <div className="onboarding__docs-input-wrapper">
                                             <p className="onboarding__input-label">Partners' Resolution</p>
                                             {renderFileInput("reso", "Drag and drop here or click to upload", resoFileName, 
                                                  (e) => handleFileChange(e, setFieldValue, setResoFileName, setResoError, 'reso'), 
                                                  resoError)}
                                        </div>
                                   </div>
                              }

                              {merchantData.entity_type === 'Corporation' && 
                                   <div className="onboarding__docs-section-corp">
                                        {/* COI SECTION */}
                                        <div className="onboarding__input-wrapper">
                                             <p className="onboarding__input-label">Certificate of Incorporation</p>
                                             {renderFileInput("reg_cert", "Drag and drop here or click to upload", regCertFileName, 
                                                  (e) => handleFileChange(e, setFieldValue, setRegCertFileName, setRegCertError, 'reg_cert'), 
                                                  regCertError)}
                                        </div>

                                        {/* ARTICLES & BY-LAWS SECTION */}
                                        <div className="onboarding__docs-input-wrapper">
                                             <p className="onboarding__input-label">Articles of Incorporation</p>
                                             {renderFileInput("articles", "Drag and drop here or click to upload", articlesFileName, 
                                                  (e) => handleFileChange(e, setFieldValue, setArticlesFileName, setArticlesError, 'articles'), 
                                                  articlesError)}
                                        </div>

                                        {/* GENERAL INFORMATION SHEET SECTION */}
                                        <div className="onboarding__docs-input-wrapper">
                                             <p className="onboarding__input-label">General Information Sheet</p>
                                             {renderFileInput("gis", "Drag and drop here or click to upload", gisFileName, 
                                                  (e) => handleFileChange(e, setFieldValue, setGisFileName, setGisError, 'gis'), 
                                                  gisError)}
                                        </div>

                                        {/* SECRETARY'S CERTIFICATE SECTION */}
                                        <div className="onboarding__docs-input-wrapper">
                                             <p className="onboarding__input-label">Secretary's Certificate</p>
                                             {renderFileInput("reso", "Drag and drop here or click to upload", resoFileName, 
                                                  (e) => handleFileChange(e, setFieldValue, setResoFileName, setResoError, 'reso'), 
                                                  resoError)}
                                        </div>
                                   </div>
                              }
                              
                              {/* SUBMIT BUTTON */}
                              <div className="onboarding__button-wrapper">
                                   <button 
                                        type="submit" 
                                        className={`onboarding__button ${
                                             (merchantData.entity_type === "Sole Proprietorship" && values.reg_cert) ||
                                             (merchantData.entity_type === "Partnership" && values.reg_cert && values.articles && values.reso) ||
                                             (merchantData.entity_type === "Corporation" && values.reg_cert && values.articles && values.reso && values.gis)
                                             ? "" : "disabled"
                                        }`}
                                        disabled={
                                             isSubmitting ||
                                             !(
                                                  (merchantData.entity_type === "Sole Proprietorship" && values.reg_cert) ||
                                                  (merchantData.entity_type === "Partnership" && values.reg_cert && values.articles && values.reso) ||
                                                  (merchantData.entity_type === "Corporation" && values.reg_cert && values.articles && values.reso && values.gis)
                                             )
                                        }
                                   >
                                        Submit Application
                                   </button>
                              </div>
                         </div>
                    </Form>
               )}

          </Formik>
     )
}

export default Step5