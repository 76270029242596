import { createSlice } from '@reduxjs/toolkit';

export const merchantSlice = createSlice({
    name: 'merchant',
    initialState: {
        profile: {
            merchant_name: null,
            merchant_id: null,
            callsign: null,
            is_profile_setup: false,
            is_company_set: false,
        }
    },
    reducers: {
        setup_merchant: (state, action) => {
            state.profile = {...state.profile, ...action.payload};
        },
        set_merchant_after_login: (state, action) => {
            state.profile = {...state.profile, ...action.payload};
        },
        update_store_name: (state, action) => {
            state.profile.merchant_name = action.payload;
        },
        reset_merchant: (state) => {
            state.profile = {
                merchant_name: null,
                merchant_id: null,
                callsign: null,
                is_profile_setup: false,
                is_company_set: false,
            }
        }
    }
})

export const { setup_merchant, set_merchant_after_login, update_store_name, reset_merchant } = merchantSlice.actions;

export default merchantSlice.reducer;