import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { BsExclamationCircle, BsEyeFill, BsEyeSlashFill, BsChevronRight } from "react-icons/bs";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { register } from '../../../redux/slices/authSlice';
import * as Yup from 'yup';
import Logo from '../../../assets/logo.svg';
import RegisterLoader from './RegisterLoader';
import RegisterError from './RegisterError';
import axios from '../../../api/axios';
import Cookies from 'js-cookie';
import VerifySignup from './VerifySignup';
import WelcomeModal from './Welcome';
import CSRFToken from '../../../components/CSRFToken';
import '../../../Styles/AuthStyles/Register/Register.css';

// API ENDPOINTS
const REGISTER_ENDPOINT = '/apps/signup/'
const VERIFY_REGISTRATION_ENDPOINT = '/apps/confirm_email/'
const RESEND_OTP_ENDPOINT = '/apps/resend_signup_otp/'

function Register () {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [accountEmail, setAccountEmail] = useState(null);
    const [userExist, setUserExist] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [emailConfirmed, setEmailConfirmed] = useState(false);

    const [registerError, setRegisterError] = useState(false);
    const [registerErrorMsg, setRegisterErrorMsg] = useState(null);
    const [registerErrorAction, setRegisterErrorAction] = useState(null);
    const [registerErrorCode, setRegisterErrorCode] = useState(null);

    const [wrongOTP, setWrongOTP] = useState(false);
    const [expiredOTP, setExpiredOTP] = useState(false)
    const [otpError, setOtpError] = useState(null);
    const [resentOTP, setResentOTP] = useState(false);
    const [resendingOTP, setResendingOTP] = useState(false);

    const [type, setType] = useState('password');
    const [showPassword, setShowPassword] = useState(false);

    const [regData, setRegData] = useState({
        first_name:'',
        last_name:'',
        email: '',
        password: '',
        otp: ''
    })

    // This function handles data validation
    const validate = Yup.object({
        first_name: Yup.string()
        .required('Please complete this field.'),

        last_name: Yup.string()
        .required('Please complete this field.'),

        email: Yup.string()
        .email('Please complete this field.')
        .required('Email address is required.'),

        password: Yup.string()
        .required('Please complete this field.')
        .matches(
            // eslint-disable-next-line
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Password must have atleast 8 characters, 1 number, 1 uppercase, 1 lowercase and 1 special character."
        ),
    })

    // This function handles showing and hiding password
    const handlePasswordToggle = () => {
        if (type === 'password'){
            setType('text');
            setShowPassword(true);
        }
        else {
            setType('password');
            setShowPassword(false);
        }
    }

    // Updates the Register Data State on every keystroke
    const updateRegisterData = (updatedData) => {
        setRegData(updatedData);
    };

    // This function send request to backend to create account
    const submitRegistration = async (regData) => {
        setIsRegistering(true)
        try {
            const res = await axios.post(
                REGISTER_ENDPOINT,
                JSON.stringify({ 
                    first_name: regData.first_name, 
                    last_name: regData.last_name,
                    email: regData.email, 
                    password: regData.password 
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success){
                setShowOtpModal(true);
                setAccountEmail(res.data.email)
                dispatch(
                    register({
                        email: res.data.email,
                        first_name: res.data.first_name,
                        last_name: res.data.last_name,
                    }),
                );
            } else if (res.data.user_exist){
                setUserExist(true);
            } else {
                console.error('Invalid data received', res.data)
                setRegisterError(true);
                setRegisterErrorMsg("Oops! We encountered an error.")
                setRegisterErrorAction("Please try again or contact support.")
                setRegisterErrorCode("ERR_FINDUKU_2000")                
            }

        } catch (error) {
            console.error('Something went wrong when creating account', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please login your account.")
                        setRegisterErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setRegisterErrorMsg("Oops! We encountered an error");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setRegisterErrorMsg("Oops! We encountered an error");
                setRegisterErrorAction("Please try again or contact support.")
                setRegisterErrorCode("ERR_FINDUKU_8000") 
            }                     
            setRegisterError(true)
        } finally {
            setIsRegistering(false)
        }
    };

    // This function handles verification of sign up
    const confirmSignup = async (regData) => {
        setIsRegistering(true);
        try {
            const res = await axios.post(
                VERIFY_REGISTRATION_ENDPOINT,
                JSON.stringify({ 
                    email: accountEmail,
                    otp: regData.otp  
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success){
                continueApplication()
            } else if (res.data.wrong_otp){
                setWrongOTP(true);
                setExpiredOTP(false);
                setOtpError("The verification code you entered is incorrect.")
            } else if (res.data.expired_otp){
                setExpiredOTP(true);
                setWrongOTP(false);
                setOtpError("The verification code you entered has expired.") 
            } else {
                console.error('Invalid data received', res.data)
                setRegisterError(true);
                setRegisterErrorMsg("Oops! We encountered an error.")
                setRegisterErrorAction("Please try again or contact support.")
                setRegisterErrorCode("ERR_FINDUKU_2000")                
            }

        } catch (error) {
            console.error('Something went wrong confirming registration', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please login your account.")
                        setRegisterErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setRegisterErrorMsg("Oops! We encountered an error");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setRegisterErrorMsg("Oops! We encountered an error");
                setRegisterErrorAction("Please try again or contact support.")
                setRegisterErrorCode("ERR_FINDUKU_8000") 
            }                     
            setRegisterError(true)
        } finally {
            setIsRegistering(false)
        }
    };

    // This function handles sending request to resend OTP
    const handleResendOTP = async (regData) => {
        setResendingOTP(true);
        setWrongOTP(false);
        setExpiredOTP(false)
        setOtpError(null);
        try {
            const res = await axios.post(
                RESEND_OTP_ENDPOINT,
                JSON.stringify({ 
                    email: accountEmail, 
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success){
                setResentOTP(true)
            } else {
                console.error('Invalid data received', res.data)
                setRegisterError(true);
                setRegisterErrorMsg("Oops! We encountered an error.")
                setRegisterErrorAction("Please try again or contact support.")
                setRegisterErrorCode("ERR_FINDUKU_2000")                
            }

        } catch (error) {
            console.error('Something went wrong requesting resend OTP', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please login your account.")
                        setRegisterErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setRegisterErrorMsg("Oops! We encountered an error.");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setRegisterErrorMsg("Oops! We encountered an error");
                        setRegisterErrorAction("Please try again or contact support.")
                        setRegisterErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setRegisterErrorMsg("Oops! We encountered an error");
                setRegisterErrorAction("Please try again or contact support.")
                setRegisterErrorCode("ERR_FINDUKU_8000") 
            }                     
            setRegisterError(true)
        } finally {
            setResendingOTP(false)
        }
    };

    const resetInvalidOTPMsg = () => {
        setWrongOTP(false);
        setExpiredOTP(false);
        setOtpError(null);
        setResentOTP(false);
    }

    const closeErrorModal = () => {
        setRegData({
            first_name:'',
            last_name:'',
            email: '',
            password: ''
        });
        setRegisterError(false);
    }

    const routeToLogin = () => {
        navigate("/signin");
    }

    const continueApplication = () => {
        setEmailConfirmed(true);
        setTimeout(() => {
            navigate('/onboarding/company');
        }, 3000);
    }

    return (
        <>
            <div className="register">
                <div className="register__page-container">
                    <div className="register__header">
                        <div className="register__logo-wrapper">
                            <img src={Logo} alt="Finduku Logo"/>
                        </div>
                        <div className="register__login-wrapper" onClick={routeToLogin}>
                            <p className="register__login-text">Sign in</p>
                            <BsChevronRight className="register__login-icon" />
                        </div>
                    </div>
                    <div className="register__page-border">
                        <div className="register__body">
                            <p className="register__form-heading">Sign up for Finduku</p>
                            <p className="register__form-subheading">Get started by applying for an account.</p>

                            <div className="register__route-to-login-section">
                                <span className="register__route-to-login">Already have an account?</span>
                                <NavLink to="/signin" className="register__route-to-login-link">
                                    Sign in
                                </NavLink>
                            </div>

                            <Formik
                                initialValues={regData}
                                onSubmit={submitRegistration}
                                validationSchema={validate}
                            >
                                {( { handleChange, handleBlur, values, errors, touched }) => {

                                    return (
                                        <Form>
                                            <CSRFToken />
                                            <div className="register__regform">
                                                <div className="register__name-section">
                                                    <div className="register__first-name-section">
                                                        <p className="register__name-title">First name</p>
                                                        <input
                                                            className={`register__name-input ${errors.first_name && touched.first_name ? "input-error": null}`}
                                                            type="text"
                                                            name="first_name"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateRegisterData({ ...values, first_name: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.first_name}
                                                            autoComplete="off"
                                                            required
                                                        />
                                                        {errors.first_name && touched.first_name && (
                                                            <p className="register__name-error"> 
                                                                <BsExclamationCircle className="register__name-error-icon" />
                                                                {errors.last_name}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="register__last-name-section">
                                                        <p className="register__name-title">Last name</p>
                                                        <input
                                                            className={`register__name-input ${errors.last_name && touched.last_name ? "input-error": null}`}
                                                            type="text"
                                                            name="last_name"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateRegisterData({ ...values, last_name: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.last_name}
                                                            autoComplete="off"
                                                            required
                                                        />
                                                        {errors.last_name && touched.last_name && (
                                                            <p className="register__name-error"> 
                                                                <BsExclamationCircle className="register__name-error-icon" />
                                                                {errors.last_name}
                                                            </p>
                                                        )}
                                                    </div> 
                                                </div>
                                        

                                                {/* THIS IS THE EMAIL INPUT SECTION */}
                                                <div className="register__email-section">
                                                    <p className={`register__email-label ${userExist ? "user-exist" : ""}`}>Email address</p>
                                                    <input
                                                        className={`register__email-input ${
                                                            errors.email && touched.email
                                                            ? "input-error"
                                                            : userExist
                                                            ? "user-exist-error"
                                                            : null
                                                        }`}
                                                        type="email"
                                                        name="email"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateRegisterData({ ...values, email: e.target.value });
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.email}
                                                        autoComplete="off"
                                                        required
                                                    />
                                                    {errors.email && touched.email && (
                                                        <p className="register__email-error"> 
                                                            <BsExclamationCircle className="register__email-error-icon" />
                                                            {errors.email}
                                                        </p>
                                                    )}
                                                    {userExist && (
                                                        <p className="register__email-error">
                                                            <BsExclamationCircle className="register__email-error-icon" />
                                                            A user with this email address already exists.
                                                        </p>
                                                    )}
                                                </div>

                                                {/* THIS IS THE PASSWORD INPUT SECTION */}
                                                <div className="register__password-section">
                                                    <p className="register__password-input-title">Password</p>
                                                    <div className="register__password-input-container">
                                                        <input
                                                            className={`register__password-input ${errors.password && touched.password ? "input-error": null}`}
                                                            type={type}
                                                            name="password"
                                                            minLength="8"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateRegisterData({ ...values, password: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.password}
                                                            required
                                                        />
                                                        <div className="register__password-toggle-text" onClick={handlePasswordToggle}>
                                                            {values.password && (
                                                                <span className="register__password-toggle-icon-wrapper">
                                                                    {showPassword ? (
                                                                        <BsEyeSlashFill className="register__password-toggle-eye-icon"/>
                                                                    ) : (
                                                                        <BsEyeFill className="register__password-toggle-eye-icon"/>
                                                                    )}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {errors.password && touched.password && (
                                                        <p className="register__password-error"> 
                                                            <BsExclamationCircle className="register__password-error-icon" />
                                                            {errors.password}
                                                        </p>
                                                    )}
                                                </div>

                                                <div className="register__button-wrapper">
                                                    <button 
                                                        type="submit" 
                                                        className={`register__create-account-btn ${values.first_name && values.last_name && values.email && values.password ? "" : "disabled"}`}
                                                        disabled={!values.first_name || !values.last_name || !values.email || !values.password}
                                                    >
                                                        {isRegistering ? "Processing" : "Start Application"}
                                                    </button>
                                                </div>

                                                <div className="register__policy">
                                                    <p>By clicking "Start Application", I agree to 
                                                        Finduku's <a href="https://www.finduku.com/terms-of-use" target="_blank" rel="noopener noreferrer" className="register__terms-of-service">Terms of Service</a> and <a href="https://www.finduku.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="register__privacy-policy">Privacy Policy</a>, and
                                                        to receive electronic communication about my accounts and services per Finduku's <a href="https://www.finduku.com/electronic-communication-agreement" target="_blank" rel="noopener noreferrer" className="register__communication-agreement">Electronic Communications Agreement.</a>
                                                    </p>
                                                </div>

                                            </div>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>    
                </div>
                {isRegistering &&
                    <RegisterLoader />
                }
                {registerError &&
                    <RegisterError
                        registerErrorMsg={registerErrorMsg}
                        registerErrorAction={registerErrorAction}
                        registerErrorCode={registerErrorCode}
                        onClose={closeErrorModal}
                    />
                }
            </div>
            {showOtpModal &&
                <VerifySignup 
                    wrongOTP={wrongOTP}
                    expiredOTP={expiredOTP}
                    otpError={otpError}
                    confirmSignup={confirmSignup}
                    regData={regData}
                    updateRegisterData={updateRegisterData}
                    resendingOTP={resendingOTP}
                    resentOTP={resentOTP}
                    handleResendOTP={handleResendOTP}
                    resetInvalidOTPMsg={resetInvalidOTPMsg}
                    accountEmail={accountEmail}
                    isRegistering={isRegistering}
                />
            }
            {emailConfirmed &&
                <WelcomeModal />
            }
        </>
    )

};



export default Register;