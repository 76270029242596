import React from 'react';
import SyncLoader from "react-spinners/SyncLoader";
import '../../../Styles/AuthStyles/Onboarding/OnboardingLoader.css';

function OnboardingLoader() {
    return (
        <>
          <div className="onboarding-loader">
            <SyncLoader
                size={13}
                margin={5}
                color="#294254" 
                loading="true"
                speedMultiplier={0.50} 
                aria-label="Loading Spinner" 
                data-testid="loader"
            />                
          </div>
        </>
    )
}

export default OnboardingLoader