import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import CSRFToken from '../../../components/CSRFToken';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { login } from '../../../redux/slices/authSlice';
import { set_merchant_after_login } from '../../../redux/slices/merchantSlice';
import Logo from '../../../assets/logo.svg';
import axios from '../../../api/axios';
import Cookies from 'js-cookie';
import ConfirmLogin from './ConfirmLogin';
import LoginLoader from './LoginLoader';
import LoginError from './LoginError';
import '../../../Styles/AuthStyles/Login/Login.css'
import { 
    BsEyeSlashFill, 
    BsEyeFill, 
    BsExclamationCircle,
    BsChevronRight,
} from "react-icons/bs";

// API ENDPOINTS
const LOGIN_ENDPOINT = '/apps/login/'
const CONFIRM_LOGIN_ENDPOINT = '/apps/confirm_login/'
const RESEND_LOGIN_OTP_ENDPOINT = '/apps/resend_otp/'


function Login () {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // Stores the Login Data provided in the form
    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
        otp: ''
    })

    // Stores the returned email
    const [returnedEmail, setReturnEmail] = useState([])

    // Controls the Login Loading Effect
    const [isLoggingIn, setIsLoggingIn] = useState(false)

    // Incorrect Login State
    const [incorrectLogin, setIncorrectLogin] = useState(false)

    // Hides and displays Confirm Login Modal
    const [showOtpModal, setShowOtpModal] = useState(false)

    // State for incorrect OTP
    const [wrongOTP, setWrongOTP] = useState(false)
    const [expiredOTP, setExpiredOTP] = useState(false)
    const [otpError, setOtpError] = useState(null);

    // State for resending OTP
    const [resendingOTP, setResendingOTP] = useState(false)
    const [resentOTPSuccessful, setResentOTPSuccessful] = useState(false)

    // Controls errors during Login
    const [loginError, setLoginError] = useState(false)
    const [loginErrorMsg, setLoginErrorMsg] = useState(null)
    const [loginErrorAction, setLoginErrorAction] = useState(null)
    const [loginErrorCode, setLoginErrorCode] = useState(null)
    
    // Form Validation
    const validate = Yup.object({
        email: Yup.string()
        .email('The email address format is not valid.')
        .required('Please complete this field.'),
    
        password: Yup.string()
        .required('Please complete this field.'),
    })

    // Password Toggle
    const [type, setType] = useState('password');
    const [showPassword, setShowPassword] = useState(false);

    // Password Toggle Function
    const handlePasswordToggle = () => {
        if (type === 'password'){
            setType('text');
            setShowPassword(true);
        }
        else {
            setType('password');
            setShowPassword(false);
        }
    }

    // Closes Login Error Modal
    const closeLoginErrorModal = () => {
        setLoginError(false);
    }

    // Resets loginData and Incorrect Login states
    const resetIncorrectLoginMsg = () => {
        setIncorrectLogin(false);
    };

    const resetInvalidOTPMsg = () => {
        setWrongOTP(false);
        setOtpError(null);
        setResentOTPSuccessful(false);
    }

    // Submit request for Login to backend
    const submitLogin = async (loginData) => {
        setIsLoggingIn(true)
        try {
            const res = await axios.post(
                LOGIN_ENDPOINT,
                JSON.stringify({ 
                    email: loginData.email, 
                    password: loginData.password 
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );

            if (res.data.success){
                setShowOtpModal(true);
                setReturnEmail(res.data.email);
            } else if (res.data.incorrect_login){
                setIncorrectLogin(true);
            } else {
                console.error('Invalid data received', res.data)
                setLoginError(true);
                setLoginErrorMsg("Oops! We encountered an error.")
                setLoginErrorAction("Please try again or contact support.")
                setLoginErrorCode("ERR_FINDUKU_2000")
            }
        } catch (error) {
            console.error('Something went wrong when logging in', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setLoginErrorMsg("Oops! We encountered an error.");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setLoginErrorMsg("Oops! Authorization is required.");
                        setLoginErrorAction("Please login your account.")
                        setLoginErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setLoginErrorMsg("Oops! We encountered an error.");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setLoginErrorMsg("Oops! We encountered an error.");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setLoginErrorMsg("Oops! We encountered an error.");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setLoginErrorMsg("Oops! We encountered an error");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setLoginErrorMsg("Oops! We encountered an error");
                setLoginErrorAction("Please try again or contact support.")
                setLoginErrorCode("ERR_FINDUKU_8000") 
            }                     
            setLoginError(true)
        } finally {
            setIsLoggingIn(false)
        }
    };

    // Updates the Login Data State on every keystroke
    const updateLoginData = (updatedData) => {
        setLoginData(updatedData);
    };
    
    // Submits request to Confirm Login to backend
    const submitConfirmLogin = async () => {
        setIsLoggingIn(true)
        try {
            const res = await axios.post(
                CONFIRM_LOGIN_ENDPOINT,
                JSON.stringify({ 
                    email: loginData.email, 
                    otp: loginData.otp 
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success){
                const login_data = res.data.login_data
                dispatch(
                    login({
                        email: login_data.email,
                        first_name: login_data.first_name,
                        last_name: login_data.last_name,
                        is_login: true,
                        is_first_login: login_data.is_first_login
                    })
                );
                dispatch(
                    set_merchant_after_login({
                        merchant_name: login_data.merchant_name,
                        merchant_id: login_data.merchant_id,
                        callsign: login_data.callsign,
                        is_profile_setup: login_data.is_profile_setup,
                        is_company_set: login_data.is_company_set,
                    })
                );
                navigate('/');
            } else if (res.data.wrong_otp){
                setWrongOTP(true);
                setExpiredOTP(false);
                setOtpError("The verification code you entered is incorrect.")
            } else if (res.data.expired_otp){
                setExpiredOTP(true);
                setWrongOTP(false);
                setOtpError("The verification code you entered has expired.") 
            } else {
                console.error('Invalid data received', res.data)
                setLoginError(true);
                setLoginErrorMsg("Oops! We encountered an error.")
                setLoginErrorAction("Please try again or contact support.")
                setLoginErrorCode("ERR_FINDUKU_2000")                
            }
        } catch (error) {
            console.log('Something went wrong when confirming login', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setLoginErrorMsg("Oops! We encountered an error.");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setLoginErrorMsg("Oops! Authorization is required.");
                        setLoginErrorAction("Please login your account.")
                        setLoginErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setLoginErrorMsg("Oops! We encountered an error.");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setLoginErrorMsg("Oops! We encountered an error.");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setLoginErrorMsg("Oops! We encountered an error.");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setLoginErrorMsg("Oops! We encountered an error");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setLoginErrorMsg("Oops! We encountered an error");
                setLoginErrorAction("Please try again or contact support.")
                setLoginErrorCode("ERR_FINDUKU_8000") 
            } 
            setLoginError(true)           
        } finally {
            setIsLoggingIn(false)
        }
    };

    // Submits request to Confirm Login to backend
    const submitResendLoginOTP = async () => {
        setResendingOTP(true);
        setWrongOTP(false);
        setExpiredOTP(false);
        setOtpError(null);
        try {
            const res = await axios.post(
                RESEND_LOGIN_OTP_ENDPOINT,
                JSON.stringify({ 
                    email: loginData.email, 
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success){
                setResentOTPSuccessful(true);
            } else {
                console.error('Invalid data received', res.data)
                setLoginError(true);
                setLoginErrorMsg("Oops! We encountered an error.")
                setLoginErrorAction("Please try again or contact support.")
                setLoginErrorCode("ERR_FINDUKU_2000")                
            }
        } catch (error) {
            console.log('Something went wrong when confirming login', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setLoginErrorMsg("Oops! We encountered an error.");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setLoginErrorMsg("Oops! Authorization is required.");
                        setLoginErrorAction("Please login your account.")
                        setLoginErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setLoginErrorMsg("Oops! We encountered an error.");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setLoginErrorMsg("Oops! We encountered an error.");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setLoginErrorMsg("Oops! We encountered an error.");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setLoginErrorMsg("Oops! We encountered an error");
                        setLoginErrorAction("Please try again or contact support.")
                        setLoginErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setLoginErrorMsg("Oops! We encountered an error");
                setLoginErrorAction("Please try again or contact support.")
                setLoginErrorCode("ERR_FINDUKU_8000") 
            } 
            setLoginError(true)           
        } finally {
            setResendingOTP(false)
        }
    };
    
    // Navigates to Forgot Password Page
    const handleForgetPassword = () => {
        navigate('/forgotpassword')
    }

    // Navigates to Contact Sales Page
    const routeToSignUp = () => {
        navigate("/signup");
    }


    return (
        <>
            <div className="login">
                <div className="login__page-container">
                    <div className="login__header">
                        <div className="login__logo-wrapper">
                            <img src={Logo} alt="Finduku Logo"/>
                        </div>
                        <div className="login__signup-wrapper" onClick={routeToSignUp}>
                            <p className="login__signup-text">Sign Up</p>
                            <BsChevronRight className="login__signup-icon" />
                        </div>
                    </div>
                    <div className="login__page-border">
                        <div className="login__body">
                            <p className="login__form-heading">Sign in to Finduku</p>
                            <p className="login__form-subheading">Sign in to access your Finduku account.</p>
                            <div className="login__route-to-sales-wrapper">
                                <span className="login__route-to-sales">New to Finduku?</span>
                                <span className="login__route-to-sales-link" onClick={routeToSignUp}>
                                    Get Started
                                </span>
                            </div>
                            <Formik
                                initialValues={loginData}
                                onSubmit={submitLogin}
                                validationSchema={validate}
                            >
                                {({ handleChange, handleBlur, values, errors, touched }) => {

                                    return (
                                        <Form>
                                            <CSRFToken />
                                            <div className="login__form">

                                                {/* EMAIL INPUT SECTION */}
                                                <div className="login__email-input-wrapper">
                                                    <p className={`login__email-input-label ${incorrectLogin ? "incorrect-login" : ""}`}>Email address</p>
                                                    <input
                                                        className={`login__email-input ${
                                                            errors.email && touched.email
                                                            ? "email-input-error"
                                                            : incorrectLogin
                                                            ? "incorrect-login-error"
                                                            : null
                                                        }`}
                                                        type="email"
                                                        name="email"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateLoginData({ ...values, email: e.target.value });
                                                            resetIncorrectLoginMsg();
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.email}
                                                        autoComplete="off"
                                                        required
                                                    />
                                                    {errors.email && touched.email && (
                                                        <p className="login__email-input-error"> 
                                                            <BsExclamationCircle className="login__email-input-error-icon" />
                                                            {errors.email}
                                                        </p>
                                                    )}
                                                    {incorrectLogin && (
                                                        <p className="login__email-input-error">
                                                            <BsExclamationCircle className="login__email-input-error-icon" />
                                                            Invalid login credentials provided.
                                                        </p>
                                                    )}
                                                </div>

                                                {/* PASSWORD INPUT SECTION */}
                                                <div className="login__password-input-wrapper">
                                                    <p className={`login__password-input-label ${incorrectLogin ? "incorrect-login" : ""}`}>Password</p>
                                                    <div className="login__password-input-container">
                                                        <input
                                                            className={`login__password-input ${
                                                                errors.password && touched.password
                                                                ? "password-input-error"
                                                                : incorrectLogin
                                                                ? "incorrect-login-error"
                                                                : null
                                                            }`}
                                                            type={type}
                                                            name="password"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateLoginData({ ...values, password: e.target.value });
                                                                resetIncorrectLoginMsg();
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.password}
                                                            required
                                                        />
                                                        <div className="login__password-toggle" onClick={handlePasswordToggle}>
                                                            {values.password && (
                                                                <span className={`login__password-toggle-icon-wrapper ${incorrectLogin ? 'incorrect-login-error' : ''}`}>
                                                                    {showPassword ? (
                                                                        <BsEyeSlashFill className="login__password-toggle-eye-icon" />
                                                                    ) : (
                                                                        <BsEyeFill className="login__password-toggle-eye-icon" />
                                                                    )}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {errors.password && touched.password && (
                                                        <p className="login__password-input-error"> 
                                                            <BsExclamationCircle className="login__password-input-error-icon" />{errors.password}
                                                        </p>
                                                    )}
                                                    {incorrectLogin && (
                                                        <p className="login__password-input-error">
                                                            <BsExclamationCircle className="login__password-input-error-icon" />
                                                            Invalid login credentials provided.
                                                        </p>
                                                    )}
                                                </div>
                                                
                                                {/* BUTTON SECTION */}
                                                <div className="login__button-wrapper">
                                                    <button 
                                                        type="submit" 
                                                        className={`login__submit-button ${values.email && values.password ? "" : "disabled"}`}
                                                        disabled={!values.email || !values.password}
                                                    >
                                                        {isLoggingIn ? (
                                                            'Signing in'
                                                        ) : (
                                                            'Sign In'
                                                        )}
                                                    </button>
                                                </div>

                                                {/* FORGOT PASSWORD SECTION */}
                                                <div className="login__forgot-password-wrapper" onClick={handleForgetPassword}>
                                                    <p className="login__forgot-password-link">Forgot Password?</p>
                                                </div>
                
                                                {/* SIGN UP REROUTE SECTION */}
                                                <div className="login__signup-route-wrapper">
                                                    <span className="login__signup-route-text">Got a question or concern?</span>
                                                    <a 
                                                        href="https://www.finduku.com/terms-of-use" 
                                                        target="_blank" 
                                                        rel="noopener noreferrer"
                                                        className="login__signup-route-link" 
                                                    >
                                                        Contact Us
                                                    </a>
                                                </div>
                                            </div>{/* END OF LOGIN FORM */}
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
                {isLoggingIn &&
                    <LoginLoader />
                }
                {loginError &&
                    <LoginError
                        loginErrorMsg={loginErrorMsg} 
                        loginErrorAction={loginErrorAction}
                        loginErrorCode={loginErrorCode}
                        closeLoginErrorModal={closeLoginErrorModal}
                    />
                }    
            </div>
            {showOtpModal &&
                <ConfirmLogin  
                    wrongOTP={wrongOTP}
                    expiredOTP={expiredOTP}
                    otpError={otpError}
                    returnedEmail={returnedEmail}
                    submitConfirmLogin={submitConfirmLogin}
                    loginData={loginData}
                    updateLoginData={updateLoginData}
                    resendingOTP={resendingOTP}
                    resentOTPSuccessful={resentOTPSuccessful}
                    submitResendLoginOTP={submitResendLoginOTP}
                    resetInvalidOTPMsg={resetInvalidOTPMsg}
                    isLoggingIn={isLoggingIn}
                />
            }   
        </>
    )
};



export default Login;