import React from 'react';
import { BsCheckCircle } from "react-icons/bs";
import '../../../Styles/AuthStyles/Users/VerifySetupSuccess.css';

function SetupUserSuccess({ navigateToLogin }) {
     return (
          <>
               <div className="verify-setup-success">
                    <div className="verify-setup-success__message-container">
                         <BsCheckCircle  className="verify-setup-success__message-icon"/>
                         <p className="verify-setup-success__text">Your account has been setup.</p>
                         <p className="verify-setup-successr__action">Log in to start using your account.</p>
                         <div className="verify-setup-success__button-wrapper">
                              <button 
                                   type="button"
                                   className="verify-setup-success__button"
                                   onClick={navigateToLogin}
                              >
                                   LOG IN
                              </button>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default SetupUserSuccess