import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import SetupUserLoader from './SetupUserLoader';
import ResendSetupUserOTPLoader from './ResendSetupUserOTPLoader';
import Logo from '../../../assets/logo.svg';
import CSRFToken from '../../../components/CSRFToken';
import '../../../Styles/AuthStyles/Users/VerifySetup.css'

function VerifySetup ({ 
    wrongOTP,
    expiredOTP,
    otpError,
    confirmSetup,
    invitedUserData,
    updateRegisterData,
    resendingOTP,
    resentOTP,
    resendInviteEmail,
    resetInvalidOTPMsg,
    isSettingAccount
}) {

    // Form Validation
    const validateOTP = Yup.object().shape({
        otp: Yup.string()
            .required('Verification code is required')
            .matches(/^[0-9]\d{5}$/, {message: "Verification code must be 6 digits.", excludeEmptyString: false}),
    })

    return (
        <>
            <div className="verify-setup" onClick={(e) => {e.stopPropagation()}}>
                <div className="verify-setup__page-container">
                        <Formik
                            initialValues={invitedUserData}
                            onSubmit={confirmSetup}
                            validationSchema={validateOTP}
                        >

                        {({ handleChange, handleBlur, setFieldValue, values, errors, touched, isValid, dirty  }) => {

                            const handleOtpPaste = (e, setFieldValue) => {
                                e.preventDefault();
                                const pastedData = e.clipboardData.getData("text").trim();
                                if (pastedData.length === 6 && /^\d{6}$/.test(pastedData)) {
                                    setFieldValue("otp", pastedData);
                                    updateRegisterData({ ...values, otp: pastedData });
                                    resetInvalidOTPMsg();
                                }
                            };

                            return (
                                <Form>
                                    <CSRFToken />

                                    {/* HEADER SECTION */}
                                    <div className="verify-setup__header">
                                        <img src={Logo} alt="Finduku Logo"/>
                                        <p className="verify-setup__subheading">We're just making sure it's you.</p>
                                    </div>

                                    {/* BODY SECTION */}
                                    <div className="verify-setup__body">

                                        {resentOTP 
                                        ?   (<div className="verify-setup__resend-otp">
                                                <p className="verify-setup__resend-otp-text">A new OTP has been sent to your email.</p>
                                            </div>)
                                        :   null 
                                        }

                                        {/* DIRECTION SECTION */}
                                        <div className="verify-setup__direction-wrapper">
                                            <p className="verify-setup__direction-text">
                                                Please enter the single - use verification code provided in the user invitation email we sent.
                                            </p>
                                        </div>

                                        {/* OTP INPUT SECTION */}
                                        <div className="verify-setup__otp-wrapper" onPaste={(e) => handleOtpPaste(e, setFieldValue)}>
                                            <input
                                                type="text"
                                                className={`verify-setup__otp-input ${
                                                        errors.otp && touched.otp
                                                        ? "verify-setup__input-error"
                                                        : (wrongOTP || expiredOTP)
                                                        ? "verify-setup__wrong-otp-error"
                                                        : null
                                                }`}
                                                name="otp"
                                                inputMode="numeric"
                                                value={values.otp}
                                                autoComplete="off"
                                                onKeyDown={(e) => {
                                                        const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'v'];
                                                        const isCtrlV = (e.ctrlKey || e.metaKey) && e.key === 'v'; 
                                                
                                                        if (!/\d/.test(e.key) && !allowedKeys.includes(e.key) && !isCtrlV) {
                                                            e.preventDefault();
                                                        }
                                                }}
                                                onChange={(e) => {
                                                        handleChange(e);
                                                        updateRegisterData({ ...values, otp: e.target.value });
                                                        resetInvalidOTPMsg();
                                                }}
                                                onBlur={handleBlur}
                                                maxLength="6"
                                            /> 
                                        </div>
                                        {/* VALIDATION ERRORS SECTION */}
                                        <div className="verify-setup__verify-error-wrapper">
                                            {errors.otp && touched.otp && <p className="verify-setup__verify-error">{errors.otp}</p>}
                                            {wrongOTP && <p className="verify-setup__verify-error">{otpError}</p>}
                                            {expiredOTP && <p className="verify-setup__verify-error">{otpError}</p>}
                                        </div>

                                        {/* BUTTON SECTION */}
                                        <div className="verify-setup__button-wrapper">
                                            <button 
                                                type="submit" 
                                                className={`verify-setup__submit-button ${!(values.otp && values.otp.length === 6) ? "disabled" : ""}`}
                                                disabled={!(values.otp && values.otp.length === 6)}
                                            >
                                                {isSettingAccount ? "Processing" : "CONFIRM"}
                                            </button>
                                        </div>

                                        {/* RESENT CODE SECTION */}
                                        <div className="verify-setup__resend-code-wrapper">
                                            <p className="verify-setup__resend-code">Haven't receive the email?</p>
                                            <p className="verify-setup__resend-code-link" onClick={resendInviteEmail}>Resend email</p>
                                        </div>
                                    </div>

                                    {/* FOOTER SECTION */}
                                    <div className="verify-setup__footer">
                                        <div className="verify-setup__code-direction-wrapper">
                                            <p className="verify-setup__code-direction-text">
                                                Confirm your identity to create a password for your Finduku account.
                                                Your code will expire if not used in the next 24 hours.
                                            </p>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
                {isSettingAccount &&
                    <SetupUserLoader />
                }
                {resendingOTP &&
                    <ResendSetupUserOTPLoader />
                }
            </div>
        </>
    )
};



export default VerifySetup;