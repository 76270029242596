import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import { destinationBanks } from './destinationBanks';
import { provinces } from './provinces';
import { BsFillExclamationTriangleFill, BsCheckCircle, BsEmojiFrown } from "react-icons/bs";
import PayoutInfoLoader from './PayoutInfoLoader';
import CSRFToken from '../../../../components/CSRFToken';
import '../../../../Styles/MainStyles/Settings/PayoutInfo/AddBankAccount.css';


const AccountTypeOptions = [
    'Corporate',
    'Personal',
]

const BankNameTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        width: '100%', 
        height: '10px', 
        fontFamily: 'PP Neue Montreal Variable, Arial, sans-serif', 
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            border: '0.1px solid #afaeae',
        },
        '&.Mui-focused fieldset': {
            border: '0.1px solid #284154', 
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: '0.1px solid #afaeae', 
        borderRadius: 2,
    },
    '& .MuiInputBase-input::placeholder': {
        fontFamily: 'PP Neue Montreal Variable, Arial, sans-serif', 
        fontSize: '15px', 
        color: '#A3ACC9', 
        opacity: 1,
    },
    '&.input-error .MuiOutlinedInput-root': {
        borderColor: 'rgb(211, 12, 12) !important',
        backgroundColor: 'rgb(251,233,232)',
        '&:hover fieldset': {
            borderColor: 'rgb(211, 12, 12) !important',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgb(211, 12, 12) !important',
        },
    },
});


const ProvincesField = styled(TextField)({
    '& .MuiInputBase-input': {
        width: '100%', 
        height: '10px', 
        fontFamily: 'PP Neue Montreal Variable, Arial, sans-serif', 
    },
   '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            border: '0.1px solid #afaeae',
        },
        '&.Mui-focused fieldset': {
            border: '0.1px solid #284154', 
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: '0.1px solid #afaeae', 
        borderRadius: 2,
    },
    '& .MuiInputBase-input::placeholder': {
        fontFamily: 'PP Neue Montreal Variable, Arial, sans-serif', 
        fontSize: '15px', 
        color: '#A3ACC9', 
        opacity: 1,
    },
    '&.input-error .MuiOutlinedInput-root': {
        borderColor: 'rgb(211, 12, 12) !important',
        backgroundColor: 'rgb(251,233,232)',
        '&:hover fieldset': {
            borderColor: 'rgb(211, 12, 12) !important',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgb(211, 12, 12) !important',
        },
    },
});

const AccountTypeField = styled(TextField)({
    '& .MuiInputBase-input': {
      width: '100%', 
      height: '10px', 
      fontFamily: 'PP Neue Montreal Variable, Arial, sans-serif', 
    },
   '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        border: '0.1px solid #afaeae',
      },
     '&.Mui-focused fieldset': {
        border: '0.1px solid #284154', 
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0.1px solid #afaeae', 
      borderRadius: 2,
    },
    '& .MuiInputBase-input::placeholder': {
      fontFamily: 'PP Neue Montreal Variable, Arial, sans-serif', 
      fontSize: '15px', 
      color: '#A3ACC9', 
      opacity: 1,
    },
    '&.input-error .MuiOutlinedInput-root': {
      borderColor: 'rgb(211, 12, 12) !important',
      backgroundColor: 'rgb(251,233,232)',
      '&:hover fieldset': {
        borderColor: 'rgb(211, 12, 12) !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(211, 12, 12) !important',
      },
    },
});

function AddBankAccount({ 
    newBankData, 
    submitNewBank, 
    isAddBankAccountSuccessful, 
    updateNewBankData,
    onClose, 
    isAddingBankAccount,
    addBankError,
    addBankErrorMsg,
    addBankErrorAction,
    addBankErrorCode
}) {
    
    const validate = Yup.object({
        bank_name: Yup.string()
        .required('Please select a bank')
        .matches(/^[a-zA-Z0-9 .]*$/, 'Only letters, numbers, periods, and spaces are allowed'),

        account_name: Yup.string()
        .required('Account holder is required'),

        account_number: Yup.string()
        .required('Account number is required')
        .matches(/^\d+(\.\d{1,2})?$/, { message: "Account number must be a valid number.", excludeEmptyString: false })
        .test('is-not-zero', 'Account number cannot be zero', value => value !== '0'),

        account_type: Yup.string()
        .required('Please select an account type'),

        address_line1: Yup.string()
        .required('Address line 1 is required'),

        address_line2: Yup.string()
        .required('Address line 2 is required'),

        city: Yup.string()
        .required('City or municipality is required'),

        province: Yup.string()
        .required('Province is required'),

        zipcode: Yup.string()
        .required('Zipcode is required')
        .matches(/^\d+(\.\d{1,2})?$/, { message: "Zipcode must be a valid number.", excludeEmptyString: false })
    })

    return (
        <>
            <div className="add-bank" onClick={(e) => {e.stopPropagation()}}>
                <div className="add-bank__page-container">
                    {addBankError ? (
                        <div className="add-bank__error-message-wrapper">
                            <BsEmojiFrown className="add-bank__error-message-icon"/>
                            <p className="add-bank__error-message">{addBankErrorMsg}</p>
                            <p className="add-bank__error-action">{addBankErrorAction}</p>
                            <p className="add-bank__error-code">{addBankErrorCode}</p>
                            <div className="add-bank__error-button-wrapper">
                                <button 
                                    className="add-bank__error-button"
                                    type="button" 
                                    onClick={onClose}
                                >
                                    OKAY
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            {isAddBankAccountSuccessful ? (
                                <div className="add-bank__message-wrapper">
                                    <BsCheckCircle className="add-bank__message-icon"/>
                                    <p>Your bank account has been added successfully.</p>
                                    <button 
                                        type="button" 
                                        onClick={onClose}
                                    >
                                        Close
                                    </button>
                                </div>

                            ) : (
                                <Formik
                                    initialValues={newBankData}
                                    onSubmit={submitNewBank}
                                    validationSchema={validate}
                                >
                                    {( { handleChange, handleBlur, values, handleSubmit, errors, touched, isValid, dirty }) => {

                                        return (
                                            <Form>
                                                <CSRFToken />
                                                <div className="add-bank__header">
                                                    <div className="heading-wrapper">
                                                        <p className="heading">Add Bank Account</p>
                                                    </div>
                                                    <div className="subheading-wrapper">
                                                        <p className="subheading">
                                                            Enumerate a new bank account to receive your payouts.
                                                            Your payout requests will be sent to this bank account.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="add-bank__body">

                                                    {/* BANK NAME & BANK CODE */}
                                                    <div className="add-bank__input-wrapper">
                                                        <p>Bank Name</p>
                                                        <Autocomplete
                                                            options={destinationBanks}
                                                            getOptionLabel={(option) => option.bank_name}
                                                            onChange={(event, newValue) => {
                                                                handleChange("bank_name")(newValue ? newValue.bank_name : "");
                                                                handleChange("bank_code")(newValue ? newValue.bank_code : "");
                                                            }}
                                                            renderInput={(params) => (
                                                                <BankNameTextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    name="bank_name"
                                                                    onBlur={handleBlur("bank_name")}
                                                                    value={values.bank_name}
                                                                    placeholder="Select your bank"
                                                                    error={touched.bank_name && !!errors.bank_name}
                                                                    className={touched.bank_name && errors.bank_name ? 'add-bank-input-error' : ''}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    {errors.bank_name && touched.bank_name && (
                                                        <p className="add-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.bank_name}
                                                        </p>
                                                    )}

                                                    {/* ACCOUNT HOLDER */}
                                                    <div className="add-bank__input-wrapper">
                                                        <p>Account Holder</p>
                                                        <input
                                                            className={`add-bank__inputs ${errors.account_name && touched.account_name ? "add-bank-input-error" : null}`}
                                                            type="text"
                                                            name="account_name"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateNewBankData({ ...values, account_name: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.account_name}
                                                            placeholder="Name of the Account Holder"
                                                            autoComplete="off"
                                                        />
                                                        
                                                    </div>
                                                    {errors.account_name && touched.account_name && (
                                                        <p className="add-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.account_name}
                                                        </p>
                                                    )}

                                                    {/* ACCOUNT NUMBER */}
                                                    <div className="add-bank__input-wrapper">
                                                        <p>Account Number</p>
                                                        <input
                                                            className={`add-bank__inputs ${errors.account_number && touched.account_number ? "add-bank-input-error" : null}`}
                                                            type="text"
                                                            name="account_number"
                                                            onKeyDown={(e) => {
                                                            const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateNewBankData({ ...values, account_number: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.account_number}
                                                            placeholder="Provide your account number"
                                                            autoComplete="off"
                                                            required
                                                            inputMode="numeric"
                                                        />
                                                                                                        
                                                    </div>
                                                    {errors.account_number && touched.account_number && (
                                                        <p className="add-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.account_number}
                                                        </p>
                                                    )}

                                                    {/* ACCOUNT TYPE */}
                                                    <div className="add-bank__input-wrapper">
                                                        <p>Account Type</p>
                                                        <Autocomplete
                                                            options={AccountTypeOptions}
                                                            getOptionLabel={(option) => option} 
                                                            onChange={(event, newValue) => {
                                                                handleChange("account_type")(newValue || "");
                                                            }}
                                                            renderInput={(params) => (
                                                                <AccountTypeField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    name="account_type"
                                                                    onBlur={handleBlur("account_type")}
                                                                    value={values.account_type}
                                                                    placeholder="Select an account type"
                                                                    error={touched.account_type && !!errors.account_type}
                                                                    className={touched.account_type && errors.account_type ? 'add-bank-input-error' : ''}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    {errors.account_type && touched.account_type && (
                                                        <p className="add-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.account_type}
                                                        </p>
                                                    )}

                                                    {/* ADDRESS LINE 1 */}
                                                    <div className="add-bank__input-wrapper">
                                                        <p>Address Line 1</p>
                                                        <input
                                                            className={`add-bank__inputs ${errors.address_line1 && touched.address_line1 ? "add-bank-input-error" : null}`}
                                                            type="text"
                                                            name="address_line1"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateNewBankData({ ...values, address_line1: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.address_line1}
                                                            placeholder="House No, Building and Street Name"
                                                            autoComplete="off"
                                                        />
                                                        
                                                    </div>
                                                    {errors.address_line1 && touched.address_line1 && (
                                                        <p className="add-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.address_line1}
                                                        </p>
                                                    )}

                                                    {/* ADDRESS LINE 2 */}
                                                    <div className="add-bank__input-wrapper">
                                                        <p>Address Line 2</p>
                                                        <input
                                                            className={`add-bank__inputs ${errors.address_line2 && touched.address_line2 ? "add-bank-input-error" : null}`}
                                                            type="text"
                                                            name="address_line2"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateNewBankData({ ...values, address_line2: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.address_line2}
                                                            placeholder="Village, Subdivision and Barangay"
                                                            autoComplete="off"
                                                        />
                                                        
                                                    </div>
                                                    {errors.address_line2 && touched.address_line2 && (
                                                        <p className="add-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.address_line2}
                                                        </p>
                                                    )}

                                                    
                                                    {/* CITY OR MUNICIPALITY */}
                                                    <div className="add-bank__input-wrapper">
                                                        <p>City or Municipality</p>
                                                        <input
                                                            className={`add-bank__inputs ${errors.city && touched.city ? "add-bank-input-error" : null}`}
                                                            type="text"
                                                            name="city"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateNewBankData({ ...values, city: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.city}
                                                            placeholder="City or Municipality"
                                                            autoComplete="off"
                                                        />
                                                        
                                                    </div>
                                                    {errors.city && touched.city && (
                                                        <p className="add-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.address_line2}
                                                        </p>
                                                    )}

                                                    {/* PROVINCES */}
                                                    <div className="add-bank__input-wrapper">
                                                        <p>Province</p>
                                                        <Autocomplete
                                                            options={provinces}
                                                            getOptionLabel={(option) => option} 
                                                            onChange={(event, newValue) => {
                                                                handleChange("province")(newValue || "");
                                                            }}
                                                            renderInput={(params) => (
                                                                <ProvincesField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    name="province"
                                                                    onBlur={handleBlur("province")}
                                                                    value={values.province}
                                                                    placeholder="Select your province"
                                                                    error={touched.province && !!errors.province}
                                                                    className={touched.province && errors.province ? 'add-bank-input-error' : ''}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    {errors.province && touched.province && (
                                                        <p className="add-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.province}
                                                        </p>
                                                    )}

                                                    {/* ZIPCODE */}
                                                    <div className="add-bank__input-wrapper">
                                                        <p>Zipcode</p>
                                                        <input
                                                            className={`add-bank__inputs ${errors.zipcode && touched.zipcode ? "add-bank-input-error" : null}`}
                                                            type="text"
                                                            name="zipcode"
                                                            onKeyDown={(e) => {
                                                            const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateNewBankData({ ...values, zipcode: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.zipcode}
                                                            placeholder="Provide your zipcode"
                                                            autoComplete="off"
                                                            required
                                                            inputMode="numeric"
                                                        />
                                                                                                        
                                                    </div>
                                                    {errors.zipcode && touched.zipcode && (
                                                        <p className="add-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.zipcode}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="add-bank__footer">
                                                    <div className="add-bank__button-wrapper">
                                                        {!isAddingBankAccount && (
                                                            <button 
                                                                type="button" 
                                                                onClick={() => {
                                                                    onClose();
                                                                }}
                                                            >
                                                                {isAddBankAccountSuccessful ? "Close" : "Cancel"}
                                                            </button>
                                                        )}
                                                        {isAddBankAccountSuccessful ? null : 
                                                            <button 
                                                                type="submit"
                                                                disabled={!(isValid && dirty && newBankData.bank_name && newBankData.account_name && newBankData.account_number !== '0' && newBankData.address_line1 && newBankData.address_line2 && newBankData.city && newBankData.province && newBankData.zipcode )}
                                                                className={!(isValid && dirty && newBankData.bank_name && newBankData.account_name && newBankData.account_number !== '0' && newBankData.address_line1 && newBankData.address_line2 && newBankData.city && newBankData.province && newBankData.zipcode ) ? "disabled" : ""}
                                                            >
                                                                {isAddingBankAccount ? "Adding" : "Add Account"}
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            )}
                        </>
                    )}
                </div>
                {isAddingBankAccount && <PayoutInfoLoader />}
            </div>
        </>
    ) 
}

export default AddBankAccount