import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { BsInfoCircleFill } from "react-icons/bs";
import { styled } from '@mui/system';
import '../../../../Styles/MainStyles/Settings/PayoutInfo/PayoutInfo.css';

const PayoutMethodTooltip = styled(({ className, ...props }) => (
    <Tooltip classes={{ popper: className }} {...props} />
))({
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'white',  // Change this to your preferred color
        maxWidth: 480,           // Adjust the maximum width
        color: 'black',
        fontFamily: 'Figtree',
        fontSize: '14px',
        boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.25)', 
        padding: '6px',
    },
    '& .MuiTooltip-arrow': {
        color: 'white',  // Change this to your preferred color
    },
});

const PayoutScheduleTooltip = styled(({ className, ...props }) => (
    <Tooltip classes={{ popper: className }} {...props} />
))({
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'white',  // Change this to your preferred color
        maxWidth: 480,           // Adjust the maximum width
        color: 'black',
        fontFamily: 'Figtree',
        fontSize: '14px',
        boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.25)', 
        padding: '6px',
    },
    '& .MuiTooltip-arrow': {
        color: 'white',  // Change this to your preferred color
    },
});

function PayoutInfo({ 
    settingsData,
    openNewBankModal, 
    openUpdateBankModal, 
    isLoadingSettingPage,
    updateBankModalOpen,
    newBankModalOpen,
    loggedInUserPerm,
    displayNoPermModal
}) {
    
        return (
        <>
            <div className="payout-info">
                <div className="payout-info__page-container">
                    <div className="payout-info__title-wrapper">
                        <p className={`title ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-title' : ''}`}>
                            {isLoadingSettingPage && 'Payout Settings'}
                        </p>
                        <p className={`caption ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-caption' : ''}`}>
                            {isLoadingSettingPage && 
                                "Configure payout preferences and manage banking details."
                            }
                        </p>  
                    </div>
                    <div className="payout-info__content-wrapper">
                        <div className="content-item">
                            <div className="label">
                                <p className={`label-text ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Payout Method'}
                                </p>
                            </div>
                            <div className="value">
                                <p className={`payout-method-value ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-payout-value' : ''}`}>
                                    {isLoadingSettingPage && 
                                        <>
                                            Payout to Bank
                                            <PayoutMethodTooltip 
                                                title="All payouts will be deposited to your bank" 
                                                placement="right" 
                                                arrow
                                            >
                                                <span className="value-non-edit">
                                                    <BsInfoCircleFill className="non-edit-info-icon" />
                                                </span>
                                            </PayoutMethodTooltip>
                                            <span className="other-method">(more payout methods available soon)</span>
                                        </>
                                    }
                                </p>
                            </div>
                            <div className="action">
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="payout-info__content-wrapper">
                        <div className="content-item">
                            <div className="label">
                                <p className={`label-text ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Payout Schedule'}
                                </p>
                            </div>
                            <div className="value">
                                <p className={`payout-schedule-value ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-payout-value' : ''}`}>
                                    {isLoadingSettingPage && 
                                        <>
                                            Manual
                                            <PayoutScheduleTooltip 
                                                title="User must initiate payout request manually" 
                                                placement="right" 
                                                arrow
                                            >
                                                <span className="value-non-edit">
                                                    <BsInfoCircleFill className="non-edit-info-icon" />
                                                </span>
                                            </PayoutScheduleTooltip>
                                            <span className="other-method">(automatic payout available soon)</span>
                                        </>
                                    }
                                </p>
                            </div>
                            <div className="action">
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="payout-info__content-wrapper">
                        <div className="content-item">
                            <div className="label">
                                <p className={`label-text ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Banking Details'}
                                </p>
                            </div>
                            <div className="bank-account-value">
                                {!isLoadingSettingPage ? (
                                    <>
                                        <p className="bank-name skeleton-loader skeleton-loader-value"></p>
                                        <p className="bank-account skeleton-loader skeleton-loader-value"></p>
                                        <p className="bank-account skeleton-loader skeleton-loader-value"></p>
                                    </>
                                ) : (
                                    settingsData.account_number ? (
                                        <>
                                            <p className="bank-name">
                                                {settingsData.bank_name}
                                            </p>
                                            <p className="bank-account">
                                                Account Number <span className="separator">••••</span>{settingsData.account_number}
                                            </p>
                                            <p className="account-name">
                                                Account Name: {settingsData.account_name}
                                            </p>
                                        </>
                                    ) : (
                                        <p className="no-bank-value">No bank account has been added to this merchant</p>
                                    )
                                )}
                            </div>
                            <div className="action">
                                <button 
                                    className={`bank-action-button ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''} ${newBankModalOpen || updateBankModalOpen  ? 'active-button' : ''}`}
                                    onClick={isLoadingSettingPage ? () => {
                                        if (loggedInUserPerm.can_manage_bank_account) {
                                            if (settingsData.account_number) {
                                                openUpdateBankModal();
                                            } else {
                                                openNewBankModal();
                                            }
                                        } else {
                                            displayNoPermModal();
                                        }
                                    } : undefined}
                                >
                                    {isLoadingSettingPage ? (settingsData.account_number ? 'Update' : 'Add') : ''}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PayoutInfo