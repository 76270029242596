import React from 'react';
import { BsEmojiFrown } from "react-icons/bs";
import '../../../Styles/MainStyles/Transactions/NoPermModal.css';

function NoPermModal({ onClose }) {
    return (
        <>
            <div className="noPermModal">
                <div className="noPermModal__page-container">
                    <div className="noPermModal__content">
                        <BsEmojiFrown className="noPermModal__perm-icon"/>
                        <p className="noPermModal__perm-heading">You don't currently have permission to make changes to this page.</p>
                        <p className="noPermModal__perm-subheading">Contact your administrator for assistance.</p>
                        <button type="button" onClick={onClose}>OKAY</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoPermModal
