import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CSRFToken from '../../../../components/CSRFToken';
import ChangeEmailLoader from './ChangeEmailLoader';
import '../../../../Styles/MainStyles/Settings/UserInfo/ChangeEmail.css';
import { 
    BsEyeSlashFill, 
    BsEyeFill, 
    BsCheckCircle, 
    BsExclamationCircle,
    BsEmojiFrown 
} from "react-icons/bs";

function ChangeEmail({ 
    onClose, 
    oldEmail, 
    changeEmailData, 
    updateChangeEmailData, 
    submitNewEmail,
    isChangingEmail,
    wrongPassword, 
    changeEmailConfirmed,
    changeEmailError,
    changeEmailErrorMsg,
    changeEmailErrorAction,
    changeEmailErrorCode,
    resetWrongPasswordMsg,
    navigate2Login,
    userExist,
    resetUserExistMsg
}) {

    const validate = Yup.object({
        new_email: Yup.string()
            .email('The email address format is not valid.')
            .required('Please complete this field.')
            .test('is-email-different', 'New email should not be the same as the old email.', value => {
                return value !== oldEmail;
            }),

        password: Yup.string()
            .required('Please complete this field.'),
    });

    // HANDLES SHOWING AND HIDING PASSWORD
    const [type, setType] = useState('password');
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordToggle = () => {
        if (type === 'password'){
            setType('text');
            setShowPassword(true);
        }
        else {
            setType('password');
            setShowPassword(false);
        }
    }


    return (
        <>
            <div className="change-email" onClick={(e) => {e.stopPropagation()}}>
                <div className="change-email__page-container">
                    {changeEmailError ? (
                        <div className="change-email__error-message-wrapper">
                            <BsEmojiFrown  className="change-email__error-message-icon"/>
                            <p className="change-email__error-msg">{changeEmailErrorMsg}</p>
                            <p className="change-email__error-action">{changeEmailErrorAction}</p>
                            <p className="change-email__error-code">{changeEmailErrorCode}</p>
                            <button 
                                type="button" 
                                className="change-email__error-button"
                                onClick={onClose}
                            >
                                OKAY
                            </button>
                        </div>
                    ) : (
                        <>
                            {changeEmailConfirmed ? (
                                <div className="change-email__confirmation-message-wrapper">
                                    <BsCheckCircle className="confirm-icon"/>
                                    <p className="confirmation-message-heading">Email has been changed.</p>
                                    <p className="confirmation-message-subheading">Use the new email for future logins.</p>
                                    <p className="confirmation-message-notice">
                                        We're logging you out for security reasons.
                                    </p>
                                    <button className="change-email__confirmation-button"type="button" onClick={navigate2Login}>OKAY</button>
                                </div>
                            ) : (
                                <Formik
                                    initialValues={changeEmailData}
                                    onSubmit={submitNewEmail}
                                    validationSchema={validate}
                                >
                                    {({ handleChange, handleBlur, values, errors, touched }) => {
                                    
                                        return (
                                            <Form>
                                                <CSRFToken />
                                                <div className="change-email__header">
                                                    <p className="change-email__heading">Update email address</p>
                                                </div>
                                                <div className="change-email__body">
                                                    <div className="change-email__input-wrapper">
                                                        <p className="change-email__input-label">Current email address</p>
                                                        <input 
                                                            className="change-email__input oldEmail-input" 
                                                            type="text" 
                                                            value={oldEmail}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="change-email__input-wrapper height">
                                                        <p className={`change-email__input-label ${userExist ? "user-exist" : ""}`}>New email address</p>
                                                        <input 
                                                            className={`change-email__input ${
                                                                errors.new_email && touched.new_email 
                                                                ? "change-email__input-error"
                                                                : userExist
                                                                ? "change-email__user-exist-error"
                                                                : null
                                                            }`}
                                                            type="text" 
                                                            name="new_email"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateChangeEmailData({ ...values, new_email: e.target.value });
                                                                resetUserExistMsg();
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.new_email}
                                                            autoComplete="off"
                                                        />
                                                        {errors.new_email && touched.new_email && (
                                                            <p className="change-email__input-error-message"> 
                                                                <BsExclamationCircle className="change-email__input-error-icon" />
                                                                {errors.new_email}
                                                            </p>
                                                        )}
                                                        {userExist && (
                                                            <p className="change-email__input-error-message">
                                                                <BsExclamationCircle className="change-email__input-error-icon" />
                                                                A user with this email address already exists.
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="change-email__input-wrapper height">
                                                        <p className={`change-email__input-label ${wrongPassword ? "wrong-password" : ""}`}>Password</p>
                                                        <input 
                                                            className={`change-email__input ${
                                                                errors.password && touched.password
                                                                ? "change-email__input-error"
                                                                : wrongPassword
                                                                ? "change-email__wrong-password-error"
                                                                : null
                                                            }`}
                                                            type={type} 
                                                            name="password"
                                                            minLength="8"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateChangeEmailData({ ...values, password: e.target.value });
                                                                resetWrongPasswordMsg();
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.password}
                                                            autoComplete="off"
                                                        />
                                                        <div className="change-email__password-toggle" onClick={handlePasswordToggle}>
                                                            {values.password && (
                                                                <span className="change-email__toggle-icon-wrapper">
                                                                    {showPassword ? (
                                                                        <BsEyeSlashFill className="change-email__eye-icon" />
                                                                    ) : (
                                                                        <BsEyeFill className="change-email__eye-icon" />
                                                                    )}
                                                                </span>
                                                            )}
                                                        </div>
                                                        {errors.password && touched.password && (
                                                            <p className="change-email__input-error-message">
                                                                <BsExclamationCircle className="change-email__input-error-icon" />
                                                                {errors.password}
                                                            </p>
                                                        )}
                                                        {wrongPassword && (
                                                            <p className="change-email__input-error-message">
                                                                <BsExclamationCircle className="change-email__input-error-icon" />
                                                                Password is incorrect.
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="change-email__footer">
                                                    <button
                                                        type="button"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className={`change-email__submit-btn ${values.new_email && values.password ? "" : "disabled"}`}
                                                        disabled={!values.new_email || !values.password}
                                                    >
                                                        {isChangingEmail ? "Processing" : "Submit"}
                                                    </button>
                                                </div>
                                            </Form>
                                        )
                                    }}

                                </Formik>
                            )}
                        </>
                    )}
                </div>
                {isChangingEmail && <ChangeEmailLoader />}
            </div>
        </>
    )
}

export default ChangeEmail