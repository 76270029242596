import React from 'react';
import ProductLandingPage from '../../../assets/product_landing_page.svg';
import '../../../Styles/MainStyles/Integrations/Integrations.css';

function Integrations() {
  return (
    <>
      <div className="integrations">
        <div className="integrations__page-container">
          <div className="integrations__content-area">
            <img src={ProductLandingPage} alt="Reports Landing Page Icon" />
            <div className="integrations__text-wrapper">
              <p className="integrations__subheading1">
                Our Integrations module is currently in development.
              </p>
              <p className="integrations__subheading1">
                We're developing tools to help you manage integrations more efficiently..
              </p>
              <p className="integrations__subheading2">
                Hold tight! Our Integrations feature is on its way. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Integrations;
