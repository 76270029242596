import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BsArrowLeft } from "react-icons/bs";
import CSRFToken from '../../../components/CSRFToken';

const Step2 = ({ handleNextStep, handlePrevStep, companyDocs, updateCompanyDocs }) => {
     const validationSchema = Yup.object().shape({
          owner_id: Yup.mixed()
               .required('Please complete this field.')
               .test('fileSize', 'File size is too large', value => !value || (value && value.size <= 1024 * 1024))
               .test('fileType', 'Unsupported file format', value => !value || (value && ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type))),
          owner_selfie: Yup.mixed()
               .required('Please complete this field.')
               .test('fileSize', 'File size is too large', value => !value || (value && value.size <= 1024 * 1024))
               .test('fileType', 'Unsupported file format', value => !value || (value && ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type)))
     });

     const [ownerIdFileName, setOwnerIdFileName] = useState(companyDocs.owner_id ? companyDocs.owner_id.name : '');
     const [ownerSelfieFileName, setOwnerSelfieFileName] = useState(companyDocs.owner_selfie ? companyDocs.owner_selfie.name : '');
     const [ownerIdError, setOwnerIdError] = useState('');
     const [ownerSelfieError, setOwnerSelfieError] = useState('');

     useEffect(() => {
          if (companyDocs.owner_id) setOwnerIdFileName(companyDocs.owner_id.name);
          if (companyDocs.owner_selfie) setOwnerSelfieFileName(companyDocs.owner_selfie.name);
     }, [companyDocs]);

     const handleFileChange = (e, setFieldValue, setFileName, setError, fieldName) => {
          const { files } = e.target;
          const file = files[0];

          if (file) {
               let error = null;

               if (file.size > 1024 * 1024) {
                    error = 'File size is too large';
               } else if (!['image/jpeg', 'image/png', 'application/pdf'].includes(file.type)) {
                    error = 'Unsupported file format';
               }

               if (error) {
                    setError(error);
                    setFileName(file.name);
                    setFieldValue(fieldName, null);
               } else {
                    setError('');
                    setFileName(file.name);
                    setFieldValue(fieldName, file);
                    updateCompanyDocs({ ...companyDocs, [fieldName]: file });
               }
          } else {
               setError('Please complete this field.');
               setFileName('');
               setFieldValue(fieldName, null);
          }
     };

     const renderFileInput = (name, label, fileName, handleChange, error) => (
          <div className="onboarding__custom-file-input-wrapper">
               <input
                    type="file"
                    name={name}
                    accept="application/pdf,image/jpeg,image/png"
                    onChange={handleChange}
                    style={{ display: 'none' }}
                    id={name}
               />
               <div className={`onboarding__custom-file-input ${error ? "onboarding__custom-input-error" : ""}`} onClick={() => document.getElementById(name).click()}>
                    <p className={fileName ? (error ? "onboarding__custom-file-input-filename error" : "onboarding__custom-file-input-filename") : "onboarding__custom-file-input-placeholder"}>
                         {fileName ? fileName : label}
                    </p>
                    {!fileName && <p className="onboarding__custom-file-types">(PDF/JPG/PNG)</p>}
               </div>
               {error && (<p className="onboarding__custom-input-error-message">{error}</p>)}
          </div>
     );

     return (
          <Formik
               initialValues={{ owner_id: companyDocs.owner_id, owner_selfie: companyDocs.owner_selfie }}
               validationSchema={validationSchema}
               onSubmit={(values, { setSubmitting }) => {
                    handleNextStep(values);
                    setSubmitting(false);
               }}
          >
               {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                    <Form>
                         <CSRFToken />
                         <div className="onboarding__form">

                         {/* BACK BUTTON */}
                         <button className="onboarding__back-button" type="button" onClick={handlePrevStep}>
                              <BsArrowLeft className="onboarding__back-button-icon" />
                              <p className="onboarding__back-button-text">Back to Step 1</p>
                         </button>

                         {/* TITLE SECTION */}
                         <p className="onboarding__form-title">Identification Documents</p>

                         {/* SUBTITLE SECTION */}
                         <p className="onboarding__form-subtitle">
                              Take a selfie while holding your ID, then upload your selfie photo and a photo of your government-issued IDs.
                         </p>

                         {/* ID SECTION */}
                         <div className="onboarding__input-wrapper">
                              <p className="onboarding__input-label">Valid Identification</p>
                              <p className="onboarding__input-sublabel">
                                   Please upload one (1) Primary ID or two (2) Secondary IDs (only if you cannot provide a primary ID),
                                   and make sure to put them into one file before uploading.
                              </p>
                              {renderFileInput("owner_id", "Drag and drop here or click to upload", ownerIdFileName, 
                                   (e) => handleFileChange(e, setFieldValue, setOwnerIdFileName, setOwnerIdError, 'owner_id'), 
                                   ownerIdError)}
                         </div>

                         {/* SELFIE SECTION */}
                         <div className="onboarding__selfie-input-wrapper">
                              <p className="onboarding__input-label">Selfie Photo</p>
                              <p className="onboarding__input-sublabel">
                                   The purpose of uploading your selfie is to comply with the KYC
                                   requirements set by the Bangko Sentral ng Pilipinas for account opening.
                              </p>
                              {renderFileInput("owner_selfie", "Drag and drop here or click to upload", ownerSelfieFileName, 
                                   (e) => handleFileChange(e, setFieldValue, setOwnerSelfieFileName, setOwnerSelfieError, 'owner_selfie'), 
                                   ownerSelfieError)}
                         </div>

                         {/* SUBMIT BUTTON */}
                         <div className="onboarding__button-wrapper">
                              <button
                                   type="submit"
                                   className={`onboarding__button ${values.owner_id && values.owner_selfie ? "" : "disabled"}`}
                                   disabled={isSubmitting || !values.owner_id || !values.owner_selfie}
                              >
                                   Continue
                              </button>
                         </div>
                         </div>
                    </Form>
               )}
          </Formik>
     );
};

export default Step2;
