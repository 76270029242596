import React, { useState, useEffect } from 'react';
import axios from '../../../api/axios';
import Cookies from 'js-cookie';
import { BsChevronDown } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import OnboardingLoader from './OnboardingLoader';
import OnboardingError from './OnboardingError';
import Logo from '../../../assets/logo.svg';
import '../../../Styles/AuthStyles/Onboarding/Onboarding.css'

// API ENDPOINTS
const CREATE_PROFILE_ENDPOINT = '/apps/onboarding/'


function Onboarding () {
    const navigate = useNavigate();

    const is_profile_setup  = useSelector((state) => state.merchant.profile ? state.merchant.profile.is_profile_setup : '');
    const is_company_set  = useSelector((state) => state.merchant.profile ? state.merchant.profile.is_company_set : '');
    const merchant_id = useSelector((state) => state.merchant.profile ? state.merchant.profile.merchant_id : '');
    const email = useSelector((state) => state.auth.user ? state.auth.user.email : '');
    const first_name = useSelector((state) => state.auth.user ? state.auth.user.first_name : '');
    const last_name = useSelector((state) => state.auth.user ? state.auth.user.last_name : '');
    const full_name = `${first_name} ${last_name}`.trim();

    const [currentPage, setCurrentPage] = useState(0);
    const [isCreatingProfile, setIsCreatingProfile] = useState(false)
    const [onboardingError, setOnboardingError] = useState(false)
    const [onboardingErrorMsg, setOnboardingErrorMsg] = useState(null)
    const [onboardingErrorAction, setOnboardingErrorAction] = useState(null)
    const [onboardingErrorCode, setOnboardingErrorCode] = useState(null)

    // Stores the Merchant Data provided in the form
    const [merchantData, setMerchantData] = useState({
        first_name:'',
        last_name:'',
        job_title: '',
        legal_name: '',
        reg_number: '',
        entity_type: '',
        tax_id: '',
        reg_date: '',
        address: '',
        contact_number: '',
        entity_nature: '',
        industry: '',
    });

    const [companyDocs, setCompanyDocs] = useState({
        owner_id: null,
        owner_selfie: null,
        reg_cert: null,
        articles: null,
        gis: null,
        reso: null,
    });
    

    useEffect(() => {
        setMerchantData((prevData) => ({
          ...prevData,
          first_name: first_name,
          last_name: last_name,
        }));
    }, [first_name, last_name]);

    useEffect(() => {
        if (!is_company_set && is_profile_setup) {
            navigate('/onboarding/company');
        } else if (is_company_set && is_profile_setup) {
            navigate('/signin');
        } else if (!email){
            navigate('/signin');
       }
    }, [is_company_set, is_profile_setup, email, navigate]);

    // Updates the Merchant Data State on every keystroke
    const updateMerchantData = (updatedData) => {
        setMerchantData(prevData => ({
            ...prevData,
            ...updatedData
        }));
    };

    // Updates the Company Docs Data State on every keystroke
    const updateCompanyDocs = (updatedData) => {
        setCompanyDocs(prevData => ({
            ...prevData,
            ...updatedData
        }));
    };

    // Closes Onboarding Error Modal
    const closeOnboardingErrorModal = () => {
        setOnboardingError(false);
    }
    
    // Submits Create Profile Request to Server
    const submitCreateProfile = async () => { 
        setIsCreatingProfile(true)    
        try {
            const formData = new FormData();
            formData.append('email', email);
            formData.append('merchant_id', merchant_id); 
            formData.append('first_name', merchantData.first_name);
            formData.append('last_name', merchantData.last_name);
            formData.append('job_title', merchantData.job_title);
            formData.append('owner_id', companyDocs.owner_id);
            formData.append('owner_selfie', companyDocs.owner_selfie);
            formData.append('reg_name', merchantData.legal_name);
            formData.append('reg_number', merchantData.reg_number);
            formData.append('entity_type', merchantData.entity_type);
            formData.append('tax_id', merchantData.tax_id);
            formData.append('reg_date', merchantData.reg_date);
            formData.append('address', merchantData.address);
            formData.append('contact_number', merchantData.contact_number);
            formData.append('entity_nature', merchantData.entity_nature);
            formData.append('industry', merchantData.industry);
            formData.append('reg_cert', companyDocs.reg_cert);
            formData.append('articles', companyDocs.articles);
            formData.append('gis', companyDocs.gis);
            formData.append('reso', companyDocs.reso);

            const res = await axios.post(
                CREATE_PROFILE_ENDPOINT,
                formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success){
                navigate('/signup-success');
            } else {
                console.error('Invalid data received', res.data)
                setOnboardingError(true);
                setOnboardingErrorMsg("Oops! We encountered an error.")
                setOnboardingErrorAction("Please try again or contact support.")
                setOnboardingErrorCode("ERR_FINDUKU_2000")                
            }
        } catch (error) {
            console.log('Something went wrong when creating profile', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setOnboardingErrorMsg("Oops! We encountered an error.");
                        setOnboardingErrorAction("Please try again or contact support.")
                        setOnboardingErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setOnboardingErrorMsg("Oops! Authorization is required.");
                        setOnboardingErrorAction("Please login your account.")
                        setOnboardingErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setOnboardingErrorMsg("Oops! We encountered an error.");
                        setOnboardingErrorAction("Please try again or contact support.")
                        setOnboardingErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setOnboardingErrorMsg("Oops! We encountered an error.");
                        setOnboardingErrorAction("Please try again or contact support.")
                        setOnboardingErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setOnboardingErrorMsg("Oops! We encountered an error.");
                        setOnboardingErrorAction("Please try again or contact support.")
                        setOnboardingErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setOnboardingErrorMsg("Oops! We encountered an error");
                        setOnboardingErrorAction("Please try again or contact support.")
                        setOnboardingErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setOnboardingErrorMsg("Oops! We encountered an error");
                setOnboardingErrorAction("Please try again or contact support.")
                setOnboardingErrorCode("ERR_FINDUKU_8000") 
            } 
            setOnboardingError(true) 
        } finally {
            setIsCreatingProfile(false)
        }
    };

    // Handles Continue to Next Step Behavior
    const handleNextStep = () => {
        setCurrentPage(prev => prev + 1)
    }; 

    // Handles Back to Last Step Behavior
    const handlePrevStep = () => {
        setCurrentPage(prev => prev - 1)
    };

    

    // Controls what page to display
    const pages = [
        <Step1 
            handleNextStep={handleNextStep} 
            merchantData={merchantData} 
            updateMerchantData={updateMerchantData}
        />,
        <Step2 
            handleNextStep={handleNextStep} 
            handlePrevStep={handlePrevStep} 
            companyDocs={companyDocs}
            updateCompanyDocs={updateCompanyDocs} 
        />,
        <Step3 
            handleNextStep={handleNextStep} 
            handlePrevStep={handlePrevStep} 
            merchantData={merchantData}
            updateMerchantData={updateMerchantData} 
        />,
        <Step4 
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep} 
            merchantData={merchantData}
            updateMerchantData={updateMerchantData} 
        />,
        <Step5 
            submitCreateProfile={submitCreateProfile}
            handlePrevStep={handlePrevStep} 
            companyDocs={companyDocs}
            updateCompanyDocs={updateCompanyDocs}
            merchantData={merchantData} 
        />,
    ];

    // Controls Eendering of Dots
    const renderDots = () => {
        return (
            <div className="dots-container">
            {pages.map((_, index) => (
                <span
                key={index}
                className={`dot${index === currentPage ? " active" : ""}`}
                ></span>
            ))}
            </div>
        );
    };


    return (
        <>
            <div className="onboarding">
                <div className="onboarding__page-container">

                    {/* THIS IS THE HEADER SECTION */}
                    <div className="onboarding__header">
                        <div className="onboarding__logo-wrapper">
                            <img src={Logo} alt="Finduku Logo"/>
                        </div>
                        <div className="onboarding__user-wrapper">
                            <p className="onboarding__user-name">{full_name}</p>
                            <BsChevronDown className="onboarding__user-icon" />
                        </div>
                    </div>
                    
                    {/* THIS IS THE BODY SECTION */}
                    <div className="onboarding__body">
                        <div className="onboarding__form-container">
                            <div className="onboarding__steps-indicators">
                                {renderDots()}
                            </div>
                            {pages[currentPage]}
                        </div>
                    </div>
                </div>
            </div>
            {isCreatingProfile &&
                <OnboardingLoader />
            }
            {onboardingError &&
                <OnboardingError
                    onboardingErrorMsg={onboardingErrorMsg} 
                    onboardingErrorAction={onboardingErrorAction}
                    onboardingErrorCode={onboardingErrorCode}
                    closeOnboardingErrorModal={closeOnboardingErrorModal}
                />
            }
        </>
    )
};

export default Onboarding;