import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import Cookies from 'js-cookie';

import { logout as logoutAction } from '../slices/authSlice';
import { reset_home } from '../slices/homeSlice';
import { reset_merchant } from '../slices/merchantSlice';
import { reset_payments } from '../slices/paymentsSlice';
import { reset_payouts } from '../slices/payoutsSlice';
import { reset_settings } from '../slices/settingsSlice';

const LOGOUT_URL_ENDPOINT = '/apps/logout/'

export const handleLogout = createAsyncThunk(
    'user/logout',
    async (_, { dispatch, rejectWithValue }) => {

        try {
            const res = await axios.post(
                LOGOUT_URL_ENDPOINT,
                JSON.stringify({}),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                // Dispatch actions from other slices on successful logout
                dispatch(logoutAction());
                dispatch(reset_home());
                dispatch(reset_merchant());
                dispatch(reset_payments());
                dispatch(reset_payouts());
                dispatch(reset_settings());
                return {};
            } else {
                console.error('Invalid data received', res.data);
                return rejectWithValue({
                    message: "Oops! We encountered an error.",
                    action: "Please try again or contact support.",
                    code: "ERR_FINDUKU_1000"
                });
            }
        } catch (error) {
            console.error('Failed to fetch balance', error);
            let errorMsg, errorAction, errorCode;
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_3000";
                            break;
                    case 401:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Please login your account.";
                            errorCode = "ERR_FINDUKU_4000";
                            break;
                    case 403:
                            errorMsg = "Oops! Authorization is required.";
                            errorAction = "Click Okay to log in again now.";
                            errorCode = "ERR_FINDUKU_5000";
                            break;
                    case 404:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_6000";
                            break;
                    case 500:
                            errorMsg = "Oops! We encountered an error.";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_7000";
                            break;
                    default:
                            errorMsg = "Oops! We encountered an error";
                            errorAction = "Please try again or contact support.";
                            errorCode = "ERR_FINDUKU_2000";
                }
                return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
            } else {
                return rejectWithValue({
                    message: "Oops! We encountered an error",
                    action: "Please try again or contact support.",
                    code: "ERR_FINDUKU_8000"
                })
            }
        }
    }
);


export const logoutSlice = createSlice({
    name: 'logout',
    initialState:{
        loading: false,
        error: null,
    },
    reducers: {
        updateLogout: (state) => {
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(handleLogout.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(handleLogout.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(handleLogout.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || {
                    message: "An unexpected error occurred",
                    action: "Please try again later.",
                    code: "ERR_FINDUKU_UNKNOWN"
                };
            });
    }
});

export const { updateLogout } = logoutSlice.actions;
export default logoutSlice.reducer;
