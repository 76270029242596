import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { 
     checkPayoutsList, 
     setSearchQuery,
     setShowResetButton,
     searchPayoutsList,
     filterPayoutsList,
     setShowFiltersButton,
     setSelectedDateFilter,
     setDateFilterApplied,
     setDateFilterIcon,
     setSelectedStatusFilter,
     setStatusFilterApplied,
     setStatusFilterIcon,
     setSelectedAmountFilter,
     setAmountFilterApplied,
     setAmountValue,
     setMinAmount,
     setMaxAmount,
     setAmountFilterIcon,
} from '../../../../redux/slices/payoutsSlice';
import { styled } from '@mui/system';
import { 
     BsPlusCircle, 
     BsXCircle, 
     BsFillExclamationTriangleFill, 
     BsPlusLg, 
     BsEmojiFrown,
     BsQrCodeScan
} from 'react-icons/bs';
import { 
     Pagination, 
     Stack,  
     Grid,
     Typography,
     TextField,
     InputAdornment,
     IconButton,
} from '@mui/material';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import DatePicker from 'react-datepicker';
import PayoutLoader from './PayoutLoader';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../Styles/MainStyles/Transactions/Payouts/Payouts.css';


const SearchTextField = styled(TextField)({
     '& .MuiOutlinedInput-root': {
          height: 35,
          width: 280,
          borderRadius: 0,
          '&:hover .MuiOutlinedInput-notchedOutline': { 
               borderColor: '#284154', 
               borderWidth: '0.1px', 
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': { 
               borderColor: '#284154', 
               borderWidth: '0.1px',  
          },
          '@media only screen and (max-width: 767px)': {
               width: '100%', // Width for screens less than or equal to 767px
               height: 45,
          },
     },

});

const LabeledDatePicker = ({ label, selected, onChange, dateFormat, labelStyle, placeholderText, maxDate, hasError }) => (
          <div style={{ marginBottom: '8px', width: '100%' }}>
               <label style={{ display: 'block', marginBottom: '2px', fontWeight: 'semi-bold', ...labelStyle, color: hasError ? '#FF5161' : '#284154' }}>
                    {label}
               </label>
               <DatePicker
                    selected={selected}
                    onChange={onChange}
                    dateFormat={dateFormat}
                    placeholderText={placeholderText} 
                    className={`payouts__filter-options-custom-date-picker ${hasError ? 'payouts__date-picker-error' : ''}`}
                    maxDate={maxDate}
               />
          </div>
);

const CustomDateRangePicker = ({ selectedDateFilter, startDate, setStartDate, endDate, setEndDate, inBetweenDateError, setInBetweenDateError, setStartDateChanged, setEndDateChanged }) => {
     const today = new Date();

     return (
          <>
               {selectedDateFilter === 'in between' && (
                    <div className="payouts__filter-options-date-custom-range">
                         <LabeledDatePicker
                              label="From"
                              selected={startDate}
                              onChange={(date) => {
                                   setStartDate(date);
                                   setStartDateChanged(true);
                                   setInBetweenDateError(false);
                               }}
                              dateFormat="MM/dd/yyyy"
                              labelStyle={{ fontSize: '14px', color: '#284154' }}
                              placeholderText="Select a start date"
                              maxDate={today}
                              hasError={inBetweenDateError}
                         />
                         <LabeledDatePicker
                              label="To"
                              selected={endDate}
                              onChange={(date) => {
                                   const endOfDay = new Date(date.setHours(23, 59, 59));
                                   setEndDate(endOfDay);
                                   setEndDateChanged(true);
                                   setInBetweenDateError(false);
                              }}
                              dateFormat="MM/dd/yyyy"
                              labelStyle={{ fontSize: '14px', color: '#284154' }}
                              placeholderText="Select an end date"
                              maxDate={today}
                              hasError={inBetweenDateError}
                         />
                    </div>
               )}
          </>
     )
};


function Payouts ({ bankData }) {
     const navigate = useNavigate();
     const dispatch = useDispatch();

     // DATA FETCHED FROM REDUX STATES
     //const merchant_id = useSelector((state) => state.merchant.profile ? state.merchant.profile.merchant_id : '');
     const payouts = useSelector((state) => state.payouts.payouts_list);
     const totalCount = useSelector((state) => state.payouts.totalCount);
     const isPageLoading = useSelector((state) => state.payouts.loading);
     const hidePagination = useSelector((state) => state.payouts.hidePagination);
     const error = useSelector((state) => state.payouts.error);
     const searchQuery = useSelector((state) => state.payouts.searchQuery);
     const showResetButton = useSelector((state) => state.payouts.showResetButton);
     const isSearchFilterMade = useSelector((state) => state.payouts.isSearchFilterMade);
     const showFiltersButton = useSelector((state) => state.payouts.showFiltersButton);
     const selectedDateFilter = useSelector((state) => state.payouts.selectedDateFilter);
     const dateFilterApplied = useSelector((state) => state.payouts.dateFilterApplied);
     const toggleDateIcon = useSelector((state) => state.payouts.toggleDateIcon);
     const selectedStatusFilter = useSelector((state) => state.payouts.selectedStatusFilter);
     const statusFilterApplied = useSelector((state) => state.payouts.statusFilterApplied);
     const toggleStatusIcon = useSelector((state) => state.payouts.toggleStatusIcon);
     const selectedAmountFilter = useSelector((state) => state.payouts.selectedAmountFilter);
     const amountFilterApplied = useSelector((state) => state.payouts.amountFilterApplied);
     const amountValue = useSelector((state) => state.payouts.amountValue);
     const minAmount = useSelector((state) => state.payouts.minAmount);
     const maxAmount = useSelector((state) => state.payouts.maxAmount);
     const toggleAmountIcon = useSelector((state) => state.payouts.toggleAmountIcon);

     // STATES FOR PAGINATION
     const [page, setPage] = useState(1);
     const rowsPerPage = 10;

     // STATES FOR FILTERING
     const [activeFilter, setActiveFilter] = useState(false);
     const [startDate, setStartDate] = useState('')
     const [endDate, setEndDate] = useState('')
     const [isFilterChanged, setIsFilterChanged] = useState(false);
     const [inBetweenDateError, setInBetweenDateError] = useState(false);
     const [startDateChanged, setStartDateChanged] = useState(false);
     const [endDateChanged, setEndDateChanged] = useState(false);   
     const [inBetweenAmountError, setInBetweenAmountError] = useState(false);


     // Initial data fetching
     useEffect(() => {
          dispatch(setShowFiltersButton(false));
          dispatch(checkPayoutsList({ page, pageSize: rowsPerPage }));
          dispatch(setSearchQuery(''))
          dispatch(setSelectedDateFilter(''));
          dispatch(setDateFilterApplied(false));
          dispatch(setDateFilterIcon(true));
          dispatch(setSelectedStatusFilter(''));
          dispatch(setStatusFilterApplied(false));
          dispatch(setStatusFilterIcon(true));
          dispatch(setSelectedAmountFilter(''));
          dispatch(setAmountFilterIcon(true));
          dispatch(setAmountFilterApplied(false));
          dispatch(setAmountValue(''));
          dispatch(setMaxAmount(''));
          dispatch(setMinAmount(''));
     }, [dispatch, page, rowsPerPage, ]);


     // FUNCTIONS HANDLING WEBSOCKETS CONNECTIONS
     //useEffect(() => {
          //const wsPayoutUpdate = new WebSocket(`wss://api.finduku.com/ws/payout_update/${merchant_id}/`);
          //wsPayoutUpdate.onmessage = async (e) => {
               //const data = JSON.parse(e.data);
               //const payout_id = data.message.payout_id
               
               //dispatch(fetchNewPayout({ payout_id: payout_id }));
          //};

          //return () => {
               //if (wsPayoutUpdate.readyState === WebSocket.OPEN) wsPayoutUpdate.close();
          //};

     //}, [dispatch, merchant_id]);


     // FUNCTION NAVIGATING TO PAYMENT VIEW PAGE
     function handlePayoutClick(reference) {
          navigate(`/transactions/payouts/${reference}`);
     }

     // START OF FUNCTIONS HANDLING SEARCH LOGIC //
     const handleSearchQueryChange = (event) => {
          dispatch(setSearchQuery(event.target.value));
          dispatch(setShowFiltersButton(false));
          dispatch(setSelectedDateFilter(''));
          dispatch(setDateFilterIcon(true));
          dispatch(setDateFilterApplied(false));
          dispatch(setSelectedStatusFilter(''));
          dispatch(setStatusFilterIcon(true));
          dispatch(setStatusFilterApplied(false));
          dispatch(setSelectedAmountFilter(''));
          dispatch(setAmountFilterIcon(true));
          dispatch(setAmountFilterApplied(false));
     };

     const handleSearch = async () => {
          dispatch(searchPayoutsList());
     };

     const resetView = () => {
          dispatch(checkPayoutsList({ page, pageSize: rowsPerPage }));
          dispatch(setSearchQuery(''));
          dispatch(setShowResetButton(false));
     };
     // END OF FUNCTIONS HANDLING SEARCH LOGIC //

     // FUNCTION HANDLING WHEN FILTER IS CLICKED
     const handleFilterClick = (filterType) => {
          setActiveFilter(activeFilter === filterType ? null : filterType);
     };

     const isFilterActive = (filterType) => {
          return activeFilter === filterType;
     };

     // FUNCTION DISPLAYING & HIDING THE FILTER OPTIONS
     const handleDocumentClick = (event) => {
          const filterItem = event.target.closest('.payouts__filter-item');
          const filterOptions = event.target.closest('.payouts__filter-options');

          if ((!filterItem || !filterItem.contains(event.target)) && (!filterOptions || !filterOptions.contains(event.target))) {
               if (dateFilterApplied && selectedDateFilter === 'in between') {
                    // Check if both startDate and endDate are not provided
                    if (!startDate || !endDate) {
                         dispatch(setSelectedDateFilter(''));
                         dispatch(setDateFilterIcon(true));
                         dispatch(setDateFilterApplied(false));
                         setStartDate('');
                         setEndDate('');
                    } 
               } else if (amountFilterApplied && selectedAmountFilter === 'is between') {
                    if (!minAmount || !maxAmount) {
                         dispatch(setSelectedAmountFilter(''));
                         dispatch(setAmountFilterIcon(true));
                         dispatch(setAmountFilterApplied(false));
                         dispatch(setMinAmount(''));
                         dispatch(setMaxAmount(''));
                    } 
               } else if (amountFilterApplied && selectedAmountFilter !== 'is between') {
                    // Check if the amountValue is not provided
                    if (!amountValue) {
                         dispatch(setSelectedAmountFilter(''));
                         dispatch(setAmountFilterIcon(true));
                         dispatch(setAmountFilterApplied(false));
                         dispatch(setAmountValue(''));
                    }
               }
               // Continue with the existing logic to close the filter
               setActiveFilter(null);
          }
     };
    
     useEffect(() => {
          document.addEventListener('click', handleDocumentClick);
      
          return () => {
              document.removeEventListener('click', handleDocumentClick);
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [dateFilterApplied, selectedDateFilter, startDate, endDate, amountFilterApplied, selectedAmountFilter, minAmount, maxAmount, amountValue]);
  

     // START OF DATE FILTERING LOGIC
     // This function handles date filtering onChange
     const handleDateFilterChange = (filter) => {
          dispatch(setSelectedDateFilter(filter));
          dispatch(setDateFilterIcon(false));
          dispatch(setDateFilterApplied(true));
          dispatch(setShowFiltersButton(true));
          dispatch(setSearchQuery(''));
          setIsFilterChanged(true);
     };

     // This function handles status filtering onChange
     const handleStatusFilterChange = (filter) => {
          dispatch(setSelectedStatusFilter(filter));
          dispatch(setStatusFilterIcon(false));
          dispatch(setStatusFilterApplied(true));
          dispatch(setShowFiltersButton(true));
          dispatch(setSearchQuery(''));
          setIsFilterChanged(true);
     };
      
     const handleAmountFilterChange = (filter) => {
          dispatch(setSelectedAmountFilter(filter));
          dispatch(setAmountFilterIcon(false));
          dispatch(setAmountFilterApplied(true));
          dispatch(setShowFiltersButton(true));
          dispatch(setSearchQuery(''));
          setIsFilterChanged(true);
      
          if (filter === 'is between') {
               dispatch(setMinAmount(minAmount || ''));
               dispatch(setMaxAmount(maxAmount || ''));
               dispatch(setAmountValue(''));  // Reset the single amount value
          } else if (filter === 'is equal to') {
               dispatch(setAmountValue(''));  // Set amountValue directly
               dispatch(setMinAmount(''));  // Clear minAmount
               dispatch(setMaxAmount(''));  // Clear maxAmount
          } else {
               dispatch(setAmountValue(''));  
               dispatch(setMinAmount(''));
               dispatch(setMaxAmount(''));
          }
     };

     const handleAmountValueChange = (event) => {
          dispatch(setAmountValue(event.target.value));
     };
  
     const handleMinAmountChange = (event) => {
          dispatch(setMinAmount(event.target.value));
          setInBetweenAmountError(false);
     };
  
     const handleMaxAmountChange = (event) => {
          dispatch(setMaxAmount(event.target.value));
          setInBetweenAmountError(false);
     };

     const applyFilters = () => {
          if (startDate && endDate && startDate > endDate) {
               setInBetweenDateError(true);
          } else if (parseFloat(minAmount) > parseFloat(maxAmount)) {
               setInBetweenAmountError(true);
          } else {
               setInBetweenDateError(false);
               dispatch(filterPayoutsList({
                   startDate: startDate,
                   endDate: endDate,
               }));
               setActiveFilter(null);
               setIsFilterChanged(false);
               setStartDateChanged(false);
               setEndDateChanged(false);
          }
     }
  
     // This function clear just the date filter
     const clearDateFilters = () => {
          dispatch(setSelectedDateFilter(''));
          dispatch(setDateFilterIcon(true));
          dispatch(setDateFilterApplied(false));
          setStartDate('');
          setEndDate('');
          setIsFilterChanged(false);
          setStartDateChanged(false);
          setEndDateChanged(false);       
          applyFilters();
     }
  
     // This function clear just the status filter
     const clearStatusFilters = () => {
          dispatch(setSelectedStatusFilter(''));
          dispatch(setStatusFilterIcon(true));
          dispatch(setStatusFilterApplied(false));
          setIsFilterChanged(false);
          applyFilters();
     }
  
     // This function clear just the status filter
     const clearAmountFilters = () => {
          dispatch(setSelectedAmountFilter(''));
          dispatch(setAmountFilterIcon(true));
          dispatch(setAmountFilterApplied(false));
          dispatch(setAmountValue(''));
          dispatch(setMaxAmount(''));
          dispatch(setMinAmount(''));
          setIsFilterChanged(false);
          setInBetweenAmountError(false);
          applyFilters();
     }
  
     // This function clear all filters
     const clearFilters = () => {
          dispatch(setSelectedDateFilter(''));
          dispatch(setDateFilterApplied(false));
          dispatch(setDateFilterIcon(true));
          dispatch(setShowFiltersButton(false));
          setStartDate('');
          setEndDate('');
          dispatch(setSelectedStatusFilter(''));
          dispatch(setStatusFilterApplied(false));
          dispatch(setStatusFilterIcon(true));
          dispatch(setSelectedAmountFilter(''));
          dispatch(setAmountFilterIcon(true));
          dispatch(setAmountFilterApplied(false));
          dispatch(setAmountValue(''));
          dispatch(setMaxAmount(''));
          dispatch(setMinAmount(''));
          dispatch(checkPayoutsList({ page, pageSize: rowsPerPage }));
          setIsFilterChanged(false);
          setStartDateChanged(false);
          setEndDateChanged(false);
          setInBetweenDateError(false);
          setInBetweenAmountError(false);
     }
      
    // FUNCTION HANDLING PAGE CHANGE
     const handleChangePage = (event, newPage) => {
          setPage(newPage)
     }

     // Compute current page data range (e.g., 11 - 20 of 102)
     let startRange = (page - 1) * rowsPerPage + 1;
     let endRange = Math.min(page * rowsPerPage, totalCount);

     // Ensure that startRange does not exceed totalCount
     if (startRange > totalCount) {
          // If the calculated start exceeds the total, adjust start and end
          if (totalCount === 0) {
               // If no results, start should also be 0 (not 1)
               startRange = 0;
               endRange = 0;
          } else {
               // Otherwise, start at the first item of the last available page
               endRange = totalCount;
               const lastPage = Math.ceil(totalCount / rowsPerPage);
               const startOfLastPage = (lastPage - 1) * rowsPerPage + 1;
               startRange = Math.min(startOfLastPage, totalCount);
          }
     }

     // FUNCTIONS SETTING STATUS CLASS
     const getPayoutStatusClass = (status) => {
          switch(status) {
               case 'Sent':
                    return 'payouts__status-sent';
               case 'Failed':
                    return 'payouts__status-failed';
               case 'Pending':
                    return 'payouts__status-pending';
               case 'On Hold':
                    return 'payouts__status-onhold';
               default:
                    return '';
          }
     }
    

     return (
          <>
               <div className="payouts">
                    <div className="payouts__page-container">
                         {error ? (
                              <div className="payouts__error-wrapper">
                                   <div className="payouts__error-message-container">
                                        <BsEmojiFrown  className="payouts__error-message-icon"/>
                                        {error.message && <p className="payouts__error-msg">{error.message}</p>}
                                        {error.action && <p className="payouts__error-action">{error.action}</p>}
                                        {error.code && <p className="payouts__error-code">{error.code}</p>}
                                   </div>
                              </div>
                         ) : (
                              <>
                                   {payouts.length === 0 && !isSearchFilterMade ? (
                                        <div className="payouts__no-payouts">
                                             <div className="payouts__no-payouts-container">
                                                  <BsQrCodeScan className="payouts__no-payouts-icon"/>
                                                  <div className="payouts__no-payouts__text-wrapper">
                                                       <p className="payouts__no-payouts__heading">
                                                            No payouts
                                                       </p>
                                                       <p className="payouts__no-payouts__subheading1">
                                                            Create payouts by sending a request on your Transaction dashboard.
                                                       </p>
                                                       <p className="payouts__no-payouts__subheading2">
                                                            Payouts will show up here, along with the transaction details.
                                                       </p>
                                                  </div>
                                             </div>
                                        </div>
                                   ) : (
                                        <div className="payouts__body">
                                             <div className="payouts__data-container">
                                                  <div className="payouts__action-wrapper">
                                                       <div className="payouts__left-actions">
                                                       
                                                            {/* THIS IS THE FILTER SECTION */}
                                                            <div className="payouts__filter-wrapper">
                                                                 <span className="payouts__filter-title">Filter by:</span>

                                                                 {/* START OF FILTER BY PAYOUT DATE */}
                                                                 <div
                                                                      className={`payouts__filter-item ${
                                                                           isFilterActive('date') ? 'active' : ''
                                                                      } ${
                                                                           dateFilterApplied ? 'filter-applied' : ''
                                                                      } ${
                                                                           inBetweenDateError ? 'filter-error' : ''
                                                                      }`}
                                                                      onClick={() => handleFilterClick('date')}
                                                                 >
                                                                      {toggleDateIcon ? (
                                                                           <BsPlusCircle className="payouts__filter-item-plus-icon" />
                                                                      ) : (
                                                                           <BsXCircle 
                                                                                className="payouts__filter-item-x-icon"
                                                                                onClick={(event) => {
                                                                                     event.stopPropagation(); // prevent the event from bubbling up
                                                                                     clearDateFilters();
                                                                                     setInBetweenDateError(false); 
                                                                                }} 
                                                                           />
                                                                      )}
                                                                      <span className="payouts__filter-item-title">Date</span>
                                                                      <span>
                                                                           {selectedDateFilter === 'in between' ? (
                                                                                startDate && endDate
                                                                                ? ` : ${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`
                                                                                : ' : in between'
                                                                           ) : (
                                                                                selectedDateFilter ? ` : ${selectedDateFilter}` : ''
                                                                           )}
                                                                      </span>
                                                                      {isFilterActive('date') && (
                                                                           <div className="payouts__filter-options" onClick={(e) => e.stopPropagation()}>
                                                                                <p className="payouts__filter-options-title">Filter by Date:</p>
                                                                                <div className="payouts__filter-options-item">
                                                                                     <input 
                                                                                          type="radio"
                                                                                          id="last90Days"
                                                                                          name="dateFilter"
                                                                                          value="in last 90 days"
                                                                                          checked={selectedDateFilter === 'in last 90 days'}
                                                                                          onChange={() => handleDateFilterChange('in last 90 days')}
                                                                                     />
                                                                                     <label htmlFor="last90Days">in last 90 days</label>
                                                                                </div>
                                                                                <div className="payouts__filter-options-item">
                                                                                     <input
                                                                                          type="radio"
                                                                                          id="last30Days"
                                                                                          name="dateFilter"
                                                                                          value="in last 30 days"
                                                                                          checked={selectedDateFilter === 'in last 30 days'}
                                                                                          onChange={() => handleDateFilterChange('in last 30 days')}
                                                                                     />
                                                                                     <label htmlFor="last30Days">in last 30 days</label>
                                                                                </div>
                                                                                <div className="payouts__filter-options-item">
                                                                                     <input
                                                                                          type="radio"
                                                                                          id="last7Days"
                                                                                          name="dateFilter"
                                                                                          value="in last 7 days"
                                                                                          checked={selectedDateFilter === 'in last 7 days'}
                                                                                          onChange={() => handleDateFilterChange('in last 7 days')}
                                                                                     />
                                                                                     <label htmlFor="last7Days">in last 7 days</label>
                                                                                </div>
                                                                                <div className="payouts__filter-options-item">
                                                                                     <input
                                                                                          type="radio"
                                                                                          id="last24Hours"
                                                                                          name="dateFilter"
                                                                                          value="in last 24 hours"
                                                                                          checked={selectedDateFilter === 'in last 24 hours'}
                                                                                          onChange={() => handleDateFilterChange('in last 24 hours')}
                                                                                     />
                                                                                     <label htmlFor="last24Hours">in last 24 hours</label>
                                                                                </div>
                                                                                <div className={`payouts__filter-options-item ${inBetweenDateError ? 'date-filter-error' : ''}`}>
                                                                                     <input
                                                                                          type="radio"
                                                                                          id="inbetween"
                                                                                          name="dateFilter"
                                                                                          value="in between"
                                                                                          checked={selectedDateFilter === 'in between'}
                                                                                          onChange={() => handleDateFilterChange('in between')}
                                                                                     />
                                                                                     <label htmlFor="inbetween">in between</label>
                                                                                </div>
                                                                                <CustomDateRangePicker
                                                                                     selectedDateFilter={selectedDateFilter}
                                                                                     startDate={startDate}
                                                                                     setStartDate={setStartDate}
                                                                                     endDate={endDate}
                                                                                     setEndDate={setEndDate}
                                                                                     inBetweenDateError={inBetweenDateError}
                                                                                     setInBetweenDateError={setInBetweenDateError}
                                                                                     setStartDateChanged={setStartDateChanged} 
                                                                                     setEndDateChanged={setEndDateChanged}
                                                                                />
                                                                           </div>
                                                                      )}
                                                                 </div>
                                                                 {/* END OF FILTER BY PAYOUT DATE */}

                                                                 {/* START OF FILTER BY PAYOUT STATUS */}
                                                                 <div
                                                                      className={`payouts__filter-item ${
                                                                           isFilterActive('status') ? 'active' : ''
                                                                      } ${
                                                                           statusFilterApplied ? 'filter-applied' : ''
                                                                      }`}
                                                                      onClick={() => handleFilterClick('status')}
                                                                 >   
                                                                 
                                                                      {toggleStatusIcon ? (
                                                                           <BsPlusCircle className="payouts__filter-item-plus-icon" />
                                                                      ) : (
                                                                           <BsXCircle 
                                                                                className="payouts__filter-item-x-icon"
                                                                                onClick={(event) => {
                                                                                     event.stopPropagation(); // prevent the event from bubbling up
                                                                                     clearStatusFilters();
                                                                                }} 
                                                                           />
                                                                      )}     
                                                                      <span className="payouts__filter-item-title">Status</span>
                                                                      <span>{selectedStatusFilter ? `: ${selectedStatusFilter}` : ''}</span>
                                                                      {isFilterActive('status') && (
                                                                           <div className="payouts__filter-options" onClick={(e) => e.stopPropagation()}>
                                                                                <p className="payouts__filter-options-title">Filter by Status:</p>
                                                                                <div className="payouts__filter-options-item">
                                                                                     <input 
                                                                                          type="radio" 
                                                                                          id="sent"
                                                                                          name="statusFilter"
                                                                                          value="Sent"
                                                                                          checked={selectedStatusFilter === 'Sent'}
                                                                                          onChange={() => handleStatusFilterChange('Sent')}
                                                                                     />
                                                                                     <label htmlFor="sent">Sent</label>
                                                                                </div>
                                                                                <div className="payouts__filter-options-item">
                                                                                     <input 
                                                                                          type="radio" 
                                                                                          id="pending"
                                                                                          name="statusFilter"
                                                                                          value="Pending"
                                                                                          checked={selectedStatusFilter === 'Pending'}
                                                                                          onChange={() => handleStatusFilterChange('Pending')}
                                                                                     />
                                                                                     <label htmlFor="pending">Pending</label>
                                                                                </div>
                                                                                <div className="payouts__filter-options-item">
                                                                                     <input 
                                                                                          type="radio" 
                                                                                          id="failed"
                                                                                          name="statusFilter"
                                                                                          value="Failed"
                                                                                          checked={selectedStatusFilter === 'Failed'}
                                                                                          onChange={() => handleStatusFilterChange('Failed')}
                                                                                     />
                                                                                     <label htmlFor="failed">Failed</label>
                                                                                </div>
                                                                                <div className="payouts__filter-options-item">
                                                                                     <input 
                                                                                          type="radio" 
                                                                                          id="onhold"
                                                                                          name="statusFilter"
                                                                                          value="OnHold"
                                                                                          checked={selectedStatusFilter === 'On Hold'}
                                                                                          onChange={() => handleStatusFilterChange('On Hold')}
                                                                                     />
                                                                                     <label htmlFor="onhold">On Hold</label>
                                                                                </div>
                                                                           </div>
                                                                      )}
                                                                 </div>
                                                                 {/* END OF FILTER BY PAYOUT STATUS */}


                                                                 {/* START OF FILTER BY PAYOUT AMOUNT */}
                                                                 <div
                                                                      className={`payouts__filter-item ${
                                                                           isFilterActive('amount') ? 'active' : ''
                                                                      } ${
                                                                          amountFilterApplied ? 'filter-applied' : ''
                                                                      } ${
                                                                          inBetweenAmountError ? 'filter-error' : ''
                                                                      }`}
                                                                      onClick={() => handleFilterClick('amount')}
                                                                 >   
                                                                      {toggleAmountIcon ? (
                                                                           <BsPlusCircle className="payouts__filter-item-plus-icon" />
                                                                      ) : (
                                                                           <BsXCircle 
                                                                                className="payouts__filter-item-x-icon"
                                                                                onClick={(event) => {
                                                                                     event.stopPropagation(); 
                                                                                     clearAmountFilters();
                                                                                     setInBetweenAmountError(false);
                                                                                }} 
                                                                           />
                                                                      )}
                                                                      <span className="payouts__filter-item-title">Amount</span>
                                                                      <span>
                                                                           {selectedAmountFilter === 'is between' ? (
                                                                                minAmount && maxAmount
                                                                                ? `: ₱${parseFloat(minAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} - ₱${parseFloat(maxAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                                                                : ''
                                                                           ) : (
                                                                                selectedAmountFilter && amountValue ? `: ${selectedAmountFilter} ₱${parseFloat(amountValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ''
                                                                           )}
                                                                      </span>
                                                                      {isFilterActive('amount') && (
                                                                           <div className="payouts__filter-options" onClick={(e) => e.stopPropagation()}>
                                                                                <p className="payouts__filter-options-title">Filter by Amount:</p>
                                                                                <div className="payouts__filter-options-item">
                                                                                     <input 
                                                                                          type="radio" 
                                                                                          id="equalTo"
                                                                                          name="amountFilter"
                                                                                          value="is equal to"
                                                                                          checked={selectedAmountFilter === 'is equal to'}
                                                                                          onChange={() => handleAmountFilterChange('is equal to')}
                                                                                     />
                                                                                     <label htmlFor="equalTo">is equal to</label>
                                                                                </div>
                                                                                {selectedAmountFilter === 'is equal to' && (
                                                                                     <div className="payouts__filter-options-amount-value">
                                                                                          <p className="payouts__filter-options-peso-sign">&#8369;</p> 
                                                                                          <input
                                                                                               type="text"
                                                                                               className="payouts__filter-options-amount-input"
                                                                                               inputMode="numeric"
                                                                                               placeholder="0.00"
                                                                                               value={amountValue}
                                                                                               onKeyDown={(e) => {
                                                                                                    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', '.', ','];
                                                                                                    if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                                                         e.preventDefault();
                                                                                                    }
                                                                                               }}
                                                                                               onChange={(e) => {
                                                                                                    let rawValue = e.target.value.replace(/\D/g, ''); // filter out non-numeric characters
                                                                                                    let unformattedValue = '';
                                                                                                    
                                                                                                    if (rawValue.length <= 2) {
                                                                                                         unformattedValue = `0.${rawValue.padEnd(2, '0')}`;
                                                                                                    } else {
                                                                                                         const integerPart = parseInt(rawValue.slice(0, -2), 10); // parseInt will remove leading zeros
                                                                                                         const decimalPart = rawValue.slice(-2);
                                                                                                         unformattedValue = `${integerPart}.${decimalPart}`;
                                                                                                    }
                                                                                               
                                                                                                    // Creating a new event object with the unformatted numeric value
                                                                                                    const newEvent = {
                                                                                                         target: {
                                                                                                              name: e.target.name,
                                                                                                              value: unformattedValue
                                                                                                         }
                                                                                                    };
                                                                                                    
                                                                                                    handleAmountValueChange(newEvent); 
                                                                                               }}
                                                                                               required
                                                                                               maxLength={10}
                                                                                          />
                                                                                     </div>
                                                                                )}
                                                                                <div className="payouts__filter-options-item">
                                                                                     <input 
                                                                                          type="radio" 
                                                                                          id="moreThan"
                                                                                          name="amountFilter"
                                                                                          value="is more than"
                                                                                          checked={selectedAmountFilter === 'is more than'}
                                                                                          onChange={() => handleAmountFilterChange('is more than')}
                                                                                     />
                                                                                     <label htmlFor="moreThan">is more than</label>
                                                                                </div>
                                                                                {selectedAmountFilter === 'is more than' && (
                                                                                     <div className="payouts__filter-options-amount-value">
                                                                                          <p className="payouts__filter-options-peso-sign">&#8369;</p> 
                                                                                          <input
                                                                                               type="text"
                                                                                               className="payouts__filter-options-amount-input"
                                                                                               inputMode='numeric'
                                                                                               placeholder="0.00"
                                                                                               value={amountValue}
                                                                                               onKeyDown={(e) => {
                                                                                                    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                                                    if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                                                         e.preventDefault();
                                                                                                    }
                                                                                               }}
                                                                                               onChange={(e) => {
                                                                                                    let rawValue = e.target.value.replace(/\D/g, ''); // filter out non-numeric characters
                                                                                                    let unformattedValue = '';
                                                                                                    
                                                                                                    if (rawValue.length <= 2) {
                                                                                                         unformattedValue = `0.${rawValue.padEnd(2, '0')}`;
                                                                                                    } else {
                                                                                                         const integerPart = parseInt(rawValue.slice(0, -2), 10); // parseInt will remove leading zeros
                                                                                                         const decimalPart = rawValue.slice(-2);
                                                                                                         unformattedValue = `${integerPart}.${decimalPart}`;
                                                                                                    }
                                                                                               
                                                                                                    // Creating a new event object with the unformatted numeric value
                                                                                                    const newEvent = {
                                                                                                         target: {
                                                                                                              name: e.target.name,
                                                                                                              value: unformattedValue
                                                                                                         }
                                                                                                    };
                                                                                                    
                                                                                                    handleAmountValueChange(newEvent); 
                                                                                               }}
                                                                                               required
                                                                                               maxLength={10}
                                                                                          />
                                                                                     </div>
                                                                                )}
                                                                                <div className="payouts__filter-options-item">
                                                                                     <input 
                                                                                          type="radio" 
                                                                                          id="lessThan"
                                                                                          name="amountFilter"
                                                                                          value="is less than"
                                                                                          checked={selectedAmountFilter === 'is less than'}
                                                                                          onChange={() => handleAmountFilterChange('is less than')}
                                                                                     />
                                                                                     <label htmlFor="lessThan">is less than</label>
                                                                                </div>
                                                                                {selectedAmountFilter === 'is less than' && (
                                                                                     <div className="payouts__filter-options-amount-value">
                                                                                          <p className="payouts__filter-options-peso-sign">&#8369;</p> 
                                                                                          <input
                                                                                               type="text"
                                                                                               className="payouts__filter-options-amount-input"
                                                                                               inputMode='numeric'
                                                                                               placeholder="0.00"
                                                                                               value={amountValue}
                                                                                               onKeyDown={(e) => {
                                                                                                    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                                                    if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                                                         e.preventDefault();
                                                                                                    }
                                                                                               }}
                                                                                               onChange={(e) => {
                                                                                                    let rawValue = e.target.value.replace(/\D/g, ''); // filter out non-numeric characters
                                                                                                    let unformattedValue = '';
                                                                                                    
                                                                                                    if (rawValue.length <= 2) {
                                                                                                         unformattedValue = `0.${rawValue.padEnd(2, '0')}`;
                                                                                                    } else {
                                                                                                         const integerPart = parseInt(rawValue.slice(0, -2), 10); // parseInt will remove leading zeros
                                                                                                         const decimalPart = rawValue.slice(-2);
                                                                                                         unformattedValue = `${integerPart}.${decimalPart}`;
                                                                                                    }
                                                                                               
                                                                                                    // Creating a new event object with the unformatted numeric value
                                                                                                    const newEvent = {
                                                                                                         target: {
                                                                                                              name: e.target.name,
                                                                                                              value: unformattedValue
                                                                                                         }
                                                                                                    };
                                                                                                    
                                                                                                    handleAmountValueChange(newEvent); 
                                                                                               }}
                                                                                               required
                                                                                               maxLength={10}
                                                                                          />
                                                                                     </div>
                                                                                )}
                                                                                <div className={`payouts__filter-options-item ${inBetweenAmountError ? 'amount-filter-error' : ''}`}>
                                                                                     <input 
                                                                                          type="radio"
                                                                                          id="isBetween"
                                                                                          name="amountFilter"
                                                                                          value="is between"
                                                                                          checked={selectedAmountFilter === 'is between'}
                                                                                          onChange={() => handleAmountFilterChange('is between')}
                                                                                     />
                                                                                     <label htmlFor="isBetween">is between</label>
                                                                                </div>
                                                                                {selectedAmountFilter === 'is between' && (
                                                                                     <div className="payouts__filter-options-amount-value">
                                                                                          <div className="payouts__filter-options-minAmount">
                                                                                               <p className={`payouts__filter-options-amount-label ${inBetweenAmountError ? 'amount-filter-error' : ''}`}>
                                                                                                    Min
                                                                                               </p>
                                                                                               <div className="payouts__filter-options-amount-input-wrapper">
                                                                                                    <p className={`payouts__filter-options-peso-sign ${inBetweenAmountError ? 'amount-filter-error' : ''}`}>
                                                                                                         &#8369;
                                                                                                    </p>                                       
                                                                                                    <input
                                                                                                         className={`payouts__filter-options-amount-input ${inBetweenAmountError ? 'amount-filter-error' : ''}`}
                                                                                                         type="text"
                                                                                                         inputMode='numeric'
                                                                                                         placeholder="0.00"
                                                                                                         value={minAmount}
                                                                                                         onKeyDown={(e) => {
                                                                                                              const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                                                              if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                                                                   e.preventDefault();
                                                                                                              }
                                                                                                         }}
                                                                                                         onChange={(e) => {
                                                                                                              let rawValue = e.target.value.replace(/\D/g, ''); // filter out non-numeric characters
                                                                                                              let unformattedValue = '';
                                                                                                              
                                                                                                              if (rawValue.length <= 2) {
                                                                                                                   unformattedValue = `0.${rawValue.padEnd(2, '0')}`;
                                                                                                              } else {
                                                                                                                   const integerPart = parseInt(rawValue.slice(0, -2), 10); // parseInt will remove leading zeros
                                                                                                                   const decimalPart = rawValue.slice(-2);
                                                                                                                   unformattedValue = `${integerPart}.${decimalPart}`;
                                                                                                              }
                                                                                                         
                                                                                                              // Creating a new event object with the unformatted numeric value
                                                                                                              const newEvent = {
                                                                                                                   target: {
                                                                                                                   name: e.target.name,
                                                                                                                   value: unformattedValue
                                                                                                                   }
                                                                                                              };
                                                                                                              
                                                                                                              handleMinAmountChange(newEvent); 
                                                                                                         }}
                                                                                                         required
                                                                                                         maxLength={10}
                                                                                                    />
                                                                                               </div>
                                                                                          </div>
                                                                                          <div className="payouts__filter-options-maxAmount">
                                                                                               <p className={`payouts__filter-options-amount-label ${inBetweenAmountError ? 'amount-filter-error' : ''}`}>
                                                                                                    Max
                                                                                               </p>
                                                                                               <div className="payouts__filter-options-amount-input-wrapper">
                                                                                                    <p className={`payouts__filter-options-peso-sign ${inBetweenAmountError ? 'amount-filter-error' : ''}`}>
                                                                                                         &#8369;
                                                                                                    </p>                                                
                                                                                                    <input
                                                                                                         className={`payouts__filter-options-amount-input ${inBetweenAmountError ? 'amount-filter-error' : ''}`}
                                                                                                         type="text"
                                                                                                         inputMode='numeric'
                                                                                                         placeholder="0.00"
                                                                                                         value={maxAmount}
                                                                                                         onKeyDown={(e) => {
                                                                                                              const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                                                              if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                                                                   e.preventDefault();
                                                                                                              }
                                                                                                         }}
                                                                                                         onChange={(e) => {
                                                                                                              let rawValue = e.target.value.replace(/\D/g, ''); // filter out non-numeric characters
                                                                                                              let unformattedValue = '';
                                                                                                              
                                                                                                              if (rawValue.length <= 2) {
                                                                                                                   unformattedValue = `0.${rawValue.padEnd(2, '0')}`;
                                                                                                              } else {
                                                                                                                   const integerPart = parseInt(rawValue.slice(0, -2), 10); // parseInt will remove leading zeros
                                                                                                                   const decimalPart = rawValue.slice(-2);
                                                                                                                   unformattedValue = `${integerPart}.${decimalPart}`;
                                                                                                              }
                                                                                                         
                                                                                                              // Creating a new event object with the unformatted numeric value
                                                                                                              const newEvent = {
                                                                                                                   target: {
                                                                                                                        name: e.target.name,
                                                                                                                        value: unformattedValue
                                                                                                                   }
                                                                                                              };
                                                                                                              handleMaxAmountChange(newEvent); 
                                                                                                         }}
                                                                                                         required
                                                                                                         maxLength={10}
                                                                                                    />
                                                                                               </div>
                                                                                          </div>
                                                                                     </div>
                                                                                )}
                                                                           </div>
                                                                      )}
                                                                 </div>
                                                                 
                                                                 {/* END OF FILTER BY PAYOUT AMOUNT */}

                                                            </div>
                                                            {showFiltersButton && (dateFilterApplied || statusFilterApplied || amountFilterApplied) &&
                                                                 <div className="payouts__apply-filter-wrapper">
                                                                      {inBetweenDateError ? (
                                                                           <div className="payouts__filter-error">
                                                                                Error: Start date cannot be after end date!
                                                                           </div>
                                                                      ) : inBetweenAmountError ? (
                                                                           <div className="payouts__filter-error">
                                                                                Error: Min amount cannot be greater than max amount!
                                                                           </div>
                                                                      ) : (
                                                                           <>
                                                                                {(isFilterChanged || startDateChanged || endDateChanged) ? (
                                                                                     <button 
                                                                                          type="button" 
                                                                                          className="payouts__apply-filter-button"
                                                                                          onClick={applyFilters}
                                                                                     >
                                                                                          Apply
                                                                                     </button>
                                                                                ) : (
                                                                                     <button 
                                                                                          type="button" 
                                                                                          className="payouts__remove-filter-button"
                                                                                          onClick={clearFilters}
                                                                                     >
                                                                                          Remove
                                                                                     </button>
                                                                                )}
                                                                           </>
                                                                      )}
                                                                 </div>
                                                            }
                                                            {/* END OF THE FILTER SECTION */}


                                                       </div>
                                                       {/* END OF THE LEFT ACTIONS SECTION */}

                                                       {/* START OF THE RIGHT ACTIONS SECTION */}
                                                       <div className="payouts__right-actions">
                                                            <div className="payouts__search-wrapper">
                                                                 <form className="payouts__search-form" onSubmit={(event) => event.preventDefault()}>
                                                                      <SearchTextField 
                                                                           type="text" 
                                                                           className="payouts__search-bar" 
                                                                           name="q" 
                                                                           placeholder="Search payouts..."
                                                                           autoComplete="off"
                                                                           value={searchQuery}
                                                                           onChange={handleSearchQueryChange}
                                                                           onKeyDown={event => {
                                                                                if (event.key === 'Enter' && searchQuery) {
                                                                                     event.preventDefault(); 
                                                                                     handleSearch();
                                                                                }
                                                                           }}
                                                                           InputProps={{
                                                                                endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                     <IconButton 
                                                                                          disabled={!searchQuery}
                                                                                          sx={{ 
                                                                                               marginRight: -2,
                                                                                               '&:hover': {
                                                                                                    backgroundColor: 'transparent',
                                                                                                    '& .MuiSvgIcon-root': {
                                                                                                         color: '#57768D',
                                                                                                    },
                                                                                               },
                                                                                          }}
                                                                                          onClick={handleSearch}
                                                                                     >
                                                                                          <SearchIcon sx={{ color: 'gray' }} />
                                                                                     </IconButton>
                                                                                </InputAdornment>
                                                                                ),
                                                                                style: { fontSize: 15 },
                                                                           }}
                                                                      />
                                                                      
                                                                 </form>
                                                            </div>
                                                       </div>
                                                       {/* END OF THE RIGHT ACTIONS SECTION */}

                                                  </div>
                                                  {/* END OF THE ACTION WRAPPER SECTION */}

                                                  {!bankData && 
                                                       <div className="payouts__no-bank-container">
                                                            <div className="payouts__no-bank-message-wrapper">
                                                                 <div className="payouts__no-bank-message-text-wrapper">
                                                                      <BsFillExclamationTriangleFill className="payouts__no-bank-message-icon" />
                                                                      <p className="payouts__no-bank-message-text">
                                                                           Please add a checking or savings bank account to pay out your balance.
                                                                      </p>
                                                                 </div>
                                                                 <button 
                                                                      type="button"
                                                                      
                                                                      className="payouts__no-bank-message-button"
                                                                 >
                                                                      <BsPlusLg className="payouts__no-bank-message-plus-icon" />
                                                                      Add bank account
                                                                 </button>
                                                            </div>
                                                       </div>
                                                  }

                                                  {/* START OF THE TABLE DEKSTOP SECTION */}
                                                  <table className="payouts__table-desktop">
                                                       <thead>
                                                            <tr className="payouts__table-desktop-heading">
                                                                 <td className="payouts__table-date-heading">Date</td>
                                                                 <td className="payouts__table-status-heading">Status</td>
                                                                 <td className="payouts__table-reference-heading">Reference</td>
                                                                 <td className="payouts__table-description-heading">Description</td>
                                                                 <td className="payouts__table-bank-heading">Paid To</td>
                                                                 <td className="payouts__table-amount-heading">Amount</td>
                                                            </tr>
                                                       </thead>
                                                       <tbody>
                                                            {payouts.length > 0 ? (                                                                  
                                                                 payouts.map((payout) => (
                                                                      <tr 
                                                                           className="payouts__table-desktop-data"
                                                                           key={payout.payout_id} 
                                                                           onClick={() => handlePayoutClick(payout.reference)}
                                                                      >
                                                                           <td className="payouts__table-date-data">{payout.date}</td>
                                                                           <td className="payouts__table-status-data">
                                                                                <span className={`payouts__status ${getPayoutStatusClass(payout.status)}`}>
                                                                                     {payout.status.toUpperCase()}
                                                                                </span>
                                                                           </td>
                                                                           <td className="payouts__table-reference-data">{payout.reference}</td>
                                                                           <td className="payouts__table-description-data">{payout.description}</td>
                                                                           <td className="payouts__table-bank-data">{payout.bank_name} ••••{payout.account_number}</td>
                                                                           <td className="payouts__table-amount-data" key="amount">
                                                                                PHP {payout && payout.amount ? parseFloat(payout.amount)
                                                                                .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ""}
                                                                           </td>
                                                                      </tr>
                                                                 ))
                                                            ) : (
                                                                 <tr className="payouts__table-no-data">
                                                                      <td colSpan={8}>No results found.</td>
                                                                 </tr>
                                                            )}
                                                       </tbody>
                                                  </table>
                                                  {/* END OF THE TABLE DEKSTOP SECTION */}

                                                  {/* START OF THE TABLE MOBILE SECTION */}
                                                  <div className="payouts__table-mobile">
                                                       {payouts.length > 0 ? (                                                                  
                                                            payouts.map((payout) => (
                                                                 <div 
                                                                      className="payouts__table-mobile-data"
                                                                      key={payout.payout_id} 
                                                                      onClick={() => handlePayoutClick(payout.reference)}
                                                                 >
                                                                      <div className="payouts__table-mobile-data-left">
                                                                           <p className="payouts__transaction-text-mobile">
                                                                                Payout to Bank
                                                                           </p>
                                                                           <p className="payouts__reference-mobile">
                                                                                Ref: {payout.reference}
                                                                           </p>
                                                                      </div>
                                                                      <div className="payouts__table-mobile-data-right" key="amount">
                                                                           <p className="payout__amount-mobile">
                                                                                PHP {payout && payout.amount ? parseFloat(payout.amount)
                                                                                .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ""}
                                                                           </p>
                                                                           <p className="payouts__date-mobile">
                                                                                {payout.date}
                                                                           </p>
                                                                           <p className="payouts__status-mobile">
                                                                           <span className={`payments__status ${getPayoutStatusClass(payout.status)}`}>
                                                                                {payout.status.toUpperCase()}
                                                                           </span> 
                                                                           </p>
                                                                      </div>
                                                                 </div>
                                                            ))
                                                       ) : (
                                                            <div className="payouts__table-no-data-mobile">
                                                                 <p>No results found.</p>
                                                            </div>
                                                       )}
                                                  </div>
                                                  {/* END OF THE TABLE MOBILE SECTION */}
                                             </div>
                                             <div className="payouts__reset-view-wrapper">
                                                  {showResetButton && 
                                                       <button className="payouts__reset-view-btn" onClick={resetView}>
                                                            All Payouts
                                                       </button>
                                                  }
                                             </div>
                                             {/* END OF THE DATA CONTAINER SECTION */}

                                             {/* START OF THE PAGINATION SECTION */}
                                             <div className="payouts__pagination-wrapper">
                                                  {hidePagination  ? (
                                                       null
                                                  ) : (
                                                       <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                                                            <Typography variant="body2" sx={{ mr: 2 }}>
                                                                 {`${startRange} - ${endRange} of ${totalCount}`}
                                                            </Typography>
                                                            <Grid item>
                                                                 <Pagination 
                                                                      count={Math.ceil(totalCount / rowsPerPage)} 
                                                                      page={page} 
                                                                      onChange={handleChangePage} 
                                                                      size="medium"
                                                                      sx={{
                                                                           '& .MuiPaginationItem-previous, & .MuiPaginationItem-next': {
                                                                           cursor: 'pointer',
                                                                           '&:hover': {
                                                                                color: '#284154', // Hover color for the icons
                                                                                backgroundColor: '#e0e0e0', // Background color for hover effect
                                                                           },
                                                                           },
                                                                           '& .MuiPaginationItem-root': {
                                                                           '&:hover': {
                                                                                cursor: 'pointer',
                                                                                backgroundColor: '#f0f0f0', // General hover effect
                                                                           },
                                                                           },
                                                                           '& .MuiPaginationItem-page': {
                                                                           '&.Mui-selected': {
                                                                                backgroundColor: '#284154', // Selected page background color
                                                                                color: '#fff', // Selected page text color
                                                                                '&:hover': {
                                                                                     backgroundColor: '#57768D', // Hover effect for selected page
                                                                                },
                                                                           },
                                                                           },
                                                                      }}
                                                                 />
                                                            </Grid>
                                                       </Stack>
                                                  )}
                                             </div>
                                             {/* END OF THE PAGINATION SECTION */}

                                        </div>
                                   )}
                              </>
                         )}
                    </div>
               </div>
               {isPageLoading  &&
                    <PayoutLoader />
               }
          </>
     );
};

export default Payouts;