import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { BsChevronDown, BsExclamationCircle } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setup_merchant } from '../../../redux/slices/merchantSlice';
import axios from '../../../api/axios';
import Cookies from 'js-cookie';
import Logo from '../../../assets/logo.svg';
import OnboardingError from './OnboardingError';
import OnboardingLoader from './OnboardingLoader';
import '../../../Styles/AuthStyles/Onboarding/SetCompany.css';

// API ENDPOINTS
const SET_COMPANY_ENDPOINT = '/apps/setup_company/'


function SetCompany() {
     const navigate = useNavigate()
     const dispatch = useDispatch()

     const is_company_set  = useSelector((state) => state.merchant.profile ? state.merchant.profile.is_company_set : '');
     const is_profile_setup  = useSelector((state) => state.merchant.profile ? state.merchant.profile.is_profile_setup : '');
     const email = useSelector((state) => state.auth.user ? state.auth.user.email : '');
     const first_name = useSelector((state) => state.auth.user ? state.auth.user.first_name : '');
     const last_name = useSelector((state) => state.auth.user ? state.auth.user.last_name : '');
     const full_name = `${first_name} ${last_name}`.trim();

     const [isSubmittingData, setIsSubmittingData] = useState(false)
     const [companyError, setCompanyError] = useState(false)
     const [companyErrorMsg, setCompanyErrorMsg] = useState(null)
     const [companyErrorAction, setCompanyErrorAction] = useState(null)
     const [companyErrorCode, setCompanyErrorCode] = useState(null)
     const [callsignExist, setCallsignExist] = useState(false)

     const [companyData, setCompanyData] = useState({
          company_name:'',
          callsign:'',
     })

     useEffect(() => {
          if (is_company_set && !is_profile_setup) {
              navigate('/onboarding');
          } else if (is_profile_setup && is_profile_setup) {
              navigate('/signin');
          } else if (!email){
               navigate('/signin');
          }
     }, [is_company_set, is_profile_setup, email, navigate]);


     // This function handles data validation
     const validate = Yup.object({
          company_name: Yup.string()
          .required('Please complete this field.'),

          callsign: Yup.string()
          .required('Please complete this field.')
          .matches(/^[a-zA-Z0-9-]+$/, "Callsign must have numbers, letters and dashes only."),
     })

     const updateCompanyData = (updatedData) => {
          setCompanyData(updatedData);
     };

     // Closes Error Modal
     const closeErrorModal = () => {
          setCompanyError(false);
     }

     // This function send request to backend to set up company
     const submitCompanyData = async (companyData) => {
          setIsSubmittingData(true)
          try {
               const res = await axios.post(
                    SET_COMPANY_ENDPOINT,
                    JSON.stringify({ 
                         email: email,
                         company_name: companyData.company_name, 
                         callsign: companyData.callsign,
                    }),
                    {
                         headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'X-CSRFToken' : Cookies.get('csrftoken')
                         }
                    }
               );
               if (res.data.success){
                    dispatch(
                         setup_merchant({
                              merchant_id: res.data.store_id,
                              is_company_set: true,
                         })
                    );
                    navigate('/onboarding');
               } else if (res.data.callsign_exists){
                    setCallsignExist(true);
               } else {
                    console.error('Invalid data received', res.data)
                    setCompanyError(true);
                    setCompanyErrorMsg("Oops! We encountered an error.")
                    setCompanyErrorAction("Please try again or contact support.")
                    setCompanyErrorCode("ERR_FINDUKU_2000")                
               }

          } catch (error) {
               console.error('Something went wrong when setting up company', error)
               if (error.response) {
                    switch (error.response.status) {
                         case 400:
                              setCompanyErrorMsg("Oops! We encountered an error.");
                              setCompanyErrorAction("Please try again or contact support.")
                              setCompanyErrorCode("ERR_FINDUKU_3000")
                              break;
                         case 401:
                              setCompanyErrorMsg("Oops! We encountered an error.");
                              setCompanyErrorAction("Please login your account.")
                              setCompanyErrorCode("ERR_FINDUKU_4000")
                              break;
                         case 403:
                              setCompanyErrorMsg("Oops! We encountered an error.");
                              setCompanyErrorAction("Please try again or contact support.")
                              setCompanyErrorCode("ERR_FINDUKU_5000")
                              break;
                         case 404:
                              setCompanyErrorMsg("Oops! We encountered an error.");
                              setCompanyErrorAction("Please try again or contact support.")
                              setCompanyErrorCode("ERR_FINDUKU_6000")
                              break;
                         case 500:
                              setCompanyErrorMsg("Oops! We encountered an error.");
                              setCompanyErrorAction("Please try again or contact support.")
                              setCompanyErrorCode("ERR_FINDUKU_7000")
                              break;
                         default:
                              setCompanyErrorMsg("Oops! We encountered an error");
                              setCompanyErrorAction("Please try again or contact support.")
                              setCompanyErrorCode("ERR_FINDUKU_1000")                    
                    }
               } else {
                    setCompanyErrorMsg("Oops! We encountered an error");
                    setCompanyErrorAction("Please try again or contact support.")
                    setCompanyErrorCode("ERR_FINDUKU_8000") 
               }                     
               setCompanyError(true)
          } finally {
               setIsSubmittingData(false)
          }
     };

     return (
          <>
               <div className="setCompany">
                    <div className="setCompany__page-container">
                         <div className="setCompany__header">
                              <div className="setCompany__logo-wrapper">
                                   <img src={Logo} alt="Finduku Logo"/>
                              </div>
                              <div className="setCompany__user-wrapper">
                                   <p className="setCompany__user-name">{full_name}</p>
                                   <BsChevronDown className="setCompany__user-icon" />
                              </div>
                         </div>

                         <Formik
                              initialValues={companyData}
                              onSubmit={submitCompanyData}
                              validationSchema={validate}
                         >
                              {( { handleChange, handleBlur, values, errors, touched }) => {

                                   return (

                                        <Form>
                                             <div className="setCompany__body">
                                                  <div className="setCompany__heading-wrapper">
                                                       <p className="setCompany__heading">What is your company name?</p>
                                                       <p className="setCompany__subheading">
                                                            This name will appear on your Finduku account, but doesn't have to be your
                                                            company registered name.
                                                       </p>
                                                  </div>
                                                  <div className="setCompany__input-wrapper">
                                                       <p className={`setCompany__input-label ${errors.company_name && touched.company_name ? "setCompany__input-error" : ""}`}>
                                                            Company name
                                                       </p>
                                                       <input 
                                                            className={`setCompany__company-name-input ${errors.company_name && touched.company_name ? "setCompany__input-error": null}`}
                                                            type="text" 
                                                            name="company_name"
                                                            value={values.company_name}
                                                            onChange={(e) => {
                                                                 handleChange(e);
                                                                 updateCompanyData({ ...values, company_name: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            autoComplete="off" 
                                                       />
                                                       {errors.company_name && touched.company_name && (
                                                            <p className="setCompany__company-name-error"> 
                                                                <BsExclamationCircle className="setCompany__company-name-error-icon" />
                                                                {errors.company_name}
                                                            </p>
                                                       )}
                                                  </div>
                                                  <div className="setCompany__callsign-wrapper">
                                                       <div className="setCompany__callsign-heading">
                                                            <p className="setCompany__callsign-title">
                                                                 Set your Finduku callsign
                                                            </p>
                                                            <p className="setCompany__callsign-subtitle">
                                                                 We'll use this callsign to personalize your In-store QR, referral link,
                                                                 and more. It won't appear on invoices.
                                                            </p>
                                                       </div>
                                                       <p className={`setCompany__callsign-label ${
                                                            ((errors.callsign && touched.callsign) || callsignExist) ? "setCompany__input-error" : ""
                                                       }`}>
                                                            Callsign
                                                       </p>
                                                       <input 
                                                            className={`setCompany__callsign-input ${
                                                                 (errors.callsign && touched.callsign) || callsignExist ? "setCompany__input-error" : ""
                                                            }`}
                                                            type="text" 
                                                            name="callsign"
                                                            value={values.callsign}
                                                            onChange={(e) => {
                                                                 handleChange(e);
                                                                 updateCompanyData({ ...values, callsign: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            autoComplete="off"  
                                                       />
                                                       {errors.callsign && touched.callsign && (
                                                            <p className="setCompany__callsign-error"> 
                                                                <BsExclamationCircle className="setCompany__callsign-error-icon" />
                                                                {errors.callsign}
                                                            </p>
                                                       )}
                                                       {callsignExist && (
                                                        <p className="setCompany__callsign-error">
                                                            <BsExclamationCircle className="setCompany__callsign-error-icon" />
                                                            This callsign is no longer available.
                                                        </p>
                                                    )}
                                                  </div>
                                                  <div className="setCompany__button-wrapper">
                                                       <button 
                                                            type="submit"
                                                            className={`setCompany__next-button ${values.company_name && values.callsign ? "" : "disabled"}`}
                                                            disabled={!values.company_name || !values.callsign}
                                                       >
                                                            Continue
                                                       </button>
                                                  </div>
                                                  <div className="setCompany__agreement-wrapper">
                                                       <p className="setCompany__agreement">
                                                            By clicking "Continue", I acknowledge that I'm legally authorized
                                                            to enter into this agreement on the company's behalf.
                                                       </p>
                                                  </div>
                                             </div>
                                        </Form>
                                   )
                              }}
                         </Formik>
                    </div>
                    {companyError &&
                         <OnboardingError 
                              onboardingErrorMsg={companyErrorMsg}
                              onboardingErrorAction={companyErrorAction}
                              onboardingErrorCode={companyErrorCode}
                              closeOnboardingErrorModal={closeErrorModal}
                         />
                    }
                    {isSubmittingData &&
                         <OnboardingLoader />
                    }
               </div>
          </>
     )
}

export default SetCompany