import React from 'react';
import { BsCheckCircleFill } from "react-icons/bs";
import '../../../Styles/AuthStyles/Register/Welcome.css';

function WelcomeModal() {

     return (
          <>
               <div className="welcome-modal">
                    <div className="welcome-modal__page-container">
                         <div className="welcome-modal__loader">
                              <div className="welcome-modal__header-icon-wrapper">
                                   <BsCheckCircleFill className="welcome-modal__header-icon"/>
                              </div>
                              <p className="welcome-modal__loading-text1">
                                   Your email address has been confirmed.
                              </p>
                              <p className="welcome-modal__loading-text2">
                                   Please continue filling out the form to finalize your account opening application.
                              </p>  
                         </div>
                    </div>
               </div>
          </>
     )
}

export default WelcomeModal;