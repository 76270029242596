import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { 
    checkPaymentsList, 
    setSearchQuery,
    setShowResetButton,
    searchPaymentsList,
    filterPaymentsList,
    setShowFiltersButton,
    setSelectedDateFilter,
    setDateFilterApplied,
    setDateFilterIcon,
    setSelectedStatusFilter,
    setStatusFilterApplied,
    setStatusFilterIcon,
    setSelectedAmountFilter,
    setAmountFilterApplied,
    setAmountValue,
    setMinAmount,
    setMaxAmount,
    setAmountFilterIcon,
} from '../../../../redux/slices/paymentsSlice';
import { styled } from '@mui/system';
import { 
    BsPlusCircle, 
    BsXCircle, 
    BsEmojiFrown,
    BsQrCodeScan 
} from 'react-icons/bs';
import { 
    Pagination, 
    Stack,  
    Grid,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
} from '@mui/material';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import DatePicker from 'react-datepicker';
import PaymentLoader from './PaymentLoader';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../Styles/MainStyles/Transactions/Payments/Payments.css';


const SearchTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        height: 35,
        width: 280,
        borderRadius: 0,
        '&:hover .MuiOutlinedInput-notchedOutline': { 
            borderColor: '#284154', 
            borderWidth: '0.1px', 
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { 
            borderColor: '#284154', 
            borderWidth: '0.1px',  
        },
        '@media only screen and (max-width: 767px)': {
            width: '100%', // Width for screens less than or equal to 767px
            height: 45,
        },
    },

});

const LabeledDatePicker = ({ label, selected, onChange, dateFormat, labelStyle, placeholderText, maxDate, hasError }) => (
    <div style={{ marginBottom: '8px', width: '100%' }}>
        <label style={{ display: 'block', marginBottom: '2px', fontWeight: 'semi-bold', ...labelStyle, color: hasError ? '#FF5161' : '#284154' }}>
            {label}
        </label>
        <DatePicker
            selected={selected}
            onChange={onChange}
            dateFormat={dateFormat}
            placeholderText={placeholderText} 
            className={`payments__filter-options-custom-date-picker ${hasError ? 'payments__date-picker-error' : ''}`}
            maxDate={maxDate}
        />
    </div>
);

const CustomDateRangePicker = ({ selectedDateFilter, startDate, setStartDate, endDate, setEndDate, inBetweenDateError, setInBetweenDateError, setStartDateChanged, setEndDateChanged }) => {
    const today = new Date();

    return (
        <>
            {selectedDateFilter === 'in between' && (
                <div className="payments__filter-options-date-custom-range">
                    <LabeledDatePicker
                        label="From"
                        selected={startDate}
                        onChange={(date) => {
                            setStartDate(date);
                            setStartDateChanged(true);
                            setInBetweenDateError(false);
                        }}
                        dateFormat="MM/dd/yyyy"
                        labelStyle={{ fontSize: '14px', color: '#284154' }}
                        placeholderText="Select a start date"
                        maxDate={today}
                        hasError={inBetweenDateError}
                    />
                    <LabeledDatePicker
                        label="To"
                        selected={endDate}
                        onChange={(date) => {
                            const endOfDay = new Date(date.setHours(23, 59, 59));
                            setEndDate(endOfDay);
                            setEndDateChanged(true);
                            setInBetweenDateError(false);
                        }}
                        dateFormat="MM/dd/yyyy"
                        labelStyle={{ fontSize: '14px', color: '#5C5C5C' }}
                        placeholderText="Select an end date"
                        maxDate={today}
                        hasError={inBetweenDateError}
                    />
                </div>
            )}
        </>
    )
};

function Payments () {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // DATA FETCHED FROM REDUX STATES
    //const merchant_id = useSelector((state) => state.merchant.profile ? state.merchant.profile.merchant_id : '');
    const payments = useSelector((state) => state.payments.payments_list);
    const totalCount = useSelector((state) => state.payments.totalCount);
    const isPageLoading = useSelector((state) => state.payments.loading);
    const hidePagination = useSelector((state) => state.payments.hidePagination);
    const error = useSelector((state) => state.payments.error);
    const searchQuery = useSelector((state) => state.payments.searchQuery);
    const showResetButton = useSelector((state) => state.payments.showResetButton);
    const isSearchFilterMade = useSelector((state) => state.payments.isSearchFilterMade);
    const showFiltersButton = useSelector((state) => state.payments.showFiltersButton);
    const selectedDateFilter = useSelector((state) => state.payments.selectedDateFilter);
    const dateFilterApplied = useSelector((state) => state.payments.dateFilterApplied);
    const toggleDateIcon = useSelector((state) => state.payments.toggleDateIcon);
    const selectedStatusFilter = useSelector((state) => state.payments.selectedStatusFilter);
    const statusFilterApplied = useSelector((state) => state.payments.statusFilterApplied);
    const toggleStatusIcon = useSelector((state) => state.payments.toggleStatusIcon);
    const selectedAmountFilter = useSelector((state) => state.payments.selectedAmountFilter);
    const amountFilterApplied = useSelector((state) => state.payments.amountFilterApplied);
    const amountValue = useSelector((state) => state.payments.amountValue);
    const minAmount = useSelector((state) => state.payments.minAmount);
    const maxAmount = useSelector((state) => state.payments.maxAmount);
    const toggleAmountIcon = useSelector((state) => state.payments.toggleAmountIcon);
    
    // STATES FOR PAGINATION
    const [page, setPage] = useState(1);
    const rowsPerPage = 10;

    // STATES FOR FILTERING
    const [activeFilter, setActiveFilter] = useState(false);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [isFilterChanged, setIsFilterChanged] = useState(false); 
    const [inBetweenDateError, setInBetweenDateError] = useState(false);
    const [startDateChanged, setStartDateChanged] = useState(false);
    const [endDateChanged, setEndDateChanged] = useState(false);   
    const [inBetweenAmountError, setInBetweenAmountError] = useState(false);

    // Initial data fetching
    useEffect(() => {
        dispatch(setShowFiltersButton(false));
        dispatch(checkPaymentsList({ page, pageSize: rowsPerPage }));
        dispatch(setSearchQuery(''))
        dispatch(setSelectedDateFilter(''));
        dispatch(setDateFilterApplied(false));
        dispatch(setDateFilterIcon(true));
        dispatch(setSelectedStatusFilter(''));
        dispatch(setStatusFilterApplied(false));
        dispatch(setStatusFilterIcon(true));
        dispatch(setSelectedAmountFilter(''));
        dispatch(setAmountFilterIcon(true));
        dispatch(setAmountFilterApplied(false));
        dispatch(setAmountValue(''));
        dispatch(setMaxAmount(''));
        dispatch(setMinAmount(''));
    }, [dispatch, page, rowsPerPage ]);


    // FUNCTIONS HANDLING WEBSOCKETS CONNECTIONS
    //useEffect(() => {
    //    const wsCheckoutUpdate = new WebSocket(`wss://api.finduku.com/ws/checkout_update/${merchant_id}/`);
    //    wsCheckoutUpdate.onmessage = async (e) => {
    //        const data = JSON.parse(e.data);
    //        const payment_id = data.message.payment_id

    //        dispatch(fetchNewPayment({ payment_id: payment_id }));
    //        dispatch(updateGrossVolumeData());
    //        dispatch(updateNetVolumeData());
    //        dispatch(updateFeeSummaryData());
    //    };

    //    return () => {
    //        if (wsCheckoutUpdate.readyState === WebSocket.OPEN) wsCheckoutUpdate.close();
    //    };

    //}, [dispatch, merchant_id]);


    // FUNCTION NAVIGATING TO PAYMENT VIEW PAGE
    function handlePaymentClick(reference) {
        navigate(`/transactions/payments/${reference}`);
    }

    // START OF FUNCTIONS HANDLING SEARCH LOGIC //
    const handleSearchQueryChange = (event) => {
        dispatch(setSearchQuery(event.target.value));
        dispatch(setShowFiltersButton(false));
        dispatch(setSelectedDateFilter(''));
        dispatch(setDateFilterIcon(true));
        dispatch(setDateFilterApplied(false));
        dispatch(setSelectedStatusFilter(''));
        dispatch(setStatusFilterIcon(true));
        dispatch(setStatusFilterApplied(false));
        dispatch(setSelectedAmountFilter(''));
        dispatch(setAmountFilterIcon(true));
        dispatch(setAmountFilterApplied(false));
    };

    const handleSearch = async () => {
        dispatch(searchPaymentsList());
    };

    const resetView = () => {
        dispatch(checkPaymentsList({ page, pageSize: rowsPerPage }));
        dispatch(setSearchQuery(''));
        dispatch(setShowResetButton(false));
    };
    // END OF FUNCTIONS HANDLING SEARCH LOGIC //

    // FUNCTION HANDLING WHEN FILTER IS CLICKED
    const handleFilterClick = (filterType) => {
        setActiveFilter(activeFilter === filterType ? null : filterType);
    };

    const isFilterActive = (filterType) => {
        return activeFilter === filterType;
    };

    // FUNCTION DISPLAYING & HIDING THE FILTER OPTIONS
    const handleDocumentClick = (event) => {
        const filterItem = event.target.closest('.payments__filter-item');
        const filterOptions = event.target.closest('.payments__filter-options');

        if ((!filterItem || !filterItem.contains(event.target)) && (!filterOptions || !filterOptions.contains(event.target))) {
            if (dateFilterApplied && selectedDateFilter === 'in between') {
                // Check if both startDate and endDate are not provided
                if (!startDate || !endDate) {
                    dispatch(setSelectedDateFilter(''));
                    dispatch(setDateFilterIcon(true));
                    dispatch(setDateFilterApplied(false));
                    setStartDate('');
                    setEndDate('');
                } 
            } else if (amountFilterApplied && selectedAmountFilter === 'is between') {
                if (!minAmount || !maxAmount) {
                    dispatch(setSelectedAmountFilter(''));
                    dispatch(setAmountFilterIcon(true));
                    dispatch(setAmountFilterApplied(false));
                    dispatch(setMinAmount(''));
                    dispatch(setMaxAmount(''));
                } 
            } else if (amountFilterApplied && selectedAmountFilter !== 'is between') {
                // Check if the amountValue is not provided
                if (!amountValue) {
                    dispatch(setSelectedAmountFilter(''));
                    dispatch(setAmountFilterIcon(true));
                    dispatch(setAmountFilterApplied(false));
                    dispatch(setAmountValue(''));
                }
            }
            // Continue with the existing logic to close the filter
            setActiveFilter(null);
        }
    };
    
    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
    
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilterApplied, selectedDateFilter, startDate, endDate, amountFilterApplied, selectedAmountFilter, minAmount, maxAmount, amountValue]);


    // START OF DATE FILTERING LOGIC
    // This function handles date filtering onChange
    const handleDateFilterChange = (filter) => {
        dispatch(setSelectedDateFilter(filter));
        dispatch(setDateFilterIcon(false));
        dispatch(setDateFilterApplied(true));
        dispatch(setShowFiltersButton(true));
        dispatch(setSearchQuery(''));
        setIsFilterChanged(true);
    };

    // This function handles status filtering onChange
    const handleStatusFilterChange = (filter) => {
        dispatch(setSelectedStatusFilter(filter));
        dispatch(setStatusFilterIcon(false));
        dispatch(setStatusFilterApplied(true));
        dispatch(setShowFiltersButton(true));
        dispatch(setSearchQuery(''));
        setIsFilterChanged(true);
    };

    const handleAmountFilterChange = (filter) => {
        dispatch(setSelectedAmountFilter(filter));
        dispatch(setAmountFilterIcon(false));
        dispatch(setAmountFilterApplied(true));
        dispatch(setShowFiltersButton(true));
        dispatch(setSearchQuery(''));
        setIsFilterChanged(true);
    
        if (filter === 'is between') {
            dispatch(setMinAmount(minAmount || ''));
            dispatch(setMaxAmount(maxAmount || ''));
            dispatch(setAmountValue(''));  // Reset the single amount value
        } else if (filter === 'is equal to') {
            dispatch(setAmountValue(''));  // Set amountValue directly
            dispatch(setMinAmount(''));  // Clear minAmount
            dispatch(setMaxAmount(''));  // Clear maxAmount
        } else {
            dispatch(setAmountValue(''));  
            dispatch(setMinAmount(''));
            dispatch(setMaxAmount(''));
        }
    };

    const handleAmountValueChange = (event) => {
        dispatch(setAmountValue(event.target.value));
    };

    const handleMinAmountChange = (event) => {
        dispatch(setMinAmount(event.target.value));
        setInBetweenAmountError(false);
    };

    const handleMaxAmountChange = (event) => {
        dispatch(setMaxAmount(event.target.value));
        setInBetweenAmountError(false);
    };

    const applyFilters = () => {
        if (startDate && endDate && startDate > endDate) {
            setInBetweenDateError(true);
        } else if (parseFloat(minAmount) > parseFloat(maxAmount)) {
            setInBetweenAmountError(true);
        } else {
            setInBetweenDateError(false);
            dispatch(filterPaymentsList({
                startDate: startDate,
                endDate: endDate,
            }));
            setActiveFilter(null);
            setIsFilterChanged(false);
            setStartDateChanged(false);
            setEndDateChanged(false);
        }
    }

        // This function clear just the date filter
    const clearDateFilters = () => {
        dispatch(setSelectedDateFilter(''));
        dispatch(setDateFilterIcon(true));
        dispatch(setDateFilterApplied(false));
        setStartDate('');
        setEndDate('');
        setIsFilterChanged(false);
        setStartDateChanged(false);
        setEndDateChanged(false);  
        applyFilters();
    }

    // This function clear just the status filter
    const clearStatusFilters = () => {
        dispatch(setSelectedStatusFilter(''));
        dispatch(setStatusFilterIcon(true));
        dispatch(setStatusFilterApplied(false));
        setIsFilterChanged(false);
        applyFilters();
    }

    // This function clear just the status filter
    const clearAmountFilters = () => {
        dispatch(setSelectedAmountFilter(''));
        dispatch(setAmountFilterIcon(true));
        dispatch(setAmountFilterApplied(false));
        dispatch(setAmountValue(''));
        dispatch(setMaxAmount(''));
        dispatch(setMinAmount(''));
        setIsFilterChanged(false);
        setInBetweenAmountError(false);
        applyFilters();
    }

    // This function clear all filters
    const clearFilters = () => {
        dispatch(setSelectedDateFilter(''));
        dispatch(setDateFilterApplied(false));
        dispatch(setDateFilterIcon(true));
        dispatch(setShowFiltersButton(false));
        setStartDate('');
        setEndDate('');
        dispatch(setSelectedStatusFilter(''));
        dispatch(setStatusFilterApplied(false));
        dispatch(setStatusFilterIcon(true));
        dispatch(setSelectedAmountFilter(''));
        dispatch(setAmountFilterIcon(true));
        dispatch(setAmountFilterApplied(false));
        dispatch(setAmountValue(''));
        dispatch(setMaxAmount(''));
        dispatch(setMinAmount(''));
        dispatch(checkPaymentsList({ page, pageSize: rowsPerPage }));
        setIsFilterChanged(false);
        setStartDateChanged(false);
        setEndDateChanged(false);
        setInBetweenDateError(false);
        setInBetweenAmountError(false);
    }
    

    // FUNCTION HANDLING PAGE CHANGE
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    // Compute current page data range (e.g., 11 - 20 of 102)
    let startRange = (page - 1) * rowsPerPage + 1;
    let endRange = Math.min(page * rowsPerPage, totalCount);

    // Ensure that startRange does not exceed totalCount
    if (startRange > totalCount) {
        // If the calculated start exceeds the total, adjust start and end
        if (totalCount === 0) {
            // If no results, start should also be 0 (not 1)
            startRange = 0;
            endRange = 0;
        } else {
            // Otherwise, start at the first item of the last available page
            endRange = totalCount;
            const lastPage = Math.ceil(totalCount / rowsPerPage);
            const startOfLastPage = (lastPage - 1) * rowsPerPage + 1;
            startRange = Math.min(startOfLastPage, totalCount);
        }
    }


    // FUNCTIONS SETTING STATUS CLASS
    const getPaymentStatusClass = (status) => {
        switch(status) {
            case 'Paid':
                return 'payments__status-paid';
            case 'Failed':
                return 'payments__status-failed';
            case 'Pending':
                return 'payments__status-pending';
            case 'On Hold':
                return 'payments__status-onhold';
            default:
                return '';
        }
    }
    

    return (
        <>
            <div className="payments">
                <div className="payments__page-container">
                    {error ? (
                        <div className="payments__error-wrapper">
                            <div className="payments__error-message-container">
                                <BsEmojiFrown  className="payments__error-message-icon"/>
                                <p className="payments__error-msg">{error.message}</p>
                                <p className="payments__error-action">{error.action}</p>
                                <p className="payments__error-code">{error.code}</p>
                            </div>
                        </div>
                    ) : (
                        <>
                            {payments.length === 0 && !isSearchFilterMade ? (
                                <div className="payments__no-payments">
                                    <div className="payments__no-payments-container">
                                        <BsQrCodeScan className="payments__no-payments-icon"/>
                                        <div className="payments__no-payments__text-wrapper">
                                            <p className="payments__no-payments__heading">
                                                No payments
                                            </p>
                                            <p className="payments__no-payments__subheading1">
                                                Accept payments by letting your customers scan your In-store QR code.
                                            </p>
                                            <p className="payments__no-payments__subheading2">
                                                Payments will show up here, along with the transaction details.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="payments__body">
                                    <div className="payments__data-container">
                                        <div className="payments__action-wrapper">
                                            <div className="payments__left-actions">
                                                
                                                {/* THIS IS THE FILTER SECTION */}
                                                <div className="payments__filter-wrapper">
                                                    <span className="payments__filter-title">Filter by:</span>
                                                    <div
                                                        className={`payments__filter-item ${
                                                            isFilterActive('date') ? 'active' : ''
                                                        } ${
                                                            dateFilterApplied ? 'filter-applied' : ''
                                                        } ${
                                                            inBetweenDateError ? 'filter-error' : ''
                                                        }`}
                                                        onClick={() => handleFilterClick('date')}
                                                    >
                                                        {toggleDateIcon ? (
                                                            <BsPlusCircle className="payments__filter-item-plus-icon" />
                                                        ) : (
                                                            <BsXCircle 
                                                                className="payments__filter-item-x-icon"
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    clearDateFilters();
                                                                    setInBetweenDateError(false); 
                                                                }} 
                                                            />
                                                        )}
                                                        <span className="payments__filter-item-title">Date</span>
                                                        <span>
                                                            {selectedDateFilter === 'in between' ? (
                                                                startDate && endDate
                                                                ? ` : ${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`
                                                                : ' : in between'
                                                            ) : (
                                                                selectedDateFilter ? ` : ${selectedDateFilter}` : ''
                                                            )}
                                                        </span>
                                                        {isFilterActive('date') && (
                                                            <div className="payments__filter-options" onClick={(e) => e.stopPropagation()}>
                                                                <p className="payments__filter-options-title">Filter by Date:</p>
                                                                <div className="payments__filter-options-item">
                                                                    <input
                                                                        type="radio"
                                                                        id="last90Days"
                                                                        name="dateFilter"
                                                                        value="in last 90 days"
                                                                        checked={selectedDateFilter === 'in last 90 days'}
                                                                        onChange={() => handleDateFilterChange('in last 90 days')}
                                                                    />
                                                                    <label htmlFor="last90Days">in last 90 days</label>
                                                                </div>
                                                                <div className="payments__filter-options-item">
                                                                    <input
                                                                        type="radio"
                                                                        id="last30Days"
                                                                        name="dateFilter"
                                                                        value="in last 30 days"
                                                                        checked={selectedDateFilter === 'in last 30 days'}
                                                                        onChange={() => handleDateFilterChange('in last 30 days')}
                                                                    />
                                                                    <label htmlFor="last30Days">in last 30 days</label>
                                                                </div>
                                                                <div className="payments__filter-options-item">
                                                                    <input
                                                                        type="radio"
                                                                        id="last7Days"
                                                                        name="dateFilter"
                                                                        value="in last 7 days"
                                                                        checked={selectedDateFilter === 'in last 7 days'}
                                                                        onChange={() => handleDateFilterChange('in last 7 days')}
                                                                    />
                                                                    <label htmlFor="last7Days">in last 7 days</label>
                                                                </div>
                                                                <div className="payments__filter-options-item">
                                                                    <input
                                                                        type="radio"
                                                                        id="last24Hours"
                                                                        name="dateFilter"
                                                                        value="in last 24 hours"
                                                                        checked={selectedDateFilter === 'in last 24 hours'}
                                                                        onChange={() => handleDateFilterChange('in last 24 hours')}
                                                                    />
                                                                    <label htmlFor="last24Hours">in last 24 hours</label>
                                                                </div>
                                                                <div className={`payments__filter-options-item ${inBetweenDateError ? 'date-filter-error' : ''}`}>
                                                                    <input
                                                                        type="radio"
                                                                        id="inbetween"
                                                                        name="dateFilter"
                                                                        value="in between"
                                                                        checked={selectedDateFilter === 'in between'}
                                                                        onChange={() => handleDateFilterChange('in between')}
                                                                    />
                                                                    <label htmlFor="inbetween">in between</label>
                                                                </div>
                                                                <CustomDateRangePicker
                                                                    selectedDateFilter={selectedDateFilter}
                                                                    startDate={startDate}
                                                                    setStartDate={setStartDate}
                                                                    endDate={endDate}
                                                                    setEndDate={setEndDate}
                                                                    inBetweenDateError={inBetweenDateError}
                                                                    setInBetweenDateError={setInBetweenDateError}
                                                                    setStartDateChanged={setStartDateChanged} 
                                                                    setEndDateChanged={setEndDateChanged}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        className={`payments__filter-item ${
                                                            isFilterActive('status') ? 'active' : ''
                                                        } ${
                                                            statusFilterApplied ? 'filter-applied' : ''
                                                        }`}
                                                        onClick={() => handleFilterClick('status')}
                                                    >   
                                                    
                                                        {toggleStatusIcon ? (
                                                            <BsPlusCircle className="payments__filter-item-plus-icon" />
                                                        ) : (
                                                            <BsXCircle 
                                                                className="payments__filter-item-x-icon"
                                                                onClick={(event) => {
                                                                    event.stopPropagation(); 
                                                                    clearStatusFilters();
                                                                }} 
                                                            />
                                                        )}     
                                                        <span className="payments__filter-item-title">Status</span>
                                                        <span>{selectedStatusFilter ? `: ${selectedStatusFilter}` : ''}</span>
                                                        {isFilterActive('status') && (
                                                            <div className="payments__filter-options" onClick={(e) => e.stopPropagation()}>
                                                                <p className="payments__filter-options-title">Filter by Status:</p>
                                                                <div className="payments__filter-options-item">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        id="paid"
                                                                        name="statusFilter"
                                                                        value="Paid"
                                                                        checked={selectedStatusFilter === 'Paid'}
                                                                        onChange={() => handleStatusFilterChange('Paid')}
                                                                    />
                                                                    <label htmlFor="paid">Paid</label>
                                                                </div>
                                                                <div className="payments__filter-options-item">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        id="pending"
                                                                        name="statusFilter"
                                                                        value="Pending"
                                                                        checked={selectedStatusFilter === 'Pending'}
                                                                        onChange={() => handleStatusFilterChange('Pending')}
                                                                    />
                                                                    <label htmlFor="pending">Pending</label>
                                                                </div>
                                                                <div className="payments__filter-options-item">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        id="failed"
                                                                        name="statusFilter"
                                                                        value="Failed"
                                                                        checked={selectedStatusFilter === 'Failed'}
                                                                        onChange={() => handleStatusFilterChange('Failed')}
                                                                    />
                                                                    <label htmlFor="failed">Failed</label>
                                                                </div>
                                                                <div className="payments__filter-options-item">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        id="onhold"
                                                                        name="statusFilter"
                                                                        value="On Hold"
                                                                        checked={selectedStatusFilter === 'On Hold'}
                                                                        onChange={() => handleStatusFilterChange('On Hold')}
                                                                    />
                                                                    <label htmlFor="onhold">On Hold</label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        className={`payments__filter-item ${
                                                            isFilterActive('amount') ? 'active' : ''
                                                        } ${
                                                            amountFilterApplied ? 'filter-applied' : ''
                                                        } ${
                                                            inBetweenAmountError ? 'filter-error' : ''
                                                        }`}
                                                        onClick={() => handleFilterClick('amount')}
                                                    >   
                                                        {toggleAmountIcon ? (
                                                            <BsPlusCircle className="payments__filter-item-plus-icon" />
                                                        ) : (
                                                            <BsXCircle 
                                                                className="payments__filter-item-x-icon"
                                                                onClick={(event) => {
                                                                    event.stopPropagation(); 
                                                                    clearAmountFilters();
                                                                    setInBetweenAmountError(false);
                                                                }} 
                                                            />
                                                        )}
                                                        <span className="payments__filter-item-title">Amount</span>
                                                        <span>
                                                            {selectedAmountFilter === 'is between' ? (
                                                                minAmount && maxAmount
                                                                ? `: ₱${parseFloat(minAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} - ₱${parseFloat(maxAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                                                : ''
                                                            ) : (
                                                                selectedAmountFilter && amountValue ? `: ${selectedAmountFilter} ₱${parseFloat(amountValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ''
                                                            )}
                                                        </span>
                                                        {isFilterActive('amount') && (
                                                            <div className="payments__filter-options" onClick={(e) => e.stopPropagation()}>
                                                                <p className="payments__filter-options-title">Filter by Amount:</p>
                                                                <div className="payments__filter-options-item">
                                                                    <input 
                                                                        type="radio" 
                                                                        id="equalTo"
                                                                        name="amountFilter"
                                                                        value="is equal to"
                                                                        checked={selectedAmountFilter === 'is equal to'}
                                                                        onChange={() => handleAmountFilterChange('is equal to')}
                                                                    />
                                                                    <label htmlFor="equalTo">is equal to</label>
                                                                </div>
                                                                {selectedAmountFilter === 'is equal to' && (
                                                                    <div className="payments__filter-options-amount-value">
                                                                        <p className="payments__filter-options-peso-sign">&#8369;</p> 
                                                                        <input
                                                                            type="text"
                                                                            className="payments__filter-options-amount-input"
                                                                            inputMode="numeric"
                                                                            placeholder="0.00"
                                                                            value={amountValue}
                                                                            onKeyDown={(e) => {
                                                                                const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', '.', ','];
                                                                                if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                let rawValue = e.target.value.replace(/\D/g, ''); // filter out non-numeric characters
                                                                                let unformattedValue = '';
                                                                                
                                                                                if (rawValue.length <= 2) {
                                                                                    unformattedValue = `0.${rawValue.padEnd(2, '0')}`;
                                                                                } else {
                                                                                    const integerPart = parseInt(rawValue.slice(0, -2), 10); // parseInt will remove leading zeros
                                                                                    const decimalPart = rawValue.slice(-2);
                                                                                    unformattedValue = `${integerPart}.${decimalPart}`;
                                                                                }
                                                                            
                                                                                // Creating a new event object with the unformatted numeric value
                                                                                const newEvent = {
                                                                                    target: {
                                                                                        name: e.target.name,
                                                                                        value: unformattedValue
                                                                                    }
                                                                                };
                                                                                
                                                                                handleAmountValueChange(newEvent); 
                                                                            }}
                                                                            required
                                                                            maxLength={10}
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div className="payments__filter-options-item">
                                                                    <input 
                                                                        type="radio" 
                                                                        id="moreThan"
                                                                        name="amountFilter"
                                                                        value="is more than"
                                                                        checked={selectedAmountFilter === 'is more than'}
                                                                        onChange={() => handleAmountFilterChange('is more than')}
                                                                    />
                                                                    <label htmlFor="moreThan">is more than</label>
                                                                </div>
                                                                {selectedAmountFilter === 'is more than' && (
                                                                    <div className="payments__filter-options-amount-value">
                                                                        <p className="payments__filter-options-peso-sign">&#8369;</p> 
                                                                        <input
                                                                            type="text"
                                                                            className="payments__filter-options-amount-input"
                                                                            inputMode='numeric'
                                                                            value={amountValue}
                                                                            placeholder="0.00"
                                                                            onKeyDown={(e) => {
                                                                                const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                                if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                let rawValue = e.target.value.replace(/\D/g, ''); // filter out non-numeric characters
                                                                                let unformattedValue = '';
                                                                                
                                                                                if (rawValue.length <= 2) {
                                                                                    unformattedValue = `0.${rawValue.padEnd(2, '0')}`;
                                                                                } else {
                                                                                    const integerPart = parseInt(rawValue.slice(0, -2), 10); // parseInt will remove leading zeros
                                                                                    const decimalPart = rawValue.slice(-2);
                                                                                    unformattedValue = `${integerPart}.${decimalPart}`;
                                                                                }
                                                                            
                                                                                // Creating a new event object with the unformatted numeric value
                                                                                const newEvent = {
                                                                                    target: {
                                                                                        name: e.target.name,
                                                                                        value: unformattedValue
                                                                                    }
                                                                                };
                                                                                
                                                                                handleAmountValueChange(newEvent); 
                                                                            }}
                                                                            required
                                                                            maxLength={10}
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div className="payments__filter-options-item">
                                                                    <input 
                                                                        type="radio" 
                                                                        id="lessThan"
                                                                        name="amountFilter"
                                                                        value="is less than"
                                                                        checked={selectedAmountFilter === 'is less than'}
                                                                        onChange={() => handleAmountFilterChange('is less than')}
                                                                    />
                                                                    <label htmlFor="lessThan">is less than</label>
                                                                </div>
                                                                {selectedAmountFilter === 'is less than' && (
                                                                    <div className="payments__filter-options-amount-value">
                                                                        <p className="payments__filter-options-peso-sign">&#8369;</p> 
                                                                        <input
                                                                            type="text"
                                                                            className="payments__filter-options-amount-input"
                                                                            inputMode='numeric'
                                                                            placeholder="0.00"
                                                                            value={amountValue}
                                                                            onKeyDown={(e) => {
                                                                                const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                                if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                let rawValue = e.target.value.replace(/\D/g, ''); // filter out non-numeric characters
                                                                                let unformattedValue = '';
                                                                                
                                                                                if (rawValue.length <= 2) {
                                                                                    unformattedValue = `0.${rawValue.padEnd(2, '0')}`;
                                                                                } else {
                                                                                    const integerPart = parseInt(rawValue.slice(0, -2), 10); // parseInt will remove leading zeros
                                                                                    const decimalPart = rawValue.slice(-2);
                                                                                    unformattedValue = `${integerPart}.${decimalPart}`;
                                                                                }
                                                                            
                                                                                // Creating a new event object with the unformatted numeric value
                                                                                const newEvent = {
                                                                                    target: {
                                                                                        name: e.target.name,
                                                                                        value: unformattedValue
                                                                                    }
                                                                                };
                                                                                
                                                                                handleAmountValueChange(newEvent); 
                                                                            }}
                                                                            required
                                                                            maxLength={10}
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div className={`payments__filter-options-item ${inBetweenAmountError ? 'amount-filter-error' : ''}`}>
                                                                    <input 
                                                                        type="radio"
                                                                        id="isBetween"
                                                                        name="amountFilter"
                                                                        value="is between"
                                                                        checked={selectedAmountFilter === 'is between'}
                                                                        onChange={() => handleAmountFilterChange('is between')}
                                                                    />
                                                                    <label htmlFor="isBetween">is between</label>
                                                                </div>
                                                                {selectedAmountFilter === 'is between' && (
                                                                    <div className="payments__filter-options-amount-value">
                                                                        <div className="payments__filter-options-minAmount">
                                                                            <p className={`payments__filter-options-amount-label ${inBetweenAmountError ? 'amount-filter-error' : ''}`}>
                                                                                Min
                                                                            </p>
                                                                            <div className="payments__filter-options-amount-input-wrapper">
                                                                                <p className={`payments__filter-options-peso-sign ${inBetweenAmountError ? 'amount-filter-error' : ''}`}>
                                                                                    &#8369;
                                                                                </p>                                       
                                                                                <input
                                                                                    className={`payments__filter-options-amount-input ${inBetweenAmountError ? 'amount-filter-error' : ''}`}
                                                                                    type="text"
                                                                                    inputMode='numeric'
                                                                                    placeholder="0.00"
                                                                                    value={minAmount}
                                                                                    onKeyDown={(e) => {
                                                                                        const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                                        if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        let rawValue = e.target.value.replace(/\D/g, ''); // filter out non-numeric characters
                                                                                        let unformattedValue = '';
                                                                                        
                                                                                        if (rawValue.length <= 2) {
                                                                                            unformattedValue = `0.${rawValue.padEnd(2, '0')}`;
                                                                                        } else {
                                                                                            const integerPart = parseInt(rawValue.slice(0, -2), 10); // parseInt will remove leading zeros
                                                                                            const decimalPart = rawValue.slice(-2);
                                                                                            unformattedValue = `${integerPart}.${decimalPart}`;
                                                                                        }
                                                                                    
                                                                                        // Creating a new event object with the unformatted numeric value
                                                                                        const newEvent = {
                                                                                            target: {
                                                                                            name: e.target.name,
                                                                                            value: unformattedValue
                                                                                            }
                                                                                        };
                                                                                        
                                                                                        handleMinAmountChange(newEvent); 
                                                                                    }}
                                                                                    required
                                                                                    maxLength={10}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="payments__filter-options-maxAmount">
                                                                            <p className={`payments__filter-options-amount-label ${inBetweenAmountError ? 'amount-filter-error' : ''}`}>
                                                                                Max
                                                                            </p>
                                                                            <div className="payments__filter-options-amount-input-wrapper">
                                                                                <p className={`payments__filter-options-peso-sign ${inBetweenAmountError ? 'amount-filter-error' : ''}`}>
                                                                                    &#8369;
                                                                                </p>                                                
                                                                                <input
                                                                                    className={`payments__filter-options-amount-input ${inBetweenAmountError ? 'amount-filter-error' : ''}`}
                                                                                    type="text"
                                                                                    inputMode='numeric'
                                                                                    placeholder="0.00"
                                                                                    value={maxAmount}
                                                                                    onKeyDown={(e) => {
                                                                                        const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                                        if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        let rawValue = e.target.value.replace(/\D/g, ''); // filter out non-numeric characters
                                                                                        let unformattedValue = '';
                                                                                        
                                                                                        if (rawValue.length <= 2) {
                                                                                            unformattedValue = `0.${rawValue.padEnd(2, '0')}`;
                                                                                        } else {
                                                                                            const integerPart = parseInt(rawValue.slice(0, -2), 10); // parseInt will remove leading zeros
                                                                                            const decimalPart = rawValue.slice(-2);
                                                                                            unformattedValue = `${integerPart}.${decimalPart}`;
                                                                                        }
                                                                                    
                                                                                        // Creating a new event object with the unformatted numeric value
                                                                                        const newEvent = {
                                                                                            target: {
                                                                                            name: e.target.name,
                                                                                            value: unformattedValue
                                                                                            }
                                                                                        };
                                                                                        
                                                                                        handleMaxAmountChange(newEvent); 
                                                                                    }}
                                                                                    required
                                                                                    maxLength={10}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {showFiltersButton && (dateFilterApplied || statusFilterApplied || amountFilterApplied) &&
                                                    <div className="payments__apply-filter-wrapper">
                                                        {inBetweenDateError ? (
                                                            <div className="payments__filter-error">
                                                                Error: Start date cannot be after end date!
                                                            </div>
                                                        ) : inBetweenAmountError ? (
                                                            <div className="payments__filter-error">
                                                                Error: Min amount cannot be greater than max amount!
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {(isFilterChanged || startDateChanged || endDateChanged) ? (
                                                                    <button 
                                                                            type="button" 
                                                                            className="payments__apply-filter-button"
                                                                            onClick={applyFilters}
                                                                    >
                                                                            Apply
                                                                    </button>
                                                                ) : (
                                                                    <button 
                                                                            type="button" 
                                                                            className="payments__remove-filter-button"
                                                                            onClick={clearFilters}
                                                                    >
                                                                            Remove
                                                                    </button>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>                                                   
                                                }
                                                {/* END OF THE FILTER SECTION */}
                                            </div>
                                            <div className="payments__right-actions">
                                                <div className="payments__search-wrapper">
                                                    <form className="payments__search-form" onSubmit={(event) => event.preventDefault()} >
                                                        <SearchTextField 
                                                            type="text" 
                                                            className="payments__search-bar" 
                                                            name="q" 
                                                            placeholder="Search payments..."
                                                            autoComplete="off"
                                                            value={searchQuery}
                                                            onChange={handleSearchQueryChange}
                                                            onKeyDown={event => {
                                                                if (event.key === 'Enter' && searchQuery) {
                                                                    event.preventDefault(); 
                                                                    handleSearch();
                                                                }
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton 
                                                                            disabled={!searchQuery}
                                                                            sx={{ 
                                                                                marginRight: -2,
                                                                                '&:hover': {
                                                                                    backgroundColor: 'transparent',
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        color: '#57768D',
                                                                                    },
                                                                                },
                                                                            }}
                                                                            onClick={handleSearch}
                                                                        >
                                                                            <SearchIcon sx={{ color: 'gray' }} />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                                style: { fontSize: 15 },
                                                            }}
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <table className="payments__table-desktop">
                                            <thead>
                                                <tr className="payments__table-desktop-heading">
                                                    <td className="payments__table-date-heading">Date</td>
                                                    <td className="payments__table-status-heading">Status</td>
                                                    <td className="payments__table-reference-heading">Reference</td>
                                                    <td className="payments__table-description-heading">Description</td>
                                                    <td className="payments__table-bank-heading">Paid From</td>
                                                    <td className="payments__table-amount-heading">Amount</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {payments.length > 0 ? (                                                                  
                                                    payments.map((payment) => (
                                                        <tr 
                                                            className="payments__table-desktop-data"
                                                            key={payment.payment_id} 
                                                            onClick={() => handlePaymentClick(payment.reference)}
                                                        >
                                                            <td className="payments__table-date-data">{payment.date}</td>
                                                            <td className="payments__table-status-data">
                                                                <span className={`payments__status ${getPaymentStatusClass(payment.status)}`}>
                                                                    {payment.status.toUpperCase()}
                                                                </span>
                                                            </td>
                                                            <td className="payments__table-reference-data">{payment.reference}</td>
                                                            <td className="payments__table-description-data">{payment.description}</td>
                                                            <td className="payments__table-bank-data">{payment.source_bank}</td>
                                                            <td className="payments__table-amount-data" key="amount">
                                                                PHP {payment && payment.amount ? parseFloat(payment.amount)
                                                                .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ""}
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr className="payments__table-no-data">
                                                        <td colSpan={8}>No results found.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        {/* TABLE LAYOUT WHEN IN MOBILE DEVICES */}
                                        <div className="payments__table-mobile">
                                            {payments.length > 0 ? (                                                                  
                                                payments.map((payment) => (
                                                    <div 
                                                        className="payments__table-mobile-data"
                                                        key={payment.payment_id} 
                                                        onClick={() => handlePaymentClick(payment.reference)}
                                                    >
                                                        <div className="payments__table-mobile-data-left">
                                                            <p className="payments__transaction-text-mobile">
                                                                In-store Payment
                                                            </p>
                                                            <p className="payments__reference-mobile">
                                                                Ref: {payment.reference}
                                                            </p>
                                                        </div>
                                                        <div className="payments__table-mobile-data-right" key="amount">
                                                            <p className="payments__amount-mobile">
                                                                PHP {payment && payment.amount ? parseFloat(payment.amount)
                                                                .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ""}
                                                            </p>
                                                            <p className="payments__date-mobile">
                                                                {payment.date}
                                                            </p>
                                                            <p className="payments__status-mobile">
                                                                <span className={`payments__status ${getPaymentStatusClass(payment.status)}`}>
                                                                    {payment.status.toUpperCase()}
                                                                </span> 
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="payments__table-no-data-mobile">
                                                    <p>No results found.</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="payments__reset-view-wrapper">
                                        {showResetButton && 
                                            <button className="payments__reset-view-btn" onClick={resetView}>
                                                All Payments
                                            </button>
                                        }
                                    </div>
                                    <div className="payments__pagination-wrapper">
                                        {hidePagination ? (
                                            null
                                        ) : (
                                            <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                                                <Typography variant="body2" sx={{ mr: 2 }}>
                                                    {`${startRange} - ${endRange} of ${totalCount}`}
                                                </Typography>
                                                <Grid item>
                                                    <Pagination 
                                                        count={Math.ceil(totalCount / rowsPerPage)} 
                                                        page={page} 
                                                        onChange={handleChangePage} 
                                                        size="medium"
                                                        sx={{
                                                            '& .MuiPaginationItem-previous, & .MuiPaginationItem-next': {
                                                                cursor: 'pointer',
                                                                '&:hover': {
                                                                    color: '#284154', // Hover color for the icons
                                                                    backgroundColor: '#e0e0e0', // Background color for hover effect
                                                                },
                                                            },
                                                            '& .MuiPaginationItem-root': {
                                                                '&:hover': {
                                                                    cursor: 'pointer',
                                                                    backgroundColor: '#f0f0f0', // General hover effect
                                                                },
                                                            },
                                                            '& .MuiPaginationItem-page': {
                                                                '&.Mui-selected': {
                                                                    backgroundColor: '#284154', // Selected page background color
                                                                    color: '#fff', // Selected page text color
                                                                    '&:hover': {
                                                                        backgroundColor: '#57768D', // Hover effect for selected page
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Stack>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            {isPageLoading  &&
                <PaymentLoader />
            }
        </>
    );
};

export default Payments;