import React, { useEffect } from 'react';
import { BsCheckCircle } from "react-icons/bs";
import { useDispatch } from 'react-redux'
import { logout } from '../../../redux/slices/authSlice';
import { reset_home } from '../../../redux/slices/homeSlice';
import { reset_insights } from '../../../redux/slices/insightsSlice';
import { updateLogout } from '../../../redux/slices/logoutSlice';
import { reset_merchant } from '../../../redux/slices/merchantSlice';
import { reset_payments } from '../../../redux/slices/paymentsSlice';
import { reset_payouts } from '../../../redux/slices/payoutsSlice';
import { reset_settings } from '../../../redux/slices/settingsSlice';
import '../../../Styles/AuthStyles/Onboarding/AppSubmitted.css';

function AppSubmitted() {
     const dispatch = useDispatch();

     useEffect(() => {
          dispatch(logout());
          dispatch(reset_home());
          dispatch(reset_insights());
          dispatch(updateLogout());
          dispatch(reset_merchant());
          dispatch(reset_payments());
          dispatch(reset_payouts());
          dispatch(reset_settings());
     }, [dispatch])

     return (
          <>
               <div className="appSubmitted">
                    <div className="appSubmitted__page-container">
                         <div className="appSubmitted__heading-icon-wrapper">
                              <BsCheckCircle className="appSubmitted__heading-icon"/>
                         </div>
                         <div className="appSubmitted__heading-wrapper">
                              <p className="appSubmitted__heading">Request submitted!</p>
                         </div>
                         <div className="appSubmitted__subheading-wrapper">
                              <p className="appSubmitted__subheading">
                                   Your request to open an account with Finduku has been submitted successfully. 
                                   We'll review your request and get back to you within 24 hours.
                              </p>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default AppSubmitted;