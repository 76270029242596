import React from 'react'
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { BsPrinterFill, BsInfoCircle, BsChevronLeft } from 'react-icons/bs';
import NotFound from '../../../../components/ErrorPages/NotFound';
import '../../../../Styles/MainStyles/Transactions/Payouts/PayoutView.css';

const FeeTooltip = styled(({ className, ...props }) => (
    <Tooltip classes={{ popper: className }} {...props} />
))({
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'white',  
        maxWidth: 480,           
        color: 'black',
        fontFamily: 'Figtree',
        fontSize: '14px',
        boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.25)', 
        padding: '6px',
    },
    '& .MuiTooltip-arrow': {
        color: 'white',  
    },
});

function PayoutView() {
    const navigate = useNavigate()
    const { reference } = useParams();
    const payouts = useSelector((state) => state.payouts.payouts_list);
    const payoutData = payouts.find(payout => payout.reference === reference);

    if (!payoutData) {
        return <NotFound />; 
    }

    const handlePrintTransaction = () => {
        const payoutTransaction = document.querySelector('.payoutView__page-container');
    
        html2canvas(payoutTransaction, { scale: 2 })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'p',
                    unit: 'mm',
                    format: 'a4',
                });
                const width = pdf.internal.pageSize.getWidth();
                const height = (canvas.height * width) / canvas.width;
                pdf.addImage(imgData, 'PNG', 0, 0, width, height);
                pdf.save('payout_transaction.pdf');
            })
            .catch((error) => {
                console.error('Failed to capture screenshot', error);
            });
    };

    const routeBack = () => {
        navigate('/transactions/payouts')
    }

    const getPayoutDataStatusClass = (status) => {
        switch(status) {
            case 'Sent':
                return 'payoutView__status-sent';
            case 'Failed':
                return 'payoutView__status-failed';
            case 'Pending':
                return 'payoutView__status-pending';
            case 'On Hold':
                return 'payoutView__status-onhold';
            default:
                return '';
        }
    }
    
    
    return (
        <>
            <div className="payoutView">
                <div className="payoutView__page-container">
                    <div className="payoutView__breadcrumb-wrapper">
                        <BsChevronLeft className="payoutView__breadcrumb-icon"/>
                        <p className="payoutView__breadcrumb-text" onClick={routeBack}>Back to Payouts</p>
                    </div>
                    <div className="payoutView__header">
                        <div className="payoutView__title-wrapper">
                            <p className="payoutView__title">Transaction details</p>
                        </div>
                        <div className="payoutView__print-wrapper" onClick={handlePrintTransaction}>
                            <BsPrinterFill className="payoutView__print-icon" />
                            <p className="payoutView__print-text">Print</p>
                        </div>
                    </div>
                    <div className="payoutView__body">
                        <div className="payoutView__headline">
                            <div className="payoutView__headline-item1">
                                <div className="payoutView__headline-item1-title-wrapper">
                                    <p className="payoutView__headline-item1-title">
                                        Payout to Bank
                                    </p>
                                </div>
                                <div className="payoutView__headline-item1-amount-wrapper">
                                    <p className="payoutView__headline-item1-amount">Amount</p>
                                </div>
                            </div>
                            <div className="payoutView__headline-item2">
                                <div className="payoutView__headline-item2-date-wrapper">
                                    <p className="payoutView__headline-item2-date">Date: {payoutData.date}</p>
                                </div>
                                <div className="payoutView__headline-item2-id-wrapper">
                                    <p className="payoutView__headline-item2-id">Reference: {payoutData.reference}</p>
                                </div>
                                <div className="payoutView__headline-item2-amount-wrapper">
                                    <p className="payoutView__headline-item2-amount">
                                        PHP {payoutData && payoutData.amount ? parseFloat(payoutData.amount)
                                        .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="payoutView__headline-item2-mobile">
                                <div className="payoutView__headline-item2-date-wrapper-mobile">
                                    <p className="payoutView__headline-item2-date-label-mobile">Payout Date:</p>
                                    <p className="payoutView__headline-item2-date-value-mobile">{payoutData.date}</p>
                                </div>
                                <div className="payoutView__headline-item2-id-wrapper-mobile">
                                    <p className="payoutView__headline-item2-id-label-mobile">Reference:</p>
                                    <p className="payoutView__headline-item2-id-value-mobile">{payoutData.reference}</p>
                                </div>
                            </div>
                            <div className="payoutView__headline-item3">
                                <div className="payoutView__headline-item3-status-wrapper">
                                    <p className="payoutView__headline-item3-status-label">Payout Status: </p>
                                    <p className={`payoutView__headline-item3-status ${getPayoutDataStatusClass(payoutData.status)}`}>
                                        {payoutData.status.toUpperCase()}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="payoutView__data-body">
                            {/* PAYMENT DETAILS SECTION */}
                            <div className="payoutView__payout-details-section">
                                <p className="payoutView__payout-details-section--title">
                                    Payout Details
                                </p>
                                <table className="payoutView__payout-details-table">
                                    <tbody>
                                        <tr>{/* PAYOUT ID */}
                                            <td className="payoutView__table-row-label">Payout ID</td>
                                            <td className="payoutView__table-row-value">{payoutData.payout_id}</td>
                                        </tr>
                                        <tr>{/* GROSS AMOUNT */}
                                            <td className="payoutView__table-row-label">Gross Amount</td>
                                            <td className="payoutView__table-row-value">
                                                PHP {payoutData && payoutData.amount ? parseFloat(payoutData.amount)
                                                .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : "Not available"}
                                            </td>
                                        </tr>
                                        <tr>{/* CONVENIENCE FEE */}
                                            <td className="payoutView__table-row-label">Fees</td>
                                            <td className="payoutView__table-row-value">
                                                PHP 0.00
                                                <FeeTooltip title="Finduku offers free instant payout" placement="right" arrow>
                                                    <span className="payoutView__table-row-tooltip">
                                                        <BsInfoCircle />
                                                    </span>
                                                </FeeTooltip>
                                            </td>
                                        </tr>
                                        <tr>{/* NET AMOUNT */}
                                            <td className="payoutView__table-row-label">Net Amount</td>
                                            <td className="payoutView__table-row-value">
                                                PHP {payoutData && payoutData.amount ? parseFloat(payoutData.amount)
                                                .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : "Not available"}
                                            </td>
                                        </tr>
                                        <tr>{/* DESCRIPTION */}
                                            <td className="payoutView__table-row-label">Description</td>
                                            <td className="payoutView__table-row-value">{payoutData.description}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* END OF PAYMENT DETAILS SECTION */}

                            {/* TRANSACTION RESULTS SECTION */}
                            <div className="payoutView__transaction-results-section">
                                <p className="payoutView__transaction-results-section--title">
                                    Transaction Results
                                </p>
                                <table className="payoutView__transaction-results-table">
                                    <tbody>
                                        <tr>{/* COMPLETED DATE */}
                                            <td className="payoutView__table-row-label">Date Updated</td>
                                            <td className="payoutView__table-row-value">{payoutData.updated_on}</td>
                                        </tr>
                                        <tr>{/* GATEWAY ID */}
                                            <td className="payoutView__table-row-label">Trace ID</td>
                                            <td className="payoutView__table-row-value">{payoutData.trace_id}</td>
                                        </tr>
                                        <tr>{/* PAYMENT METHOD */}
                                            <td className="payoutView__table-row-label">Payout Method</td>
                                            <td className="payoutView__table-row-value">Payout to Bank</td>
                                        </tr>
                                        <tr>{/* PAYMENT SOURCE  */}
                                            <td className="payoutView__table-row-label">Paid To</td>
                                            <td className="payoutView__table-row-value">{payoutData.bank_name} ••••{payoutData.account_number}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* END OF TRANSACTION RESULTS SECTION */}
                        </div>
                        <div className="payoutView__merchant-support">
                            <p className="payoutView__merchant-support--title">Need help with this transaction?</p>
                            <p className="payoutView__merchant-support--details">
                                Send us an email to support@finduku.com for assistance with this transaction.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PayoutView