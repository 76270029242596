import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import Cookies from 'js-cookie';

const FETCH_HOME_DATA_ENDPOINT = '/apps/fetch_home_data/';

const isOlderThan5Minutes = (timestamp) => {
    if (!timestamp) return true;
    const now = new Date();
    const lastFetched = new Date(timestamp);
    const diff = (now - lastFetched) / 1000 / 60; // difference in minutes
    return diff > 5;
};

export const fetchHomeData = createAsyncThunk(
     'balance/info',
     async (_, { getState, rejectWithValue }) => {
          const state = getState();
          const lastFetchedTime = state.home.lastFetchedTime;

          if (!isOlderThan5Minutes(lastFetchedTime)) {
               //Data is up to date, do nothing
               return null;
          }

          const merchant_id = getState().merchant.profile ? getState().merchant.profile.merchant_id : '';
          try {
               const res = await axios.post(
                    FETCH_HOME_DATA_ENDPOINT,
                    JSON.stringify({ merchant_id: merchant_id }),
                    {
                         headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'X-CSRFToken': Cookies.get('csrftoken')
                         }
                    }
               );
               if (res.data.success) {
                    return {
                         balanceData: res.data.balanceData,
                         dailyPayments: res.data.dailyTotalPayments,
                         recentPayments: res.data.recentPayments,
                         lastFetchedTime: new Date().toISOString()
                    };
               } else {
                    console.error('Invalid data received', res.data);
                    return rejectWithValue({
                         message: "Oops! We encountered an error.",
                         action: "Please try again or contact support.",
                         code: "ERR_FINDUKU_1000"
                    });
               }
          } catch (error) {
               console.error('Failed to fetch balance', error);
               let errorMsg, errorAction, errorCode;
               if (error.response) {
                    switch (error.response.status) {
                         case 400:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_3000";
                              break;
                         case 401:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Please login your account.";
                              errorCode = "ERR_FINDUKU_4000";
                              break;
                         case 403:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Click Okay to log in again now.";
                              errorCode = "ERR_FINDUKU_5000";
                              break;
                         case 404:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_6000";
                              break;
                         case 500:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_7000";
                              break;
                         default:
                              errorMsg = "Oops! We encountered an error";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_2000";
                    }
                    return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
               } else {
                    return rejectWithValue({
                         message: "Oops! We encountered an error",
                         action: "Please try again or contact support.",
                         code: "ERR_FINDUKU_8000"
                    })
               }
          }
     }
);


export const homeSlice = createSlice({
     name: 'home',
     initialState:{
          balanceData: {
               amount: '',
               asOfDate: ''
          },
          dailyPayments: {
               total: '',
               date: '' 
          },
          recentPayments: [],
          lastFetchedTime: null,
          loading: false,
          error: null,
     },
     reducers: {
          updateBalance: (state, action) => {
               const { balance, fetched_on } = action.payload;
               state.balanceData.amount = balance;
               state.balanceData.asOfDate = fetched_on;
          },
          updateDailyPayments: (state, action) => {
               state.dailyPayments = { ...state.dailyPayments, ...action.payload };
          },
          reset_home: (state) => {
               state.balanceData = {
                   amount: '',
                    asOfDate: ''
               };
               state.dailyPayments = {
                    total: '',
                    date: '' 
               };
               state.recentPayments = [];
               state.lastFetchedTime = null;
               state.loading = false;
               state.error = null;
          }
     },
     extraReducers: (builder) => {
          builder
               .addCase(fetchHomeData.pending, (state) => {
                    state.loading = true;
                    state.error = null;
               })
               .addCase(fetchHomeData.fulfilled, (state, action) => {
                    if (action.payload) {
                         const { 
                              balanceData, 
                              dailyPayments, 
                              recentPayments, 
                              lastFetchedTime 
                         } = action.payload;
     
                         if (balanceData && balanceData.balance) {
                             state.balanceData.amount = balanceData.balance;
                         } else {
                             state.balanceData.amount = '';
                         }
     
                         if (balanceData && balanceData.fetched_on) {
                             state.balanceData.asOfDate = balanceData.fetched_on;
                         } else {
                             state.balanceData.asOfDate = '';
                         }

                         if (dailyPayments && dailyPayments.total) {
                              state.dailyPayments.total = dailyPayments.total;
                         } else {
                              state.dailyPayments.total = '';
                         }

                         if (dailyPayments && dailyPayments.date) {
                              state.dailyPayments.date = dailyPayments.date;
                         } else {
                              state.dailyPayments.date = '';
                         }
     
                         state.recentPayments = recentPayments;
                         state.lastFetchedTime = lastFetchedTime;
                    }
                    state.loading = false;
               })
               .addCase(fetchHomeData.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.payload || {
                         message: "An unexpected error occurred",
                         action: "Please try again later.",
                         code: "ERR_FINDUKU_UNKNOWN"
                    };
               });

     }
});

export const { updateBalance, updateDailyPayments, reset_home } = homeSlice.actions;
export default homeSlice.reducer;
