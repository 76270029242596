import React from 'react';
import '../../../../Styles/MainStyles/Settings/LegalInfo/LegalInfo.css';

function LegalInfo({ settingsData, isLoadingSettingPage }) {
    return (
        <>
            <div className="legal-info">
                <div className="legal-info__page-container">
                    <div className="legal-info__title-wrapper">
                        <p className={`title ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-title' : ''}`}>
                            {isLoadingSettingPage && 'Legal Information'}
                        </p>
                        <p className={`caption ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-caption' : ''}`}>
                            {isLoadingSettingPage && 
                                "View and manage your company's legal and compliance information."
                            }
                        </p>                        
                    </div>
                    <div className="legal-info__content-wrapper">
                        <div className="content-item">
                            <div className="label">
                                <p className={`label-text ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Registered Name'}
                                </p>
                            </div>
                            <div className="value">
                                <p className={`value-output ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                    {isLoadingSettingPage ? settingsData.company_name : ''}
                                </p>
                            </div>
                            <div className="action">
                                <button 
                                    className={`action-button ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''}`}
                                >
                                    {isLoadingSettingPage && 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="legal-info__content-wrapper">
                        <div className="content-item">
                            <div className="label">
                                <p className={`label-text ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Registration ID'}
                                </p>
                            </div>
                            <div className="value">
                                <p className={`value-output ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                    {isLoadingSettingPage ? settingsData.reg_number : ''}
                                </p>
                            </div>
                            <div className="action">
                                <button 
                                    className={`action-button ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''}`}
                                >
                                    {isLoadingSettingPage && 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="legal-info__content-wrapper">
                        <div className="content-item">
                            <div className="label">
                                <p className={`label-text ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Registration Date'}
                                </p>
                            </div>
                            <div className="value">
                                <p className={`value-output ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                    {isLoadingSettingPage ? settingsData.reg_date : ''}
                                </p>
                            </div>
                            <div className="action">
                                <button 
                                    className={`action-button ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''}`}
                                >
                                    {isLoadingSettingPage && 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="legal-info__content-wrapper">
                        <div className="content-item">
                            <div className="label">
                                <p className={`label-text ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Business Type'}
                                </p>
                            </div>
                            <div className="value">
                                <p className={`value-output ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                    {isLoadingSettingPage ? settingsData.business_type : ''}
                                </p>
                            </div>
                            <div className="action">
                                <button 
                                    className={`action-button ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''}`}
                                >
                                    {isLoadingSettingPage && 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="legal-info__content-wrapper">
                        <div className="content-item">
                            <div className="label">
                                <p className={`label-text ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Business Activity'}
                                </p>
                            </div>
                            <div className="value">
                                <p className={`value-output ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                    {isLoadingSettingPage ? settingsData.business_activity : ''}
                                </p>
                            </div>
                            <div className="action">
                                <button 
                                    className={`action-button ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''}`}
                                >
                                    {isLoadingSettingPage && 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="legal-info__content-wrapper">
                        <div className="content-item">
                            <div className="label">
                                <p className={`label-text ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-label' : ''}`}>
                                    {isLoadingSettingPage && 'Registered Address'}
                                </p>
                            </div>
                            <div className="value">
                                <p className={`value-output ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-value' : ''}`}>
                                    {isLoadingSettingPage ? settingsData.company_address : ''}
                                </p>
                            </div>
                            <div className="action">
                                <button 
                                    className={`action-button ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-btn' : ''}`}
                                >
                                    {isLoadingSettingPage && 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="legal-info__footer">
                        <p className={`legal-info__contact-support ${!isLoadingSettingPage ? 'skeleton-loader skeleton-loader-contact-support' : ''}`}>
                            {isLoadingSettingPage && 
                                "**Contact our support team if you wish to update your company's legal information."
                            }
                        </p> 
                    </div>
                </div>
            </div>
        </>
    )
}

export default LegalInfo