import React from 'react';
import { BsEmojiFrown } from "react-icons/bs";
import '../../../Styles/MainStyles/Logout/LogoutErrorModal.css';

function LogoutErrorModal({ logoutError, tryAgain }) {
    return (
        <>
            <div className="logout-error">
                <div className="logout-error__page-container">
                    <div className="logout-error__message-wrapper">
                        <BsEmojiFrown  className="logout-error__message-icon"/>
                        <p className="logout-error__msg">{logoutError.message}</p>
                        <p className="logout-error__action">{logoutError.action}</p>
                        <p className="logout-error__code">{logoutError.code}</p>
                        <button 
                            type="button" 
                            className="logout-error__button"
                            onClick={tryAgain}
                        >
                            TRY AGAIN
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LogoutErrorModal