import React from 'react';
import { BsEmojiFrown } from 'react-icons/bs';
import '../../../Styles/AuthStyles/Users/SetupUserError.css';

function SetupUserError({ setupUserErrorMsg, setupUserErrorAction, setupUserErrorCode, onClose }) {
     return (
          <>
               <div className="setup-user-error">
                    <div className="setup-user-error__message-container">
                         <BsEmojiFrown  className="setup-user-error__message-icon"/>
                         <p className="setup-user-error__text">{setupUserErrorMsg}</p>
                         <p className="setup-user-error__action">{setupUserErrorAction}</p>
                         <p className="setup-user-error__code">{setupUserErrorCode}</p>
                         <div className="setup-user-error__button-wrapper">
                              <button 
                                   type="button"
                                   className="setup-user-error__button"
                                   onClick={onClose}
                              >
                                   OKAY
                              </button>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default SetupUserError