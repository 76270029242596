import React, { useState, useEffect } from 'react';
import { BsQrCodeScan, BsBoxArrowInUpRight } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import Payments from './Payments/Payments';
import Payouts from './Payouts/Payouts';
import CreatePayout from './Payouts/CreatePayout';
import NoBank from './Payouts/NoBank';
import ViewQR from '../Settings/BusinessProfile/ViewQR';
import NoPermModal from './NoPermModal';
import '../../../Styles/MainStyles/Transactions/Transactions.css';

function Transactions() {
    // Redux state
    const bankData = useSelector((state) => state.settings.bankData)
    const isFetchingBankData = useSelector((state) => state.settings.loading)
    const qrValue = useSelector((state) => state.settings.settingsData.store_qr)
    const loggedInUserPerm = useSelector((state) => state.settings.loggedInUserPerm);
    const location = useLocation();

    // State management
    const [selectedMenu, setSelectedMenu] = useState('payments');
    const [openPayoutModal, setOpenPayoutModal] = useState(false);
    const [viewQRModalOpen, setViewQRModalOpen] = useState(false);
    const [noBankAccount, setNoBankAccount] = useState(false);


    // Set the selected menu based on the route
    useEffect(() => {
        setSelectedMenu(location.pathname === '/transactions/payouts' ? 'payouts' : 'payments');
    }, [location.pathname]);


    // Menu selection handler
    const handleMenuSelect = (menu) => setSelectedMenu(menu);

    const isBankDataValid = (data) => {
        if (!data) return false;
        for (let key in data) {
            if (data[key] !== null && data[key] !== '') {
                return true;
            }
        }
        return false;
    };

    // Request payout handler
    const requestPayout = () => {
        if (isBankDataValid(bankData)) {
            setOpenPayoutModal(true);
        } else {
            setNoBankAccount(true);
        }
    };

    // Modal close handlers
    const closeNoBankModal = () => setNoBankAccount(false);
    const closePayoutModal = () => setOpenPayoutModal(false);


    // FUNCTIONS HANDLING View QR MODAL
    const displayViewQRModal = () => {
        setViewQRModalOpen(true);
    };
        
    const hideViewQRModal = () => {
        setViewQRModalOpen(false);
    };

    const [showNoPermModal, setShowNoPermModal] = useState(false);

    const displayNoPermModal = () => {
        setShowNoPermModal(true)
    }

    const hideNoPermModal = () => {
        setShowNoPermModal(false)
    }

    return (
        <div className="transactions">
            <div className="transactions__page-container">
                <div className="transactions__header">{/* HEADER SECTION */}
                    <div className="transactions__header-topbar">{/* TOPBAR */}
                        <div className="transactions__title-wrapper">{/* TITLE */}
                            <p className="transactions__title">Transactions</p>
                        </div>
                        <div className="transactions__navmenu-wrapper">
                            <p className="transactions__navmenu-title">Sort by:</p>
                            <NavLink 
                                to="/transactions"
                                className={`transactions__navmenu-item ${selectedMenu === 'payments' ? 'nav_active' : ''}`}
                                onClick={() => handleMenuSelect('payments')}
                            >
                                Payments
                            </NavLink>
                            <NavLink 
                                to="/transactions/payouts"
                                className={`transactions__navmenu-item ${selectedMenu === 'payouts' ? 'nav_active' : ''}`}
                                onClick={() => handleMenuSelect('payouts')}
                            >
                                Payouts
                            </NavLink>
                        </div>
                        <div className="transactions__button-wrapper">{/* BUTTONS */}
                            <button className="transactions__download-button" type="button">
                                Download
                            </button>
                            <button 
                                className="transactions__action-button" 
                                type="button"
                                onClick={() => {
                                    if (loggedInUserPerm.can_manage_payouts) {
                                        if (selectedMenu === 'payouts') {
                                            requestPayout();
                                        } else {
                                            displayViewQRModal();
                                        }
                                    } else {
                                        displayNoPermModal();
                                    }
                                }}
                                disabled={selectedMenu === 'payouts' && !isFetchingBankData}
                            >
                                {selectedMenu === 'payouts' 
                                    ? <BsBoxArrowInUpRight className="transactions__action-button-icon" /> 
                                    : <BsQrCodeScan className="transactions__action-button-icon" />
                                }
                                {selectedMenu === 'payouts' ? 'Create Payout' : 'New Payment'}
                            </button>
                        </div>
                    </div>{/* END OF THE TOPBAR */}
                </div>{/* END OF THE HEADER SECTION*/}
                <div className="transactions__navmenu-wrapper-mobile">
                    <p className="transactions__navmenu-title">Sort by:</p>
                    <NavLink 
                        to="/transactions"
                        className={`transactions__navmenu-item ${selectedMenu === 'payments' ? 'nav_active' : ''}`}
                        onClick={() => handleMenuSelect('payments')}
                    >
                        Payments
                    </NavLink>
                    <NavLink 
                        to="/transactions/payouts"
                        className={`transactions__navmenu-item ${selectedMenu === 'payouts' ? 'nav_active' : ''}`}
                        onClick={() => handleMenuSelect('payouts')}
                    >
                        Payouts
                    </NavLink>
                </div>
                <div className="transactions__body">
                    {selectedMenu === 'payments' 
                        ? <Payments /> 
                        : <Payouts bankData={bankData}/>
                    }
                </div>
            </div>

            {/* Modals */}
            <CreatePayout open={openPayoutModal} onClose={closePayoutModal} />
            <NoBank open={noBankAccount} onClose={closeNoBankModal} />
            {viewQRModalOpen &&
                <ViewQR qrValue={qrValue} onClose={hideViewQRModal} />
            }
            {showNoPermModal &&
                <NoPermModal  onClose={hideNoPermModal} />
            }
        </div>

    )
}

export default Transactions