import React from 'react'
import '../../Styles/MainStyles/Main.css'
import { NavLink } from 'react-router-dom'


function Navbar() {


    return (
        <>
            <div className="navbar">
                <div className="navbar-container">
                    <div className="navbar-item-wrapper">
                        <div className="navbar-item-left-section">
                            <NavLink 
                                to="/" 
                                className={({ isActive }) =>
                                    isActive ? "navbar-active" : "navbar-item"
                                }
                            >
                                <p className="menu-name">Dashboard</p>
                            </NavLink>
                            <NavLink
                                to="/transactions"
                                className={({ isActive }) =>
                                    isActive ? "navbar-active" : "navbar-item"
                                }
                            >
                                <p className="menu-name">Transactions</p>
                            </NavLink>
                            <NavLink 
                                to="/reports" 
                                className={({ isActive }) =>
                                    isActive ? "navbar-active" : "navbar-item"
                                }
                            >
                                <p className="menu-name">Reports</p>
                            </NavLink>
                        </div>
                        <div className="navbar-item-right-section">
                            <NavLink 
                                to="/integrations" 
                                className={({ isActive }) =>
                                    isActive ? "navbar-active" : "navbar-item"
                                } 
                            >
                                <p className="menu-name">Integrations</p>
                            </NavLink>
                            <NavLink 
                                to="/settings" 
                                className={({ isActive }) =>
                                    isActive ? "navbar-active" : "navbar-item"
                                }
                            >
                                <p className="menu-name">Settings</p>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;