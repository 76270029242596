import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import axios from '../../../api/axios';
import Cookies from 'js-cookie';
import UserInfo from './UserInfo/UserInfo';
import LoginSecurity from './LoginSecurity/LoginSecurity';
import BusinesProfile from './BusinessProfile/BusinesProfile';
import LegalInfo from './LegalInfo/LegalInfo';
import PayoutInfo from './PayoutInfo/PayoutInfo';
import ManageUsers from './ManageUsers/ManageUsers';
import ChangeEmail from './UserInfo/ChangeEmail';
import ChangeEmailOTP from './UserInfo/ChangeEmailOTP';
import AddBankAccount from './PayoutInfo/AddBankAccount';
import UpdateBank from './PayoutInfo/UpdateBank';
import AddUser from './ManageUsers/AddUser';
import EditUser from './ManageUsers/EditUser';
import DeleteUser from './ManageUsers/DeleteUser';
import ChangePassword from './LoginSecurity/ChangePassword';
import ChangePasswordOTP from './LoginSecurity/ChangePasswordOTP';
import NoPermMsg from './NoPermMsg';
import SettingsLoader from './SettingsLoader';
import LogoutErrorModal from './LogoutErrorModal';
import EditNameModal from './UserInfo/EditNameModal';
import ComingSoon from './LoginSecurity/ComingSoon';
import ComingSoonCSID from './LoginSecurity/ComingSoonCSID';
import { logout } from '../../../redux/slices/authSlice';
import { reset_home } from '../../../redux/slices/homeSlice';
import { updateLogout } from '../../../redux/slices/logoutSlice';
import { reset_insights } from '../../../redux/slices/insightsSlice';
import { reset_merchant } from '../../../redux/slices/merchantSlice';
import { reset_payments } from '../../../redux/slices/paymentsSlice';
import { reset_payouts } from '../../../redux/slices/payoutsSlice';
import { reset_settings } from '../../../redux/slices/settingsSlice';
import { update_store_name } from '../../../redux/slices/merchantSlice';
import { 
    fetchSettingsData, 
    setProfilePhoto, 
    setUserAddress, 
    setPhoneNumber,
    setCompanyLogo,
    setStoreName,
    setContactNumber,
    setWebsite,
    setBankData,
    setNewUser,
    updateUserPermissions,
    removeUser,
    toggleUnauthorizedError 
} from '../../../redux/slices/settingsSlice';
import '../../../Styles/MainStyles/Settings/Settings.css';

// API ENDPOINTS
const UPLOAD_PROFILE_PHOTO_ENDPOINT = '/apps/upload_profile_photo/'
const REMOVE_PROFILE_PHOTO_ENDPOINT = '/apps/remove_profile_photo/'
const UPLOAD_LOGO_ENDPOINT = '/apps/upload_logo/'
const REMOVE_LOGO_ENDPOINT = '/apps/remove_logo/'
const ADD_BANK_ACCOUNT_ENDPOINT = '/apps/add_bank_account/'
const UPDATE_BANK_ACCOUNT_ENDPOINT = '/apps/update_bank_account/'
const SAVING_EDITED_STORE_NAME_ENDPOINT = '/apps/save_edited_store_name/'
const SAVING_EDITED_CONTACT_NUMBER_ENDPOINT = '/apps/save_edited_contact_number/'
const SAVING_EDITED_WEBSITE_ENDPOINT = '/apps/save_edited_website/'
const CHANGE_EMAIL_ENDPOINT = '/apps/update_email_address/'
const CHANGE_EMAIL_OTP_ENDPOINT = '/apps/update_email_address_otp/'
const RESEND_CHANGE_EMAIL_OTP_ENDPOINT = '/apps/resend_update_email_address_otp/'
const ADDING_AND_UPDATING_ADDRESS_ENDPOINT = '/apps/add_and_update_address/'
const ADDING_AND_UPDATING_PHONE_ENDPOINT = '/apps/add_and_update_phone/'
const UPDATING_PASSWORD_ENDPOINT = '/apps/update_password/'
const UPDATING_PASSWORD_OTP_ENDPOINT = '/apps/confirm_update_password/'
const RESEND_NEW_PASSWORD_OTP_ENDPOINT = '/apps/resend_new_password_otp/'
const ADDING_NEW_USER_ENDPOINT = '/apps/add_new_user/'
const EDITING_ACCOUNT_USER_ENDPOINT = '/apps/edit_account_user/'
const REMOVE_ACCOUNT_USER_ENDPOINT = '/apps/remove_account_user/'
const LOGOUT_ENDPOINT = '/apps/logout/'



function Settings() {
    const location = useLocation();
    const dispatch = useDispatch();

    const merchant_id = useSelector((state) => state.merchant.profile ? state.merchant.profile.merchant_id : '');
    const user_email = useSelector((state) => state.auth.user ? state.auth.user.email : '');
    const settingsData = useSelector((state) => state.settings.settingsData);
    const bankData = useSelector((state) => state.settings.bankData);
    const loggedInUserPerm = useSelector((state) => state.settings.loggedInUserPerm);
    const usersData = useSelector((state) => state.settings.users);
    const pageLoading = useSelector((state) => state.settings.loading);
    const error =  useSelector((state) => state.settings.error);
    const first_name = useSelector((state) => state.auth.user ? state.auth.user.first_name : '');
    const last_name = useSelector((state) => state.auth.user ? state.auth.user.last_name : '');
    const full_name = `${first_name} ${last_name}`.trim();

    // ====== START OF MAIN COMPONENT'S LOGIC
    // Selected Menu Item States
    const [selectedMenuItem, setSelectedMenuItem] = useState('user-information');

    // Handle selection of menu item
    const handleMenuItemClick = (menuItem) => {
        setSelectedMenuItem(menuItem);
    };

    // Mounts the child component when user selects a menu item
    useEffect(() => {
        switch (location.pathname) {
            case '/settings/Security':
                setSelectedMenuItem('security');
                break;
            case '/settings/BusinessProfile':
                setSelectedMenuItem('business-profile');
                break;
            case '/settings/LegalInformation':
                setSelectedMenuItem('legal-information');
                break;
            case '/settings/PayoutSettings':
                setSelectedMenuItem('payout-settings');
                break;
            case '/settings/ManageUsers':
                setSelectedMenuItem('manage-users');
                break;
            default:
                setSelectedMenuItem('user-information');
                break;
        }
    }, [location.pathname]);

    // Fetch the settings & users data when component loads/mounts
    useEffect(() => {
        dispatch(fetchSettingsData())
    }, [dispatch]);


    // FUNCTIONS HANDLING WEBSOCKETS CONNECTIONS
    //useEffect(() => {
    //   const wsCheckoutUpdate = new WebSocket(`wss://api-dev.finduku.com/ws/update_invited_user/${merchant_id}/`);
    //    wsCheckoutUpdate.onmessage = async (e) => {
    //        const data = JSON.parse(e.data);
    //        const invited_user_id = data.message.user_id

    //        console.log("invited_user_id", invited_user_id)

    //        dispatch(updateInvitedUsers(invited_user_id));
    //    };

    //    return () => {
    //        if (wsCheckoutUpdate.readyState === WebSocket.OPEN) wsCheckoutUpdate.close();
    //    };

    //}, [dispatch, merchant_id]);

    // ====== START OF USER PROFILE PHOTO LOGIC
    const [isUploadingPhoto, setIsUploadingPhoto] = useState(false)
    const [isRemovingPhoto, setIsRemovingPhoto] = useState(false)

    // Error States
    const [profilePhotoError, setProfilePhotoError] =useState(false)
    const [profilePhotoErrorMsg, setProfilePhotoErrorMsg] =useState(null)
    const [profilePhotoErrorCode, setProfilePhotoErrorCode] =useState(null)

    const handleProfilePhotoOKButton = () => {
        setIsUploadingPhoto(false);
        setIsRemovingPhoto(false);
        setProfilePhotoError(false);
        setProfilePhotoErrorMsg(null);
        setProfilePhotoErrorCode(null);
    }

    const fileInputRef = useRef(null);

    // Function that uploads profile photo
    const handleImageUpload = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            return;
        }
    
        const formData = new FormData();
        formData.append('profile_photo', file);
    
        setIsUploadingPhoto(true)
        try {
            const res = await axios.post(
                UPLOAD_PROFILE_PHOTO_ENDPOINT,
                formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {              
                dispatch(setProfilePhoto(res.data.profile_photo_url))
                // Reset the file input value
                event.target.value = null;
            } else {
                console.error('Failed to upload image', res.data);
                setProfilePhotoError(true);
                setProfilePhotoErrorMsg("Something went wrong, try again.")
                setProfilePhotoErrorCode("ERR_FINDUKU_2000")
                // Reset the file input value
                event.target.value = null;
            }
        } catch (error) {
            console.error('Error uploading the image', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setProfilePhotoErrorMsg("Something went wrong, try again.")
                        setProfilePhotoErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setProfilePhotoErrorMsg("Something went wrong, try again.")
                        setProfilePhotoErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setProfilePhotoErrorMsg("Something went wrong, try again.")
                        setProfilePhotoErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setProfilePhotoErrorMsg("Something went wrong, try again.")
                        setProfilePhotoErrorCode("ERR_FINDUKU_6000")      
                        break;
                    case 500:         
                        setProfilePhotoErrorMsg("Something went wrong, try again.")
                        setProfilePhotoErrorCode("ERR_FINDUKU_7000") 
                        break;
                    default:
                        setProfilePhotoErrorMsg("Something went wrong, try again.")
                        setProfilePhotoErrorCode("ERR_FINDUKU_1000")                                
                }
            } else {
                setProfilePhotoErrorMsg("Something went wrong, try again.")
                setProfilePhotoErrorCode("ERR_FINDUKU_8000")                 
            }
            setProfilePhotoError(true);
            // Reset the file input value
            event.target.value = null;
        } finally {
            setIsUploadingPhoto(false);           
        }
    }

    // Function that removes the profile photo
    const handleImageRemove = async () => {
        setIsRemovingPhoto(true)
        try {
            const res = await axios.post(
                REMOVE_PROFILE_PHOTO_ENDPOINT,  
                {}, 
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                dispatch(setProfilePhoto(res.data.profile_photo))
                // Reset the file input value
                if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
            } else {
                console.error('Failed to remove image', res.data);
                setProfilePhotoError(true);
                setProfilePhotoErrorMsg("Something went wrong, try again.")
                setProfilePhotoErrorCode("ERR_FINDUKU_2000")
            }
        } catch (error) {
            console.error('Error removing the image', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setProfilePhotoErrorMsg("Something went wrong, try again.")
                        setProfilePhotoErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setProfilePhotoErrorMsg("Something went wrong, try again.")
                        setProfilePhotoErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setProfilePhotoErrorMsg("Something went wrong, try again.")
                        setProfilePhotoErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setProfilePhotoErrorMsg("Something went wrong, try again.")
                        setProfilePhotoErrorCode("ERR_FINDUKU_6000")      
                        break;
                    case 500:         
                        setProfilePhotoErrorMsg("Something went wrong, try again.")
                        setProfilePhotoErrorCode("ERR_FINDUKU_7000") 
                        break;
                    default:
                        setProfilePhotoErrorMsg("Something went wrong, try again.")
                        setProfilePhotoErrorCode("ERR_FINDUKU_1000")                                
                }
            } else {
                setProfilePhotoErrorMsg("Something went wrong, try again.")
                setProfilePhotoErrorCode("ERR_FINDUKU_8000")                 
            }
            setProfilePhotoError(true);            
        } finally {
            setIsRemovingPhoto(false);
        }
    }
    // ====== END OF USER PROFILE PHOTO LOGIC

    const [showEditNameModal, setEditNameModal] = useState(false);

    const editName = () => {
        setEditNameModal(true);
    }

    const onCloseEditName = () => {
        setEditNameModal(false);
    }


    // ====== START OF CHANGE EMAIL LOGIC
    const [changeEmailData, setChangeEmailData] = useState({
        new_email: '',
        password: '',
        otp: '',
    })
    const [emailToVerify, setEmailToVerify] = useState(null);

    const [changeEmailModalOpen, setChangeEmailModalOpen] = useState(false);
    const [confirmingChangeEmail, setConfirmingChangeEmail] = useState(false);
    const [changeEmailConfirmed, setChangeEmailConfirmed] = useState(false);
    const [isChangingEmail, setIsChangingEmail] = useState(false)
    const [isConfirmingChangeEmailOTP, setIsConfirmingChangeEmailOTP] = useState(false)

    const [wrongOTP, setWrongOTP] = useState(false);
    const [expiredOTP, setExpiredOTP] = useState(false)
    const [otpError, setOtpError] = useState(null);
    const [resendOTPSuccessful, setResendOTPSuccessful] = useState(false);

    const [wrongPassword, setWrongPassword] = useState(false);
    const [changeEmailError, setChangeEmailError] = useState(false);
    const [changeEmailErrorMsg, setChangeEmailErrorMsg] = useState(null);
    const [changeEmailErrorAction, setChangeEmailErrorAction] = useState(null);
    const [changeEmailErrorCode, setChangeEmailErrorCode] = useState(false);
    const [userExist, setUserExist] = useState(false);

    // Displays the Change Email Modal
    const showChangeEmailModal = () => {
        setChangeEmailModalOpen(true)
    };

    // Hides the Change Email Modal
    const hideChangeEmailModal = () => {
        setChangeEmailModalOpen(false);
        setWrongPassword(false);
        setConfirmingChangeEmail(false);
        setWrongOTP(false);
        setExpiredOTP(false);
        setChangeEmailData({
            new_email: '',
            password: '',
            otp: '',
        });
    };

    const resetInvalidOTPMsg = () => {
        setWrongOTP(false);
        setExpiredOTP(false);
        setOtpError(null);
        setResendOTPSuccessful(false);
    }

    const resetWrongPasswordMsg = () => {
        setWrongPassword(false);
    }

    const resetUserExistMsg = () => {
        setUserExist(false);
    }

    // Updates the Change Email Date on every keystroke
    const updateChangeEmailData = (updatedData) => {
        setChangeEmailData(updatedData);
    };

    // Function that submits new email address
    const submitNewEmail = async () => {
        setIsChangingEmail(true)
        setWrongPassword(false)
        try {
            const res = await axios.post(
                CHANGE_EMAIL_ENDPOINT,
                JSON.stringify({
                    old_email: settingsData.email, 
                    new_email: changeEmailData.new_email,
                    password: changeEmailData.password,
                    otp: changeEmailData.otp,
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                setConfirmingChangeEmail(true);
                setEmailToVerify(res.data.email);
            } else if (res.data.user_exist){
                setUserExist(true);
            } else if (res.data.incorrect_password) {
                setWrongPassword(true);
            } else {
                console.error('Invalid data received', res.data)
                setChangeEmailError(true);
                setChangeEmailErrorMsg("Oops! We encountered an error.")
                setChangeEmailErrorAction("Please try again or contact support.")
                setChangeEmailErrorCode("ERR_FINDUKU_2000")                
            }
        } catch (error) {
            console.error('Failed to change email address', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setChangeEmailErrorMsg("Oops! We encountered an error");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setChangeEmailErrorMsg("Oops! We encountered an error");
                setChangeEmailErrorAction("Please try again or contact support.")
                setChangeEmailErrorCode("ERR_FINDUKU_8000") 
            }                     
            setChangeEmailError(true);
        } finally {
            setIsChangingEmail(false);
        }
    } 
    
    // Function that sends Change EMAIL OTP
    const submitChangeEmailOTP = async () => {
        setIsConfirmingChangeEmailOTP(true);
        try {
            const res = await axios.post(
                CHANGE_EMAIL_OTP_ENDPOINT,
                JSON.stringify({
                    old_email: settingsData.email, 
                    new_email: changeEmailData.new_email,
                    otp: changeEmailData.otp,
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                setChangeEmailConfirmed(true);
                setConfirmingChangeEmail(false); 
            } else if (res.data.wrong_otp){
                setWrongOTP(true);
                setExpiredOTP(false);
                setOtpError("The verification code you entered is incorrect.")
            } else if (res.data.expired_otp){
                setExpiredOTP(true);
                setWrongOTP(false);
                setOtpError("The verification code you entered has expired.") 
            } else {
                console.error('Invalid data received', res.data)
                setChangeEmailError(true);
                setChangeEmailErrorMsg("Oops! We encountered an error.")
                setChangeEmailErrorAction("Please try again or contact support.")
                setChangeEmailErrorCode("ERR_FINDUKU_2000")                
            }
        } catch (error) {
            console.error('Failed to change email confirmation', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setChangeEmailErrorMsg("Oops! We encountered an error");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setChangeEmailErrorMsg("Oops! We encountered an error");
                setChangeEmailErrorAction("Please try again or contact support.")
                setChangeEmailErrorCode("ERR_FINDUKU_8000") 
            }                     
            setChangeEmailError(true);      
        } finally {
            setIsConfirmingChangeEmailOTP(false)
        }
    }

    // This function handles sending request to resend OTP
    const resendChangeEmailOTP = async () => {
        setIsConfirmingChangeEmailOTP(true)
        setWrongOTP(false);
        setExpiredOTP(false);
        setOtpError(null);
        try {
            const res = await axios.post(
                RESEND_CHANGE_EMAIL_OTP_ENDPOINT,
                JSON.stringify({ 
                    email: user_email, 
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success){
                setResendOTPSuccessful(true)
            } else {
                console.error('Invalid data received', res.data)
                setChangeEmailError(true);
                setChangeEmailErrorMsg("Oops! We encountered an error.")
                setChangeEmailErrorAction("Please try again or contact support.")
                setChangeEmailErrorCode("ERR_FINDUKU_2000")                
            }

        } catch (error) {
            console.error('Something went wrong requesting resend OTP', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setChangeEmailErrorMsg("Oops! We encountered an error.");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setChangeEmailErrorMsg("Oops! We encountered an error");
                        setChangeEmailErrorAction("Please try again or contact support.")
                        setChangeEmailErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setChangeEmailErrorMsg("Oops! We encountered an error");
                setChangeEmailErrorAction("Please try again or contact support.")
                setChangeEmailErrorCode("ERR_FINDUKU_8000") 
            }                     
            setChangeEmailError(true)
        } finally {
            setIsConfirmingChangeEmailOTP(false)
        }
    };
    
    //const [showLogoutModal, setShowLogoutModal] = useState(false)
    const [isLoggingOut, setIsLoggingOut] = useState(false)

    const [logoutError, setLogoutError] = useState(false);
    const [logoutErrorMsg, setLogoutErrorMsg] = useState(null);
    const [logoutErrorAction, setLogoutErrorAction] = useState(null);
    const [logoutErrorCode, setLogoutErrorCode] = useState(null);

    const navigate2Login = () => {
        handleLogout()
    }

    const handleLogout = async () => {
        setIsLoggingOut(true);
        try {
            const res = await axios.post(
                LOGOUT_ENDPOINT,
                JSON.stringify({}),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                dispatch(logout());
                dispatch(reset_home());
                dispatch(reset_insights());
                dispatch(updateLogout());
                dispatch(reset_merchant());
                dispatch(reset_payments());
                dispatch(reset_payouts());
                dispatch(reset_settings());
            } else {
                console.error('Invalid data received', res.data)
                setLogoutError(true);
                setLogoutErrorMsg("Oops! We encountered an error.")
                setLogoutErrorAction("Please try again or contact support.")
                setLogoutErrorCode("ERR_FINDUKU_2000")                
            }
        } catch (error) {
            console.error('Invalid data', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setLogoutErrorMsg("Oops! We encountered an error.");
                        setLogoutErrorAction("Please try again or contact support.")
                        setLogoutErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setLogoutErrorMsg("Oops! We encountered an error.");
                        setLogoutErrorAction("Please try again or contact support.")
                        setLogoutErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setLogoutErrorMsg("Oops! We encountered an error.");
                        setLogoutErrorAction("Please try again or contact support.")
                        setLogoutErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setLogoutErrorMsg("Oops! We encountered an error.");
                        setLogoutErrorAction("Please try again or contact support.")
                        setLogoutErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setLogoutErrorMsg("Oops! We encountered an error.");
                        setLogoutErrorAction("Please try again or contact support.")
                        setLogoutErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setLogoutErrorMsg("Oops! We encountered an error");
                        setLogoutErrorAction("Please try again or contact support.")
                        setLogoutErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setLogoutErrorMsg("Oops! We encountered an error");
                setLogoutErrorAction("Please try again or contact support.")
                setLogoutErrorCode("ERR_FINDUKU_8000") 
            }                     
            setLogoutError(true);
        } finally {
            setIsLoggingOut(false);
        }
    } 

    const tryAgain = () => {
        handleLogout();
        setLogoutError(false);
        setLogoutErrorMsg(false);
        setLogoutErrorAction(false);
        setLogoutErrorCode(false);
    }
    // ====== END OF CHANGE EMAIL LOGIC


    // ====== START OF ADDING/UPDATING USER ADDRESS LOGIC
    const [addedAddress, setAddedAddress] = useState(settingsData.address || '');
    const [isAddingAddress, setIsAddingAddress] = useState(false);
    const [updatedAddress, setUpdatedAddress] = useState(settingsData.address || '');
    const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);
    const [isSavingAddress, setIsSavingAddress] = useState(false);
    const [addressError, setAddressError] = useState(false)
    const [addressErrorMsg, setAddressErrorMsg] =useState(null)
    const [addressErrorCode, setAddressErrorCode] =useState(null)

    const handleAddressOKButton = () => {
        setAddedAddress(settingsData.address || '');
        setIsAddingAddress(false);
        setUpdatedAddress(settingsData.address || '');
        setIsUpdatingAddress(false);
        setAddressError(false);
        setAddressErrorMsg(null);
        setAddressErrorCode(null);
    }

    const addAddress = () => {
        setAddedAddress(settingsData.address || '');
        setIsAddingAddress(true);
    }

    const handleAddingAddress = (event) => {
        setAddedAddress(event.target.value || '');
    }

    const cancelAddAddress = () => {
        setAddedAddress(settingsData.address || '');
        setIsAddingAddress(false);
    }

    const saveAddAddress = async () => {
        setIsSavingAddress(true)
        setIsAddingAddress(false)
        try {
            const res = await axios.post(
                ADDING_AND_UPDATING_ADDRESS_ENDPOINT,
                JSON.stringify({ email: settingsData.email, address: addedAddress }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }  
                }
            );
            if (res.data.success) {
                dispatch(setUserAddress(res.data.address));
                setIsSavingAddress(false);
            } else {
                console.error('Invalid data received', res.data);
                setAddressError(true);
                setAddressErrorMsg("Something went wrong, try again.");
                setAddressErrorCode("ERR_FINDUKU_2000")
            }
        } catch (error) {
            console.error('Failed to add address', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setAddressErrorMsg("Something went wrong, try again.")
                        setAddressErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setAddressErrorMsg("Something went wrong, try again.")
                        setAddressErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setAddressErrorMsg("Something went wrong, try again.")
                        setAddressErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setAddressErrorMsg("Something went wrong, try again.")
                        setAddressErrorCode("ERR_FINDUKU_6000")      
                        break;
                    case 500:         
                        setAddressErrorMsg("Something went wrong, try again.")
                        setAddressErrorCode("ERR_FINDUKU_7000") 
                        break;
                    default:
                        setAddressErrorMsg("Something went wrong, try again.")
                        setAddressErrorCode("ERR_FINDUKU_1000")                                
                }
            } else {
                setAddressErrorMsg("Something went wrong, try again.")
                setAddressErrorCode("ERR_FINDUKU_8000")                 
            }       
            setAddressError(true);     
        } finally {
            setIsSavingAddress(false);
        }
    }

    const updateAddress = () => {
        setUpdatedAddress(settingsData.address || '');
        setIsUpdatingAddress(true);
    }

    const handleUpdatingAddress = (event) => {
        setUpdatedAddress(event.target.value || '');
    }

    const cancelUpdateAddress = () => {
        setUpdatedAddress(settingsData.address || '');
        setIsUpdatingAddress(false);
    }

    const saveUpdateAddress = async () => {
        setIsSavingAddress(true)
        setIsUpdatingAddress(false)
        try {
            const res = await axios.post(
                ADDING_AND_UPDATING_ADDRESS_ENDPOINT,
                JSON.stringify({ email: settingsData.email, address: updatedAddress }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }  
                }
            );
            if (res.data.success) {
                dispatch(setUserAddress(res.data.address))
                setIsSavingAddress(false);
            } else {
                console.error('Invalid data received', res.data);
                setAddressError(true);
                setAddressErrorMsg("Something went wrong, try again.");
                setAddressErrorCode("ERR_FINDUKU_2000")
            }
        } catch (error) {
            console.error('Failed to update address', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setAddressErrorMsg("Something went wrong, try again.")
                        setAddressErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setAddressErrorMsg("Something went wrong, try again.")
                        setAddressErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setAddressErrorMsg("Something went wrong, try again.")
                        setAddressErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setAddressErrorMsg("Something went wrong, try again.")
                        setAddressErrorCode("ERR_FINDUKU_6000")      
                        break;
                    case 500:         
                        setAddressErrorMsg("Something went wrong, try again.")
                        setAddressErrorCode("ERR_FINDUKU_7000") 
                        break;
                    default:
                        setAddressErrorMsg("Something went wrong, try again.")
                        setAddressErrorCode("ERR_FINDUKU_1000")                                
                }
            } else {
                setAddressErrorMsg("Something went wrong, try again.")
                setAddressErrorCode("ERR_FINDUKU_8000")                 
            }       
            setAddressError(true);            
        } finally {
            setIsSavingAddress(false);
        }
    }
    // ====== END OF ADDING/UPDATING USER ADDRESS LOGIC


    // ====== START OF ADDING PHONE NUMBER LOGIC
    // Data States
    const [addedPhone, setAddedPhone] = useState(settingsData.phone_number || '');
    const [updatedPhone, setUpdatedPhone] = useState(settingsData.phone_number || '');

    // Adding or Updating States
    const [isUpdatingPhone, setIsUpdatingPhone] = useState(false);
    const [isAddingPhone, setIsAddingPhone] = useState(false);

    // Loading States
    const [isSavingPhone, setIsSavingPhone] = useState(false);

    // Error States
    const [phoneNumberError, setPhoneNumberError] = useState(false)
    const [phoneNumberErrorMsg, setPhoneNumberErrorMsg] =useState(null)
    const [phoneNumberErrorCode, setPhoneNumberErrorCode] =useState(null)

    const handlePhoneNumberOKButton = () => {
        setAddedPhone(settingsData.phone_number || '');
        setIsAddingPhone(false);
        setUpdatedPhone(settingsData.phone_number || '');
        setIsUpdatingPhone(false);
        setPhoneNumberError(false);
        setPhoneNumberErrorMsg(null);
        setPhoneNumberErrorCode(null);
    }

    const addPhone = () => {
        setAddedPhone(settingsData.phone_number || '');
        setIsAddingPhone(true);
    }

    const handleAddingPhone = (event) => {
        setAddedPhone(event.target.value || '');
    }

    const cancelAddPhone = () => {
        setAddedPhone(settingsData.phone_number || '');
        setIsAddingPhone(false);
    }

    const saveAddPhone = async () => {
        setIsSavingPhone(true)
        setIsAddingPhone(false)
        try {
            const res = await axios.post(
                ADDING_AND_UPDATING_PHONE_ENDPOINT,
                JSON.stringify({ email: settingsData.email, phone_number: addedPhone }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }  
                }
            );
            if (res.data.success) {
                dispatch(setPhoneNumber(res.data.phone_number))
                setIsSavingPhone(false);
            } else {
                console.error('Invalid data received', res.data);
                setPhoneNumberError(false);
                setPhoneNumberErrorMsg("Something went wrong, try again.");
                setPhoneNumberErrorCode("ERR_FINDUKU_2000");
            }
        } catch (error) {
            console.error('Failed to add phone number', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setPhoneNumberErrorMsg("Something went wrong, try again.")
                        setPhoneNumberErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setPhoneNumberErrorMsg("Something went wrong, try again.")
                        setPhoneNumberErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setPhoneNumberErrorMsg("Something went wrong, try again.")
                        setPhoneNumberErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setPhoneNumberErrorMsg("Something went wrong, try again.")
                        setPhoneNumberErrorCode("ERR_FINDUKU_6000")      
                        break;
                    case 500:         
                        setPhoneNumberErrorMsg("Something went wrong, try again.")
                        setPhoneNumberErrorCode("ERR_FINDUKU_7000") 
                        break;
                    default:
                        setPhoneNumberErrorMsg("Something went wrong, try again.")
                        setPhoneNumberErrorCode("ERR_FINDUKU_1000")                                
                }
            } else {
                setPhoneNumberErrorMsg("Something went wrong, try again.")
                setPhoneNumberErrorCode("ERR_FINDUKU_8000")                 
            }
            setPhoneNumberError(true);
        } finally {
            setIsSavingPhone(false);            
        }
    }

    const updatePhone = () => {
        setUpdatedPhone(settingsData.phone_number || '');
        setIsUpdatingPhone(true);
    }

    const handleUpdatingPhone = (event) => {
        setUpdatedPhone(event.target.value || '');
    }

    const cancelUpdatePhone = () => {
        setUpdatedPhone(settingsData.phone_number || '');
        setIsUpdatingPhone(false);
    }

    const saveUpdatePhone = async () => {
        setIsSavingPhone(true)
        setIsUpdatingPhone(false)
        try {
            const res = await axios.post(
                ADDING_AND_UPDATING_PHONE_ENDPOINT,
                JSON.stringify({ email: settingsData.email, phone_number: updatedPhone }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }  
                }
            );
            if (res.data.success) {
                dispatch(setPhoneNumber(res.data.phone_number));
                setIsSavingPhone(false);
            } else {
                console.error('Invalid data received', res.data);
                setPhoneNumberError(true);
                setPhoneNumberErrorMsg("Something went wrong, try again.");
                setPhoneNumberErrorCode("ERR_FINDUKU_2000");
            }
        } catch (error) {
            console.error('Failed to update phone number', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setPhoneNumberErrorMsg("Something went wrong, try again.")
                        setPhoneNumberErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setPhoneNumberErrorMsg("Something went wrong, try again.")
                        setPhoneNumberErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setPhoneNumberErrorMsg("Something went wrong, try again.")
                        setPhoneNumberErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setPhoneNumberErrorMsg("Something went wrong, try again.")
                        setPhoneNumberErrorCode("ERR_FINDUKU_6000")      
                        break;
                    case 500:         
                        setPhoneNumberErrorMsg("Something went wrong, try again.")
                        setPhoneNumberErrorCode("ERR_FINDUKU_7000") 
                        break;
                    default:
                        setPhoneNumberErrorMsg("Something went wrong, try again.")
                        setPhoneNumberErrorCode("ERR_FINDUKU_1000")                                
                }
            } else {
                setPhoneNumberErrorMsg("Something went wrong, try again.")
                setPhoneNumberErrorCode("ERR_FINDUKU_8000")                 
            }
            setPhoneNumberError(true);
        } finally {
            setIsSavingPhone(false);
        }
    }
    // ====== END OF ADDING PHONE NUMBER LOGIC


    // ====== START OF CHANGING PASSWORD LOGIC
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
    const [submittingNewPassword, setSubmittingNewPassword] = useState(false)
    const [confirmingNewPassword, setConfirmingNewPassword] = useState(false)
    const [confirmingNewPasswordOTP, setConfirmingNewPasswordOTP] = useState(false)
    const [wrongOldPassword, setWrongOldPassword] = useState(false)
    const [changePasswordSuccessful, setChangePasswordSuccessful] = useState(false)

    const [wrongPasswordOTP, setWrongPasswordOTP] = useState(false);
    const [expiredPasswordOTP, setExpiredPasswordOTP] = useState(false)
    const [passwordOtpError, setPasswordOtpError] = useState(null);
    const [resendPasswordOTPSuccessful, setResendPasswordOTPSuccessful] = useState(false);

    const [changePasswordError, setChangePasswordError] = useState(false)
    const [changePasswordErrorMsg, setChangePasswordErrorMsg] = useState(null)
    const [changePasswordErrorAction, setChangePasswordErrorAction] = useState(null)
    const [changePasswordErrorCode, setChangePasswordErrorCode] = useState(null)

    const [passwordData, setPasswordData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        otp: '',
    })

    const [email, setEmail] = useState(null);

    const displayChangePasswordModal = () => {
        setShowChangePasswordModal(true)
    }

    const hideChangePasswordModal = () => {
        setShowChangePasswordModal(false)
        setSubmittingNewPassword(false)
        setWrongOldPassword(false)
        setChangePasswordError(false)
        setPasswordData({
            oldPassword: '',
            newPassword: '',
            confirmPassword: '', 
            otp: '',          
        })
    }

    const resetWrongOldPasswordMsg = () => {
        setWrongOldPassword(false);
    };

    const resetInvalidPasswordOTPMsg = () => {
        setResendPasswordOTPSuccessful(false);
        setWrongPasswordOTP(false);
        setExpiredPasswordOTP(false);
        setPasswordOtpError(null);
    }
    
    // Updates the Change Password Data on every keystroke
    const updateChangePasswordData = (updatedData) => {
        setPasswordData(updatedData);
    };

    const submitNewPassword = async() => {
        setSubmittingNewPassword(true)
        try {
            const res = await axios.post(
                UPDATING_PASSWORD_ENDPOINT,
                JSON.stringify({ 
                    old_password: passwordData.oldPassword, 
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }                      
                }
            );
            if (res.data.success) {
                setConfirmingNewPassword(true)
                setEmail(res.data.email);
            } else if (res.data.incorrect_password) {
                setWrongOldPassword(true)
            } else {
                console.error('Invalid data received', res.data)
                setChangePasswordError(true);
                setChangePasswordErrorMsg("Oops! We encountered an error.")
                setChangePasswordErrorAction("Please try again or contact support.")
                setChangePasswordErrorCode("ERR_FINDUKU_2000")
            }
        } catch (error) {
            console.error('Failed to change password', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setChangePasswordErrorMsg("Oops! We encountered an error");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setChangePasswordErrorMsg("Oops! We encountered an error");
                setChangePasswordErrorAction("Please try again or contact support.")
                setChangePasswordErrorCode("ERR_FINDUKU_8000") 
            }           
            setChangePasswordError(true);       
        } finally {
            setSubmittingNewPassword(false)
        }
    }

    const confirmChangePassword = async () => {
        setConfirmingNewPasswordOTP(true)
        try {
            const res = await axios.post(
                UPDATING_PASSWORD_OTP_ENDPOINT,
                JSON.stringify({
                    old_password : passwordData.oldPassword,
                    new_password : passwordData.newPassword,
                    otp : passwordData.otp,
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                setChangePasswordSuccessful(true);
                setConfirmingNewPassword(false);
            } else if (res.data.wrong_otp){
                setWrongPasswordOTP(true);
                setExpiredPasswordOTP(false);
                setPasswordOtpError("The verification code you entered is incorrect.")
            } else if (res.data.expired_otp){
                setExpiredPasswordOTP(true);
                setWrongPasswordOTP(false);
                setPasswordOtpError("The verification code you entered has expired.") 
            } else {
                console.error('Invalid data received', res.data)
                setChangePasswordError(true);
                setChangePasswordErrorMsg("Oops! We encountered an error.")
                setChangePasswordErrorAction("Please try again or contact support.")
                setChangePasswordErrorCode("ERR_FINDUKU_2000")
            }
        } catch (error) {
            console.error('Failed to confirm password update', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        dispatch(toggleUnauthorizedError(true))
                        setChangePasswordErrorMsg("Oops! Authorization is required.");
                        setChangePasswordErrorAction("Please login your account.")
                        setChangePasswordErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setChangePasswordErrorMsg("Oops! We encountered an error");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setChangePasswordErrorMsg("Oops! We encountered an error");
                setChangePasswordErrorAction("Please try again or contact support.")
                setChangePasswordErrorCode("ERR_FINDUKU_8000") 
            }           
            setChangePasswordError(true); 
        } finally {
            setConfirmingNewPasswordOTP(false)
        }
    }
    
    // This function handles sending request to resend OTP
    const resendNewPasswordOTP = async (regData) => {
        setConfirmingNewPasswordOTP(true)
        setWrongPasswordOTP(false);
        setExpiredPasswordOTP(false);
        setPasswordOtpError(null);
        try {
            const res = await axios.post(
                RESEND_NEW_PASSWORD_OTP_ENDPOINT,
                JSON.stringify({ 
                    email: user_email, 
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success){
                setResendPasswordOTPSuccessful(true)
            } else {
                console.error('Invalid data received', res.data)
                setChangePasswordError(true);
                setChangePasswordErrorMsg("Oops! We encountered an error.")
                setChangePasswordErrorAction("Please try again or contact support.")
                setChangePasswordErrorCode("ERR_FINDUKU_2000")                
            }

        } catch (error) {
            console.error('Something went wrong requesting resend OTP', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setChangePasswordErrorMsg("Oops! We encountered an error.");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setChangePasswordErrorMsg("Oops! We encountered an error");
                        setChangePasswordErrorAction("Please try again or contact support.")
                        setChangePasswordErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setChangePasswordErrorMsg("Oops! We encountered an error");
                setChangePasswordErrorAction("Please try again or contact support.")
                setChangePasswordErrorCode("ERR_FINDUKU_8000") 
            }                     
            setChangePasswordError(true)
        } finally {
            setConfirmingNewPasswordOTP(false)
        }
    };
    // ====== END OF CHANGING PASSWORD LOGIC

    const [showComingSoonModal, setShowComingSoonModal] = useState(false);
    const [showComingSoonCSIDModal, setShowComingSoonCSIDModal] = useState(false);

    const displayComingSoon = () => {
        setShowComingSoonModal(true);
    }

    const hideComingSoon = () => {
        setShowComingSoonModal(false);
    }

    const displayComingSoonCSID = () => {
        setShowComingSoonCSIDModal(true);
    }

    const hideComingSoonCSID = () => {
        setShowComingSoonCSIDModal(false);
    }

    // START OF FUNCTIONS HANDLING COMPANY LOGO UPLOADS/REMOVAL
    const [isUploadingLogo, setIsUploadingLogo] = useState(false)
    const [isRemovingLogo, setIsRemovingLogo] = useState(false)

    // Error States
    const [companyLogoError, setCompanyLogoError] =useState(false)
    const [companyLogoErrorMsg, setCompanyLogoErrorMsg] =useState(null)
    const [companyLogoErrorCode, setCompanyLogoErrorCode] =useState(null)

    const handleCompanyLogoOKButton = () => {
        setIsUploadingLogo(false);
        setIsRemovingLogo(false);
        setCompanyLogoError(false);
        setCompanyLogoErrorMsg(null);
        setCompanyLogoErrorCode(null);
    }

    // Function that uploads the company logo
    const handleLogoUpload = async (event) => {
        const file = event.target.files[0];
    
        if (!file) {
            return;
        }
    
        const formData = new FormData();
        formData.append('company_logo', file);
        formData.append('merchant_id', merchant_id);

        setIsUploadingLogo(true)
        try {
            const res = await axios.post(
                UPLOAD_LOGO_ENDPOINT,
                formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );    
            if (res.data.success) {              
                dispatch(setCompanyLogo(res.data.company_logo_url))
                // Reset the file input value
                event.target.value = null;
            } else if (res.data.user_no_perm){
                setShowNoPermModal(true);
            } else {
                console.error('Failed to upload logo', res.data);
                setCompanyLogoError(true);
                setCompanyLogoErrorMsg("Something went wrong, try again.")
                setCompanyLogoErrorCode("ERR_FINDUKU_2000")
                // Reset the file input value
                event.target.value = null;
            }
    
        } catch (error) {
            console.error('Error uploading the logo', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setCompanyLogoErrorMsg("Something went wrong, try again.");
                        setCompanyLogoErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setCompanyLogoErrorMsg("Something went wrong, try again.");
                        setCompanyLogoErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setCompanyLogoErrorMsg("Something went wrong, try again.");
                        setCompanyLogoErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setCompanyLogoErrorMsg("Something went wrong, try again.");
                        setCompanyLogoErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setCompanyLogoErrorMsg("Something went wrong, try again.");
                        setCompanyLogoErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setCompanyLogoErrorMsg("Something went wrong, try again.");
                        setCompanyLogoErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setCompanyLogoErrorMsg("Something went wrong, try again.");
                setCompanyLogoErrorCode("ERR_FINDUKU_8000") 
            }                     
            setCompanyLogoError(true);
            // Reset the file input value
            event.target.value = null;
        } finally {
            setIsUploadingLogo(false);           
        }
    }

    // Function that removes the company logo
    const handleLogoRemove = async () => {
        setIsRemovingLogo(true)
        try {
            const res = await axios.post(
                REMOVE_LOGO_ENDPOINT,  
                JSON.stringify({ merchant_id: merchant_id }), 
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                dispatch(setCompanyLogo(res.data.company_logo))
                // Reset the file input value
                if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
            } else if (res.data.user_no_perm){
                setShowNoPermModal(true);
            } else {
                console.error('Failed to remove logo', res.data);
                setCompanyLogoError(true);
                setCompanyLogoErrorMsg("Something went wrong, try again.")
                setCompanyLogoErrorCode("ERR_FINDUKU_2000")
            }
        } catch (error) {
            console.error('Error removing the logo', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setCompanyLogoErrorMsg("Something went wrong, try again.");
                        setCompanyLogoErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setCompanyLogoErrorMsg("Something went wrong, try again.");
                        setCompanyLogoErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setCompanyLogoErrorMsg("Something went wrong, try again.");
                        setCompanyLogoErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setCompanyLogoErrorMsg("Something went wrong, try again.");
                        setCompanyLogoErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setCompanyLogoErrorMsg("Something went wrong, try again.");
                        setCompanyLogoErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setCompanyLogoErrorMsg("Something went wrong, try again.");
                        setCompanyLogoErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setCompanyLogoErrorMsg("Something went wrong, try again.");
                setCompanyLogoErrorCode("ERR_FINDUKU_8000") 
            }                     
            setCompanyLogoError(true);
        } finally {
            setIsRemovingLogo(false);           
        }
    }

    const [showNoPermModal, setShowNoPermModal] = useState(false)
    const displayNoPermModal = () => {
        setShowNoPermModal(true)
    }

    const hideNoPermModal = () => {
        setShowNoPermModal(false)
    }
    // END OF FUNCTIONS HANDLING COMPANY LOGO UPLOADS/REMOVAL

    // START OF LOGIC FOR EDITING STORE NAME
    const [isEditingStoreName, setIsEditingStoreName] = useState(false);
    const [editedStoreName, setEditedStoreName] = useState(settingsData.store_name);
    const [isSavingStoreNameChanges, setIsSavingStoreNameChanges] = useState(false);

    const [storeNameError, setStoreNameError] =useState(false)
    const [storeNameErrorMsg, setStoreNameErrorMsg] =useState(null)
    const [storeNameErrorCode, setStoreNameErrorCode] =useState(null)


    const handleStoreNameOKButton = () => {
        setIsEditingStoreName(false)
        setEditedStoreName(settingsData.store_name || '');
        setIsSavingStoreNameChanges(false);
        setStoreNameError(false);
        setStoreNameErrorMsg(null);
        setStoreNameErrorCode(null);
    }


    const editStoreName = () => {
        setEditedStoreName(settingsData.store_name);
        setIsEditingStoreName(true);
    }

    const handleStoreNameChange = (event) => {
        setEditedStoreName(event.target.value);
    }

    const cancelEditStoreName = () => {
        setEditedStoreName(settingsData.store_name); 
        setIsEditingStoreName(false);
    }

    const saveEditedStoreName = async () => {
        setIsSavingStoreNameChanges(true)
        try {
            const res = await axios.post(
                SAVING_EDITED_STORE_NAME_ENDPOINT,
                JSON.stringify({ merchant_id: merchant_id, store_name: editedStoreName }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }                    
                }
            );
            if (res.data.success) {
                dispatch(setStoreName(res.data.new_store_name))
                dispatch(update_store_name(res.data.new_store_name));
                setIsEditingStoreName(false);
            } else if (res.data.user_no_perm){
                setShowNoPermModal(true);
            } else {
                console.error('Invalid data received', res.data);
                setStoreNameError(true);
                setStoreNameErrorMsg("Something went wrong, try again");
                setStoreNameErrorCode("ERR_FINDUKU_2000")
            }
        } catch (error) {
            console.error('Failed to save edited store name', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setStoreNameErrorMsg("Something went wrong, try again.")
                        setStoreNameErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setStoreNameErrorMsg("Something went wrong, try again.")
                        setStoreNameErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setStoreNameErrorMsg("Something went wrong, try again.")
                        setStoreNameErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setStoreNameErrorMsg("Something went wrong, try again.")
                        setStoreNameErrorCode("ERR_FINDUKU_6000")      
                        break;
                    case 500:         
                        setStoreNameErrorMsg("Something went wrong, try again.")
                        setStoreNameErrorCode("ERR_FINDUKU_7000") 
                        break;
                    default:
                        setStoreNameErrorMsg("Something went wrong, try again.")
                        setStoreNameErrorCode("ERR_FINDUKU_1000")                                
                }
            } else {
                setStoreNameErrorMsg("Something went wrong, try again.")
                setStoreNameErrorCode("ERR_FINDUKU_8000")                 
            }
            setStoreNameError(true);            
        } finally {
            setIsSavingStoreNameChanges(false);
        }
    }    
    // END OF LOGIC FOR EDITNG STORE NAME


    // START OF LOGIC FOR EDITNG COMPANY CONTACT INFO
    const [isEditingContactNo, setIsEditingContactNo] = useState(false);
    const [editedContactNo, setEditedContactNo] = useState(settingsData.contact_number);
    const [isSavingContactNoChanges, setIsSavingContactNoChanges] = useState(false);

    // Error States
    const [contactNumberError, setContactNumberError] =useState(false)
    const [contactNumberErrorMsg, setContactNumberErrorMsg] =useState(null)
    const [contactNumberErrorCode, setContactNumberErrorCode] =useState(null)

    const handleContactNumberOKButton = () => {
        setIsEditingContactNo(false)
        setEditedContactNo(settingsData.contact_number || '');
        setIsSavingContactNoChanges(false);
        setContactNumberError(false);
        setContactNumberErrorMsg(null);
        setContactNumberErrorCode(null);
    }

    const editContactNo = () => {
        setEditedContactNo(settingsData.contact_number);
        setIsEditingContactNo(true);
    }

    const handleContactNoChange = (event) => {
        setEditedContactNo(event.target.value);
    }

    const cancelEditContactNo = () => {
        setEditedContactNo(settingsData.contact_number); 
        setIsEditingContactNo(false);
    }

    const saveEditedContactNo = async () => {
        setIsSavingContactNoChanges(true)
        try {
            const res = await axios.post(
                SAVING_EDITED_CONTACT_NUMBER_ENDPOINT,
                JSON.stringify({ 
                    merchant_id: merchant_id, 
                    contact_number: editedContactNo }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }                    
                }
            );
            if (res.data.success) {
                dispatch(setContactNumber(res.data.newContactNumber))
                setIsEditingContactNo(false);
            } else if (res.data.user_no_perm){
                setShowNoPermModal(true);
            } else {
                console.error('Invalid data received', res.data);
                setContactNumberError(true);
                setContactNumberErrorMsg("Something went wrong, try again.");
                setContactNumberErrorCode("ERR_FINDUKU_2000");
            }
        } catch (error) {
            console.error('Failed to save edited contact number', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setContactNumberErrorMsg("Something went wrong, try again.")
                        setContactNumberErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setContactNumberErrorMsg("Something went wrong, try again.")
                        setContactNumberErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setContactNumberErrorMsg("Something went wrong, try again.")
                        setContactNumberErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setContactNumberErrorMsg("Something went wrong, try again.")
                        setContactNumberErrorCode("ERR_FINDUKU_6000")      
                        break;
                    case 500:         
                        setContactNumberErrorMsg("Something went wrong, try again.")
                        setContactNumberErrorCode("ERR_FINDUKU_7000") 
                        break;
                    default:
                        setContactNumberErrorMsg("Something went wrong, try again.")
                        setContactNumberErrorCode("ERR_FINDUKU_1000")                                
                }
            } else {
                setContactNumberErrorMsg("Something went wrong, try again.")
                setContactNumberErrorCode("ERR_FINDUKU_8000")                 
            }
            setContactNumberError(true);     
        } finally {
            setIsSavingContactNoChanges(false);
        }
    }   
    // END OF LOGIC FOR EDITNG COMPANY CONTACT INFO


    // START OF LOGIC FOR EDITNG COMPANY WEBSITE
    const [isEditingWebsite, setIsEditingWebsite] = useState(false);
    const [editedWebsite, setEditedWebsite] = useState(settingsData.website || '');
    const [isSavingWebsiteChanges, setIsSavingWebsiteChanges] = useState(false);

    const [editWebsiteError, setEditWebsiteError] =useState(false)
    const [editWebsiteErrorMsg, setEditWebsiteErrorMsg] =useState(null)
    const [editWebsiteErrorCode, setEditWebsiteErrorCode] =useState(null)

    const handleEditWebsiteOKButton = () => {
        setIsEditingWebsite(false)
        setEditedWebsite(settingsData.website || '');
        setIsSavingWebsiteChanges(false);
        setEditWebsiteError(false);
        setEditWebsiteErrorMsg(null);
        setEditWebsiteErrorCode(null);
    }

    const editWebsite = () => {
        setEditedWebsite(settingsData.website || '');
        setIsEditingWebsite(true);
    }

    const handleWebsiteChange = (event) => {
        setEditedWebsite(event.target.value || '');
    }

    const cancelEditWebsite = () => {
        setEditedWebsite(settingsData.contact_number || ''); 
        setIsEditingWebsite(false);
    }

    const saveEditedWebsite = async () => {
        setIsSavingWebsiteChanges(true)
        try {
            const res = await axios.post(
                SAVING_EDITED_WEBSITE_ENDPOINT,
                JSON.stringify({ 
                    merchant_id: merchant_id, 
                    website: editedWebsite 
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }                    
                }
            );
            if (res.data.success) {
                dispatch(setWebsite(res.data.newWebsite))
                setIsEditingWebsite(false);
            } else if (res.data.user_no_perm){
                setShowNoPermModal(true);
            } else {
                console.error('Invalid data received', res.data);
                setEditWebsiteError(true);
                setEditWebsiteErrorMsg("Something went wrong, try again");
                setEditWebsiteErrorCode("ERR_FINDUKU_2000")
            }
        } catch (error) {
            console.error('Failed to save edited website', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setEditWebsiteErrorMsg("Something went wrong, try again.")
                        setEditWebsiteErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setEditWebsiteErrorMsg("Something went wrong, try again.")
                        setEditWebsiteErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setEditWebsiteErrorMsg("Something went wrong, try again.")
                        setEditWebsiteErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setEditWebsiteErrorMsg("Something went wrong, try again.")
                        setEditWebsiteErrorCode("ERR_FINDUKU_6000")      
                        break;
                    case 500:         
                        setEditWebsiteErrorMsg("Something went wrong, try again.")
                        setEditWebsiteErrorCode("ERR_FINDUKU_7000") 
                        break;
                    default:
                        setEditWebsiteErrorMsg("Something went wrong, try again.")
                        setEditWebsiteErrorCode("ERR_FINDUKU_1000")                                
                }
            } else {
                setEditWebsiteErrorMsg("Something went wrong, try again.")
                setEditWebsiteErrorCode("ERR_FINDUKU_8000")                 
            }
            setEditWebsiteError(true);
        } finally {
            setIsSavingWebsiteChanges(false);
        }
    }   
    // END OF LOGIC FOR EDITNG COMPANY WEBSITE


    // START OF LOGIC HANDLING ADDING & UPDATING BANK ACCOUNT
    const [newBankModalOpen, setNewBankModalOpen] = useState(false)
    const [isAddingBankAccount, setIsAddingBankAccount] = useState(false)
    const [isAddBankAccountSuccessful, setIsAddBankAccountSuccessful] = useState(false)

    const [addBankError, setAddBankError] = useState(false)
    const [addBankErrorMsg, setAddBankErrorMsg] = useState(null)
    const [addBankErrorAction, setAddBankErrorAction] = useState(null)
    const [addBankErrorCode, setAddBankErrorCode] = useState(null)


    const [newBankData, setNewBankData] = useState({
        bank_code: '',
        bank_name: '',
        account_number: '',
        account_name: '',
        account_type: '',
        address_line1: '',
        address_line2: '',
        city: '',
        province: '',
        zipcode: '',
    })

    const updateNewBankData = (updatedData) => {
        setNewBankData(updatedData);
    };

    const openNewBankModal = () => {
        setNewBankModalOpen(true);
    };

    const closeNewBankModal = () => {
        setNewBankModalOpen(false)
        setNewBankData({
            bank_code: '',
            bank_name: '',
            account_number: '',
            account_name: '',
            account_type: '',
            address_line1: '',
            address_line2: '',
            city: '',
            province: '',
            zipcode: '',
        });
        setIsAddBankAccountSuccessful(false);
        setIsAddingBankAccount(false);
        setAddBankError(false);
        setAddBankErrorMsg(null);
        setAddBankErrorAction(null);
        setAddBankErrorCode(null);
    };

    const submitNewBank = async () => {
        setIsAddingBankAccount(true)
        try {
            const res = await axios.post(
                ADD_BANK_ACCOUNT_ENDPOINT,
                JSON.stringify({
                    merchant_id: merchant_id,
                    ...newBankData,
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                setIsAddBankAccountSuccessful(true);
                dispatch(setBankData({
                    bank_code: res.data.newBankAccount.bank_code,
                    bank_name: res.data.newBankAccount.bank_name,
                    account_name: res.data.newBankAccount.account_name,
                    account_number: res.data.newBankAccount.account_number,
                    account_number_4digits: res.data.newBankAccount.account_number_4digits,
                    account_type: res.data.newBankAccount.account_type,
                    address_line1: res.data.newBankAccount.address_line1,
                    address_line2: res.data.newBankAccount.address_line2,
                    city: res.data.newBankAccount.city,
                    province: res.data.newBankAccount.province,
                    zipcode: res.data.newBankAccount.zipcode,
                }));
            } else if (res.data.user_no_perm){
                setShowNoPermModal(true);    
            } else {
                console.error('Invalid data received', res.data)
                setAddBankError(true);
                setAddBankErrorMsg("Oops! We encountered an error.")
                setAddBankErrorAction("Please try again or contact support.")
                setAddBankErrorCode("ERR_FINDUKU_2000")                
            }
        } catch (error) {
            console.error('Something went wrong when adding bank account', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setAddBankErrorMsg("Oops! We encountered an error.");
                        setAddBankErrorAction("Please try again or contact support.")
                        setAddBankErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setAddBankErrorMsg("Oops! Authorization is required.");
                        setAddBankErrorAction("Please login your account.")
                        setAddBankErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setAddBankErrorMsg("Oops! We encountered an error.");
                        setAddBankErrorAction("Please try again or contact support.")
                        setAddBankErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setAddBankErrorMsg("Oops! We encountered an error.");
                        setAddBankErrorAction("Please try again or contact support.")
                        setAddBankErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setAddBankErrorMsg("Oops! We encountered an error.");
                        setAddBankErrorAction("Please try again or contact support.")
                        setAddBankErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setAddBankErrorMsg("Oops! We encountered an error");
                        setAddBankErrorAction("Please try again or contact support.")
                        setAddBankErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setAddBankErrorMsg("Oops! We encountered an error");
                setAddBankErrorAction("Please try again or contact support.")
                setAddBankErrorCode("ERR_FINDUKU_8000") 
            } 
            setAddBankError(true)    
        } finally {
            setIsAddingBankAccount(false);
        }
    }

    const [updateBankModalOpen, setUpdateBankModalOpen] = useState(false)
    const [isUpdateBankSuccessful, setIsUpdateBankSuccessful] = useState(false)
    const [isUpdatingBankAccount, setIsUpdatingBankAccount] = useState(false)
    const [bankFormData, setBankFormData] = useState(bankData);

    const [updateBankError, setUpdateBankError] = useState(false)
    const [updateBankErrorMsg, setUpdateBankErrorMsg] = useState(null)
    const [updateBankErrorAction, setUpdateBankErrorAction] = useState(null)
    const [updateBankErrorCode, setUpdateBankErrorCode] = useState(null)

    const updateBankData = (updatedData) => {
        setBankFormData(updatedData);
    };

    const openUpdateBankModal = () => {
        setBankFormData(bankData);
        setUpdateBankModalOpen(true);
    };

    const closeUpdateBankModal = () => {
        setUpdateBankModalOpen(false);
        setIsUpdateBankSuccessful(false);
        setIsUpdatingBankAccount(false);
        setUpdateBankError(false);
        setUpdateBankErrorMsg(null);
        setUpdateBankErrorAction(null);
        setUpdateBankErrorCode(null);
    };
    
    const submitUpdatedBankData = async () => {
        setIsUpdatingBankAccount(true)
        try {
            const res = await axios.post(
                UPDATE_BANK_ACCOUNT_ENDPOINT,
                JSON.stringify({
                    merchant_id: merchant_id,
                    account_name: bankFormData.account_name,
                    account_number: bankFormData.account_number,
                    account_type: bankFormData.account_type,
                    bank_code: bankFormData.bank_code,
                    bank_name: bankFormData.bank_name,
                    address_line1: bankFormData.address_line1,
                    address_line2: bankFormData.address_line2,
                    city: bankFormData.city,
                    province: bankFormData.province,
                    zipcode: bankFormData.zipcode,
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }
                }
            );
            if (res.data.success) {
                setIsUpdateBankSuccessful(true)
                dispatch(setBankData({
                    bank_code: res.data.updatedBankAccount.bank_code,
                    bank_name: res.data.updatedBankAccount.bank_name,
                    account_name: res.data.updatedBankAccount.account_name,
                    account_number: res.data.updatedBankAccount.account_number,
                    account_number_4digits: res.data.updatedBankAccount.account_number_4digits,
                    account_type: res.data.updatedBankAccount.account_type,
                    address_line1: res.data.updatedBankAccount.address_line1,
                    address_line2: res.data.updatedBankAccount.address_line2,
                    city: res.data.updatedBankAccount.city,
                    province: res.data.updatedBankAccount.province,
                    zipcode: res.data.updatedBankAccount.zipcode,
                }));
            } else if (res.data.user_no_perm){
                setShowNoPermModal(true);
            } else {
                console.error('Invalid data received', res.data);
                setUpdateBankError(true);
                setUpdateBankErrorMsg("Oops! We encountered an error.")
                setUpdateBankErrorAction("Please try again or contact support.")
                setUpdateBankErrorCode("ERR_FINDUKU_2000")  
            }
            setIsUpdatingBankAccount(false)
        } catch (error) {
            console.error('Failed to update bank account', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setUpdateBankErrorMsg("Oops! We encountered an error.");
                        setUpdateBankErrorAction("Please try again or contact support.")
                        setUpdateBankErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setUpdateBankErrorMsg("Oops! We encountered an error");
                        setUpdateBankErrorAction("Please try again or contact support.")
                        setUpdateBankErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setUpdateBankErrorMsg("Oops! We encountered an error.");
                        setUpdateBankErrorAction("Please try again or contact support.")
                        setUpdateBankErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setUpdateBankErrorMsg("Oops! We encountered an error.");
                        setUpdateBankErrorAction("Please try again or contact support.")
                        setUpdateBankErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setUpdateBankErrorMsg("Oops! We encountered an error.");
                        setUpdateBankErrorAction("Please try again or contact support.")
                        setUpdateBankErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setUpdateBankErrorMsg("Oops! We encountered an error");
                        setUpdateBankErrorAction("Please try again or contact support.")
                        setUpdateBankErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setUpdateBankErrorMsg("Oops! We encountered an error");
                setUpdateBankErrorAction("Please try again or contact support.")
                setUpdateBankErrorCode("ERR_FINDUKU_8000") 
            } 
            setAddBankError(true)   
        } finally {
            setIsUpdatingBankAccount(false);
        }
    }
    // END OF LOGIC HANDLING ADDING & UPDATING BANK ACCOUNT


    // START OF LOGIC HANDLING ADDING NEW USERS
    const [newUserData, setNewUserData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        can_manage_users: false,
        can_manage_business: false,
        can_manage_bank_account: false,
        can_manage_payouts: false,
    })

    const [newUserName, setNewUserName] = useState([])
    const [showAddUser, setShowAddUser] = useState(false)
    const [userExists, setUserExists] = useState(false);
    const [addUserModalActive, setAddUserModalActive] = useState(false)
    const [submittingNewUser, setSubmittingNewUser] = useState(false)
    const [submitNewUserSuccess, setSubmitNewUserSuccess] = useState(false)

    const [addUserError, setAddUserError] = useState(false)
    const [addUserErrorMsg, setAddUserErrorMsg] = useState(null)
    const [addUserErrorAction, setAddUserErrorAction] = useState(null)
    const [addUserErrorCode, setAddUserErrorCode] = useState(null)

    const openAddUserModal = () => {
        setShowAddUser(true);
        setAddUserModalActive(true);
    }

    const resetUserExistsMsg = () => {
        setUserExists(false);
    };

    const closeAddUserModal = () => {
        setShowAddUser(false);
        setAddUserModalActive(false);
        setNewUserData({
            first_name: '',
            last_name: '',
            email: '',
            can_manage_users: false,
            can_manage_business: false,
            can_manage_bank_account: false,
            can_manage_payouts: false,
        });
        setSubmitNewUserSuccess(false);
        setSubmittingNewUser(false);
        setAddUserError(false);
        setAddUserErrorMsg(null);
        setAddUserErrorAction(null);
        setAddUserErrorCode(null);
    };

    const addAnotherUser = () => {
        setShowAddUser(true);
        setAddUserModalActive(true);
        setSubmitNewUserSuccess(false);
        setNewUserData({
            first_name: '',
            last_name: '',
            email: '',
            can_manage_users: false,
            can_manage_business: false,
            can_manage_bank_account: false,
            can_manage_payouts: false,
        });
    } 

    const updateNewUserData = (updatedData) => {
        setNewUserData(updatedData);
    };
    
    const submitNewUser = async() => {
        setSubmittingNewUser(true);

        // Collect keys of true permissions
        const truePermissions = Object.keys(newUserData).filter(key => 
            newUserData[key] === true && key.startsWith("can_")
        );

        try {
            const res = await axios.post(
                ADDING_NEW_USER_ENDPOINT,
                JSON.stringify({ 
                    merchant_id: merchant_id,
                    first_name: newUserData.first_name,
                    last_name: newUserData.last_name,
                    email: newUserData.email,  
                    permissions: truePermissions,
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }  
                }
            );
            if (res.data.success) {
                const fullName = `${res.data.newUser.first_name} ${res.data.newUser.last_name}`;
                setNewUserName(fullName);
                setSubmitNewUserSuccess(true);
                dispatch(setNewUser(res.data.newUser))
            } else if (res.data.user_exists){
                setUserExists(true);
            } else if (res.data.user_no_perm){
                setShowNoPermModal(true)
            } else {
                console.error('Invalid data received', res.data)
                setAddUserError(true);
                setAddUserErrorMsg("Oops! We encountered an error.")
                setAddUserErrorAction("Please try again or contact support.")
                setAddUserErrorCode("ERR_FINDUKU_2000")                
            }
        } catch (error) {
            console.error('Something went wrong when adding an account user', error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setAddUserErrorMsg("Oops! We encountered an error.");
                        setAddUserErrorAction("Please try again or contact support.")
                        setAddBankErrorCode("ERR_FINDUKU_3000")
                        break;
                    case 401:
                        setAddUserErrorMsg("Oops! Authorization is required.");
                        setAddUserErrorAction("Please login your account.")
                        setAddUserErrorCode("ERR_FINDUKU_4000")
                        break;
                    case 403:
                        setAddUserErrorMsg("Oops! We encountered an error.");
                        setAddUserErrorAction("Please try again or contact support.")
                        setAddUserErrorCode("ERR_FINDUKU_5000")
                        break;
                    case 404:
                        setAddUserErrorMsg("Oops! We encountered an error.");
                        setAddUserErrorAction("Please try again or contact support.")
                        setAddUserErrorCode("ERR_FINDUKU_6000")
                        break;
                    case 500:
                        setAddUserErrorMsg("Oops! We encountered an error.");
                        setAddUserErrorAction("Please try again or contact support.")
                        setAddUserErrorCode("ERR_FINDUKU_7000")
                        break;
                    default:
                        setAddUserErrorMsg("Oops! We encountered an error");
                        setAddUserErrorAction("Please try again or contact support.")
                        setAddUserErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setAddUserErrorMsg("Oops! We encountered an error");
                setAddUserErrorAction("Please try again or contact support.")
                setAddUserErrorCode("ERR_FINDUKU_8000") 
            } 
            setAddUserError(true)  
        } finally {
            setSubmittingNewUser(false);
        }
    }
    // END OF LOGIC HANDLING ADDING NEW USERS

    // START OF LOGIC HANDLING EDITING ACCOUNT USERS
    const [showEditUser, setShowEditUser] = useState(false)
    const [submittingEditedUser, setSubmittingEditUser] = useState(false)
    const [submitEditUserSuccess, setSubmitEditUserSuccess] = useState(false)
    const [selectedUserIdToEdit, setSelectedUserIdToEdit] = useState(null);
    const [editedUserData, setEditedUserData] = useState({
        name: '',
        email: '',
        can_manage_users: false,
        can_manage_business: false,
        can_manage_bank_account: false,
        can_manage_payouts: false,
    })

    const updateEditedUserData = (updatedData) => {
        setEditedUserData(updatedData);
    };

    const closeEditUserModal = () => {
        setShowEditUser(false);
        setEditedUserData({
            name: '',
            email: '',
            can_manage_users: false,
            can_manage_business: false,
            can_manage_bank_account: false,
            can_manage_payouts: false,
        });
        setSubmitEditUserSuccess(false);
        setSelectedUserIdToEdit(null);
    };

    const handleEditClick = (user) => {
        setSelectedUserIdToEdit(user.user_id);
        const full_name = `${user.first_name} ${user.last_name}`.trim();

        // Initialize a new object to store the edited user data
        const newEditedUserData = {
            name: full_name,
            email: user.email,
            can_manage_users: false,
            can_manage_business: false,
            can_manage_bank_account: false,
            can_manage_payouts: false,
        };
    
        // Process permissions
        user.permissions.forEach(permission => {
            // Extract the relevant part of the permission string
            // It assumes the format is "apps.permissionName"
            let permissionKey = permission.replace('apps.', '');
    
            // Check if this permission exists in the editedUserData and set it to true
            if (permissionKey in newEditedUserData) {
                newEditedUserData[permissionKey] = true;
            }
        });
    
        // Update the editedUserData state
        setEditedUserData(newEditedUserData);
        setShowEditUser(true);
    };

    const submitEditedUser = async() => {
        setSubmittingEditUser(true)

        try {
            const res = await axios.post(
                EDITING_ACCOUNT_USER_ENDPOINT,
                JSON.stringify({ 
                    merchant_id: merchant_id,
                    email: editedUserData.email,  
                    can_manage_users: editedUserData.can_manage_users,
                    can_manage_business: editedUserData.can_manage_business,
                    can_manage_bank_account: editedUserData.can_manage_bank_account,
                    can_manage_payouts: editedUserData.can_manage_payouts,
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }  
                }
            );
            if (res.data.success) {
                console.log(res.data.permissions)
                dispatch(updateUserPermissions({
                    userID: res.data.userID,
                    permissions: res.data.permissions
                }));
                dispatch(updateUserPermissions(res.data));
                setSubmitEditUserSuccess(true);
            } else if (res.data.user_no_perm){
                setShowNoPermModal(true);
            } else {
                console.error('Invalid data received', res.data);
            }
            setSubmittingEditUser(false);
        } catch (error) {
            console.error('Failed to edit an account user', error);
            setSubmittingEditUser(false);
        }
    }
    // END OF LOGIC HANDLING EDITING ACCOUNT USERS

    // START OF LOGIC HANDLING REMOVING ACCOUNT USERS
    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const [selectedUserToDelete, setSelectedUserToDelete] = useState(null);
    const [selectedUserIdToDelete, setSelectedUserIdToDelete] = useState(null);
    const [submittingRemoveUser, setSubmittingRemoveUser] = useState(false);
    const [removingUserSuccess, setRemovingUserSuccess] = useState(false);

    const handleDeleteUserClick = (user) => {
        const full_name = `${user.first_name} ${user.last_name}`.trim();
        setSelectedUserIdToDelete(user.user_id);
        setSelectedUserToDelete(full_name)
        setShowDeleteUser(true);
    };

    const closeDeleteUserModal = () => {
        setShowDeleteUser(false);
        setSelectedUserToDelete(null);
        setSelectedUserIdToDelete(null);
        setRemovingUserSuccess(false);
    }

    const submitRemoveUser = async() => {
        setSubmittingRemoveUser(true)

        try {
            const res = await axios.post(
                REMOVE_ACCOUNT_USER_ENDPOINT,
                JSON.stringify({ 
                    user_id: selectedUserIdToDelete,
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken' : Cookies.get('csrftoken')
                    }  
                }
            );
            if (res.data.success) {
                dispatch(removeUser(res.data.userId))
                setRemovingUserSuccess(true);
            } else if (res.data.user_no_perm){
                setShowNoPermModal(true);
            } else {
                console.error('Invalid data received', res.data);
            }
            setSubmittingRemoveUser(false);
        } catch (error) {
            console.error('Failed to remove an account user', error);
            setSubmittingRemoveUser(false);
        }
    }
    // END OF LOGIC HANDLING REMOVING ACCOUNT USERS


    return (
        <>
            <div className="settings">
                <div className="settings__page-container">
                    <div className="settings__header">
                        <div className="settings__header__topbar">
                            <div className="settings-title-wrapper">
                                <p className="title">Settings</p>
                            </div>
                        </div>
                    </div>
                    <div className="settings__body">
                        <div className="settings__menu-area">
                            <div className="menu-item-container">
                                <div className="title-wrapper">
                                    <p className="title">PROFILE SETTINGS</p>
                                </div>
                                <div className="settings__menu-wrapper">
                                    <NavLink 
                                        to="/settings"
                                        className={selectedMenuItem === 'user-information' ? "settings__nav-active" : "settings__nav-item"}
                                        onClick={() => handleMenuItemClick('user-information')}
                                    >
                                        User Information
                                    </NavLink>
                                    <NavLink 
                                        to="/settings/Security"
                                        className={selectedMenuItem === 'security' ? "settings__nav-active" : "settings__nav-item"}
                                        onClick={() => handleMenuItemClick('security')}
                                    >
                                        Security and Login
                                    </NavLink>
                                </div>
                            </div>
                            <div className="menu-item-container">
                                <div className="title-wrapper">
                                    <p className="title">BUSINESS SETTINGS</p>
                                </div>
                                <div className="settings__menu-wrapper">
                                    <NavLink 
                                        to="/settings/BusinessProfile"
                                        className={selectedMenuItem === 'business-profile' ? "settings__nav-active" : "settings__nav-item"}
                                        onClick={() => handleMenuItemClick('business-profile')}
                                    >
                                        Business Profile
                                    </NavLink>
                                    <NavLink 
                                        to="/settings/LegalInformation"
                                        className={selectedMenuItem === 'legal-information' ? "settings__nav-active" : "settings__nav-item"}
                                        onClick={() => handleMenuItemClick('legal-information')}
                                    >
                                        Legal Information
                                    </NavLink>
                                    <NavLink 
                                        to="/settings/PayoutSettings"
                                        className={selectedMenuItem === 'payout-settings' ? "settings__nav-active" : "settings__nav-item"}
                                        onClick={() => handleMenuItemClick('payout-settings')}
                                    >
                                        Payout Settings 
                                    </NavLink>
                                </div>
                            </div>
                            <div className="menu-item-container">
                                <div className="title-wrapper">
                                    <p className="title">ACCOUNT SETTINGS</p>
                                </div>
                                <div className="settings__menu-wrapper">
                                    <NavLink 
                                        to="/settings/ManageUsers"
                                        className={selectedMenuItem === 'manage-users' ? "settings__nav-active" : "settings__nav-item"}
                                        onClick={() => handleMenuItemClick('manage-users')}
                                    >
                                        Manage Users
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="settings__content-area">
                            {selectedMenuItem === 'user-information' && 
                                <UserInfo  
                                    settingsData={settingsData}
                                    full_name={full_name} 
                                    onImageUpload={handleImageUpload}
                                    isUploadingPhoto={isUploadingPhoto}
                                    onImageRemove={handleImageRemove}
                                    isRemovingPhoto={isRemovingPhoto}
                                    isLoadingSettingPage={pageLoading}
                                    handleProfilePhotoOKButton={handleProfilePhotoOKButton}
                                    showChangeEmailModal={showChangeEmailModal}
                                    changeEmailModalOpen={changeEmailModalOpen}
                                    addedAddress={addedAddress}
                                    isAddingAddress={isAddingAddress}
                                    handleAddingAddress={handleAddingAddress}
                                    addAddress={addAddress}
                                    cancelAddAddress={cancelAddAddress}
                                    saveAddAddress={saveAddAddress}
                                    isSavingAddress={isSavingAddress}
                                    updateAddress={updateAddress}
                                    updatedAddress={updatedAddress}
                                    isUpdatingAddress={isUpdatingAddress}
                                    handleUpdatingAddress={handleUpdatingAddress}
                                    cancelUpdateAddress={cancelUpdateAddress}
                                    saveUpdateAddress={saveUpdateAddress}
                                    addedPhone={addedPhone}
                                    isAddingPhone={isAddingPhone}
                                    isSavingPhone={isSavingPhone}
                                    addPhone={addPhone}
                                    handleAddingPhone={handleAddingPhone}
                                    cancelAddPhone={cancelAddPhone}
                                    saveAddPhone={saveAddPhone}
                                    updatedPhone={updatedPhone}
                                    isUpdatingPhone={isUpdatingPhone}
                                    updatePhone={updatePhone}
                                    handleUpdatingPhone={handleUpdatingPhone}
                                    cancelUpdatePhone={cancelUpdatePhone}
                                    saveUpdatePhone={saveUpdatePhone}
                                    error={error}
                                    profilePhotoError={profilePhotoError}
                                    profilePhotoErrorMsg={profilePhotoErrorMsg}
                                    profilePhotoErrorCode={profilePhotoErrorCode}
                                    addressError={addressError}
                                    addressErrorMsg={addressErrorMsg}
                                    addressErrorCode={addressErrorCode}
                                    handleAddressOKButton={handleAddressOKButton}
                                    phoneNumberError={phoneNumberError}
                                    phoneNumberErrorMsg={phoneNumberErrorMsg}
                                    phoneNumberErrorCode={phoneNumberErrorCode}
                                    handlePhoneNumberOKButton={handlePhoneNumberOKButton}
                                    editName={editName}
                                    showEditNameModal={showEditNameModal}
                                    fileInputRef={fileInputRef}
                                />
                            }
                            {selectedMenuItem === 'security' && 
                                <LoginSecurity 
                                    settingsData={settingsData}
                                    isLoadingSettingPage={pageLoading} 
                                    showChangeEmailModal={showChangeEmailModal}
                                    changeEmailModalOpen={changeEmailModalOpen}
                                    isUpdatingPhone={isUpdatingPhone}
                                    updatedPhone={updatedPhone}
                                    handleUpdatingPhone={handleUpdatingPhone}
                                    cancelUpdatePhone={cancelUpdatePhone}
                                    saveUpdatePhone={saveUpdatePhone}
                                    isSavingPhone={isSavingPhone}
                                    isAddingPhone={isAddingPhone}
                                    addedPhone={addedPhone}
                                    handleAddingPhone={handleAddingPhone}
                                    cancelAddPhone={cancelAddPhone}
                                    saveAddPhone={saveAddPhone}
                                    addPhone={addPhone}
                                    updatePhone={updatePhone}
                                    displayChangePasswordModal={displayChangePasswordModal}
                                    showChangePasswordModal={showChangePasswordModal}
                                    phoneNumberError={phoneNumberError}
                                    phoneNumberErrorMsg={phoneNumberErrorMsg}
                                    phoneNumberErrorCode={phoneNumberErrorCode}
                                    handlePhoneNumberOKButton={handlePhoneNumberOKButton}
                                    displayComingSoon={displayComingSoon}
                                    displayComingSoonCSID={displayComingSoonCSID}
                                    showComingSoonModal={showComingSoonModal}
                                    showComingSoonCSIDModal={showComingSoonCSIDModal}
                                />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
                            }
                            {selectedMenuItem === 'business-profile' && 
                                <BusinesProfile 
                                    settingsData={settingsData} 
                                    onLogoUpload={handleLogoUpload}
                                    onLogoRemove={handleLogoRemove}
                                    handleCompanyLogoOKButton={handleCompanyLogoOKButton}
                                    isEditingStoreName={isEditingStoreName}
                                    editStoreName={editStoreName}
                                    editedStoreName={editedStoreName}
                                    handleStoreNameChange={handleStoreNameChange}
                                    cancelEditStoreName={cancelEditStoreName}
                                    saveEditedStoreName={saveEditedStoreName}   
                                    isSavingStoreNameChanges={isSavingStoreNameChanges}
                                    isLoadingSettingPage={pageLoading}
                                    loggedInUserPerm={loggedInUserPerm}
                                    displayNoPermModal={displayNoPermModal}
                                    isSavingContactNoChanges={isSavingContactNoChanges}
                                    isEditingContactNo={isEditingContactNo}
                                    editContactNo={editContactNo}
                                    editedContactNo={editedContactNo}
                                    handleContactNoChange={handleContactNoChange}
                                    cancelEditContactNo={cancelEditContactNo}
                                    saveEditedContactNo={saveEditedContactNo}
                                    isSavingWebsiteChanges={isSavingWebsiteChanges}
                                    isEditingWebsite={isEditingWebsite}
                                    editWebsite={editWebsite}
                                    editedWebsite={editedWebsite}
                                    handleWebsiteChange={handleWebsiteChange}
                                    cancelEditWebsite={cancelEditWebsite}
                                    saveEditedWebsite={saveEditedWebsite}
                                    isUploadingLogo={isUploadingLogo}
                                    isRemovingLogo={isRemovingLogo}
                                    companyLogoError={companyLogoError}
                                    companyLogoErrorMsg={companyLogoErrorMsg}
                                    companyLogoErrorCode={companyLogoErrorCode}
                                    fileInputRef={fileInputRef}
                                    storeNameError={storeNameError}
                                    storeNameErrorMsg={storeNameErrorMsg}
                                    storeNameErrorCode={storeNameErrorCode}
                                    handleStoreNameOKButton={handleStoreNameOKButton}
                                    contactNumberError={contactNumberError}
                                    contactNumberErrorMsg={contactNumberErrorMsg}
                                    contactNumberErrorCode={contactNumberErrorCode}
                                    handleContactNumberOKButton={handleContactNumberOKButton}
                                    editWebsiteError={editWebsiteError}
                                    editWebsiteErrorMsg={editWebsiteErrorMsg}
                                    editWebsiteErrorCode={editWebsiteErrorCode}
                                    handleEditWebsiteOKButton={handleEditWebsiteOKButton}
                                />
                            }
                            {selectedMenuItem === 'legal-information' && 
                                <LegalInfo 
                                    settingsData={settingsData}
                                    isLoadingSettingPage={pageLoading} 
                                />
                            }
                            {selectedMenuItem === 'payout-settings' && 
                                <PayoutInfo 
                                    settingsData={settingsData} 
                                    openNewBankModal={openNewBankModal} 
                                    openUpdateBankModal={openUpdateBankModal}
                                    isLoadingSettingPage={pageLoading}
                                    newBankModalOpen={newBankModalOpen}
                                    updateBankModalOpen={updateBankModalOpen}
                                    displayNoPermModal={displayNoPermModal}
                                    loggedInUserPerm={loggedInUserPerm}
                                />
                            }
                            {selectedMenuItem === 'manage-users' && 
                                <ManageUsers 
                                    settingsData={settingsData} 
                                    isLoadingSettingPage={pageLoading}
                                    usersData={usersData}
                                    openAddUserModal={openAddUserModal}
                                    addUserModalActive={addUserModalActive}
                                    handleEditClick={handleEditClick}
                                    selectedUserIdToEdit={selectedUserIdToEdit}
                                    handleDeleteUserClick={handleDeleteUserClick}
                                    selectedUserIdToDelete={selectedUserIdToDelete}
                                    loggedInUserPerm={loggedInUserPerm}
                                    displayNoPermModal={displayNoPermModal}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {newBankModalOpen && (
                <AddBankAccount 
                    newBankData={newBankData} 
                    submitNewBank={submitNewBank} 
                    isAddBankAccountSuccessful={isAddBankAccountSuccessful}
                    updateNewBankData={updateNewBankData}
                    onClose={closeNewBankModal} 
                    isAddingBankAccount={isAddingBankAccount}
                    addBankError={addBankError}
                    addBankErrorMsg={addBankErrorMsg}
                    addBankErrorAction={addBankErrorAction}
                    addBankErrorCode={addBankErrorCode}
                />
            )}
            {updateBankModalOpen && (
                <UpdateBank 
                    bankFormData={bankFormData} 
                    onClose={closeUpdateBankModal} 
                    updateBankData={updateBankData}
                    submitUpdatedBankData={submitUpdatedBankData}
                    isUpdateBankSuccessful={isUpdateBankSuccessful}
                    isUpdatingBankAccount={isUpdatingBankAccount}
                    updateBankError={updateBankError}
                    updateBankErrorMsg={updateBankErrorMsg}
                    updateBankErrorAction={updateBankErrorAction}
                    updateBankErrorCode={updateBankErrorCode}
                />
            )}
            {changeEmailModalOpen && (
                <ChangeEmail 
                    onClose={hideChangeEmailModal}
                    oldEmail={settingsData.email}
                    changeEmailData={changeEmailData}
                    updateChangeEmailData={updateChangeEmailData}
                    submitNewEmail={submitNewEmail}
                    isChangingEmail={isChangingEmail}
                    wrongPassword={wrongPassword}
                    resetWrongPasswordMsg={resetWrongPasswordMsg}
                    changeEmailConfirmed={changeEmailConfirmed}
                    changeEmailError={changeEmailError}
                    changeEmailErrorMsg={changeEmailErrorMsg}
                    changeEmailErrorAction={changeEmailErrorAction}
                    changeEmailErrorCode={changeEmailErrorCode}
                    navigate2Login={navigate2Login}
                    userExist={userExist}
                    resetUserExistMsg={resetUserExistMsg}
                />
            )}
            {confirmingChangeEmail &&
                <ChangeEmailOTP 
                    changeEmailData={changeEmailData}
                    submitChangeEmailOTP={submitChangeEmailOTP}
                    updateChangeEmailData={updateChangeEmailData}
                    resetInvalidOTPMsg={resetInvalidOTPMsg}
                    emailToVerify={emailToVerify}
                    otpError={otpError}
                    wrongOTP={wrongOTP}
                    expiredOTP={expiredOTP}
                    resendChangeEmailOTP={resendChangeEmailOTP}
                    resendOTPSuccessful={resendOTPSuccessful}
                    isConfirmingChangeEmailOTP={isConfirmingChangeEmailOTP}
                />
            }
            {showChangePasswordModal &&
                <ChangePassword 
                    passwordData={passwordData}
                    submitNewPassword={submitNewPassword}
                    updateChangePasswordData={updateChangePasswordData}
                    hideChangePasswordModal={hideChangePasswordModal}
                    changePasswordSuccessful={changePasswordSuccessful}
                    wrongOldPassword={wrongOldPassword}
                    submittingNewPassword={submittingNewPassword}
                    changePasswordError={changePasswordError}
                    changePasswordErrorMsg={changePasswordErrorMsg}
                    changePasswordErrorAction={changePasswordErrorAction}
                    changePasswordErrorCode={changePasswordErrorCode}
                    resetWrongOldPasswordMsg={resetWrongOldPasswordMsg}
                    navigate2Login={navigate2Login}
                />
            }
            {confirmingNewPassword && (
                <ChangePasswordOTP 
                    passwordData={passwordData}
                    email={email}
                    updateChangePasswordData={updateChangePasswordData}
                    confirmingNewPasswordOTP={confirmingNewPasswordOTP}
                    confirmChangePassword={confirmChangePassword}
                    wrongPasswordOTP={wrongPasswordOTP}
                    expiredPasswordOTP={expiredPasswordOTP}
                    passwordOtpError={passwordOtpError}
                    resendNewPasswordOTP={resendNewPasswordOTP}
                    resetInvalidPasswordOTPMsg={resetInvalidPasswordOTPMsg}
                    resendPasswordOTPSuccessful={resendPasswordOTPSuccessful}
                />
            )}
            {logoutError &&
                <LogoutErrorModal 
                    logoutErrorMsg={logoutErrorMsg}
                    logoutErrorAction={logoutErrorAction}
                    logoutErrorCode={logoutErrorCode}
                    tryAgain={tryAgain}
                />
            }
            {showAddUser && (
                <AddUser 
                    newUserData={newUserData} 
                    submitNewUser={submitNewUser}
                    updateNewUserData={updateNewUserData}
                    onClose={closeAddUserModal}
                    submittingNewUser={submittingNewUser}
                    submitNewUserSuccess={submitNewUserSuccess}
                    addAnotherUser={addAnotherUser}
                    newUserName={newUserName}
                    addUserError={addUserError}
                    addUserErrorMsg={addUserErrorMsg}
                    addUserErrorAction={addUserErrorAction}
                    addUserErrorCode={addUserErrorCode}
                    userExists={userExists}
                    resetUserExistsMsg={resetUserExistsMsg}
                />
            )}
            {showEditUser && (
                <EditUser 
                    editedUserData={editedUserData}
                    submitEditedUser={submitEditedUser}
                    updateEditedUserData={updateEditedUserData}
                    onClose={closeEditUserModal}
                    submittingEditedUser={submittingEditedUser}
                    submitEditUserSuccess={submitEditUserSuccess}
                />
            )}
            {showDeleteUser &&
                <DeleteUser 
                    selectedUserToDelete={selectedUserToDelete}
                    onClose={closeDeleteUserModal}
                    submitRemoveUser={submitRemoveUser}
                    submittingRemoveUser={submittingRemoveUser}
                    removingUserSuccess={removingUserSuccess}
                />
            }
            {showEditNameModal &&
                <EditNameModal 
                    onCloseEditName={onCloseEditName}
                />
            }
            {showComingSoonModal &&
                <ComingSoon 
                    hideComingSoon={hideComingSoon}
                />
            }
            {showComingSoonCSIDModal &&
                <ComingSoonCSID 
                    hideComingSoonCSID={hideComingSoonCSID}
                />
            }
            {showNoPermModal &&
                <NoPermMsg onClose={hideNoPermModal} />
            }
            {isLoggingOut && <SettingsLoader />}
        </>
    )
}

export default Settings