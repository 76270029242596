import React from 'react';
import '../../../Styles/CompStyles/Footer/Footer.css';

function Footer() {
    return (
        <>
            <div className="footer">
                <div className="footer__page-container">
                    <div className="footer__right-section">
                        <a href="https://www.finduku.com/about" target="_blank" rel="noopener noreferrer" className="menu-link">
                            <p className="menu-name">About Us</p>
                        </a>
                        <a href="https://www.finduku.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="menu-link">
                            <p className="menu-name">Privacy Policy</p>
                        </a>
                        <a href="https://www.finduku.com/terms-of-use" target="_blank" rel="noopener noreferrer" className="menu-link">
                            <p className="menu-name">Terms of Use</p>
                        </a>
                        <a href="https://www.finduku.com/contact" target="_blank" rel="noopener noreferrer" className="menu-link">
                            <p className="menu-name">Contact Us</p>
                        </a>
                    </div>
                    <div className="footer__left-section">
                        <p className="copyright">&copy; {new Date().getFullYear()} Finduku. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer