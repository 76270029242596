import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Logo from '../../../../assets/logo.svg'
import CSRFToken from '../../../../components/CSRFToken';
import ChangeEmailLoader from './ChangeEmailLoader';
import '../../../../Styles/MainStyles/Settings/UserInfo/ChangeEmailOTP.css';

function ChangeEmailOTP({ 
    changeEmailData, 
    submitChangeEmailOTP,
    updateChangeEmailData, 
    resetInvalidOTPMsg,
    emailToVerify,
    otpError,
    wrongOTP,
    expiredOTP,
    resendChangeEmailOTP,
    resendOTPSuccessful, 
    isConfirmingChangeEmailOTP
}) {

    // Form Validation
    const validate = Yup.object().shape({
        otp: Yup.string()
            .required('Verification code is required')
            .matches(/^[0-9]\d{5}$/, {message: "Verification code must be 6 digits.", excludeEmptyString: false}),
    })

    return (
        <>
            <div className="change-email-otp" onClick={(e) => {e.stopPropagation()}}>
                <div className="change-email-otp__page-container">
                    <Formik
                        initialValues={changeEmailData}
                        onSubmit={submitChangeEmailOTP}
                        validationSchema={validate}
                    >

                        {({ handleChange, handleBlur, setFieldValue, values, errors, touched  }) => {
                            
                            const handleOtpPaste = (e, setFieldValue) => {
                                e.preventDefault();
                                const pastedData = e.clipboardData.getData("text").trim();
                                if (pastedData.length === 6 && /^\d{6}$/.test(pastedData)) {
                                    setFieldValue("otp", pastedData);
                                    updateChangeEmailData({ ...values, otp: pastedData });
                                    resetInvalidOTPMsg();
                                }
                            };

                            return (
                                <Form>
                                    <CSRFToken />

                                    {/* HEADER SECTION */}
                                    <div className="change-email-otp__header">
                                        <img src={Logo} alt="Finduku Logo"/>
                                        <p className="change-email-otp__subheading">We're just making sure it's you.</p>
                                    </div>

                                    {/* BODY SECTION */}
                                    <div className="change-email-otp__body">

                                        {resendOTPSuccessful 
                                            ?   (<div className="change-email-otp__resend-otp">
                                                    <p className="change-email-otp__resend-otp-text">A new OTP has been sent to your email.</p>
                                                </div>)
                                            :   null 
                                        }

                                        {/* DIRECTION SECTION */}
                                        <div className="change-email-otp__direction-wrapper">
                                            <p className="change-email-otp__direction-text">
                                                We sent a single-use verification code to your email, <span className="email">{emailToVerify}</span>
                                            </p>
                                        </div>

                                        {/* OTP INPUT SECTION */}
                                        <div className="change-email-otp__otp-wrapper" onPaste={(e) => handleOtpPaste(e, setFieldValue)}>
                                            <input
                                                type="text"
                                                className={`change-email-otp__otp-input ${
                                                    errors.otp && touched.otp
                                                    ? "change-email-otp__input-error"
                                                    : (wrongOTP || expiredOTP)
                                                    ? "change-email-otp__wrong-otp-error"
                                                    : null
                                                }`}
                                                name="otp"
                                                inputMode="numeric"
                                                value={values.otp}
                                                autoComplete="off"
                                                onKeyDown={(e) => {
                                                    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'v'];
                                                    const isCtrlV = (e.ctrlKey || e.metaKey) && e.key === 'v'; 
                                                
                                                    if (!/\d/.test(e.key) && !allowedKeys.includes(e.key) && !isCtrlV) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    updateChangeEmailData({ ...values, otp: e.target.value });
                                                    resetInvalidOTPMsg();
                                                }}
                                                onBlur={handleBlur}
                                                maxLength="6"
                                            /> 
                                        </div>

                                        {/* VALIDATION ERRORS SECTION */}
                                        <div className="change-email-otp__verify-error-wrapper">
                                            {errors.otp && touched.otp && <p className="confirm-login__verify-error">{errors.otp}</p>}
                                            {wrongOTP && <p className="change-email-otp__verify-error">{otpError}</p>}
                                            {expiredOTP && <p className="change-email-otp__verify-error">{otpError}</p>}
                                        </div>

                                        {/* BUTTON SECTION */}
                                        <div className="change-email-otp__button-wrapper">
                                            <button 
                                                type="submit" 
                                                className={`change-email-otp__submit-button ${!(values.otp && values.otp.length === 6) ? "disabled" : ""}`}
                                                disabled={!(values.otp && values.otp.length === 6)}
                                            >
                                                {isConfirmingChangeEmailOTP ? "Confirming" : "CONFIRM"}
                                            </button>
                                        </div>

                                        {/* RESENT CODE SECTION */}
                                        <div className="change-email-otp__resend-code-wrapper">
                                            <p className="change-email-otp__resend-code">Haven't receive the code?</p>
                                            <p className="change-email-otp__resend-code-link" onClick={resendChangeEmailOTP}>Resend code</p>
                                        </div>
                                    </div>

                                    {/* FOOTER SECTION */}
                                    <div className="change-email-otp__footer">
                                        <div className="change-email-otp__code-direction-wrapper">
                                            <p className="change-email-otp__code-direction-text">
                                                Please enter the verification code to proceed with updating your email address.
                                                Your code will expire if not used in 5 minutes.
                                            </p>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
                {isConfirmingChangeEmailOTP && <ChangeEmailLoader />}
            </div>
        </>
    )
}

export default ChangeEmailOTP
