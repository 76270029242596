import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CSRFToken from '../../../components/CSRFToken'
import DatePicker from 'react-datepicker';
import { Autocomplete, TextField, FormControl } from '@mui/material';
import { styled } from '@mui/system';
import { BsArrowLeft, BsExclamationCircle } from "react-icons/bs";
import 'react-datepicker/dist/react-datepicker.css';


const StyledTextField = styled(({ hasValue, ...other }) => <TextField {...other} />)(({ hasValue }) => ({
    '& .MuiInputBase-root': {
        borderRadius: '3px', // Customize the border radius
        height: '40px', // Set the height of the input box
        padding: '0 10px', // Adjust padding to align with the height
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
    },
    '& .MuiInputBase-input': {
        color: hasValue ? '#626974' : '#aab2be', // Value color when hasValue, placeholder color otherwise
        fontStyle: hasValue ? 'normal' : 'normal',
        fontSize: hasValue ? '16px' : '14px',
        fontFamily: 'PP Neue Montreal, sans-serif',
        letterSpacing: hasValue ? '-0.10px' : 'normal',
    },
    '& .MuiInputBase-input::placeholder': {
        color: '#aab2be', // Clearer placeholder color
        opacity: 1, // Ensure placeholder text is visible
        fontStyle: 'normal',
        fontSize: '14px',
    },
    '&.Mui-focused .MuiInputBase-input': {
        color: '#626974', // Color when focused
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #009196', // Border style when focused
    },
    '&.Mui-error .MuiInputBase-input': {
        color: '#d30c0c', // Error color
        fontStyle: 'normal',
        fontSize: '14px',
        fontFamily: 'PP Neue Montreal, sans-serif',
    },
}));


const Step3 = ({ handlePrevStep, handleNextStep, merchantData, updateMerchantData }) => {

    const [hasValue, setHasValue] = useState(false);

    //FUNCTION HANDLING VALIDATION
    const validate = Yup.object({
        legal_name: Yup.string()
        .required('Please complete this field.')
        .max(30, 'Only 30 characters allowed.'),

        reg_number: Yup.string()
        .required('Please complete this field.')
        .max(40, 'Only 40 characters allowed.'),

        entity_type: Yup.string().nullable()
        .required('Please complete this field.'),

        tax_id: Yup.string()
        .required('Please complete this field.'),

        reg_date: Yup.string()
        .required('Please complete this field.'),

    })

    const handleSelectChange = (value) => {
        if (value && value !== "") {
            setHasValue(true);
        } else {
            setHasValue(false);
        }
    };

    const handleEntityTypeChange = (event, newValue, setFieldValue) => {
        setFieldValue('entity_type', newValue);
        handleSelectChange(newValue);
    };

    return (
        <Formik
            initialValues={merchantData}
            onSubmit={handleNextStep}
            validationSchema={validate}
        >

            {( { handleChange, handleBlur, values, handleSubmit, errors, touched, setFieldValue, setFieldTouched, validateField }) => {

                const handleDateChange = (date) => {
                    if (date) {
                        // Normalize the date to ensure it is consistent regardless of timezone
                        const normalizedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                        const formattedDate = normalizedDate.toISOString().split('T')[0]; // "YYYY-MM-DD" format
                        setFieldValue('reg_date', formattedDate);
                        setFieldTouched('reg_date', true, false);
                        validateField('reg_date'); // Explicitly validate the field after setting the value
                    } else {
                        setFieldValue('reg_date', '');
                        setFieldTouched('reg_date', true, false);
                        validateField('reg_date');
                    }
                };

                return(
                    <Form>
                        <CSRFToken />
                        <div className="onboarding__form">
                            {/* BACK BUTTON */}
                            <button className="onboarding__back-button" type="button" onClick={handlePrevStep}>
                                <BsArrowLeft className="onboarding__back-button-icon" />
                                <p className="onboarding__back-button-text">Back to Step 2</p>
                            </button>

                            <p className="onboarding__form-title">
                                Company Information
                            </p>
                            <p className="onboarding__form-subtitle">
                                We will use your company information to configure your account. 
                                You can always change this in the settings.
                            </p>          

                            {/* BUSINESS NAME INPUT */}               
                            <div className="onboarding__input-wrapper">
                                <p className={`onboarding__input-label ${errors.legal_name && touched.legal_name ? "onboarding__input-error" : ""}`}>
                                    Registered Name
                                </p>
                                <input
                                    className={`onboarding__text-input ${errors.legal_name && touched.legal_name ? "onboarding__input-error": null}`}
                                    type="text"
                                    name="legal_name"
                                    onChange={(e) => {
                                        handleChange(e);
                                        updateMerchantData({ ...values, legal_name: e.target.value });
                                    }}
                                    onBlur={handleBlur}
                                    value={values.legal_name}
                                    autoComplete="off"
                                    maxLength={31}
                                    required
                                />
                                {errors.legal_name && touched.legal_name && (
                                    <p className="onboarding__input-error-message"> 
                                        <BsExclamationCircle className="onboarding__input-error-icon" />
                                        {errors.legal_name}
                                    </p>
                                )}
                            </div>

                            {/* REGISTRATION NUMBER */}
                            <div className="onboarding__input-wrapper">
                                <p className={`onboarding__input-label ${errors.reg_number && touched.reg_number ? "onboarding__input-error" : ""}`}>
                                    Registration ID
                                </p>
                                <input
                                    className={`onboarding__text-input ${errors.reg_number && touched.reg_number ? "onboarding__input-error": null}`}
                                    type="text"
                                    name="reg_number"
                                    onChange={(e) => {
                                        handleChange(e);
                                        updateMerchantData({ ...values, reg_number: e.target.value });
                                    }}
                                    onBlur={handleBlur}
                                    value={values.reg_number}
                                    autoComplete="off"
                                    maxLength={41}
                                />
                                {errors.reg_number && touched.reg_number && (
                                    <p className="onboarding__input-error-message"> 
                                        <BsExclamationCircle className="onboarding__input-error-icon" />
                                        {errors.reg_number}
                                    </p>
                                )}
                            </div>

                            {/* ENTITY TYPE SECTION */}
                            <div className="onboarding__input-wrapper">
                                <p className={`onboarding__input-label ${errors.entity_type && touched.entity_type ? "onboarding__input-error" : ""}`}>
                                    Company Structure
                                </p>
                                <FormControl
                                    fullWidth
                                    error={Boolean(errors.entity_type && touched.entity_type)}
                                    required
                                >
                                    <Autocomplete
                                        options={["Sole Proprietorship", "Partnership", "Corporation"]}
                                        getOptionLabel={(option) => option}
                                        value={values.entity_type || ''}
                                        onChange={(event, newValue) => handleEntityTypeChange(event, newValue, setFieldValue)}
                                        onBlur={() => setFieldTouched('entity_type', true)}
                                        isOptionEqualToValue={(option, value) => option === value || value === ''}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                variant="outlined"
                                                name="entity_type"
                                                error={touched.entity_type && !!errors.entity_type}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                hasValue={hasValue ? 1 : 0} // Pass hasValue as a prop
                                            />
                                        )}
                                    />
                                </FormControl>
                                {errors.entity_type && touched.entity_type && (
                                    <p className="onboarding__input-error-message">
                                        <BsExclamationCircle className="onboarding__input-error-icon" />
                                        {errors.entity_type}
                                    </p>
                                )}
                            </div>

                            {/* TAX NUMBER */}
                            <div className="onboarding__input-wrapper">
                                <p className={`onboarding__input-label ${errors.tax_id && touched.tax_id ? "onboarding__input-error" : ""}`}>
                                    TIN
                                </p>
                                <input
                                    className={`onboarding__text-input ${errors.tax_id && touched.tax_id ? "onboarding__input-error": null}`}
                                    type="text"
                                    name="tax_id"
                                    onChange={(e) => {
                                        handleChange(e);
                                        updateMerchantData({ ...values, tax_id: e.target.value });
                                    }}
                                    onBlur={handleBlur}
                                    value={values.tax_id}
                                    autoComplete="off"
                                    maxLength={12}
                                />
                                {errors.tax_id && touched.tax_id && (
                                    <p className="onboarding__input-error-message"> 
                                        <BsExclamationCircle className="onboarding__input-error-icon" />
                                        {errors.tax_id}
                                    </p>
                                )}
                            </div>

                            {/* DATE OF REGISTRATION */}
                            <div className="onboarding__input-wrapper">
                                <p className={`onboarding__input-label ${errors.reg_date && touched.reg_date ? "onboarding__input-error" : ""}`}>
                                    Date of Registration
                                </p>
                                <div style={{ width: '100%', backgroundColor: '#6b6868', borderRadius: '3px' }}>
                                    <DatePicker
                                        selected={values.reg_date ? new Date(values.reg_date) : null}
                                        onChange={handleDateChange}
                                        onBlur={() => setFieldTouched('reg_date', true)}
                                        dateFormat="MM/dd/yyyy"
                                        className={`onboarding__text-input form-control ${errors.reg_date && touched.reg_date ? "onboarding__input-error" : ""}`}
                                    />
                                </div>
                                {errors.reg_date && touched.reg_date && (
                                    <p className="onboarding__input-error-message"> 
                                        <BsExclamationCircle className="onboarding__input-error-icon" />
                                        {errors.reg_date}
                                    </p>
                                )}
                            </div>
                            <div className="onboarding__button-wrapper">
                                <button 
                                    type="submit" 
                                    className={`onboarding__button ${values.legal_name && values.reg_number && values.entity_type && values.tax_id && values.reg_date ? "" : "disabled"}`}
                                    disabled={!values.legal_name || !values.reg_number || !values.entity_type || !values.tax_id || !values.reg_date }
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    );
};

export default Step3;