import React from 'react';
import { Formik, Form } from 'formik';
import CSRFToken from '../../../../components/CSRFToken';
import * as Yup from 'yup';
import ToggleSwitch from './ToggleSwitch';
import ManageUsersLoader from './ManageUsersLoader';
import { BsFillExclamationTriangleFill, BsCheckCircle, BsEmojiFrown, BsExclamationCircleFill } from 'react-icons/bs';
import '../../../../Styles/MainStyles/Settings/ManageUsers/AddUser.css';

function AddUser({ 
    newUserData, 
    submitNewUser, 
    updateNewUserData, 
    onClose, 
    submittingNewUser,
    submitNewUserSuccess,
    addAnotherUser,
    newUserName,
    addUserError,
    addUserErrorMsg,
    addUserErrorAction,
    addUserErrorCode,
    userExists, 
    resetUserExistsMsg
}) {

    const validate = Yup.object({
        first_name: Yup.string()
        .required('Please complete this field.'),
        
        last_name: Yup.string()
        .required('Please complete this field.'),

        email: Yup.string()
        .email('The email address format is not valid.')
        .required('Email address is required.'),
    })


    return (
        <>
            <div className="add-user">
                <div className="add-user__page-container">
                    {addUserError ? (
                        <div className="add-bank__error-message-wrapper">
                            <BsEmojiFrown className="add-bank__error-message-icon"/>
                            <p className="add-bank__error-message">{addUserErrorMsg}</p>
                            <p className="add-bank__error-action">{addUserErrorAction}</p>
                            <p className="add-bank__error-code">{addUserErrorCode}</p>
                            <div className="add-bank__error-button-wrapper">
                                <button 
                                    className="add-bank__error-button"
                                    type="button" 
                                    onClick={onClose}
                                >
                                    OKAY
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            {submitNewUserSuccess ? (
                                <div className="add-user__success-message-wrapper">
                                    <BsCheckCircle className="add-user__success-message-icon"/>
                                    <div className="add-user__success-message-texts">
                                        <p className="add-user__success-message-heading">
                                            Hurray!!
                                        </p>
                                        <p className="add-user__success-message-subheading">
                                            {newUserName} has been added successfully.
                                        </p>
                                    </div>
                                    <div className="add-user__success-message-btns">
                                        <button
                                            type="button"
                                            onClick={onClose}
                                        >
                                            Close
                                        </button>
                                        <button
                                            type="button"
                                            onClick={addAnotherUser}
                                        >
                                            Add New User
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <Formik
                                    initialValues={newUserData}
                                    onSubmit={submitNewUser}
                                    validationSchema={validate}
                                >
                                    {( { handleChange, handleBlur, values, handleSubmit, errors, touched, setFieldValue }) => {

                                        return(
                                            <Form>
                                                <CSRFToken />
                                                <div className="add-user__header">
                                                    <p className="add-user__title">Add User</p>
                                                </div>
                                                <div className="add-user__body">

                                                    {/* FIRST NAME SECTION */}
                                                    <div className="add-user__input-wrapper">
                                                        <p className="add-user__input-label">First Name</p>
                                                        <input 
                                                            type="text" 
                                                            className={`add-user__input ${errors.first_name && touched.first_name ? "add-user__input-error" : null}`}
                                                            name="first_name"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateNewUserData({ ...values, first_name: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.first_name}
                                                            autoComplete="off"
                                                        />
                                                        {errors.first_name && touched.first_name && (
                                                            <p className="add-user__input-error-message"> 
                                                                <BsFillExclamationTriangleFill className="add-user__input-error-icon" />
                                                                {errors.first_name}
                                                            </p>
                                                        )}
                                                    </div>

                                                    {/* LAST NAME SECTION */}
                                                    <div className="add-user__input-wrapper">
                                                        <p className="add-user__input-label">Last Name</p>
                                                        <input 
                                                            type="text" 
                                                            className={`add-user__input ${errors.last_name && touched.last_name ? "add-user__input-error" : null}`}
                                                            name="last_name"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateNewUserData({ ...values, last_name: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.last_name}
                                                            autoComplete="off"
                                                        />
                                                        {errors.last_name && touched.last_name && (
                                                            <p className="add-user__input-error-message"> 
                                                                <BsFillExclamationTriangleFill className="add-user__input-error-icon" />
                                                                {errors.last_name}
                                                            </p>
                                                        )}
                                                    </div>

                                                    {/* EMAIL SECTION */}
                                                    <div className="add-user__input-wrapper">
                                                        <p className={`add-user__input-label ${userExists ? "add-user__user-exist" : ""}`}>Email address</p>
                                                        <input 
                                                            type="text" 
                                                            className={`add-user__input 
                                                                ${errors.email && touched.email 
                                                                ? "add-user__input-error" 
                                                                :userExists
                                                                ? "add-user__user-exist-error"
                                                                : null
                                                            }`}
                                                            name="email"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateNewUserData({ ...values, email: e.target.value });
                                                                resetUserExistsMsg();
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                            autoComplete="off"
                                                        />
                                                        {errors.email && touched.email && (
                                                            <p className="add-user__input-error-message"> 
                                                                <BsFillExclamationTriangleFill className="add-user__input-error-icon" />
                                                                {errors.email}
                                                            </p>
                                                        )}
                                                        {userExists && (
                                                            <p className="add-user__input-error-message">
                                                                <BsExclamationCircleFill className="add-user__input-error-icon" />
                                                                This user has been added as account user already.
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="add-user__permissions-container">
                                                        <p className="add-user__permissions-title">Permissions</p>
                                                        <div className="add-user__permission-wrapper">
                                                            <ToggleSwitch 
                                                                name="can_manage_users"
                                                                onChange={(checked) => {
                                                                    setFieldValue('can_manage_users', checked);
                                                                    updateNewUserData({ ...values, can_manage_users: checked });
                                                                }} 
                                                            />
                                                            <div className="add-user__permission">
                                                                <p>Manage Users</p>
                                                                <p>Add and remove account users.</p>
                                                            </div>
                                                        </div>                                               
                                                        <div className="add-user__permission-wrapper">
                                                            <ToggleSwitch 
                                                                name="can_manage_business" 
                                                                onChange={(checked) => {
                                                                    setFieldValue('can_manage_business', checked);
                                                                    updateNewUserData({ ...values, can_manage_business: checked });
                                                                }}
                                                            />
                                                            <div className="add-user__permission">
                                                                <p>Manage Business</p>
                                                                <p>Update business information.</p>
                                                            </div>
                                                        </div>   
                                                        <div className="add-user__permission-wrapper">
                                                            <ToggleSwitch 
                                                                name="can_manage_bank_account" 
                                                                onChange={(checked) => {
                                                                    setFieldValue('can_manage_bank_account', checked);
                                                                    updateNewUserData({ ...values, can_manage_bank_account: checked });
                                                                }}
                                                            />
                                                            <div className="add-user__permission">
                                                                <p>Manage Banks</p>
                                                                <p>Add and update bank accounts.</p>
                                                            </div>
                                                        </div>        
                                                        <div className="add-user__permission-wrapper">
                                                            <ToggleSwitch 
                                                                name="can_manage_payouts" 
                                                                onChange={(checked) => {
                                                                    setFieldValue('can_manage_payouts', checked);
                                                                    updateNewUserData({ ...values, can_manage_payouts: checked });
                                                                }}
                                                            />
                                                            <div className="add-user__permission">
                                                                <p>Manage Payouts</p>
                                                                <p>Initiate and manage payouts.</p>
                                                            </div>
                                                        </div>                                                                                                                             
                                                    </div>
                                                </div>
                                                <div className="add-user__footer">
                                                    <button
                                                        className="add-user__cancel-btn"
                                                        type="button"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className={`add-user__submit-btn ${values.first_name && values.last_name && values.email ? "" : "disabled"}`}
                                                        disabled={!values.first_name || !values.last_name || !values.email}
                                                    >
                                                        {submittingNewUser ? "Processing" : "Add User"}
                                                    </button>
                                                </div>
                                            </Form>
                                        )

                                    }}
                                </Formik>
                            )}
                        </>
                    )}
                </div>
                {submittingNewUser && <ManageUsersLoader />}
            </div>
        </>
    )
}

export default AddUser