import React from 'react';
import { useField } from 'formik';
import '../../../../Styles/MainStyles/Settings/ManageUsers/ToggleSwitch.css';

const ToggleSwitch = ({ name, onChange }) => {
    const [field, , helpers] = useField(name);

    const toggle = () => {
        const newValue = !field.value;
        helpers.setValue(newValue);
        onChange(newValue);
    };

    return (
        <div className="add-user__toggle-wrapper">
            <label className="toggle-switch">
                <input
                    type="checkbox"
                    {...field}
                    checked={field.value}
                    onChange={toggle}
                />
                <span className="slider round">
                    <span className="switch-label-left">{field.value ? 'Yes' : ''}</span>
                    <span className="switch-label-right">{!field.value ? 'No' : ''}</span>
                </span>
            </label>
        </div>
    );
};

export default ToggleSwitch;