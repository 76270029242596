import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import Cookies from 'js-cookie';
import moment from 'moment';

import { updateBalance } from '../slices/homeSlice'

// BACKEND API ENDPOINTS
const GET_LIST_OF_PAYOUTS_ENDPOINT = '/apps/payouts/'
const GET_FILTERED_PAYOUTS_ENDPOINT = '/apps/filter-payouts/'; 
const SEARCH_PAYOUT_ENDPOINT = '/apps/query-payout/';
const CACHE_EXPIRY_MS = 60 * 1000; // 1 minute

// Helper function to check cache validity
const isCacheValid = (cachedPage) => cachedPage && (Date.now() - cachedPage.timestamp < CACHE_EXPIRY_MS);

export const checkPayoutsList = createAsyncThunk(
    'payouts/list',
     async ({ page = 1, pageSize = 10 } = {}, { getState, rejectWithValue }) => {
          const state = getState();
          const merchantProfile = state.merchant.profile;
          const merchant_id = merchantProfile ? merchantProfile.merchant_id : '';

          const cacheKey = `${merchant_id}_${page}_${pageSize}`;
          const cachedPage = state.payouts.cachedPages[cacheKey];

          // Check if the cached data is valid
          if (isCacheValid(cachedPage)) {
               return { ...cachedPage, fromCache: true };
          }

          
          try {
               const res = await axios.post(
                    GET_LIST_OF_PAYOUTS_ENDPOINT,
                    JSON.stringify({ merchant_id, page, pageSize }),
                    {
                         headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'X-CSRFToken' : Cookies.get('csrftoken')
                         }
                    }
               );
               if (res.data.results.success) {
                    const { 
                         count: totalCount, 
                         results: {
                              payout_data: payouts,
                              currentPage,
                              pageSize
                         }, 
                    } = res.data;
                    return {
                         payouts,
                         totalCount,
                         currentPage,
                         pageSize,
                         cacheKey,
                     };
               } else {
                    console.error('Invalid data received', res.data);
                    return rejectWithValue({
                         message: "Oops! We encountered an error.",
                         action: "Refresh this page or contact support.",
                         code: "ERR_FINDUKU_1000"
                    });
               } 
          } catch (error) {
               console.error('Failed to fetch payouts list', error);
               let errorMsg, errorAction, errorCode;
               if (error.response) {
                    switch (error.response.status) {
                         case 400:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Refresh this page or contact support.";
                              errorCode = "ERR_FINDUKU_3000";
                              break;
                         case 401:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Please login your account.";
                              errorCode = "ERR_FINDUKU_4000";
                              break;
                         case 403:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Click Okay to log in again now.";
                              errorCode = "ERR_FINDUKU_5000";
                              break;
                         case 404:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Refresh this page or contact support.";
                              errorCode = "ERR_FINDUKU_6000";
                              break;
                         case 500:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Refresh this page or contact support.";
                              errorCode = "ERR_FINDUKU_7000";
                              break;
                         default:
                              errorMsg = "Oops! We encountered an error";
                              errorAction = "Refresh this page or contact support.";
                              errorCode = "ERR_FINDUKU_2000";
                    }
                    return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
               } else {
                    return rejectWithValue({
                         message: "Oops! We encountered an error",
                         action: "Refresh this page or contact support.",
                         code: "ERR_FINDUKU_8000"
                    })
               }
          }
     }
);


export const filterPayoutsList = createAsyncThunk(
     'payouts/filterList',
     async ({ page = 1, pageSize = 10,  startDate, endDate } = {}, { getState, rejectWithValue }) => {
          const state = getState();
          const merchantProfile = state.merchant.profile;
          const merchant_id = merchantProfile ? merchantProfile.merchant_id : '';
          const statusFilterApplied = state.payouts.statusFilterApplied;
          const dateFilterApplied = state.payouts.dateFilterApplied;
          const amountFilterApplied = state.payouts.amountFilterApplied;
          const status = state.payouts.selectedStatusFilter;
          const date_filter = state.payouts.selectedDateFilter;
          const amount_filter = state.payouts.selectedAmountFilter;
          const amount_value = state.payouts.amountValue;
          const min_amount = state.payouts.minAmount;
          const max_amount = state.payouts.maxAmount;
     
          // Setting up the API request payload directly using provided parameters
          let payload = {
               merchant_id,
               page, 
               pageSize,
               amount_filter: amount_filter,
          };
     
          // Only add status to payload if statusFilterApplied is true
          if (statusFilterApplied) {
               payload.status = status;
          }
 
          // Conditionally handle date filtering if dateFilterApplied is true
          if (dateFilterApplied) {
               if (date_filter === 'in between') {
                    payload.date_from = startDate;
                    payload.date_to = endDate;
               } else {
                    let duration = moment(); // Resetting moment to the current date and time
                    switch (date_filter) {
                         case 'in last 24 hours':
                              payload.date_from = duration.clone().subtract(24, 'hours').format('YYYY-MM-DD');
                              payload.date_to = duration.format('YYYY-MM-DD');
                              break;
                         case 'in last 7 days':
                              payload.date_from = duration.clone().subtract(7, 'days').format('YYYY-MM-DD');
                              payload.date_to = duration.format('YYYY-MM-DD');
                              break;
                         case 'in last 30 days':
                              payload.date_from = duration.clone().subtract(30, 'days').format('YYYY-MM-DD');
                              payload.date_to = duration.format('YYYY-MM-DD');
                              break;
                         case 'in last 90 days':
                              payload.date_from = duration.clone().subtract(90, 'days').format('YYYY-MM-DD');
                              payload.date_to = duration.format('YYYY-MM-DD');
                              break;
                         default:
                              console.error('Invalid date filter:', date_filter);
                              break;
                    }
               }
          }
 
          // Conditionally handling amount filters if amountFilterApplied is true
          if (amountFilterApplied) {
               switch (amount_filter) {
                    case 'is equal to':
                    case 'is more than':
                    case 'is less than':
                         payload.amount = amount_value;
                         break;
                    case 'is between':
                         payload.min_amount = min_amount;
                         payload.max_amount = max_amount;
                         break;
                    default:
                         console.error('Invalid amount filter:', amount_filter);
                         break;
               }
          }
 
          try {
               const res = await axios.post(
                    GET_FILTERED_PAYOUTS_ENDPOINT,
                    JSON.stringify(payload),
                    {
                         headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'X-CSRFToken' : Cookies.get('csrftoken')
                         }
                    }
               );
     
               if (res.data.results.success) {
                    const { 
                         count: totalCount, 
                         results: {
                              payout_data: payouts
                         }, 
                         currentPage, 
                         pageSize 
                    } = res.data;
                    return { 
                         payouts, 
                         totalCount, 
                         currentPage, 
                         pageSize,
                    };
               } else {
                    console.error('Invalid data received', res.data);
                    return rejectWithValue({
                         message: "Oops! We encountered an error.",
                         action: "Please try again or contact support.",
                         code: "ERR_FINDUKU_1000"
                    }); 
               } 
          } catch (error) {
               console.error('Failed to filter payouts list', error);
               let errorMsg, errorAction, errorCode;
               if (error.response) {
                    switch (error.response.status) {
                         case 400:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_3000";
                              break;
                         case 401:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Please login your account.";
                              errorCode = "ERR_FINDUKU_4000";
                              break;
                         case 403:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Click Okay to log in again.";
                              errorCode = "ERR_FINDUKU_5000";
                              break;
                         case 404:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_6000";
                              break;
                         case 500:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_7000";
                              break;
                         default:
                              errorMsg = "Oops! We encountered an error";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_2000";
                    }
                    return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
               } else {
                    return rejectWithValue({
                         message: "Oops! We encountered an error",
                         action: "Please try again or contact support.",
                         code: "ERR_FINDUKU_8000"
                    })
               }
          }
     }
);

export const searchPayoutsList = createAsyncThunk(
     'payouts/searchList',
     async (_, { getState, rejectWithValue }) => {
          const state = getState();
          const merchantProfile = state.merchant.profile;
          const merchant_id = merchantProfile ? merchantProfile.merchant_id : '';
          const searchQuery = state.payouts.searchQuery;
 
          try {
               const res = await axios.post(
                    SEARCH_PAYOUT_ENDPOINT,
                    JSON.stringify({ merchant_id, searchQuery }),
                    {
                         headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'X-CSRFToken' : Cookies.get('csrftoken')
                         }
                    }
               );
               if (res.data.success) {
                    const { payoutData: payouts } = res.data;
                    return payouts
               } else if (res.data.payout_not_found) {
                    return { noPayoutData: true };
               } else {
                    console.error('Invalid data received', res.data);
                    return rejectWithValue({
                         message: "Oops! We encountered an error.",
                         action: "Please try again or contact support.",
                         code: "ERR_FINDUKU_1000"
                    }); 
               }  
          } catch (error) {
               console.error('Failed to fetch payouts list', error);
               let errorMsg, errorAction, errorCode;
               if (error.response) {
                    switch (error.response.status) {
                         case 400:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_3000";
                              break;
                         case 401:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Please login your account.";
                              errorCode = "ERR_FINDUKU_4000";
                              break;
                         case 403:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Click Okay to log in again.";
                              errorCode = "ERR_FINDUKU_5000";
                              break;
                         case 404:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_6000";
                              break;
                         case 500:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_7000";
                              break;
                         default:
                              errorMsg = "Oops! We encountered an error";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_2000";
                    }
                    return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
               } else {
                    return rejectWithValue({
                         message: "Oops! We encountered an error",
                         action: "Please try again or contact support.",
                         code: "ERR_FINDUKU_8000"
                    })
               }
          }
     }     
)

export const fetchNewPayout = createAsyncThunk(
     'payouts/new',
     async ({ page = 1, pageSize = 10, payout_id } = {}, { getState, dispatch, rejectWithValue }) => {
          const state = getState();
          const merchantProfile = state.merchant.profile;
          const merchant_id = merchantProfile ? merchantProfile.merchant_id : '';
               
          try {
               const res = await axios.post(
                    `/apps/new_payout/${payout_id}/`,
                    JSON.stringify({ merchant_id, page, pageSize }),
                    {
                         headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'X-CSRFToken' : Cookies.get('csrftoken')
                         }
                    }
               );
               if (res.data.success) {
                    const { new_payout: newPayout, balanceData  } = res.data;
                    
                    // Dispatch the actions from the other slices
                    dispatch(updateBalance(balanceData));

                    return newPayout
               } else {
                    console.error('Invalid data received', res.data);
                    return rejectWithValue({
                         message: "Oops! We encountered an error.",
                         action: "Please try again or contact support.",
                         code: "ERR_FINDUKU_1000"
                    }); 
               } 
          } catch (error) {
               console.error('Failed to fetch new payout', error);
               let errorMsg, errorAction, errorCode;
               if (error.response) {
                    switch (error.response.status) {
                         case 400:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_3000";
                              break;
                         case 401:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Please login your account.";
                              errorCode = "ERR_FINDUKU_4000";
                              break;
                         case 403:
                              errorMsg = "Oops! Authorization is required.";
                              errorAction = "Click Okay to log in again.";
                              errorCode = "ERR_FINDUKU_5000";
                              break;
                         case 404:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_6000";
                              break;
                         case 500:
                              errorMsg = "Oops! We encountered an error.";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_7000";
                              break;
                         default:
                              errorMsg = "Oops! We encountered an error";
                              errorAction = "Please try again or contact support.";
                              errorCode = "ERR_FINDUKU_2000";
                    }
                    return rejectWithValue({ message: errorMsg, action: errorAction, code: errorCode })
               } else {
                    return rejectWithValue({
                         message: "Oops! We encountered an error",
                         action: "Please try again or contact support.",
                         code: "ERR_FINDUKU_8000"
                    })
               }
          }
          
     }
);
 


export const payoutsSlice = createSlice({
     name: 'payouts',
     initialState: {
         payouts_list: [],
         totalCount: 0,
         currentPage: 1,
         pageSize: 10,
         loading: false,
         error: null,
         cachedPages: {},
         searchQuery: '',
         hidePagination: false,
         viewingSearchResults: false,
         isSearchFilterMade: false,
         showResetButton: false,
 
         // Selected filters & values
         showFiltersButton: false,
         selectedDateFilter: '',
         selectedStatusFilter: '',
         selectedAmountFilter: '',
         amountValue: '',
         minAmount: '',
         maxAmount: '',
         toggleDateIcon: true,
         toggleStatusIcon: true,
         toggleAmountIcon: true,
         dateFilterApplied: false,
         statusFilterApplied: false,
         amountFilterApplied: false,
     },
     reducers: {
          addPayout: (state, action) => {
               state.payouts_list = [action.payload, ...state.payouts_list];
          },
          setLoading: (state, action) => {
               state.loading = action.payload;
               state.error = null;
          },
          setSearchQuery: (state, action) => {
               state.searchQuery = action.payload;
          },
          resetSearchQuery: (state, action) => {
               state.searchQuery = action.payload;
          },
          setViewingSearchResults: (state, action) => {
               state.viewingSearchResults = action.payload;
          },
          setShowResetButton: (state, action) => {
               state.showResetButton = action.payload;
          },
          setSelectedDateFilter: (state, action) => {
               state.selectedDateFilter = action.payload;
          },
          setDateFilterApplied: (state, action) => {
               state.dateFilterApplied = action.payload;
          },
          setSelectedStartDate(state, action) {
               state.startDate = action.payload;
          },
          setSelectedEndDate(state, action) {
               state.endDate = action.payload;
          },
          setDateFilterIcon: (state, action) => {
               state.toggleDateIcon = action.payload;
          },
          setSelectedStatusFilter: (state, action) => {
               state.selectedStatusFilter = action.payload;
          },
          setStatusFilterApplied: (state, action) => {
               state.statusFilterApplied = action.payload;
          },
          setStatusFilterIcon: (state, action) => {
               state.toggleStatusIcon = action.payload;
          },
          setSelectedAmountFilter: (state, action) => {
               state.selectedAmountFilter = action.payload;
          },
          setAmountFilterApplied: (state, action) => {
               state.amountFilterApplied = action.payload;
          },
          setAmountValue: (state, action) => {
               state.amountValue = action.payload;
          },
          setMinAmount: (state, action) => {
               state.minAmount = action.payload;
          },
          setMaxAmount: (state, action) => {
               state.maxAmount = action.payload;
          },
          setAmountFilterIcon: (state, action) => {
               state.toggleAmountIcon = action.payload;
          },
          setShowFiltersButton: (state, action) => {
               state.showFiltersButton = action.payload;
          },
          reset_payouts: (state) => {
               state.payouts_list = [];
               state.totalCount = 0;
               state.currentPage = 1;
               state.pageSize = 10;
               state.loading = false;
               state.error = null;
               state.cachedPages = {};
               state.searchQuery = '';
               state.hidePagination = false;
               state.viewingSearchResults = false;
               state.isSearchFilterMade = false;
               state.showResetButton = false;
               state.showFiltersButton = false;
               state.selectedDateFilter = '';
               state.selectedStatusFilter = '';
               state.selectedAmountFilter = '';
               state.amountValue = '';
               state.minAmount = '';
               state.maxAmount = '';
               state.toggleDateIcon = true;
               state.toggleStatusIcon = true;
               state.toggleAmountIcon = true;
               state.dateFilterApplied = false;
               state.statusFilterApplied = false;
               state.amountFilterApplied = false;
          }
     },
     extraReducers: (builder) => {
          builder
               .addCase(checkPayoutsList.pending, (state) => {
                    state.showResetButton = false;
                    state.loading = true;
                    state.error = null;
               })
               .addCase(checkPayoutsList.fulfilled, (state, action) => {
                    const { payouts, totalCount, currentPage, pageSize } = action.payload;
                    state.payouts_list = payouts;
                    state.totalCount = totalCount;
                    state.currentPage = currentPage;
                    state.pageSize = pageSize;
                    state.loading = false;
                    state.hidePagination = false;
                    state.isSearchFilterMade = false;
                    state.error = null;
               })
               .addCase(checkPayoutsList.rejected, (state, action) => {
                    state.loading = false;
                    if (action.payload) {
                         state.error = {
                              message: action.payload.message,
                              action: action.payload.action,
                              code: action.payload.code,
                         };
                    } else {
                         state.error = {
                              message: "Oops! We encountered an error",
                              action: "Refresh the page or contact support.",
                              code: "ERR_FINDUKU_9000"
                         };
                    }
               })
              .addCase(filterPayoutsList.pending, (state) => {
                    state.showResetButton = false;
                    state.loading = true;
                    state.error = null;
               })
               .addCase(filterPayoutsList.fulfilled, (state, action) => {
                    const { payouts, totalCount, currentPage, pageSize } = action.payload;
                    state.payouts_list = payouts;
                    state.totalCount = totalCount;
                    state.currentPage = currentPage;
                    state.pageSize = pageSize;
                    state.loading = false;
                    state.error = null;
                    state.isSearchFilterMade = true;
               })
               .addCase(filterPayoutsList.rejected, (state, action) => {
                    state.loading = false;
                    if (action.payload) {
                         state.error = {
                              message: action.payload.message,
                              action: action.payload.action,
                              code: action.payload.code,
                         };
                    } else {
                         state.error = {
                              message: "Oops! We encountered an error",
                              action: "Refresh the page or contact support.",
                              code: "ERR_FINDUKU_9000"
                         };
                    }
               })
               .addCase(searchPayoutsList.pending, (state) => {
                    state.showResetButton = false;
                    state.loading = true;
                    state.error = null;
               })
               .addCase(searchPayoutsList.fulfilled, (state, action) => {
                    if (action.payload && !action.payload.noPayoutData) {
                         state.payouts_list = [action.payload];
                         state.totalCount = 1;
                         state.currentPage = 1;
                         state.pageSize = 1;
                    } else if (action.payload && action.payload.noPayoutData) {
                         state.payouts_list = [];
                         state.totalCount = 0;
                         state.currentPage = 1;
                    }
                    state.loading = false;
                    state.hidePagination = true;
                    state.viewingSearchResults = true;
                    state.isSearchFilterMade = true;
                    state.showResetButton = true;
               })
               .addCase(searchPayoutsList.rejected, (state, action) => {
                    state.loading = false;
                    state.hidePagination = false;
                    state.viewingSearchResults = false;
                    if (action.payload) {
                         state.error = {
                              message: action.payload.message,
                              action: action.payload.action,
                              code: action.payload.code,
                         };
                    } else {
                         state.error = {
                              message: "Oops! We encountered an error",
                              action: "Refresh the page or contact support.",
                              code: "ERR_FINDUKU_9000"
                         };
                    }
               })
               .addCase(fetchNewPayout.pending, (state) => {
                    state.loading = false;
                    state.error = null;
               })
               .addCase(fetchNewPayout.fulfilled, (state, action) => {
                    state.payouts_list = [action.payload, ...state.payouts_list];
                    state.loading = false;
                    state.error = null;
               })
               .addCase(fetchNewPayout.rejected, (state, action) => {
                    state.loading = false;
                    if (action.payload) {
                        state.error = {
                            message: action.payload.message,
                            action: action.payload.action,
                            code: action.payload.code,
                        };
                    } else {
                        state.error = {
                            message: "Oops! We encountered an error",
                            action: "Refresh the page or contact support.",
                            code: "ERR_FINDUKU_9000"
                        };
                    }
               })
     }
})

export const { 
     addPayout, 
     setLoading, 
     setSearchQuery,
     resetSearchQuery, 
     setViewingSearchResults,
     setShowResetButton,
     setShowFiltersButton,
     setSelectedDateFilter,
     setDateFilterApplied,
     setSelectedStartDate,
     setSelectedEndDate,
     setDateFilterIcon,
     setSelectedStatusFilter,
     setStatusFilterApplied,
     setStatusFilterIcon,
     setSelectedAmountFilter,
     setAmountFilterApplied,
     setAmountValue,
     setMinAmount,
     setMaxAmount,
     setAmountFilterIcon, 
     reset_payouts, 
} = payoutsSlice.actions;
export default payoutsSlice.reducer;