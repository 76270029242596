import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { 
    BsQuestionCircleFill, 
    BsBellFill,
    BsPersonCircle 
} from 'react-icons/bs'
import ProfileMenu from '../Comp/Header/ProfileMenu';



function Topbar () {
    const store_name = useSelector((state) => state.merchant.profile ? state.merchant.profile.merchant_name : '');
    const [openModal, setOpenModal] = useState(false);

    const handleOutsideClick = (event) => {
        if (openModal && !event.target.closest('.profile-menu') && !event.target.closest('.nav-item')) {
          setOpenModal(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
          document.removeEventListener('click', handleOutsideClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [openModal]);

    return (
        <>
            <div className="topbar">
                <div className="topbar-container">
                    <div className="topbar-profile-wrapper">
                        <div className="topbar-avatar-container">
                            <Avatar 
                                className="avatar" 
                                sx={{ bgcolor: '#284154', width: 30, height: 30 }}
                            >
                                P
                            </Avatar>
                            <p>{store_name}</p>
                        </div>
                    </div>
                    <div className="topbar-nav-wrapper">
                        <div className="nav-item">
                            <BsQuestionCircleFill className="nav-item-icon" />
                            <p>Help</p>
                        </div> 
                        <div className="nav-item">
                            <BsBellFill className="nav-item-icon" />
                        </div> 
                        <div className="nav-item profile-nav-item" onClick={()=> setOpenModal(true)}>
                            <BsPersonCircle className="nav-item-icon" />
                            {openModal && (
                                <div className="profile-menu-container">
                                    <ProfileMenu open={openModal} onClose={() => setOpenModal(false)} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Topbar;
