import React from 'react'
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { BsPrinterFill, BsInfoCircle, BsChevronLeft } from 'react-icons/bs';
import NotFound from '../../../../components/ErrorPages/NotFound';
import '../../../../Styles/MainStyles/Transactions/Payments/PaymentView.css';

const FeeTooltip = styled(({ className, ...props }) => (
    <Tooltip classes={{ popper: className }} {...props} />
))({
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'white',  
        maxWidth: 480,           
        color: 'black',
        fontFamily: 'Figtree',
        fontSize: '14px',
        boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.25)', 
        padding: '6px',
    },
    '& .MuiTooltip-arrow': {
        color: 'white',  
    },
});

function PaymentView() {
    const navigate = useNavigate()
    const { reference } = useParams();
    const payments = useSelector((state) => state.payments.payments_list);
    const paymentData = payments.find(payment => payment.reference === reference);

    if (!paymentData) {
        return <NotFound />; 
    }

    const handlePrintTransaction = () => {
        const paymentTransaction = document.querySelector('.paymentView__page-container');
    
        html2canvas(paymentTransaction, { scale: 2 })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'p',
                    unit: 'mm',
                    format: 'a4',
                });
                const width = pdf.internal.pageSize.getWidth();
                const height = (canvas.height * width) / canvas.width;
                pdf.addImage(imgData, 'PNG', 0, 0, width, height);
                pdf.save('payment_transaction.pdf');
            })
            .catch((error) => {
                console.error('Failed to capture screenshot', error);
            });
    };

    const routeBack = () => {
        navigate('/transactions')
    }

    const getPaymentDataStatusClass = (status) => {
        switch(status) {
            case 'Paid':
                return 'paymentData__status-paid';
            case 'Failed':
                return 'paymentData__status-failed';
            case 'Pending':
                return 'paymentData__status-pending';
            case 'On Hold':
                return 'paymentData__status-onhold';
            default:
                return '';
        }
    }
    
    
    return (
        <>
            <div className="paymentView">
                <div className="paymentView__page-container">
                    <div className="paymentView__breadcrumb-wrapper">
                        <BsChevronLeft className="paymentView__breadcrumb-icon"/>
                        <p className="paymentView__breadcrumb-text" onClick={routeBack}>Back to Payments</p>
                    </div>
                    <div className="paymentView__header">
                        <div className="paymentView__title-wrapper">
                            <p className="paymentView__title">Transaction details</p>
                        </div>
                        <div className="paymentView__print-wrapper" onClick={handlePrintTransaction}>
                            <BsPrinterFill className="paymentView__print-icon" />
                            <p className="paymentView__print-text">Print</p>
                        </div>
                    </div>
                    <div className="paymentView__body">
                        <div className="paymentView__headline">
                            <div className="paymentView__headline-item1">
                                <div className="paymentView__headline-item1-title-wrapper">
                                    <p className="paymentView__headline-item1-title">
                                        In-store Payment
                                    </p>
                                </div>
                                <div className="paymentView__headline-item1-amount-wrapper">
                                    <p className="paymentView__headline-item1-amount">Gross Amount</p>
                                </div>
                            </div>
                            <div className="paymentView__headline-item2">
                                <div className="paymentView__headline-item2-date-wrapper">
                                    <p className="paymentView__headline-item2-date">Date: {paymentData.date}</p>
                                </div>
                                <div className="paymentView__headline-item2-id-wrapper">
                                    <p className="paymentView__headline-item2-id">Reference: {paymentData.reference}</p>
                                </div>
                                <div className="paymentView__headline-item2-amount-wrapper">
                                    <p className="paymentView__headline-item2-amount">
                                        PHP {paymentData && paymentData.amount ? parseFloat(paymentData.amount)
                                        .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="paymentView__headline-item2-mobile">
                                <div className="paymentView__headline-item2-date-wrapper-mobile">
                                    <p className="paymentView__headline-item2-date-label-mobile">Payment Date:</p>
                                    <p className="paymentView__headline-item2-date-value-mobile">{paymentData.date}</p>
                                </div>
                                <div className="paymentView__headline-item2-id-wrapper-mobile">
                                    <p className="paymentView__headline-item2-id-label-mobile">Reference:</p>
                                    <p className="paymentView__headline-item2-id-value-mobile">{paymentData.reference}</p>
                                </div>
                            </div>
                            <div className="paymentView__headline-item3">
                                <div className="paymentView__headline-item3-status-wrapper">
                                    <p className="paymentView__headline-item3-status-label">Payment Status: </p>
                                    <p className={`paymentView__headline-item3-status ${getPaymentDataStatusClass(paymentData.status)}`}>
                                        {paymentData.status.toUpperCase()}
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                        <div className="paymentView__data-body">
                            {/* PAYMENT DETAILS SECTION */}
                            <div className="paymentView__payment-details-section">
                                <p className="paymentView__payment-details-section--title">
                                    Payment Details
                                </p>
                                <table className="paymentView__payment-details-table">
                                    <tbody>
                                        <tr>{/* CUSTOMER REFERENCE */}
                                            <td className="paymentView__table-row-label">Payment ID</td>
                                            <td className="paymentView__table-row-value">{paymentData.payment_id}</td>
                                        </tr>
                                        <tr>{/* GROSS AMOUNT */}
                                            <td className="paymentView__table-row-label">Gross Amount</td>
                                            <td className="paymentView__table-row-value">
                                                PHP {paymentData && paymentData.amount ? parseFloat(paymentData.amount)
                                                .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : "Not available"}
                                            </td>
                                        </tr>
                                        <tr>{/* CONVENIENCE FEE */}
                                            <td className="paymentView__table-row-label">Fees</td>
                                            <td className="paymentView__table-row-value">
                                                PHP {paymentData && paymentData.fees ? parseFloat(paymentData.fees)
                                                .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : "Not available"}
                                                <FeeTooltip title="Finduku processing fee" placement="right" arrow>
                                                    <span className="paymentView__table-row-tooltip">
                                                        <BsInfoCircle />
                                                    </span>
                                                </FeeTooltip>
                                            </td>
                                        </tr>
                                        <tr>{/* NET AMOUNT */}
                                            <td className="paymentView__table-row-label">Net Amount</td>
                                            <td className="paymentView__table-row-value">
                                                PHP {paymentData && paymentData.net_amount ? parseFloat(paymentData.net_amount)
                                                .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : "Not available"}
                                            </td>
                                        </tr>
                                        <tr>{/* DESCRIPTION */}
                                            <td className="paymentView__table-row-label">Description</td>
                                            <td className="paymentView__table-row-value">{paymentData.description}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* END OF PAYMENT DETAILS SECTION */}

                            {/* TRANSACTION RESULTS SECTION */}
                            <div className="paymentView__transaction-results-section">
                                <p className="paymentView__transaction-results-section--title">
                                    Transaction Results
                                </p>
                                <table className="paymentView__transaction-results-table">
                                    <tbody>
                                        <tr>{/* COMPLETED DATE */}
                                            <td className="paymentView__table-row-label">Date Completed</td>
                                            <td className="paymentView__table-row-value">{paymentData.completed_on}</td>
                                        </tr>
                                        <tr>{/* GATEWAY ID */}
                                            <td className="paymentView__table-row-label">Trace ID</td>
                                            <td className="paymentView__table-row-value">{paymentData.trace_id}</td>
                                        </tr>
                                        <tr>{/* PAYMENT METHOD */}
                                            <td className="paymentView__table-row-label">Payment Method</td>
                                            <td className="paymentView__table-row-value">In-store Payment</td>
                                        </tr>
                                        <tr>{/* PAYMENT SOURCE  */}
                                            <td className="paymentView__table-row-label">Payment From</td>
                                            <td className="paymentView__table-row-value">{paymentData.source_bank}</td>
                                        </tr>
                                        <tr>{/* FUNDING SOURCE  */}
                                            <td className="paymentView__table-row-label">Holding Account</td>
                                            <td className="paymentView__table-row-value">Account Balance</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* END OF TRANSACTION RESULTS SECTION */}
                        </div>
                        <div className="paymentView__merchant-support">
                            <p className="paymentView__merchant-support--title">Need help with this transaction?</p>
                            <p className="paymentView__merchant-support--details">
                                Send us an email to support@finduku.com for assistance with this transaction.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentView