import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import RegisterLoader from './RegisterLoader';
import Logo from '../../../assets/logo.svg';
import CSRFToken from '../../../components/CSRFToken';
import '../../../Styles/AuthStyles/Register/VerifySignup.css';

function VerifySignup ({ 
     wrongOTP,
     expiredOTP,
     otpError,
     confirmSignup,
     regData,
     updateRegisterData,
     resendingOTP,
     resentOTP,
     handleResendOTP,
     resetInvalidOTPMsg,
     accountEmail,
     isRegistering
}) {

     // Form Validation
     const validateOTP = Yup.object().shape({
          otp: Yup.string()
               .required('Verification code is required')
               .matches(/^[0-9]\d{5}$/, {message: "Verification code must be 6 digits.", excludeEmptyString: false}),
     })

     return (
          <>
               <div className="verifySignup" onClick={(e) => {e.stopPropagation()}}>
                    <div className="verifySignup__page-container">
                         <Formik
                         initialValues={regData}
                         onSubmit={confirmSignup}
                         validationSchema={validateOTP}
                         >

                         {({ handleChange, handleBlur, setFieldValue, values, errors, touched, isValid, dirty  }) => {

                                   const handleOtpPaste = (e, setFieldValue) => {
                                        e.preventDefault();
                                        const pastedData = e.clipboardData.getData("text").trim();
                                        if (pastedData.length === 6 && /^\d{6}$/.test(pastedData)) {
                                             setFieldValue("otp", pastedData);
                                        }
                                   };

                                   return (
                                        <Form>
                                             <CSRFToken />

                                             {/* HEADER SECTION */}
                                             <div className="verifySignup__header">
                                                  <img src={Logo} alt="Finduku Logo"/>
                                                  <p className="verifySignup__subheading">We're confirming your email address.</p>
                                             </div>

                                             {/* BODY SECTION */}
                                             <div className="verifySignup__body">

                                                  {resentOTP 
                                                  ?   (<div className="verifySignup__resend-otp">
                                                            <p className="verifySignup__resend-otp-text">A new OTP has been sent to your email.</p>
                                                       </div>)
                                                  :   null 
                                                  }

                                                  {/* DIRECTION SECTION */}
                                                  <div className="verifySignup__direction-wrapper">
                                                       <p className="verifySignup__direction-text">
                                                            We sent a single-use verification code to your email <span className="email">{accountEmail}</span>
                                                       </p>
                                                  </div>

                                                  {/* OTP INPUT SECTION */}
                                                  <div className="verifySignup__otp-wrapper" onPaste={(e) => handleOtpPaste(e, setFieldValue)}>
                                                       <input
                                                            type="text"
                                                            className={`verifySignup__otp-input ${
                                                                 errors.otp && touched.otp
                                                                 ? "verifySignup__input-error"
                                                                 : (wrongOTP || expiredOTP)
                                                                 ? "verifySignup__wrong-otp-error"
                                                                 : null
                                                            }`}
                                                            name="otp"
                                                            inputMode="numeric"
                                                            value={values.otp}
                                                            autoComplete="off"
                                                            onKeyDown={(e) => {
                                                                 const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'v'];
                                                                 const isCtrlV = (e.ctrlKey || e.metaKey) && e.key === 'v'; 
                                                            
                                                                 if (!/\d/.test(e.key) && !allowedKeys.includes(e.key) && !isCtrlV) {
                                                                      e.preventDefault();
                                                                 }
                                                            }}
                                                            onChange={(e) => {
                                                                 handleChange(e);
                                                                 updateRegisterData({ ...values, otp: e.target.value });
                                                                 resetInvalidOTPMsg();
                                                            }}
                                                            onBlur={handleBlur}
                                                            maxLength="6"
                                                       /> 
                                                  </div>
                                                  {/* VALIDATION ERRORS SECTION */}
                                                  <div className="verifySignup__verify-error-wrapper">
                                                       {errors.otp && touched.otp && <p className="verifySignup__verify-error">{errors.otp}</p>}
                                                       {wrongOTP && <p className="verifySignup__verify-error">{otpError}</p>}
                                                       {expiredOTP && <p className="verifySignup__verify-error">{otpError}</p>}
                                                  </div>

                                                  {/* BUTTON SECTION */}
                                                  <div className="verifySignup__button-wrapper">
                                                       <button 
                                                            type="submit" 
                                                            className={`verifySignup__submit-button ${!(values.otp && values.otp.length === 6) ? "disabled" : ""}`}
                                                            disabled={!(values.otp && values.otp.length === 6)}
                                                       >
                                                            {resendingOTP ? "Resending Code" : (isRegistering ? "Confirming" : "CONFIRM")}
                                                       </button>
                                                  </div>

                                                  {/* RESENT CODE SECTION */}
                                                  <div className="verifySignup__resend-code-wrapper">
                                                       <p className="verifySignup__resend-code">Haven't receive the code?</p>
                                                       <p className="verifySignup__resend-code-link" onClick={handleResendOTP}>Resend code</p>
                                                  </div>
                                             </div>

                                             {/* FOOTER SECTION */}
                                             <div className="verifySignup__footer">
                                                  <div className="verifySignup__code-direction-wrapper">
                                                       <p className="verifySignup__code-direction-text">
                                                            Please enter your verification code to verify your email address.
                                                            Your code will expire if not used in 5 minutes.
                                                       </p>
                                                  </div>
                                             </div>
                                        </Form>
                                   )
                              }}
                         </Formik>
                    </div>
                    {(isRegistering || resendingOTP) && <RegisterLoader />}
               </div>
          </>
     )
};



export default VerifySignup;