import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import { useSelector } from 'react-redux';
import Logo from '../../../assets/logo.svg';
import CSRFToken from '../../../components/CSRFToken';
import ResentOTP from './ResentOTP';
import ResetPassSuccess from './ResetPassSuccess';
import ForgotPassLoader from './ForgotPassLoader';
import '../../../Styles/AuthStyles/Password/ForgotPassword.css';
import { 
     BsExclamationCircle, 
     BsShieldLock, 
     BsEmojiFrown, 
     BsChevronRight,
     BsEyeSlashFill, 
     BsEyeFill
} from "react-icons/bs";


// API ENDPOINTS
const RESET_PASSWORD_ENDPOINT = '/apps/reset-password/'
const RESET_PASSWORD_CODE_ENDPOINT = '/apps/validate-reset-password-code/'
const RESEND_OTP_ENDPOINT = '/apps/resend-reset-password-otp/'
const SET_PASSWORD_ENDPOINT = '/apps/set_new_password/'

function ForgotPassword() {
     const navigate = useNavigate()
     const isLogin = useSelector((state) => state.auth.user ? state.auth.user.is_login : '');

     useEffect(() => {
          if (isLogin) {
              navigate('/signin');
          }
     }, [isLogin, navigate]);

     // Form Validation
     const validate = Yup.object({
          email: Yup.string()
          .email('The email address format is not valid.')
          .required('Please enter your email address.'),

          otp: Yup.string()
          .required('Your one-time passcode is required.')
          .matches(/^[0-9]\d{5}$/, {message: "Your one-time passcode must be 6 digits.", excludeEmptyString: false}),

          new_password: Yup.string()
          .required('Please create a strong password.')
          .matches(
               // eslint-disable-next-line
               /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
               "Password must have atleast 8 characters with 1 number, 1 uppercase, 1 lowercase and 1 special character."
          ),
          confirm_password: Yup.string()
          .required('Please confirm your password.')
          .oneOf([Yup.ref('new_password'), null], 'Passwords do not match.')
     })

     const [resetPasswordData, setResetPasswordData] = useState({
          email: '',
          otp: '',
          new_password: '',
          confirm_password: '',
     })

     // State for OTP errors
     const [wrongOTP, setWrongOTP] = useState(false)
     const [expiredOTP, setExpiredOTP] = useState(false)
     const [otpError, setOtpError] = useState(null);
     
     const [isResettingPassword, setIsResettingPassword] = useState(false)
     const [requestResetPasswordSuccessful, setRequestResetPasswordSuccessful] = useState(false)
     const [emailMissing, setEmailMissing] = useState(false)
     const [accountEmail, setAccountEmail] = useState(null)
     const [submitResetPasswordCodeSuccessful, setSubmitResetPasswordCodeSuccessful] = useState(false)
     const [resetPasswordError, setResetPasswordError] = useState(false)
     const [resetPasswordErrorMsg, setResetPasswordErrorMsg] = useState(null)
     const [resetPasswordErrorAction, setResetPasswordErrorAction] = useState(null)
     const [resetPasswordErrorCode, setResetPasswordErrorCode] = useState(null)
     const [resendingOTP, setResendingOTP] = useState(false)
     const [resentOTPSuccessful, setResentOTPSuccessful] = useState(false)
     const [submitNewPasswordSuccessful, setSubmitNewPasswordSuccessful] = useState(false)

         // Password Toggle
    const [type, setType] = useState('password');
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordToggle = () => {
          if (type === 'password'){
               setType('text');
               setShowPassword(true);
          }
          else {
               setType('password');
               setShowPassword(false);
          }
    }

     const updateResetPasswordData = (updatedData) => {
          setResetPasswordData(updatedData);
     };

     // Resets loginData and Incorrect Login states
     const resetEmailMissingMsg = () => {
          setEmailMissing(false);
     };

     const resetInvalidOTPMsg = () => {
          setWrongOTP(false);
          setExpiredOTP(false);
          setOtpError(null);
     }
      
     // Submits request to Confirm Login to backend
     const submitResetPassword = async () => {
          setIsResettingPassword(true)
          try {
               const res = await axios.post(
                    RESET_PASSWORD_ENDPOINT,
                    JSON.stringify({ 
                         email: resetPasswordData.email, 
                    }),
                    {
                         headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'X-CSRFToken' : Cookies.get('csrftoken')
                         }
                    }
               );
               if (res.data.success){
                    setRequestResetPasswordSuccessful(true);
                    setAccountEmail(res.data.validated_email)
               } else if (res.data.email_missing){
                    setEmailMissing(true);
               } else {
                    console.error('Invalid data received', res.data)
                    setResetPasswordError(true);
                    setResetPasswordErrorMsg("Oops! We encountered an error.")
                    setResetPasswordErrorAction("Please try again or contact support.")
                    setResetPasswordErrorCode("ERR_FINDUKU_2000")                
               }
          } catch (error) {
               console.log('Something went wrong when resetting passowrd', error)
               if (error.response) {
                    switch (error.response.status) {
                         case 400:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_3000")
                              break;
                         case 401:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_4000")
                              break;
                         case 403:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_5000")
                              break;
                         case 404:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_6000")
                              break;
                         case 500:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_7000")
                              break;
                         default:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_1000")                   
                    }
               } else {
                    setResetPasswordErrorMsg("Oops! We encountered an error.");
                    setResetPasswordErrorAction("Please try again or contact support.")
                    setResetPasswordErrorCode("ERR_FINDUKU_8000") 
               } 
               setResetPasswordError(true)           
          } finally {
               setIsResettingPassword(false)
          }
     };

     // Navigates to Signin Page
     const routeToLogin = () => {
          navigate('/signin')
     }

     // Submits OTP for password reset
     const submitResetPasswordCode = async () => {
          setIsResettingPassword(true)
          try {
               const res = await axios.post(
                    RESET_PASSWORD_CODE_ENDPOINT,
                    JSON.stringify({ 
                         email: resetPasswordData.email, 
                         otp: resetPasswordData.otp 
                    }),
                    {
                         headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'X-CSRFToken' : Cookies.get('csrftoken')
                         }
                    }
               );
               if (res.data.success){
                    setSubmitResetPasswordCodeSuccessful(true)
                    setRequestResetPasswordSuccessful(false)
               } else if (res.data.wrong_otp){
                    setWrongOTP(true);
                    setOtpError("Your one-time passcode is incorrect.")
               } else if (res.data.expired_otp){
                    setExpiredOTP(true);
                    setOtpError("Your one-time passcode has expired.")
               } else {
                    console.error('Invalid data received', res.data)
                    setResetPasswordError(true);
                    setResetPasswordErrorMsg("Oops! We encountered an error.")
                    setResetPasswordErrorAction("Please try again or contact support.")
                    setResetPasswordErrorCode("ERR_FINDUKU_2000")                
               }
          } catch (error) {
               console.log('Something went wrong when validating passcode', error)
               if (error.response) {
                    switch (error.response.status) {
                         case 400:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_3000")
                              break;
                         case 401:
                              setResetPasswordErrorMsg("Oops! Authorization is required.");
                              setResetPasswordErrorAction("Please login your account.")
                              setResetPasswordErrorCode("ERR_FINDUKU_4000")
                              break;
                         case 403:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_5000")
                              break;
                         case 404:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_6000")
                              break;
                         case 500:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_7000")
                              break;
                         default:
                              setResetPasswordErrorMsg("Oops! We encountered an error");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_1000")                    
                    }
               } else {
                    setResetPasswordErrorMsg("Oops! We encountered an error");
                    setResetPasswordErrorAction("Please try again or contact support.")
                    setResetPasswordErrorCode("ERR_FINDUKU_8000") 
               } 
               setResetPasswordError(true)           
          } finally {
               setIsResettingPassword(false)
          }
     };

     // Request to resend the OTP
     const requestResendCode = async () => {
          setResendingOTP(true)
          setWrongOTP(false);
          setExpiredOTP(false);
          setOtpError(null);
          try{
               const res = await axios.post(
                    RESEND_OTP_ENDPOINT,
                    JSON.stringify({ 
                        email: resetPasswordData.email, 
                    }),
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-CSRFToken' : Cookies.get('csrftoken')
                        }
                    }
               );
               if (res.data.success){
                    setResentOTPSuccessful(true);
               } else {
                    console.error('Invalid data received', res.data)
                    setResetPasswordError(true);
                    setResetPasswordErrorMsg("Oops! We encountered an error.")
                    setResetPasswordErrorAction("Please try again or contact support.")
                    setResetPasswordErrorCode("ERR_FINDUKU_2000") 
               }
          } catch (error) {
               console.log('Something went wrong when resending passcode', error)
               if (error.response) {
                    switch (error.response.status) {
                         case 400:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_3000")
                              break;
                         case 401:
                              setResetPasswordErrorMsg("Oops! Authorization is required.");
                              setResetPasswordErrorAction("Please login your account.")
                              setResetPasswordErrorCode("ERR_FINDUKU_4000")
                              break;
                         case 403:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_5000")
                              break;
                         case 404:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_6000")
                              break;
                         case 500:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_7000")
                              break;
                         default:
                              setResetPasswordErrorMsg("Oops! We encountered an error");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_1000")                    
                    }
               } else {
                    setResetPasswordErrorMsg("Oops! We encountered an error");
                    setResetPasswordErrorAction("Please try again or contact support.")
                    setResetPasswordErrorCode("ERR_FINDUKU_8000") 
               } 
               setResetPasswordError(true)           
          } finally {
               setResendingOTP(false)
          }
     };

     // Navigates to Support Page
     const handleTryAgain = () => {
          setResetPasswordError(false);
          setResetPasswordErrorMsg(null);
          setResetPasswordErrorAction(null);
          setResetPasswordErrorCode(null);
     }

     const closeResentOTPModal = () => {
          setResentOTPSuccessful(false);
     }

     // Submit the new password
     const submitNewPassword = async () => {
          setIsResettingPassword(true)
          try{
               const res = await axios.post(
                    SET_PASSWORD_ENDPOINT,
                    JSON.stringify({ 
                         email: resetPasswordData.email, 
                         otp: resetPasswordData.otp,
                         new_password: resetPasswordData.new_password,
                    }),
                    {
                         headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'X-CSRFToken' : Cookies.get('csrftoken')
                         }
                    }
               );
               if (res.data.success){
                    setSubmitNewPasswordSuccessful(true);
                    setSubmitResetPasswordCodeSuccessful(false);
               } else {
                    console.error('Invalid data received', res.data)
                    setResetPasswordError(true);
                    setResetPasswordErrorMsg("Oops! We encountered an error.")
                    setResetPasswordErrorAction("Please try again or contact support.")
                    setResetPasswordErrorCode("ERR_FINDUKU_2000") 
               }
          } catch (error) {
               console.log('Something went wrong when submitting new password', error)
               if (error.response) {
                    switch (error.response.status) {
                         case 400:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_3000")
                              break;
                         case 401:
                              setResetPasswordErrorMsg("Oops! Authorization is required.");
                              setResetPasswordErrorAction("Please login your account.")
                              setResetPasswordErrorCode("ERR_FINDUKU_4000")
                              break;
                         case 403:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_5000")
                              break;
                         case 404:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_6000")
                              break;
                         case 500:
                              setResetPasswordErrorMsg("Oops! We encountered an error.");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_7000")
                              break;
                         default:
                              setResetPasswordErrorMsg("Oops! We encountered an error");
                              setResetPasswordErrorAction("Please try again or contact support.")
                              setResetPasswordErrorCode("ERR_FINDUKU_1000")                    
                    }
               } else {
                    setResetPasswordErrorMsg("Oops! We encountered an error");
                    setResetPasswordErrorAction("Please try again or contact support.")
                    setResetPasswordErrorCode("ERR_FINDUKU_8000") 
               } 
               setResetPasswordError(true)           
          } finally {
               setIsResettingPassword(false)
          }
     };

     const closeResetPassSuccessModal = () => {
          navigate('/signin');
     }


         // Navigates to Contact Sales Page
    const routeToSignUp = () => {
     navigate("/signup");
 }

     return (
          <>   
               <div className="forgot-password">
                    <div className="forgot-password__page-container">
                         <div className="forgot-password__header">
                              <div className="forgot-password__logo-wrapper">
                                   <img src={Logo} alt="Finduku Logo"/>
                              </div>
                              <div className="forgot-password__signup-wrapper" onClick={routeToSignUp}>
                                   <p className="forgot-password__signup-text">Sign Up</p>
                                   <BsChevronRight className="forgot-password__signup-icon" />
                              </div>
                         </div>
                         <div className="forgot-password__page-border">
                              <Formik
                                   initialValues={resetPasswordData}
                                   validationSchema={validate}
                              >
                                   {({ handleChange, handleBlur, setFieldValue, values, errors, touched }) => {

                                        const handleOtpPaste = (e, setFieldValue) => {
                                             e.preventDefault();
                                             const pastedData = e.clipboardData.getData("text").trim();
                                             if (pastedData.length === 6 && /^\d{6}$/.test(pastedData)) {
                                                  setFieldValue("otp", pastedData);
                                             }
                                        };

                                        return (
                                             <Form>
                                                  <CSRFToken />
                                                  {resetPasswordError ? (
                                                       <div className="forgot-password__error-section">
                                                            <div className="forgot-password__error-message-container">
                                                                 <BsEmojiFrown  className="forgot-password__error-message-icon"/>
                                                                 <p className="forgot-password__error-msg">{resetPasswordErrorMsg}</p>
                                                                 <p className="forgot-password__error-action">{resetPasswordErrorAction}</p>
                                                                 <p className="forgot-password__error-code">{resetPasswordErrorCode}</p>
                                                            </div>
                                                            <div className="forgot-password__error-button-container">
                                                                 <a
                                                                      href='https://dev.finduku.com/contact-support'
                                                                      target="_blank" 
                                                                      rel="noopener noreferrer"
                                                                      className="forgot-password__contact-support"
                                                                 >
                                                                      Contact Support
                                                                 </a>
                                                                 <button 
                                                                      className="forgot-password__error-button"
                                                                      type="button"
                                                                      onClick={handleTryAgain}
                                                                 >
                                                                      Try Again
                                                                 </button>
                                                            </div>
                                                       </div>
                                                  ) : (
                                                       <>
                                                            {requestResetPasswordSuccessful ? (
                                                                 <div className="forgot-password__confirmation">
                                                                      <div className="forgot-password__confirmation-header">
                                                                           <BsShieldLock className="forgot-password__confirmation-icon" />
                                                                           <p className="forgot-password__confirmation-heading">We're just making sure it's you.</p>
                                                                      </div>
                                                                      <div className="forgot-password__confirmation-body">
                                                                           <div className="forgot-password__confirmation-message-wrapper">
                                                                                <p className="forgot-password__confirmation-message">
                                                                                     We sent a single-use passcode to your email <span className="forgot-password__confirmation-data">
                                                                                               {accountEmail}
                                                                                          </span>
                                                                                </p>
                                                                           </div>
                                                                           <div className="forgot-password__otp-wrapper" onPaste={(e) => handleOtpPaste(e, setFieldValue)}>
                                                                                <input
                                                                                     type="text"
                                                                                     className={`forgot-password__otp-input ${
                                                                                          errors.otp && touched.otp
                                                                                          ? "forgot-password__otp-input-error"
                                                                                          : wrongOTP
                                                                                          ? "forgot-password__wrong-otp-error"
                                                                                          : null
                                                                                     }`}
                                                                                     name="otp"
                                                                                     inputMode="numeric"
                                                                                     value={values.otp}
                                                                                     autoComplete="off"
                                                                                     onKeyDown={(e) => {
                                                                                          const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'v'];
                                                                                          const isCtrlV = (e.ctrlKey || e.metaKey) && e.key === 'v'; 
                                                                                     
                                                                                          if (!/\d/.test(e.key) && !allowedKeys.includes(e.key) && !isCtrlV) {
                                                                                               e.preventDefault();
                                                                                          }
                                                                                     }}
                                                                                     onChange={(e) => {
                                                                                          handleChange(e);
                                                                                          updateResetPasswordData({ ...values, otp: e.target.value });
                                                                                          resetInvalidOTPMsg();
                                                                                     }}
                                                                                     onBlur={handleBlur}
                                                                                     maxLength="6"
                                                                                     placeholder='Enter your one-time passcode'
                                                                                />
                                                                                <div className="forgot-password__otp-error-wrapper">
                                                                                     {errors.otp && touched.otp && <p className="forgot-password__otp-error">{errors.otp}</p>}
                                                                                     {wrongOTP && <p className="forgot-password__otp-error">{otpError}</p>}
                                                                                     {expiredOTP && <p className="forgot-password__otp-error">{otpError}</p>}
                                                                                </div>
                                                                           </div>
                                                                           <div className="forgot-password__confirmation-message-button-wrapper">
                                                                                <button
                                                                                     type="button"
                                                                                     className={`forgot-password__confirmation-button ${values.otp.length === 6 ? "" : "disabled"}`}
                                                                                     onClick={submitResetPasswordCode}
                                                                                     disabled={values.otp.length !== 6}
                                                                                >
                                                                                     {resendingOTP ? "Resending Code" : (isResettingPassword ? "Confirming" : "CONFIRM")}
                                                                                </button>
                                                                           </div>
                                                                      </div>
                                                                      <div className="forgot-password__confirmation-footer">
                                                                           <div className="forgot-password__resend-code-wrapper">
                                                                                <span className="forgot-password__resend-code-text">Didn't get the code?</span>
                                                                                <span 
                                                                                     className="forgot-password__resend-code-link"
                                                                                     onClick={requestResendCode}
                                                                                >
                                                                                     Resend Code
                                                                                </span>
                                                                           </div>
                                                                           <p className="forgot-password__confirmation-login-link" onClick={routeToLogin}>Return to Login</p>
                                                                      </div>
                                                                 </div>
                                                            ) : (
                                                                 <>
                                                                      {submitResetPasswordCodeSuccessful ? (
                                                                           <div className="forgot-password__set-password">
                                                                                <div className="forgot-password__set-password-header">
                                                                                     <p className="forgot-password__set-password-heading">
                                                                                          Set new password
                                                                                     </p>
                                                                                     <p className="forgot-password__set-password-subheading">
                                                                                          Create your new password and enter it twice so we can verify you typed it in correctly.
                                                                                     </p>
                                                                                     
                                                                                </div>
                                                                                <div className="forgot-password__set-password-input-wrapper">
                                                                                     <div className="forgot-password__set-password-input-item">
                                                                                          <p className="forgot-password__set-password-input-label">
                                                                                               New Password
                                                                                          </p>
                                                                                          <input 
                                                                                               type={type} 
                                                                                               className={`forgot-password__set-password-input ${
                                                                                                    errors.new_password && touched.new_password
                                                                                                    ? "validation-error"
                                                                                                    : null
                                                                                               }`}
                                                                                               name="new_password"
                                                                                               value={values.new_password}
                                                                                               minLength="8"
                                                                                               onChange={(e) => {
                                                                                                    handleChange(e);
                                                                                                    updateResetPasswordData({ ...values, new_password: e.target.value });
                                                                                               }}
                                                                                               onBlur={handleBlur}
                                                                                               autoComplete='off'
                                                                                          />
                                                                                          <div className="forgot-password__toggle-wrapper" onClick={handlePasswordToggle}>
                                                                                               {values.new_password && (
                                                                                                    <span className="forgot-password__toggle-icon-wrapper">
                                                                                                         {showPassword ? (
                                                                                                              <BsEyeSlashFill className="forgot-password__eye-icon" />
                                                                                                         ) : (
                                                                                                              <BsEyeFill className="forgot-password__eye-icon" />
                                                                                                         )}
                                                                                                    </span>
                                                                                               )}
                                                                                          </div>
                                                                                          {errors.new_password && touched.new_password && (
                                                                                               <p className="forgot-password__set-password-input-error"> 
                                                                                                    <BsExclamationCircle className="forgot-password__set-password-input-error-icon" />
                                                                                                    {errors.new_password}
                                                                                               </p>
                                                                                          )}
                                                                                     </div>
                                                                                     <div className="forgot-password__set-password-input-item">
                                                                                          <p className="forgot-password__set-password-input-label">
                                                                                               Confirm Password
                                                                                          </p>
                                                                                          <input 
                                                                                               type={type} 
                                                                                               className={`forgot-password__set-password-input ${
                                                                                                    errors.confirm_password && touched.confirm_password
                                                                                                    ? "validation-error"
                                                                                                    : null
                                                                                               }`}
                                                                                               name="confirm_password"
                                                                                               value={values.confirm_password}
                                                                                               minLength="8"
                                                                                               onChange={(e) => {
                                                                                                    handleChange(e);
                                                                                                    updateResetPasswordData({ ...values, confirm_password: e.target.value });
                                                                                               }}
                                                                                               onBlur={handleBlur}
                                                                                               autoComplete='off'
                                                                                          />
                                                                                          <div className="forgot-password__toggle-wrapper" onClick={handlePasswordToggle}>
                                                                                               {values.confirm_password && (
                                                                                                    <span className="forgot-password__toggle-icon-wrapper">
                                                                                                         {showPassword ? (
                                                                                                              <BsEyeSlashFill className="forgot-password__eye-icon" />
                                                                                                         ) : (
                                                                                                              <BsEyeFill className="forgot-password__eye-icon" />
                                                                                                         )}
                                                                                                    </span>
                                                                                               )}
                                                                                          </div>
                                                                                          {errors.confirm_password && touched.confirm_password && (
                                                                                               <p className="forgot-password__set-password-input-error"> 
                                                                                                    <BsExclamationCircle className="forgot-password__set-password-input-error-icon" />
                                                                                                    {errors.confirm_password}
                                                                                               </p>
                                                                                          )}
                                                                                     </div>
                                                                                </div>
                                                                                <div className="forgot-password__set-password-button-wrapper">
                                                                                     <button 
                                                                                          type="button"
                                                                                          className={`forgot-password__set-password-button ${values.new_password && values.confirm_password && values.new_password === values.confirm_password ? "" : "disabled"}`}
                                                                                          disabled={!values.new_password || !values.confirm_password || values.new_password !== values.confirm_password}
                                                                                          onClick={submitNewPassword}
                                                                                     >
                                                                                          {isResettingPassword ? "Setting Password" : "Set Password"}
                                                                                     </button>
                                                                                </div>
                                                                                <p className="forgot-password__confirmation-login-link" onClick={routeToLogin}>Return to Login</p>
                                                                           </div>
                                                                      ) : (
                                                                           <div className="forgot-password__body">
                                                                                <div className="forgot-password__heading-wrapper">
                                                                                     <p className="forgot-password__heading">Forgot your password?</p>
                                                                                     <p className="forgot-password__subheading">
                                                                                          Enter your email address and we will send you instructions to reset your password.
                                                                                     </p>
                                                                                </div>
                                                                                <div className="forgot-password__email-input-wrapper">
                                                                                     <input
                                                                                          className={`forgot-password__email-input ${
                                                                                               errors.email && touched.email
                                                                                               ? "validation-error"
                                                                                               : emailMissing
                                                                                               ? "email-missing-error"
                                                                                               : null
                                                                                          }`}
                                                                                          type="email"
                                                                                          name="email"
                                                                                          onChange={(e) => {
                                                                                               handleChange(e);
                                                                                               updateResetPasswordData({ ...values, email: e.target.value });
                                                                                               resetEmailMissingMsg();
                                                                                          }}
                                                                                          onBlur={handleBlur}
                                                                                          value={values.email}
                                                                                          autoComplete="off"
                                                                                          placeholder='Email address'
                                                                                          required
                                                                                     />
                                                                                     {errors.email && touched.email && (
                                                                                          <p className="forgot-password__email-input-error"> 
                                                                                               {errors.email}
                                                                                          </p>
                                                                                     )}
                                                                                     {emailMissing &&
                                                                                          <p className="forgot-password__email-input-error">
                                                                                               We couldn't find your account.
                                                                                          </p>
                                                                                     }
                                                                                </div>
                                                                                <div className="forgot-password__button-wrapper">
                                                                                     <button
                                                                                          className={`forgot-password__button ${values.email ? "" : "disabled"}`}
                                                                                          type="button"
                                                                                          disabled={!values.email}
                                                                                          onClick={submitResetPassword}
                                                                                     >
                                                                                          {isResettingPassword ? "Processing" : "Reset Password"}
                                                                                     </button>
                                                                                </div>
                                                                                <div className="forgot-password__login-link-wrapper">
                                                                                     <span className="forgot-password__login-link" onClick={routeToLogin}>Return to Login</span>
                                                                                </div>
                                                                           </div>
                                                                      )}
                                                                 </>
                                                            )}
                                                       </>
                                                  )}
                                             </Form>
                                        )
                                   }}
                              </Formik>
                         </div>
                    </div>
               </div>
               {resentOTPSuccessful &&
                    <ResentOTP 
                         closeResentOTPModal={closeResentOTPModal}
                    />
               }
               {submitNewPasswordSuccessful &&
                    <ResetPassSuccess 
                         closeResetPassSuccessModal={closeResetPassSuccessModal}
                    />
               }
               {(isResettingPassword || resendingOTP) && <ForgotPassLoader />}
          </>
     )
}

export default ForgotPassword