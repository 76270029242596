import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import { destinationBanks } from './destinationBanks';
import { provinces } from './provinces';
import CSRFToken from '../../../../components/CSRFToken';
import PayoutInfoLoader from './PayoutInfoLoader';
import { BsFillExclamationTriangleFill, BsCheckCircle, BsEmojiFrown } from "react-icons/bs";
import '../../../../Styles/MainStyles/Settings/PayoutInfo/UpdateBank.css';

const AccountTypeOptions = [
    'Corporate',
    'Personal',
]


const BankNameTextField = styled(TextField)({
    '& .MuiInputBase-input': {
      width: '100%', 
      height: '10px', 
      fontFamily: 'PP Neue Montreal Variable, Arial, sans-serif', 
    },
   '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        border: '0.1px solid #afaeae',
      },
     '&.Mui-focused fieldset': {
        border: '0.1px solid #284154', 
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0.1px solid #afaeae', 
      borderRadius: 2,
    },
    '& .MuiInputBase-input::placeholder': {
      fontFamily: 'PP Neue Montreal Variable, Arial, sans-serif', 
      fontSize: '15px', 
      color: '#A3ACC9', 
      opacity: 1,
    },
    '&.input-error .MuiOutlinedInput-root': {
      borderColor: 'rgb(211, 12, 12) !important',
      backgroundColor: 'rgb(251,233,232)',
      '&:hover fieldset': {
        borderColor: 'rgb(211, 12, 12) !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(211, 12, 12) !important',
      },
    },
});

const ProvincesField = styled(TextField)({
    '& .MuiInputBase-input': {
      width: '100%', 
      height: '10px', 
      fontFamily: 'PP Neue Montreal Variable, Arial, sans-serif', 
    },
   '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        border: '0.1px solid #afaeae',
      },
     '&.Mui-focused fieldset': {
        border: '0.1px solid #284154', 
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0.1px solid #afaeae', 
      borderRadius: 2,
    },
    '& .MuiInputBase-input::placeholder': {
      fontFamily: 'Figtree, Arial, sans-serif', 
      fontSize: '15px', 
      color: '#A3ACC9', 
      opacity: 1,
    },
    '&.input-error .MuiOutlinedInput-root': {
      borderColor: 'rgb(211, 12, 12) !important',
      backgroundColor: 'rgb(251,233,232)',
      '&:hover fieldset': {
        borderColor: 'rgb(211, 12, 12) !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(211, 12, 12) !important',
      },
    },
});

const AccountTypeField = styled(TextField)({
    '& .MuiInputBase-input': {
      width: '100%', 
      height: '10px', 
      fontFamily: 'PP Neue Montreal Variable, Arial, sans-serif', 
    },
   '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        border: '0.1px solid #afaeae',
      },
     '&.Mui-focused fieldset': {
        border: '0.1px solid #284154', 
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0.1px solid #afaeae', 
      borderRadius: 2,
    },
    '& .MuiInputBase-input::placeholder': {
      fontFamily: 'PP Neue Montreal Variable, Arial, sans-serif', 
      fontSize: '15px', 
      color: '#A3ACC9', 
      opacity: 1,
    },
    '&.input-error .MuiOutlinedInput-root': {
      borderColor: 'rgb(211, 12, 12) !important',
      backgroundColor: 'rgb(251,233,232)',
      '&:hover fieldset': {
        borderColor: 'rgb(211, 12, 12) !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(211, 12, 12) !important',
      },
    },
});



function UpdateBank({ 
    bankFormData, 
    onClose, 
    updateBankData, 
    submitUpdatedBankData, 
    isUpdateBankSuccessful, 
    isUpdatingBankAccount,
    updateBankError,
    updateBankErrorMsg,
    updateBankErrorAction,
    updateBankErrorCode,
}) {

    const validate = Yup.object({
        bank_name: Yup.string()
        .required('Please select a bank')
        .matches(/^[a-zA-Z0-9 .]*$/, 'Only letters, numbers, periods, and spaces are allowed'),

        account_name: Yup.string()
        .required('Account holder is required'),

        account_number: Yup.string()
        .required('Account number is required')
        .matches(/^\d+(\.\d{1,2})?$/, { message: "Account number must be a valid number.", excludeEmptyString: false })
        .test('is-not-zero', 'Account number cannot be zero', value => value !== '0'),

        account_type: Yup.string()
        .required('Please select an account type'),

        address_line1: Yup.string()
        .required('Address line 1 is required'),

        address_line2: Yup.string()
        .required('Address line 2 is required'),

        city: Yup.string()
        .required('City or municipality is required'),

        province: Yup.string()
        .required('Province is required'),

        zipcode: Yup.string()
        .required('Zipcode is required')
        .matches(/^\d+(\.\d{1,2})?$/, { message: "Zipcode must be a valid number.", excludeEmptyString: false })
    })


    return (
        <>
            <div className="update-bank" onClick={(e) => {e.stopPropagation()}}>
                <div className="update-bank__page-container">
                    {updateBankError ? (
                        <div className="update-bank__error-message-wrapper">
                            <BsEmojiFrown className="update-bank__error-message-icon"/>
                            <p className="update-bank__error-message">{updateBankErrorMsg}</p>
                            <p className="update-bank__error-action">{updateBankErrorAction}</p>
                            <p className="update-bank__error-code">{updateBankErrorCode}</p>
                            <div className="update-bank__error-button-wrapper">
                                <button 
                                    className="update-bank__error-button"
                                    type="button" 
                                    onClick={onClose}
                                >
                                    OKAY
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            {isUpdateBankSuccessful ? (
                                <div className="update-bank__message-wrapper">
                                    <BsCheckCircle className="update-bank__message-icon"/>
                                    <p>Your bank account has been updated successfully.</p>
                                    <button 
                                        type="button" 
                                        onClick={onClose}
                                    >
                                        Close
                                    </button>
                                </div>

                            ) : (
                                <Formik
                                    initialValues={bankFormData}
                                    onSubmit={submitUpdatedBankData}
                                    validationSchema={validate}
                                >

                                    {( { handleChange, handleBlur, values, handleSubmit, errors, touched, isValid, dirty, setFieldValue }) => {

                                        return (
                                            <Form>
                                                <CSRFToken />
                                                <div className="update-bank__header">
                                                    <div className="heading-wrapper">
                                                        <p className="heading">Update Bank Account</p>
                                                    </div>
                                                    <div className="subheading-wrapper">
                                                        <p className="subheading">
                                                            Enumerate a new bank account to receive your payouts.
                                                            Your payout requests will be sent to this bank account.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="update-bank__body">

                                                    {/* BANK NAME & BANK CODE */}
                                                    <div className="update-bank__input-wrapper">
                                                        <p>Bank Name</p>
                                                        <Autocomplete
                                                            options={destinationBanks}
                                                            getOptionLabel={(option) => option.bank_name}
                                                            onChange={(event, newValue) => {
                                                                setFieldValue("bank_name", newValue ? newValue.bank_name : "");
                                                                setFieldValue("bank_code", newValue ? newValue.bank_code : "");
                                                                updateBankData({ 
                                                                    ...values, 
                                                                    bank_name: newValue ? newValue.bank_name : "", 
                                                                    bank_code: newValue ? newValue.bank_code : ""
                                                                });
                                                            }}
                                                            value={destinationBanks.find(option => option.bank_code === bankFormData.bank_code) || null}
                                                            renderInput={(params) => (
                                                                <BankNameTextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    name="bank_name"
                                                                    onBlur={(event) => {
                                                                        handleBlur("bank_name")(event);
                                                                        // Optionally update form state on blur if necessary
                                                                        setFieldValue("bank_name", event.target.value);
                                                                    }}
                                                                    value={values.bank_name}
                                                                    placeholder="Select your bank"
                                                                    error={touched.bank_name && !!errors.bank_name}
                                                                    className={touched.bank_name && errors.bank_name ? 'update-bank-input-error' : ''}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    {errors.bank_name && touched.bank_name && (
                                                        <p className="update-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.bank_name}
                                                        </p>
                                                    )}

                                                    {/* ACCOUNT NAME */}
                                                    <div className="update-bank__input-wrapper">
                                                        <p>Account Holder</p>
                                                        <input
                                                            className={`update-bank__inputs ${errors.account_name && touched.account_name ? "update-bank-input-error" : null}`}
                                                            type="text"
                                                            name="account_name"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateBankData({ ...values, account_name: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.account_name}
                                                            placeholder="Name of the Account Holder"
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                    {errors.account_name && touched.account_name && (
                                                        <p className="update-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.account_name}
                                                        </p>
                                                    )}

                                                    {/* ACCOUNT NUMBER */}
                                                    <div className="update-bank__input-wrapper">
                                                        <p>Account Number</p>
                                                        <input
                                                            className={`update-bank__inputs ${errors.account_number && touched.account_number ? "update-bank-input-error" : null}`}
                                                            type="text"
                                                            name="account_number"
                                                            onKeyDown={(e) => {
                                                                const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateBankData({ ...values, account_number: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.account_number}
                                                            placeholder="Provide your account number"
                                                            autoComplete="off"
                                                            required
                                                            inputMode="numeric"
                                                        />                                                  
                                                    </div>
                                                    {errors.account_number && touched.account_number && (
                                                        <p className="update-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.account_number}
                                                        </p>
                                                    )}

                                                    {/* ACCOUNT TYPE */}
                                                    <div className="update-bank__input-wrapper">
                                                        <p>Account Type</p>
                                                        <Autocomplete
                                                            options={AccountTypeOptions}
                                                            getOptionLabel={(option) => option} 
                                                            onChange={(event, newValue) => {
                                                                setFieldValue("account_type", newValue || "");
                                                                updateBankData({
                                                                    ...values,
                                                                    account_type: newValue || ""
                                                                });
                                                            }}
                                                            value={values.account_type || null}
                                                            renderInput={(params) => (
                                                                <AccountTypeField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    name="account_type"
                                                                    onBlur={(event) => {
                                                                        handleBlur("account_type")(event);
                                                                        setFieldValue("account_type", values.account_type || "");
                                                                    }}
                                                                    value={values.account_type}
                                                                    placeholder="Select an account type"
                                                                    error={touched.account_type && !!errors.account_type}
                                                                    className={touched.account_type && errors.account_type ? 'update-bank-input-error' : ''}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    {errors.account_type && touched.account_type && (
                                                        <p className="update-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.account_type}
                                                        </p>
                                                    )}

                                                    {/* ADDRESS LINE 1 */}
                                                    <div className="update-bank__input-wrapper">
                                                        <p>Address Line 1</p>
                                                        <input
                                                            className={`update-bank__inputs ${errors.address_line1 && touched.address_line1 ? "update-bank-input-error" : null}`}
                                                            type="text"
                                                            name="address_line1"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateBankData({ ...values, address_line1: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.address_line1}
                                                            placeholder="House No, Building and Street Name"
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                    {errors.address_line1 && touched.address_line1 && (
                                                        <p className="update-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.address_line1}
                                                        </p>
                                                    )}

                                                    {/* ADDRESS LINE 2 */}
                                                    <div className="update-bank__input-wrapper">
                                                        <p>Address Line 2</p>
                                                        <input
                                                            className={`update-bank__inputs ${errors.address_line2 && touched.address_line2 ? "update-bank-input-error" : null}`}
                                                            type="text"
                                                            name="address_line2"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateBankData({ ...values, address_line2: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.address_line2}
                                                            placeholder="Village, Subdivision and Barangay"
                                                            autoComplete="off"
                                                        >
                                                        </input>
                                                    </div>
                                                    {errors.address_line2 && touched.address_line2 && (
                                                        <p className="update-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.address_line2}
                                                        </p>
                                                    )}

                                                    {/* CITY OR MUNICIPALITY */}
                                                    <div className="update-bank__input-wrapper">
                                                        <p>City or Municipality</p>
                                                        <input
                                                            className={`update-bank__inputs ${errors.city && touched.city ? "update-bank-input-error" : null}`}
                                                            type="text"
                                                            name="city"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateBankData({ ...values, city: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.city}
                                                            placeholder="City or Municipality"
                                                            autoComplete="off"
                                                        >
                                                        </input>
                                                    </div>
                                                    {errors.city && touched.city && (
                                                        <p className="update-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.address_line2}
                                                        </p>
                                                    )}

                                                    {/* PROVINCES */}
                                                    <div className="update-bank__input-wrapper">
                                                        <p>Province</p>
                                                        <Autocomplete
                                                            options={provinces}
                                                            getOptionLabel={(option) => option} 
                                                            onChange={(event, newValue) => {
                                                                setFieldValue("province", newValue || "");
                                                                updateBankData({
                                                                    ...values,
                                                                    province: newValue || ""
                                                                });
                                                            }}
                                                            value={values.province || null}
                                                            renderInput={(params) => (
                                                                <ProvincesField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    name="province"
                                                                    onBlur={(event) => {
                                                                        handleBlur("province")(event);
                                                                        setFieldValue("province", values.province || "");
                                                                    }}
                                                                    value={values.province}
                                                                    placeholder="Select your province"
                                                                    error={touched.province && !!errors.province}
                                                                    className={touched.province && errors.province ? 'update-bank-input-error' : ''}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    {errors.province && touched.province && (
                                                        <p className="update-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.province}
                                                        </p>
                                                    )}

                                                    {/* ZIPCODE */}
                                                    <div className="update-bank__input-wrapper">
                                                        <p>Zipcode</p>
                                                        <input
                                                            className={`update-bank__inputs ${errors.zipcode && touched.zipcode ? "update-bank-input-error" : null}`}
                                                            type="text"
                                                            name="zipcode"
                                                            onKeyDown={(e) => {
                                                                const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                updateBankData({ ...values, zipcode: e.target.value });
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.zipcode}
                                                            placeholder="Provide your zipcode"
                                                            autoComplete="off"
                                                            required
                                                            inputMode="numeric"
                                                        />                                             
                                                    </div>
                                                    {errors.zipcode && touched.zipcode && (
                                                        <p className="update-bank-error-message"> 
                                                            <BsFillExclamationTriangleFill className="input-error-icon" />
                                                            {errors.zipcode}
                                                        </p>
                                                    )}

                                                </div>
                                                <div className="update-bank__footer">
                                                    <div className="update-bank__button-wrapper">
                                                        <button 
                                                            type="button" 
                                                            onClick={() => {
                                                                onClose();
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button 
                                                            type="submit"
                                                            disabled={!(isValid && dirty && bankFormData.bank_name && bankFormData.account_name && bankFormData.account_number !== '0' && bankFormData.address_line1 && bankFormData.address_line2 && bankFormData.city && bankFormData.province && bankFormData.zipcode )}
                                                            className={!(isValid && dirty && bankFormData.bank_name && bankFormData.account_name && bankFormData.account_number !== '0' && bankFormData.address_line1 && bankFormData.address_line2 && bankFormData.city && bankFormData.province && bankFormData.zipcode ) ? "disabled" : ""}
                                                        >
                                                            {isUpdatingBankAccount ? "Updating" : "Update Account"}
                                                        </button>
                                                    </div>
                                                </div>

                                            </Form>
                                        )
                                    }}

                                </Formik>
                            )}
                        </>
                    )}
                </div>
                {isUpdatingBankAccount && <PayoutInfoLoader />}
            </div>
        </>
    )
}

export default UpdateBank