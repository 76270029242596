import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from './redux/slices/authSlice.js';
import { reset_home } from './redux/slices/homeSlice.js';
import { reset_insights } from './redux/slices/insightsSlice.js';
import { updateLogout } from './redux/slices/logoutSlice.js';
import { reset_merchant } from './redux/slices/merchantSlice.js';
import { reset_payments } from './redux/slices/paymentsSlice.js';
import { reset_payouts } from './redux/slices/payoutsSlice.js';
import { reset_settings } from './redux/slices/settingsSlice.js';


// LAY-OUTS
import WONLayout from './hocs/WONLayout';
import WNLayout from './hocs/WNLayout';

// OTHER PAGES
import NotFound from './components/ErrorPages/NotFound'

// AUTH PAGES
import Onboarding from './Pages/Auth/Onboarding/Onboarding.js'
import SetCompany from './Pages/Auth/Onboarding/SetCompany.js'
import AppSubmitted from './Pages/Auth/Onboarding/AppSubmitted.js'
import Register from './Pages/Auth/Register/Register.js'
import Login from './Pages/Auth/Login/Login.js'
import ForgotPassword from './Pages/Auth/Password/ForgotPassword.js'
import SetupUser from './Pages/Auth/Users/SetupUser.js';

// MAIN PAGES
import Main from './Pages/Main/Main';
import Home from './Pages/Main/Home/Home';
import Transactions from './Pages/Main/Transactions/Transactions';
import PaymentView from './Pages/Main/Transactions/Payments/PaymentView';
import PayoutView from './Pages/Main/Transactions/Payouts/PayoutView.js'
import Reports from './Pages/Main/Reports/Reports';
import Integrations from './Pages/Main/Integrations/Integrations';
import Settings from './Pages/Main/Settings/Settings';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    let timer;

    const resetInactivityTimer = () => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(logout());
        dispatch(reset_home());
        dispatch(reset_insights());
        dispatch(updateLogout());
        dispatch(reset_merchant());
        dispatch(reset_payments());
        dispatch(reset_payouts());
        dispatch(reset_settings());
      }, 30 * 60 * 1000);
    };

    // Reset the timer on any user interaction
    window.addEventListener('mousemove', resetInactivityTimer);
    window.addEventListener('keydown', resetInactivityTimer);
    window.addEventListener('scroll', resetInactivityTimer);
    window.addEventListener('click', resetInactivityTimer);

    // Initialize the timer
    resetInactivityTimer();

    return () => {
      // Clean up event listeners on component unmount
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keydown', resetInactivityTimer);
      window.removeEventListener('scroll', resetInactivityTimer);
      window.removeEventListener('click', resetInactivityTimer);
    };
  }, [dispatch]);


  return (
    <div className="App">
      <Routes>
        <Route element={<WONLayout />}>
          <Route path="signup" element={<Register />} />
          <Route path="/signup-success" element={<AppSubmitted />} />
          <Route path="onboarding" element={<Onboarding />} />
          <Route path="/onboarding/company" element={<SetCompany />} />
          <Route path="signin" element={<Login />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="setup" element={<SetupUser />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route element={<WNLayout />}>
          <Route path="/" element={<Main />} >
            <Route index element={<Home />} />
            <Route path="transactions" element={<Transactions />} >
              <Route index element={<Transactions />} />
              <Route path="payouts" element={<Transactions />} />
            </Route>
            <Route path="/transactions/payments/:reference" element={<PaymentView />} />
            <Route path="/transactions/payouts/:reference" element={<PayoutView />} />
            <Route path="reports" element={<Reports />} />
            <Route path="integrations" element={<Integrations />}/>
            <Route path="settings" element={<Settings />} >
              <Route index element={<Settings />} />
              <Route path="Security" element={<Settings />} />
              <Route path="BusinessProfile" element={<Settings />} end/>
              <Route path="LegalInformation" element={<Settings />} end/>
              <Route path="PayoutSettings" element={<Settings />} end/>
              <Route path="ManageUsers" element={<Settings />} end/>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route> 
      </Routes>
    </div>
  );
}

export default App
