import React from 'react'
import '../../../../Styles/MainStyles/Transactions/Payouts/NoBank.css';

function NoBank({ open, onClose }) {
     if (!open) return null;

     return (
          <>
               <div className="noBank" onClick={(e) => {e.stopPropagation()}}>
                    <div className="noBank__page-container">
                         <p className="noBank__message">
                              Please add a checking or savings bank account to pay out your balance.
                         </p>
                         <button 
                              type="button"
                              className="noBank__button"
                              onClick={onClose}
                         >
                              OKAY
                         </button>
                    </div>
               </div>
          </>
     )
}

export default NoBank