import React from 'react';
import '../../../Styles/AuthStyles/Password/ResentOTP.css';

function ResentOTP({ closeResentOTPModal }) {
     return (
          <>
               <div className="resentOTP">
                    <div className="resentOTP__page-container">
                         <p className="resentOTP__message">
                              Your one-time passcode has been resent successfully.
                         </p>
                         <button 
                              className="resentOTP__button"
                              type="button"
                              onClick={closeResentOTPModal}
                         >
                              OKAY
                         </button>
                    </div>
               </div>
          </>
     )
}

export default ResentOTP